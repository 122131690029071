<template>
  <BaseInfiniteGridTable class="no-border" v-if="table_exam" :tabledata="table_exam" @item-opened="$emit('item-opened')" @loadmore="$emit('loadmore')">
    <template v-slot:column_name="{item}">
      <div class="patient-box">
        <a class="btn btn-secondary" href="#">
          <IconPatients/>
          {{ item.patient.fullName }}
        </a>
        <p>
          <IconCalendar/>
          {{ formatDate(item.date) }}
        </p>
      </div>
    </template>

    <template v-slot:column_captures="{item}">
      <lightgallery-vue
        class="image-info-box grid-wrap-between ease-all" :ref="`lGallery_${item.uuid}`" :id="`lightgallery_${item.uuid}`" :key="item.uuid"
        :settings="lightGallerySettings" :onAfterOpen="extraCustomization" v-if="item.examImages.length > 0" @click.stop.prevent="prevent">
        <template v-for="(item, index) in item.examImages" :key="index">
          <a v-if="allowedImages(item.type) && index < 11" class="thumbnail-box loading-eye" onclick="return false" :class="item.type" :href="data_path +'/'+ item.name" :data-test="`anchorImage_${index}`">
            <img class="lg-selector ease-all" :src="thumb_path +'/'+ item.name" alt="" @load="removeLoading($event)"/>
          </a>
        </template>
      </lightgallery-vue>
    </template>

    <template v-slot:column_content="{item}">
      <div class="custom-box">
        <div class="btn-group" role="group" aria-label="Default button group" v-if="item.hasImage || item.hasReport">
          <button type="button" class="btn btn-outline-secondary" :disabled="!item.hasImage" :data-title="$t('tooltips.image')" :data-test="`btnExamHasImage_${item.uuid}`">
            <IconImage/>
            {{ $t('tooltips.image') }}
          </button>
          <button type="button" class="btn btn-outline-secondary" :disabled="!item.hasReport" :data-title="$t('tooltips.report')" :data-test="`btnExamHasReport_${item.uuid}`">
            <IconPdf/>
            {{ $t('tooltips.report') }}
          </button>
        </div>

        <div class="ai-box" v-if="item.scoreResult" :data-title="$t('exam.iaText')">
          <p>AI</p>
          <span :class="item.scoreResult ? item.scoreResult.odStatus : ''"></span>
          <span :class="item.scoreResult ? item.scoreResult.osStatus : ''"></span>
        </div>
      </div>
    </template>

    <!-- <template v-slot:column_ai="{item}">

    </template> -->
  </BaseInfiniteGridTable>
</template>

<style lang="scss" scoped>
.image-info-box {
  gap:                                10px;

  &.grid-wrap-between {
    grid-template-columns:            repeat(auto-fill, 32.7%) !important;
  }

  .thumbnail-box {
    margin:                           0;
    img {
      aspect-ratio:                   87 / 74;
      // aspect-ratio:                   16 / 13;
    }
  }
}

.rectract {
  .image-info-box.grid-wrap-between {
    grid-template-columns:                repeat(auto-fill, 24%) !important;
  }
}
</style>

<script>
import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from '@/assets/dependencies/lightGallery/plugins/zoom';

export default {
  name: 'ExamGridList',
  props: ['table_exam', 'thumb_path', 'data_path'],
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: null,
    lightGallerySettings: {
      showZoomInOutIcons: true,
      actualSize: false,
      autoplayVideoOnSlide: true,
      speed: 300,
      licenseKey: 'CE440F6C-34CE4C2A-86065648-48895C83',
      plugins: [],
    },
  }),
  created() {
    this.lightGallerySettings.plugins = [lgAutoplay, lgFullscreen, lgThumbnail, lgZoom]
  },
  methods: {
    extraCustomization() {
      let self = this

      $(".lg-download").on("click", function (event) {
        event.preventDefault();
        let examDataUUID = this.href.split('/').pop()
        let item = { uuid: examDataUUID }
        self.doDownload(item)
      })

      $('.lg-fullscreen.lg-icon').click();
    },
    removeLoading(event) {
      let parentNode = event.target.parentElement.classList;
      let currentlyNode = event.target.classList;

      this.$nextTick(() => {
        currentlyNode.add('loaded')
        parentNode.remove('loading-eye')
      });
    },
    allowedImages(image) {
      return image.endsWith('COLOR') || image.endsWith('REDFREE') || image.endsWith('TEXTURE') || image.endsWith('image')
    },
    prevent() {
      // function to avoid click to expand and enter in exam details
      console.log('prevent');
    },
    formatDate(date) {
      return moment(date).format(this.$i18n.t('dateTimeFormat'))
    }
  }
}
</script>
