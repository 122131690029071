<template>
  <div class="row">
    <div class="col-lg-6 flex-center">
      <div class="info-content">
        <h1>
          {{ $t('externalPatient.hello') }} {{ patientName }}
        </h1>

        <p class="clinic-info">
          {{ $t('externalPatient.clinicName') }}
          <b>Clínica Phelcom Technologies.</b>
        </p>
        <!-- TODO YAMANAKA preciso que retorne o nome da clinica na sessao do paciente -->

        <p>
          {{ $t('externalPatient.myInformation') }}
        </p>

        <button class="btn btn-default" type="button" name="button" data-test="btnDontAccept" @click="patientMenu">{{ $t('externalPatient.myInformationTitle') }}</button>
        <button class="btn btn-default" type="button" name="button" data-test="btnDontAccept" @click="logout">Logout</button>
      </div>
    </div>
    <div class="col-lg-6 flex-center">
      <div class="external-content">
        <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
          <span v-html="alert.message"></span>
          <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
        </div>

        <BaseInfiniteGridTable class="no-border" v-if="tableExam" :tabledata="tableExam" @item-opened="examSelected">
          <template v-slot:column_captures="{item}">
            <lightgallery-vue
              class="image-info-box grid-wrap-between ease-all" :ref="`lGallery_${item.uuid}`" :id="`lightgallery_${item.uuid}`" :key="item.uuid"
              :settings="lightGallerySettings" :onAfterOpen="extraCustomization" v-if="item.examImages.length > 0" @click.stop.prevent="prevent">
              <template v-for="(item, index) in item.examImages" :key="index">
                <a v-if="allowedImages(item.type) && index < 4" class="thumbnail-box loading-eye" onclick="return false"
                   :class="item.type" :href="dataPath +'/'+ item.name" :data-test="`anchorImage_${index}`">
                  <img class="lg-selector ease-all" :src="thumbDataPath +'/'+ item.name" alt="" @load="removeLoading($event)"/>
                </a>
              </template>
            </lightgallery-vue>
          </template>

          <template v-slot:column_content="{item}">
            <button type="button" class="btn btn-outline-secondary" :data-test="`btnHasReport_${item.uuid}`" :data-title="$t('tooltips.report')">
              {{ $t('tooltips.report') }}
              <IconDownloadPatient/>
            </button>
            <button type="button" class="btn btn-outline-secondary" data-title="Download" :data-test="`btnDownload_${item.uuid}`" @click.stop.prevent="doDownload(item)">
              {{ $t('tooltips.image') }}
              <IconDownloadPatient/>
            </button>
          </template>
        </BaseInfiniteGridTable>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.col-lg-6 {
  display:                        flex;
  justify-content:                flex-start;
  align-items:                    center;
  > div {
    width:                        100%;
    height:                       100%;
    max-height:                   580px;
    &.info-content {
      display:                    flex;
      justify-content:            flex-start;
      gap:                        26px;
      max-width:                  80%;

      h1,
      p {
        margin:                   0;
      }
      h1 {
        color:                    var(--Brand-Primary, #1B294A);
        font-size:                38px;
        font-weight:              300;
        line-height:              normal;
      }
      p {
        color:                    var(--Brand-Primary, #1B294A);
        font-size:                var(--font-size-14);
        font-weight:              400;
        line-height:              140%;

        &.clinic-info {
          font-weight:            300;
        }

        b {
          font-size:              var(--font-size-14);
          font-weight:            700;
        }
      }

      .btn {
        width:                    100%;
      }
    }

    &:last-child {
      div:not(.image-info-box) {
        display:                  flex;
        flex-direction:           column;
        // align-items:           flex-start;
        justify-content:          center;
        width:                    100%;

        .btn-outline-secondary {
          color:                  var(--Shades-50, #8F8F8F);
          text-align:             center;
          font-size:              10px;
          font-weight:            400;
          line-height:            normal;
          letter-spacing:         2px;
          text-transform:         uppercase;
          display:                flex;
          justify-content:        center;
          align-items:            center;
          gap:                    10px;
          height:                 21px;
          min-height:             unset;
          padding:                5px 10px;
        }
      }
    }
  }
}

.image-info-box {
  &.grid-wrap-between {
    grid-template-columns:        repeat(auto-fill, 32%);
  }
  .thumbnail-box {
    margin:                       0;
    img {
      aspect-ratio:               87 / 74;
    }
  }
}
</style>

<script>
import { useExternalStore } from '@/store/external'
import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from '@/assets/dependencies/lightGallery/plugins/zoom';

const IS_PRODUCTION = process.env.NODE_ENV == 'production'

export default {
  name: 'external-exam-list',
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    tableExam: {
      items: [],
      total: 0,
      columns: []
    },
    perPage: 20,
    currentPage: 1,
    totalPages: 0,
    filter: {},
    patientName: null,
    lightGallerySettings: {
      showZoomInOutIcons: true,
      actualSize: false,
      autoplayVideoOnSlide: true,
      speed: 300,
      licenseKey: 'CE440F6C-34CE4C2A-86065648-48895C83',
      plugins: [],
    },
    thumbDataPath: null,
    dataPath: null,
  }),
  created() {
    // this.addEventResize()
    this.lightGallerySettings.plugins = [lgAutoplay, lgFullscreen, lgThumbnail, lgZoom]
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    extraCustomization() {
      let self = this

      $(".lg-download").on("click", function (event) {
        event.preventDefault();
        let examDataUUID = this.href.split('/').pop()
        let item = { uuid: examDataUUID }
        self.doDownload(item)
      })

      this.removeEventResize()

      $('.lg-fullscreen.lg-icon').click();
    },
    removeLoading(event) {
      let parentNode = event.target.parentElement.classList;
      let currentlyNode = event.target.classList;

      this.$nextTick(() => {
        currentlyNode.add('loaded')
        parentNode.remove('loading-eye')
        // this.getAndSetWidth();
      });
    },
    allowedImages(image) {
      return image.endsWith('COLOR') || image.endsWith('REDFREE') || image.endsWith('TEXTURE') || image.endsWith('image')
    },
    onPageChange(page) {
      this.currentPage = page;
      this.session.examCurrentPage = page;
      this.doFilter(null);
    },
    fetchData: async function() {
      this.alert = {}

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$externalService.listExams()

        const externalStore = useExternalStore()
        let session = externalStore.sessionInfo
        console.log('session', session);
        this.patientName = session.patient.fullName

        this.$nextTick(() => {
          this.loadTableData(response)
        })
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async fetchDataImage(examID) {
      let examImages = []
      this.loading = true
      NProgress.start()
      try {
        let response = await this.$externalService.getExam({id: examID});
        examImages = response.examDataList

        this.thumbDataPath = `${response.thumbDataPath}`
        this.dataPath = `${response.dataPath}`

        // TODO move to service
        if (!IS_PRODUCTION) {
          this.thumbDataPath = `http://localhost:1337${response.thumbDataPath}`
          this.dataPath = `http://localhost:1337${response.dataPath}`
        }

      } catch (err) {
        console.log("OPSSS...", err)
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false

      return examImages
    },
    loadTableData(response) {
      let data = response.result
      data.forEach(async (item) => {
        try {
          item.examImages = await this.fetchDataImage(item.id)
          console.log('dentro do try');
        } catch(error) {
          console.log('img errors', error);
        }
      });


      setTimeout(() => {
        console.log('fora do try');

        this.tableExam = {
          'items': data,
          'total': data.length,
          'columns': [
            {name: this.$i18n.t('exam.date'), type: 'date', value: 'createdAt', width: '50%'},
            {key: 'captures', name: 'Capturas', type: 'custom', value: 'captures', width: '100%'},
            {key: 'content', name: this.$i18n.t('exam.content'), type: 'custom', value: 'actions', width: '50%'},
          ]
        };
      }, 1000)


      console.log('data', data);

      this.isTableReady = true

      console.log('item.examImages', this.tableExam[1].items.examImages);
      if (data.length === 0) {
        this.tableExam = {};
        this.alert = this.$message.alert(this, 'request_notfound', 'warning')
        // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
      }
    },
    formatDate(date) {
      return moment(date).valueOf();
    },
    examSelected(item) {
      if (!item) {
        return
      }

      let data = this.$utils.dataQuery({
        patient_name: item.patient.rawName
      })

      this.$router.push({name: 'external-exam-show', params: {examID: item.id}, query: {tab: '', data: data}})
    },
    doDownload(item) {
      this.alert.message = null;

      this.loading = true
      NProgress.start()
      try {
        window.open(
          `/api/v2/eyercloud/exam/download?id=${item.id}`,
          '_blank'
        );
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    // toPatient() {
    //   this.$router.push({name: 'external-patient-show'})
    // },
    patientMenu: async function() {
      this.goToLink(this, 'patient', 'external-patient-show')
    },
    async logout() {
      await this.$externalService.accessLogout(this)
      window.open('/acesso', '_self');
    },
    goToLink(self, active, link) {
      self.active = active
      self.$router.push({name: link, query: { ...self.$route.query, t: Date.now() }})
    },
    prevent() {
      // function to avoid click to expand and enter in exam details
      console.log('prevent');
    }
  },
  // unmounted() {
  //   this.alert.message = null;
  //   this.tableExam = [];
  // }
}
</script>
