<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect x="2.75" y="4.75" width="7.5" height="14.5" rx="1.25" stroke="#1B294A" stroke-width="1.5"/>
    <rect x="13.75" y="4.75" width="7.5" height="14.5" rx="1.25" stroke="#1B294A" stroke-width="1.5"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconExamsSimple'
}
</script>
