<template>
  <div class="">
    <h3 class="mb-3">{{ $t('createAccount.confirmInformation') }}</h3>
    <p class="mb-3">{{ $t('createAccount.confirmClinicInformation') }}</p>

    <p class="clinic-info mb-3">
      <span>{{ $t('createClinic.clinicName') }}</span>
      {{ clinic.clinicName }}
    </p>

    <p class="clinic-info mb-3">
      <span>{{ $t('createAccount.emailContact') }}</span>
      {{ clinic.email }}
    </p>

    <p class="clinic-info mb-3">
      <span>{{ `${$t('createAccount.telephone')}/${$t('createAccount.mobile')}` }}</span>
      {{ clinic.telephone }}
    </p>

    <!-- <p class="clinic-info mb-3">
      <span>{{ $t('createAccount.mobile') }}</span>
      {{ clinic.mobile }}
    </p> -->

    <!-- <p class="clinic-info mb-3">
      <span>{{ $t('createAccount.telephone3') }}</span>
      {{ clinic.telephone3 }}
    </p> -->

    <p class="clinic-info mb-3">
      <span>{{ $t('createClinic.mandatoryDocument') }}</span>
      {{ clinic.mandatoryDocument }}
    </p>

    <!-- <p class="clinic-info mb-3">
      <span>{{ $t('createClinic.language') }}</span>
      {{ clinic.locale }}
    </p> -->

    <p class="clinic-info mb-3">
      <span>{{ $t('createClinic.address') }}</span>
      {{ clinic.street }}
    </p>

    <p class="clinic-info mb-3">
      <span>{{ `${$t('createClinic.city')}/${$t('createClinic.state')} - ${$t('createClinic.country')}` }}</span>
      {{ `${clinic.city}/${clinic.state} - ${clinic.country}` }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.clinic-info {
  color:                         var(--Shades-80, #232323);
  font-size:                     var(--font-size-14);
  font-weight:                   500;
  line-height:                   normal;
  letter-spacing:                0.7px;
  display:                       flex;
  flex-direction:                column;
  justify-content:               center;
  flex-shrink:                   0;
  align-self:                    stretch;
  overflow:                      hidden;
  text-overflow:                 ellipsis;
  line-height:                   normal;
  white-space:                   nowrap;

  span {
    color:                       var(--Shades-50, #8F8F8F);
    font-size:                   var(--font-size-10);
    letter-spacing:              1.5px;
    text-transform:              uppercase;
    padding-bottom:              5px;
  }
}
</style>

<script>
module.exports = {
  name: 'RegistrationClinicStepThree',
  props: ['clinic'],
  mounted() {
    this.$nextTick(() => {
      this.$refs.phone.focus()
    })
  }
}
</script>
