<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M9 1V17" stroke="black" stroke-linecap="round"/>
    <path d="M1 9L17 9" stroke="black" stroke-linecap="round"/>
  </svg>
  <!-- <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
    <path d="M20.1553 10L20.0227 30.1822" stroke="url(#paint0_linear_842_1875)" stroke-width="2"/>
    <path d="M10 20.2485L30.1801 19.9328" stroke="url(#paint1_linear_842_1875)" stroke-width="2"/>
    <defs>
      <linearGradient id="paint0_linear_842_1875" x1="29.4855" y1="20.3014" x2="10.6371" y2="22.2421" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1B294A"/>
        <stop offset="1" stop-color="#3CB391"/>
      </linearGradient>
      <linearGradient id="paint1_linear_842_1875" x1="20.0917" y1="10.6919" x2="22.4505" y2="29.4925" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1B294A"/>
        <stop offset="1" stop-color="#3CB391"/>
      </linearGradient>
    </defs>
  </svg> -->
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconPlus'
}
</script>
