<template>
  <div class="sidenav" :class="isOpen ? 'open' : ''" data-bs-backdrop="static"
        id="offCanvasPatientAdd" aria-labelledby="offCanvasPatientAddLabel">
    <div class="offcanvas-header">
      <button class="btn-back" id="btnCloseOffcanvas" type="button" name="button" data-test="btnCloseOffcanvas"
              @click="isNewPatient ? isNewPatient = false : closePatient(null)">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" id="offCanvasPatientAddLabel">Pesquisar paciente</h5>
    </div>

    <ExamPatientAddFormData v-if="isNewPatient" @patient-created="closePatient"/>

    <div class="offcanvas-body" v-else>
      <p>Pesquise um paciente para adicionar no exame</p>

      <div class="form-floating">
        <input class="form-control" type="text" id="searchPatient" placeholder="Digite o nome..." v-model="search" @keypress.enter="doSearch">
        <label for="searchPatient">{{ $t('patientSearch.patientSearchPlaceholder') }}</label>
        <button class="btn-icon" type="button" name="button" data-test="btnSearchPatient" @click="fetchData">
          <IconSearch/>
        </button>
      </div>

      <ul class="scrollbar">
        <!-- precisa de um novo endpoint trazendo todos os pacientes sem paginar -->
        <li v-for="(item, index) in patientList" @click="closePatient(item)">
          {{ item.fullName }}
        </li>
      </ul>

      <p class="patient-selected" v-if="patient_name">{{ patient_name }}</p>

      <p>Se não tiver o paciente, você pode adicionar clicando abaixo</p>

      <!-- footer inside body to avoid empty space between body and footer -->
      <div class="offcanvas-footer">
        <button type="button" class="btn btn-success" data-test="btnAdd" @click="isNewPatient = true">Adicionar paciente</button>
      </div>
    </div>

    <teleport to="body">
      <div v-if="isOpen" class="offcanvas-backdrop fade show"></div>
    </teleport>

  </div>
</template>

<style lang="scss" scoped>
.sidenav {
  color:                                var(--bs-offcanvas-color);
  position:                             absolute;
  display:                              flex;
  height:                               0;
  flex-direction:                       column;
  width:                                0 !important;
  padding:                              0;
  background-color:                     white;
  background-clip:                      padding-box;
  transition:                           transform 0.3s ease-in-out;
  z-index:                              1050;
  visibility:                           hidden;
  transform:                            translateX(100%);

  * {
    display:                            none;
  }

  .offcanvas-header {
    display:                            none !important;
  }

  &.open {
    visibility:                         visible;
    height:                             100vh;
    padding:                            40px;
    width:                              510px !important;
    border-left:                        var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    right:                              0;
    bottom:                             0;
    top:                                0;
    pointer-events:                     auto;
    transform:                          none;

    * {
      display:                          flex;
    }

    .offcanvas-header,
    ul,
    .date-box * {
      width:                            100%;
      display:                          block !important;
    }

    *,
    .offcanvas-header,
    ul,
    .date-box * {
      @include                          animation(fadein 0.5s);
    }
  }

  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    p:not(.patient-selected) {
      color:                            var(--Brand-Primary, #1B294A);
      font-size:                        var(--font-size-14);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      margin-bottom:                    0;
    }

    .patient-selected {
      @include                          border_radius(99px);
      color:                            var(--Brand-Primary, #1B294A);
      text-align:                       center;
      font-size:                        18px;
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   0.9px;
      display:                          flex;
      justify-content:                  center;
      align-items:                      center;
      height:                           55px;
      margin-bottom:                    0;
      padding:                          20px;
      border:                           2px solid var(--Brand-Primary, #1B294A);
      background:                       var(--Shades-10, #FAFAFA);
    }

    .btn-icon {
      padding:                          0;
      border:                           0;
    }

    ul {
      list-style-type:                  none;
      height:                           100%;
      max-height:                       calc(100vh - 470px);
      margin-bottom:                    0;
      padding:                          0 10px 0 0;
      overflow-y:                       auto;

      li {
        @include                        border_radius(10px);
        color:                          var(--Brand-Primary, #1B294A);
        font-size:                      var(--font-size-14);
        font-weight:                    400;
        line-height:                    normal;
        letter-spacing:                 0.7px;
        text-overflow:                  ellipsis;
        overflow:                       hidden;
        margin-bottom:                  10px;
        padding:                        10px;
        background:                     var(--Shades-10, #FAFAFA);
        border:                         1px solid var(--Shades-20, #F0F0F0);
        cursor:                         pointer;

        &:last-child {
          margin:                       0;
        }
      }
    }
  }

  .btn {
    font-size:                          var(--font-size-14);
    font-weight:                        700;
    line-height:                        normal;
    letter-spacing:                     4.2px;
    text-align:                         center;
    text-transform:                     uppercase;
    width:                              100%;
    margin:                             0;
    padding:                            20px;
  }

  .offcanvas-footer {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    .btn-success {
      color:                            var(--Shades-W, #FFF);
    }
  }
}
</style>

<script>
import ExamPatientAddFormData from './ExamPatientAddFormData.vue'

export default {
  name: 'ExamPatientAddOffcanvas',
  components: {
    ExamPatientAddFormData
  },
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    isOpen: false,
    isNewPatient: false,
    patientList: {},
    search: null,
  }),
  mounted() {
    this.fetchData()

    document.getElementById('searchPatient').focus();
  },
  methods: {
    closePatient(patient) {
      this.isOpen = false
      this.$emit('offcanvas-patient', patient)
    },
    openPatient() {
      this.isOpen = true
    },
    async fetchData(pageValue) {
      console.log('fetchdata');
      this.alert = {}

      // TODO yamanaka: endpoint trazendo uma lista completa dos pacientes ? ou usar scroll infinito ?
      let params = {
        page: 1
      }

      if (this.search)
        params.q = this.search

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$patientService.getList(params)
        this.patientList = response.result

        if (response.result.length === 0) {
          this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async doSearch() {
      this.alert = {}

      let params = {
        // page: this.currentPage
        q: this.search
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$patientService.getList(params)
        this.patientList = response.result

        if (response.result.length === 0) {
          // this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
      NProgress.done(true)
      this.loading = false
    },

  }
}
</script>
