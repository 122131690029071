<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M15.25 7C15.25 8.79493 13.7949 10.25 12 10.25C10.2051 10.25 8.75 8.79493 8.75 7C8.75 5.20507 10.2051 3.75 12 3.75C13.7949 3.75 15.25 5.20507 15.25 7Z" stroke="#1B294A" stroke-width="1.5"/>
    <path d="M5 20V18C5 15.7909 6.79086 14 9 14H15C17.2091 14 19 15.7909 19 18V20" stroke="#1B294A" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconPatientsSimple'
}
</script>
