<template>
  <div class="row">
    <div class="col flex-between">
      <div class="">
        <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
          <IconArrowBack/>
          {{ $t('goBack') }}
        </button>

        <h1>{{ $t('externalPatient.myInformationTitle') }}</h1>

        <!-- <div class="action-buttons rounded text-end">
          <button class="btn btn-outline-secondary" type="button" name="button" data-test="btnOpenModalChangePassword" @click="openChangePassword()">
            <span>{{ $t('user.changePassword') }}</span>
          </button>
          <button class="btn btn-outline-secondary" type="button" name="button" data-test="btnOpenModalChangeEmail" @click="openChangeEmail()">
            <span>{{ $t('email.changeEmail') }}</span>
          </button>
        </div> -->

        <!-- <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
          <span v-html="alert.message"></span>
          <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
        </div> -->

        <div class="form-floating">
          <input type="text" class="form-control" name="patient[firstName]" ref="firstName" id="firstName" :placeholder="$t('user.firstName')" v-model="patientData.firstName" data-test="inputPatientFirstName" disabled>
          <label for="firstName">{{ $t('user.firstName') }}</label>
        </div>
        <div class="form-floating">
          <input type="text" class="form-control" id="lastName" name="patient[lastName]" :placeholder="$t('user.lastName')" v-model="patientData.lastName" data-test="inputPatientLastName" disabled>
          <label for="lastName">{{ $t('user.lastName') }}</label>
        </div>
        <div class="form-floating">
          <input type="text" class="form-control" id="telephone1" name="patient[telephone1]" :placeholder="$t('patient.telephone1')" v-model="patientData.telephone1" data-test="inputPatientTelephone">
          <label for="document">{{ $t('patient.telephone1') }}</label>
        </div>
        <div class="form-floating">
          <input type="text" class="form-control" id="email" name="user[email]" :placeholder="$t('user.email')" v-model="user.email" data-test="inputPatientEmail">
          <label for="email">{{ $t('user.email') }}</label>
        </div>
        <div class="form-floating">
          <input type="text" class="form-control" id="document" name="patient[document]" :placeholder="$t('user.document')" v-model="patientData.document" data-test="inputPatientDocument">
          <label for="document">{{ $t('user.document') }}</label>
        </div>
      </div>
    </div>

    <teleport to="body">
      <ExternalPatientChangeEmailModal v-if="showChangeEmail" @close="showChangeEmail = false"></ExternalPatientChangeEmailModal>

      <ExternalPatientChangePasswordModal v-if="showChangePassword" @close="showChangePassword = false"></ExternalPatientChangePasswordModal>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.row {
  height:                         100%;
}

.col {
  flex-direction:                 column;
  > div:not(.flex-center) {
    display:                      flex;
    flex-direction:               column;
    align-items:                  flex-start;
    gap:                          26px;
    max-width:                    440px;
    padding-bottom:               2rem;
    padding-top:                  1rem;
  }
  h1 {
    color:                        var(--Brand-Primary);
    font-size:                    38px;
    font-weight:                  300;
    line-height:                  normal;
    margin:                       0;
  }
  p {
    color:                        var(--Brand-Primary);
    font-size:                    var(--font-size-14);
    font-weight:                  300;
    line-height:                  140%; /* 19.6px */
  }
  .form-floating {
    width:                        100%;
  }
}
</style>

<script>
import { useExternalStore } from '@/store/external'

import ExternalPatientChangeEmailModal from './ExternalPatientChangeEmailModal.vue'
import ExternalPatientChangePasswordModal from './ExternalPatientChangePasswordModal.vue'

export default {
  name: 'external-patient-show',
  components: {
    ExternalPatientChangeEmailModal,
    ExternalPatientChangePasswordModal,
  },
  data: () => ({
    alert: {},
    showChangeEmail: false,
    showChangePassword: false,
    patientData: {},
    user: {},
    showEmail: false
  }),
  mounted() {
    const externalStore = useExternalStore()
    this.patientData = externalStore.sessionInfo.patient
    this.user = externalStore.sessionInfo.user

    console.log(this.patientData);

    this.showEmail = !this.user.email.endsWith("@null.com")
  },
  methods: {
    goBack() {
      this.$router.push({name: 'external-exam-list'})
    },
    openChangePassword() {
      this.showChangePassword = true

      setTimeout(function() {
        new bootstrap.Modal(change_password_modal).show();
      }, 100);
    },
    openChangeEmail() {
      this.showChangeEmail = true

      setTimeout(function() {
        new bootstrap.Modal(change_email_modal).show();
      }, 100);
    },
  }
}
</script>
