<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="27" viewBox="0 0 31 27" fill="none">
    <rect x="5.91821" y="0.712799" width="21.6463" height="25.0109" rx="1.5" stroke="#3CB391"/>
    <path d="M8.98388 4.11922H1.88647C1.33419 4.11922 0.886475 4.56693 0.886475 5.11922V7.20665C0.886475 7.75893 1.33419 8.20665 1.88647 8.20665H8.98388C9.53616 8.20665 9.98388 7.75893 9.98388 7.20665V5.11922C9.98388 4.56693 9.53616 4.11922 8.98388 4.11922Z" stroke="#1B294A"/>
    <path d="M8.98393 11.2722H6.85107C6.29879 11.2722 5.85107 11.7199 5.85107 12.2722V14.3596C5.85107 14.9119 6.29879 15.3596 6.85107 15.3596H8.98393C9.53621 15.3596 9.98393 14.9119 9.98393 14.3596V12.2722C9.98393 11.7199 9.53621 11.2722 8.98393 11.2722Z" stroke="#1B294A"/>
    <path d="M16.2156 4.11922H14.0828C13.5305 4.11922 13.0828 4.56693 13.0828 5.11922V7.20665C13.0828 7.75893 13.5305 8.20665 14.0828 8.20665H16.2156C16.7679 8.20665 17.2156 7.75893 17.2156 7.20665V5.11922C17.2156 4.56693 16.7679 4.11922 16.2156 4.11922Z" stroke="#1B294A"/>
    <path d="M19.8325 25.4361V25.9361H20.3325V25.4361H19.8325ZM13.6509 25.4361H13.1509V25.9361H13.6509V25.4361ZM18.8325 18.8965H14.6509V19.8965H18.8325V18.8965ZM20.3325 20.3965C20.3325 19.568 19.6609 18.8965 18.8325 18.8965V19.8965C19.1086 19.8965 19.3325 20.1203 19.3325 20.3965H20.3325ZM20.3325 25.4361V20.3965H19.3325V25.4361H20.3325ZM19.8325 24.9361H13.6509V25.9361H19.8325V24.9361ZM13.1509 20.3965V25.4361H14.1509V20.3965H13.1509ZM14.6509 18.8965C13.8225 18.8965 13.1509 19.568 13.1509 20.3965H14.1509C14.1509 20.1203 14.3747 19.8965 14.6509 19.8965V18.8965Z" fill="#1B294A"/>
    <path d="M16.2156 11.2722H14.0828C13.5305 11.2722 13.0828 11.7199 13.0828 12.2722V14.3596C13.0828 14.9119 13.5305 15.3596 14.0828 15.3596H16.2156C16.7679 15.3596 17.2156 14.9119 17.2156 14.3596V12.2722C17.2156 11.7199 16.7679 11.2722 16.2156 11.2722Z" stroke="#1B294A"/>
    <path d="M23.448 4.11922H21.3152C20.7629 4.11922 20.3152 4.56693 20.3152 5.11922V7.20665C20.3152 7.75893 20.7629 8.20665 21.3152 8.20665H23.448C24.0003 8.20665 24.448 7.75893 24.448 7.20665V5.11922C24.448 4.56693 24.0003 4.11922 23.448 4.11922Z" stroke="#1B294A"/>
    <path d="M29.3912 11.2722H21.3152C20.7629 11.2722 20.3152 11.7199 20.3152 12.2722V14.3596C20.3152 14.9119 20.7629 15.3596 21.3152 15.3596H29.3912C29.9435 15.3596 30.3912 14.9119 30.3912 14.3596V12.2722C30.3912 11.7199 29.9435 11.2722 29.3912 11.2722Z" stroke="#1B294A"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconClinics'
}
</script>
