<template>
  <div class="row">
    <div class="col flex-center">
      <div class="">
        <h1>{{ $t('externalPatient.dataConfirmTitle') }}</h1>

        <p class="mb-0">{{ $t('externalPatient.dataConfirmInfo') }}</p>

        <div class="form-floating" :class="patientData.telephone1 == '' ? 'warning' : ''">
          <input type="text" class="form-control" id="telephone1" name="patient[telephone1]" :placeholder="$t('patient.telephone1')" v-model="patientData.telephone1" data-test="inputPatientTelephone">
          <label for="document">{{ $t('patient.telephone1') }}</label>
          <IconWarning v-if="patientData.telephone1 == ''"/>
          <small v-if="patientData.telephone1 == ''">{{ $t('externalPatient.missingInformation') }}</small>
        </div>
        <div class="form-floating" v-if="!confirmEmail">
          <input type="text" class="form-control" id="email" name="user[email]" :placeholder="$t('user.email')" v-model="newEmail" data-test="inputPatientEmail">
          <label for="email">{{ $t('user.email') }}</label>
          <IconWarning v-if="newEmail == ''"/>
          <small v-if="newEmail == ''">{{ $t('externalPatient.missingInformation') }}</small>
        </div>

        <div v-else>
          <p>{{ $t('email.linkSent') }} <strong>{{ newEmail }}</strong>.</p>
          <p>{{ $t('email.insertCode') }}</p>
          <div class="form-floating">
            <vee-field name="verificationCode" v-model="verificationCode" rules="required" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="password" class="form-control" id="verificationCode" :placeholder="$t('email.verificationCode')" data-test="inputPatientVerificationCode">
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="verificationCode">{{ $t('email.verificationCode') }}</label>
          </div>
        </div>

        <div class="form-floating" v-if="this.newEmail != this.user.email && !confirmEmail">
          <vee-field name="currentPassword" v-model="currentPassword" rules="required" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="password" class="form-control" id="currentPassword" :placeholder="$t('user.currentPassword')" data-test="inputPatientCurrentPassword">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
          <label for="currentPassword">{{ $t('user.currentPassword') }}</label>
        </div>


        <button class="btn btn-success" type="submit" name="button" data-test="btnCorrect" v-if="!confirmEmail" @click="registerNewEmail">{{ $t('externalPatient.isCorrect') }}</button>
        <button class="btn btn-success" type="submit" name="button" data-test="btnCorrect" v-else @click="confirmNewEmail">{{ $t('confirm') }}</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.row {
  height:                         100%;
}

.col {
  flex-direction:                 column;
  > div:not(.flex-center) {
    display:                      flex;
    flex-direction:               column;
    align-items:                  flex-start;
    gap:                          26px;
    max-width:                    440px;
    padding-bottom:               2rem;
    padding-top:                  1rem;
  }
  h1 {
    color:                        var(--Brand-Primary);
    font-size:                    38px;
    font-weight:                  300;
    line-height:                  normal;
    margin:                       0;
  }
  p {
    color:                        var(--Brand-Primary);
    font-size:                    var(--font-size-14);
    font-weight:                  300;
    line-height:                  140%; /* 19.6px */
  }
  .form-floating {
    width:                        100%;
    position:                     relative;

    small {
      position:                   absolute;
      bottom:                     -18px;
      left:                       5px;
    }

    svg {
      position:                   absolute;
      right:                      10px;
      top:                        20px;
    }
  }
}
</style>

<script>
import { useExternalStore } from '@/store/external'

export default {
  name: 'ExternalDataConfirm',
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    patientData: {},
    user: {},
    currentPassword: "",
    newEmail: "",
    confirmEmail: false,
    activationCode: null,
    verificationCode: null,
  }),
  mounted() {
    const externalStore = useExternalStore()
    this.patientData = externalStore.sessionInfo.patient
    this.user = externalStore.sessionInfo.user
    this.newEmail = this.user.email
  },
  methods: {
    async registerNewEmail() {
      let params = {
        email: this.newEmail,
        password: this.currentPassword
      }

      if (this.newEmail == this.user.email)
        this.$router.push("/external/exam");

      NProgress.start()
      try {
        let result = await this.$externalService.registerNewEmail(params)
        console.log('result', result);
        this.confirmEmail = true
        this.activationCode = result.activationCode;
      } catch(err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    },
    async confirmNewEmail() {
      let params = {
        activationCode: this.activationCode,
        verificationCode: this.verificationCode
      }

      NProgress.start()
      try {
        let result = await this.$externalService.confirmNewEmail(params)

        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')

        this.$nextTick(() => {
          this.$router.push("/external/exam");
        })
      } catch(err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    },
  }
}
</script>
