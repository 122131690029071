<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path d="M8.04343 2.99036C8.23588 2.65702 8.717 2.65702 8.90945 2.99036L13.8666 11.5764C14.059 11.9097 13.8185 12.3264 13.4336 12.3264H3.51931C3.13441 12.3264 2.89385 11.9097 3.0863 11.5764L8.04343 2.99036Z" stroke="#FFF8EC"/>
    <path d="M8.42676 5.7738V9.06189" stroke="#FFF8EC"/>
    <path d="M8.42676 9.86646V10.9159" stroke="#FFF8EC"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconWarning'
}
</script>
