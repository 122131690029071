<template>
  <div class="external-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <div class="">
            <h6>{{ $t('createAccount.welcome') }}</h6>
            <img src="@/assets/images/phelcom/eyercloud_logo_white.svg" alt="Eyercloud logo">
          </div>
        </div>
        <div class="col-lg-5 flex-between">
          <div class=""></div>

          <div class="external-content">
            <h3 class="mb-3">{{ $t('createAccount.clinicRegistration') }}</h3>

            <h3 class="mb-3">
              <span>{{ firstName }}</span>,
              {{ $t('createAccount.congratulation') }}
            </h3>

            <p class="mb-3">{{ $t('createAccount.clickBelow') }}</p>

            <a href="/login" class="btn btn-success" data-test="anchorLogin">{{ $t('login.login') }}</a>
          </div>

          <div class="powered-box">
            <p class="d-inline-block">POWERED BY</p>
            <a href="https://phelcom.com/" class="logo d-inline-block" target="_blank" data-test="anchorPhelcomLink">
              <img src="@/assets/images/phelcom/phelcom_logo_horizontal_blue.svg" alt="">
            </a>
            <p class="d-inline-block">v {{ appVersion }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.col-lg-5 {
  h3 {
    span {
      font-weight:              700;
    }
  }
}
</style>

<script>
  export default {
    name: 'registration-clinic-created',
    data: () => ({
      firstName: ""
    }),
    mounted() {
      let data = this.$utils.parseDataQuery(this.$route.query.data)
      this.firstName = data.firstName
    },
  }
</script>
