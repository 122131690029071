<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
    <rect x="0.5" y="1" width="4.07692" height="4.07692" stroke="#8F8F8F"/>
    <rect x="0.5" y="7.92285" width="4.07692" height="4.07692" stroke="#8F8F8F"/>
    <rect x="7.4231" y="1" width="4.07692" height="4.07692" stroke="#8F8F8F"/>
    <rect x="7.4231" y="7.92285" width="4.07692" height="4.07692" stroke="#8F8F8F"/>
  </svg>
</template>

<style lang="css" scoped>
</style>

<script>
export default {
  name: 'IconGrid'
}
</script>
