<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
    <path d="M10.845 8.3031H3.74756C3.19527 8.3031 2.74756 8.75081 2.74756 9.3031V11.3905C2.74756 11.9428 3.19527 12.3905 3.74756 12.3905H10.845C11.3972 12.3905 11.845 11.9428 11.845 11.3905V9.3031C11.845 8.75082 11.3972 8.3031 10.845 8.3031Z" stroke="white"/>
    <path d="M10.8448 15.4561H8.71191C8.15963 15.4561 7.71191 15.9038 7.71191 16.4561V18.5435C7.71191 19.0958 8.15963 19.5435 8.71191 19.5435H10.8448C11.3971 19.5435 11.8448 19.0958 11.8448 18.5435V16.4561C11.8448 15.9038 11.3971 15.4561 10.8448 15.4561Z" stroke="#72DBB8"/>
    <path d="M18.0767 8.3031H15.9438C15.3916 8.3031 14.9438 8.75081 14.9438 9.3031V11.3905C14.9438 11.9428 15.3916 12.3905 15.9438 12.3905H18.0767C18.629 12.3905 19.0767 11.9428 19.0767 11.3905V9.3031C19.0767 8.75082 18.629 8.3031 18.0767 8.3031Z" stroke="white"/>
    <path d="M8.19487 22.6096H6.06201C5.50973 22.6096 5.06201 23.0573 5.06201 23.6096V25.697C5.06201 26.2493 5.50973 26.697 6.06201 26.697H8.19487C8.74715 26.697 9.19487 26.2493 9.19487 25.697V23.6096C9.19487 23.0573 8.74715 22.6096 8.19487 22.6096Z" stroke="#72DBB8"/>
    <path d="M18.0767 15.4561H15.9438C15.3916 15.4561 14.9438 15.9038 14.9438 16.4561V18.5435C14.9438 19.0958 15.3916 19.5435 15.9438 19.5435H18.0767C18.629 19.5435 19.0767 19.0958 19.0767 18.5435V16.4561C19.0767 15.9038 18.629 15.4561 18.0767 15.4561Z" stroke="white"/>
    <path d="M27.9585 22.6096H25.8257C25.2734 22.6096 24.8257 23.0573 24.8257 23.6096V25.697C24.8257 26.2493 25.2734 26.697 25.8257 26.697H27.9585C28.5108 26.697 28.9585 26.2493 28.9585 25.697V23.6096C28.9585 23.0573 28.5108 22.6096 27.9585 22.6096Z" stroke="white"/>
    <path d="M25.3091 8.3031H23.1763C22.624 8.3031 22.1763 8.75081 22.1763 9.3031V11.3905C22.1763 11.9428 22.624 12.3905 23.1763 12.3905H25.3091C25.8614 12.3905 26.3091 11.9428 26.3091 11.3905V9.3031C26.3091 8.75082 25.8614 8.3031 25.3091 8.3031Z" stroke="#72DBB8"/>
    <path d="M31.2523 15.4561H23.1763C22.624 15.4561 22.1763 15.9038 22.1763 16.4561V18.5435C22.1763 19.0958 22.624 19.5435 23.1763 19.5435H31.2523C31.8046 19.5435 32.2523 19.0958 32.2523 18.5435V16.4561C32.2523 15.9038 31.8046 15.4561 31.2523 15.4561Z" stroke="#72DBB8"/>
    <path d="M20.7259 22.6096H12.6499C12.0976 22.6096 11.6499 23.0573 11.6499 23.6096V25.697C11.6499 26.2493 12.0976 26.697 12.6499 26.697H20.7259C21.2782 26.697 21.7259 26.2493 21.7259 25.697V23.6096C21.7259 23.0573 21.2782 22.6096 20.7259 22.6096Z" stroke="white"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconDashboard'
}
</script>
