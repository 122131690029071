<template>
  <div class="patients-main">
    <div class="sub-header-box">
      <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>

      <nav style="--bs-breadcrumb-divider: '';" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/patient/" data-test="btnRouter">
              {{ $t('home') }}
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ patientName }}</li>
        </ol>
      </nav>
    </div>

    <div class="title-box">
      <div class="flex-start">
        <h2 class="title">{{ patientName }}</h2>

        <div id="btnSharePatientShowSlot"></div>
      </div>

      <div class="flex-end" id="btnDeletePatientShowSlot"></div>
    </div>

    <BaseTab :tabs="tabs" :initialtab="tabActive" :key="tabActive" @tab-activated="switchTab">
      <template v-slot:tab-head-data>
        {{ $t('patient.data') }}
      </template>
      <template v-slot:data>
        <PatientInfoTab v-if="tabActive === 'data' && isDataReady" :patient_id="patientId" @patient_name="addPatientName"></PatientInfoTab>
      </template>

      <template v-slot:tab-head-exams>
        {{ $t('patient.exams') }}
      </template>
      <template v-slot:exams>
        <PatientExamTab v-if="tabActive === 'exams'" :patient_id="patientId" :patient_name="patientName" @patient_name="addPatientName"></PatientExamTab>
      </template>

      <template v-slot:tab-head-records>
        {{ $t('patient.medicalRecord') }}
      </template>
      <template v-slot:records>
        <PatientMedicalRecordListTab v-if="tabActive === 'records'" :patient_id="patientId" :patient_name="patientName"></PatientMedicalRecordListTab>
      </template>
    </BaseTab>

    <!-- <div class="action-bar">
      <div id="patientActionBar"></div>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.patients-main {
  .sub-header-box {
    display:                         flex;
    flex-direction:                  row;
    justify-content:                 flex-start;
    align-items:                     center;
    gap:                             20px;
    width:                           100%;
    padding:                         15px 20px;

    ol {
      margin:                        0;
    }
  }

  .title-box {
    display:                         flex;
    flex-direction:                  row;
    align-items:                     center;
    justify-content:                 space-between;
    height:                          55px;
    padding:                         10px 25px;
    // margin:                          2.3rem 0 1.7rem;

    > div {
      width:                         auto !important;
    }
  }
}

.btn-outline-secondary {
  @include                           border_radius(99px);
  color:                             var(--Brand-Primary, #1B294A);
  font-size:                         12px;
  font-weight:                       400;
  line-height:                       normal;
  letter-spacing:                    3.12px;
  text-transform:                    uppercase;
  display:                           flex;
  align-items:                       center;
  gap:                               10px;
  width:                             auto;
  // margin:                            0 0 0 20px;
  margin-left:                       20px;
  margin-bottom:                     0 !important;
  padding:                           10px 15px 10px 10px;
  border:                            1px solid var(--Shades-30, #D8D8D8);
  background:                        var(--Shades-10, #FAFAFA);
}

.inside-box {
  &::-webkit-scrollbar {
    display:                         none;
  }
  // overflow-y: hidden !important;
}
</style>

<script>
import { useUserStore } from '@/store/user'
import PatientMedicalRecordListTab from './PatientMedicalRecordListTab.vue'
import PatientInfoTab from './PatientInfoTab.vue'
import PatientExamTab from './PatientExamTab.vue'
import PatientExamNewOffcanvas from './PatientExamNewOffcanvas.vue'

export default {
  name: 'patient-show',
  components: {
    PatientMedicalRecordListTab,
    PatientInfoTab,
    PatientExamTab,
    PatientExamNewOffcanvas
  },
  data: () => ({
    loading: null,
    alert: {
      message: null,
      class: null
    },
    isSpecialist: null,
    tabActive: 'data',
    tabs: ['data'],
    patientName: null,
    isDataReady: false,
    showOffcanvasNewExam: false,
  }),
  watch: {
    patientId: {
      immediate: true,
      handler: function (patient_id) {
        if (patient_id){
          this.addTabs()
        }
    }, deep: true }
  },
  computed: {
    patientId() {
      return this.$route.params.patientID;
    },
    tab() {
      return this.$route.query.tab;
    },
  },
  mounted() {
    this.$activeLink.value = 'patient'

    if (this.tab) {
      this.tabActive = this.tab;
    }

    this.isDataReady = true
  },
  methods: {
    switchTab(tab) {
      this.tabActive = tab;
    },
    goBack() {
      this.$router.push({name: 'patient-list'})
      // this.$router.go(-1)
    },
    addTabs() {
      const userStore = useUserStore()
      this.session = userStore.sessionInfo
      this.isSpecialist = this.session.isSpecialist

      if (this.tabs.includes('exams') && this.tabs.includes('records'))
        return

      if (!this.tabs.includes('exams'))
        this.tabs.push('exams')

      const showMedicalRecord = this.$utils.isFeatureEnabled('medicalRecord')
      if (!this.isSpecialist && showMedicalRecord) {
        this.tabs.push('records')
      }
    },
    addPatientName(value) {
      this.patientName = value
    },
    openOffCanvasNewExam() {
      this.showOffcanvasNewExam = true

      setTimeout(function() {
        new bootstrap.Offcanvas(offCanvasNewExam).show();
      }, 100);
    },
  }
}
</script>
