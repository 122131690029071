<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11" fill="none">
    <path d="M0.5 1H14" stroke="#3CB391"/>
    <path d="M0.5 5.5H14" stroke="#3CB391"/>
    <path d="M0.5 10H14" stroke="#3CB391"/>
  </svg>
</template>

<style lang="css" scoped>
</style>

<script>
export default {
  name: 'IconList'
}
</script>
