<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect x="1.75" y="4.75" width="20.5" height="14.5" rx="1.25" stroke="#1B294A" stroke-width="1.5"/>
    <path d="M6 8V16.5" stroke="#1B294A" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M10 8V13.5" stroke="#1B294A" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M14 8V16.5" stroke="#1B294A" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M18 8V16.5" stroke="#1B294A" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconReports'
}
</script>
