<template>
  <div class="accordion" :class="{current : isOpen}">
    <div class="accordion-header">
      <slot name="header"></slot>
      <button class="no-btn" type="button" name="button" :data-title="'Expandir'" data-test="btnAccordion" @click="isOpen = !isOpen">
        <i class="fa fa-arrow-circle-o-down" :class="{'spin': isOpen}" aria-hidden="true"></i>
      </button>
    </div>
    <div class="accordion-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.accordion {
  @include                        border_radius(10px);
  color:                          #4e4e4e;
  text-align:                     left;
  width:                          100%;
  max-height:                     40px;
  margin-bottom:                  15px;
  padding:                        6px 10px;
  background-color:               white;
  border:                         1px solid #ccc;
  outline:                        none;
  &.current,
  table {
    overflow:                     hidden;
  }
  .accordion-header {
    line-height:                  2;
    .no-btn {
      float:                      right;
      margin:                     0;
      padding:                    0;
      i {
        @include                  transition(.4s);
        font-size:                2em;
        display:                  inline-block;
        &.spin {
          @include                rotate(-180deg);
        }
      }
    }
    h4 {
      display:                    inline-block;
      margin:                     0;
      vertical-align:             middle;
    }
  }
  .accordion-content {
    display:                      none;
    opacity:                      0;
  }
  hr {
    margin-top:                   5px;
    margin-bottom:                5px;
  }
  &.current {
    @include                      transition(max-height .4s ease-in);
    max-height:                   100%;
    .accordion-content {
      @include                    transition(opacity .4s);
      display:                    block;
      margin-top:                 10px;
      opacity:                    1;
    }
  }
}
</style>

<script>
module.exports = {
  name: 'BaseAccordion',
  props: ['open'],
  data: () => ({
    isOpen: !!this.open
  }),
}
</script>
