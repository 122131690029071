<template>
  <div class="dropdown-menu notif" ref="notification" role="menu" aria-labelledby="notifications">
    <ul class="list-unstyled msg-list">
      <li class="nav-item" :id="`event_${item.uuid}`" v-for="(item, index) in notification" :key="index" :data-test="`btnNotificationClicked_${index}`" @click="notificationClicked(item.exam.id)">
        <a class="dropdown-item">
          <span class="image"><img src="@/assets/images/phelcom/phelcom_notification.png" alt="Profile Image" /></span>
          <span>
            <span class="title-notif">{{ $t('clinicConfig.newExam') }}</span>
            <span class="time">{{ $filters.dateTime(item.date) }}</span>
          </span>
          <span class="message" v-if="item.scoreResult">
            <i class="icon-ai" :class="item.scoreResult.status"></i>
            <!-- {{ $t('od') }}: {{ item.scoreResult.odText }} {{ $t('od') }}: {{ item.scoreResult.osText }} -->
          </span>
        </a>
      </li>
    </ul>
    <!-- <a @click="addFake">
      <strong>Ver todos os exames</strong>
      <i class="fa fa-angle-right"></i>
    </a> -->
    <router-link data-test="anchorNotificationExam" :to="{name: 'exam-list'}">
      <strong>{{ $t('clinicConfig.allExams') }}</strong>
      <i class="fa fa-angle-right"></i>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
  .dropdown-menu.notif {
    @include                            transition(all 0.1s ease-out);
    font-size:                          12px;
    position:                           absolute;
    transform:                          unset !important;
    width:                              350px;
    max-width:                          350px;
    padding:                            0.5rem 0 0;
    > a {
      text-align:                       center;
      display:                          block;
      padding-bottom:                   10px;
    }
    a {
      color:                            $ucla;
      cursor:                           pointer;
      &:active,
      &:focus,
      &:hover {
        color:                          $bdazzled;
      }
    }
    .list-unstyled {
      height:                           calc(100% - 40px);
      max-height:                       340px;
      border-bottom:                    1px solid $chinese-silver;
      overflow-y:                       scroll;
      margin-bottom:                    10px;
      li {
        position:                       relative;
        background-color:               $cultured;
        padding:                        5px;
        display:                        -ms-flexbox;
        display:                        flex;
        margin:                         6px 6px 0;
        width:                          96%;
        cursor:                         pointer;
        &:last-child {
          margin-bottom:                6px;
          padding:                      10px;
        }
        &:active,
        &:focus,
        &:hover {
          background-color:             $platinum;
          a {
            color:                      $bdazzled;
            background-color:           inherit;
          }
        }
        a {
          padding:                      3px 5px;
          .time,
          .title-notif {
            font-style:                 italic;
            font-weight:                bold;
          }
          .time,
          .message {
            font-size:                  11px;
          }
          .time {
            position:                   absolute;
            right:                      12px;
          }
          .message {
            display:                    block;
          }
        }
        .image {
          vertical-align:               middle;
          border-radius:                99px;
          overflow:                     hidden;
          margin:                       auto;
          img {
            @include                    border_radius(50%);
            float:                      left;
            margin-right:               10px;
            width:                      12%;
          }
        }
      }
      span {
        white-space:                    normal;
      }
    }
    // scroll bar
    .list-unstyled::-webkit-scrollbar {
      width:                            7px;
    }
    .list-unstyled::-webkit-scrollbar-track {
      background:                       $light-platinum;
    }
    .list-unstyled::-webkit-scrollbar-thumb {
      background:                       $spanish-gray;
    }
    .list-unstyled::-webkit-scrollbar-thumb:hover {
      background:                       $granite-gray;
    }
    // end scroll bar
  }

  .main.retract .dropdown-menu.notif {
    width:                              290px;
    max-width:                          290px;
    inset:                              175px auto auto 30px !important;
    .image img {
      width:                            11% !important;
    }
  }

  @media (max-width: 767px) {
    .dropdown-menu.notif {
      width:                            300px;
      inset:                            35px 1px auto auto !important;
      li {
        a {
          .image {
            img {
              width:                    11%;
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .dropdown-menu.notif {
      inset:                            50px auto auto auto !important;
      // inset:                            100px auto auto 195px !important;
    }
  }
</style>

<script>
  export default {
    name: 'Notifications',
    props: ['notification'],
    data: () => ({
    }),
    methods: {
      async addFake() {
        try {
          let response = await this.$notificationService.addFakeNotifications();
        } catch (e) {
          console.log('erro', e);
        }
      },
      notificationClicked: async function(examid) {
        this.$router.push({name: 'exam-show', params: {examID: examid}, query: {tab: 'images'}})
      },
    }
  }
</script>
