<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="79" height="78" viewBox="0 0 79 78" fill="none">
    <path d="M43.1934 3.27832C25.0755 3.27832 10.3359 18.024 10.3359 36.148C10.3359 39.814 10.9295 43.3837 12.097 46.7597C12.2043 47.0631 12.4272 47.3117 12.7172 47.4513C13.0071 47.591 13.3405 47.6103 13.6446 47.5051C13.9487 47.3998 14.1988 47.1786 14.3405 46.8896C14.4821 46.6007 14.5036 46.2674 14.4005 45.9626C13.3144 42.8048 12.7644 39.4874 12.7734 36.148C12.7734 19.3683 26.421 5.71582 43.1934 5.71582C59.9756 5.71582 73.6256 19.3683 73.6256 36.148C73.6413 43.3113 71.1217 50.2491 66.5131 55.733C61.9045 61.2169 55.5041 64.8932 48.445 66.111C48.1327 66.1727 47.8569 66.3541 47.6767 66.6165C47.4964 66.8789 47.4259 67.2014 47.4804 67.5151C47.5349 67.8288 47.7099 68.1087 47.968 68.2949C48.2262 68.4812 48.547 68.5591 48.8618 68.5119C56.4868 67.1966 63.4005 63.2259 68.3789 57.3026C73.3573 51.3793 76.0793 43.8856 76.0631 36.148C76.0631 18.0252 61.3175 3.27832 43.1934 3.27832Z" fill="#3CB391"/>
    <path d="M43.1971 16.1897C42.8739 16.1897 42.5639 16.3181 42.3353 16.5467C42.1067 16.7752 41.9783 17.0852 41.9783 17.4084V36.1431C41.9783 36.5574 42.1892 36.9438 42.539 37.168L55.0628 45.2118C55.1975 45.3014 55.3487 45.3633 55.5075 45.3939C55.6663 45.4245 55.8297 45.4232 55.988 45.39C56.1463 45.3569 56.2964 45.2925 56.4296 45.2007C56.5628 45.1089 56.6764 44.9915 56.7638 44.8554C56.8511 44.7193 56.9105 44.5671 56.9385 44.4078C56.9664 44.2484 56.9624 44.0851 56.9266 43.9274C56.8908 43.7697 56.8239 43.6206 56.7299 43.489C56.6359 43.3573 56.5167 43.2457 56.3791 43.1606L44.4158 35.4764V17.4084C44.4158 17.0852 44.2874 16.7752 44.0589 16.5467C43.8303 16.3181 43.5203 16.1897 43.1971 16.1897ZM8.32865 74.6604H41.0911C42.0497 74.6673 42.9924 74.4149 43.8194 73.9301C44.6464 73.4452 45.3271 72.7459 45.7894 71.9061C46.2636 71.0722 46.5033 70.1255 46.4831 69.1664C46.463 68.2073 46.1837 67.2715 45.6748 66.4583L29.2936 39.831C28.3064 38.2271 26.5928 37.2692 24.7087 37.2692C22.8245 37.2692 21.1109 38.2271 20.1249 39.831L3.7425 66.4583C2.70656 68.1426 2.66268 70.1779 3.62793 71.9049C4.59318 73.6318 6.35062 74.6604 8.32865 74.6604ZM5.81803 67.7355L22.2005 41.107C22.7404 40.2295 23.6788 39.7055 24.7087 39.7055C25.7385 39.7055 26.6782 40.2295 27.2181 41.107L43.5993 67.7355C44.166 68.6569 44.1904 69.7708 43.6614 70.7154C43.4088 71.1754 43.0364 71.5586 42.5836 71.8242C42.1309 72.0899 41.6148 72.2281 41.0899 72.2242H8.32987C7.80501 72.2279 7.28891 72.0897 6.83621 71.8241C6.38351 71.5585 6.01106 71.1754 5.75831 70.7154C5.4988 70.2593 5.36746 69.7416 5.37819 69.217C5.38891 68.6925 5.54009 68.1805 5.81803 67.7355Z" fill="#1B294A"/>
    <path d="M24.709 61.6514C25.0322 61.6514 25.3422 61.5229 25.5708 61.2944C25.7993 61.0658 25.9277 60.7558 25.9277 60.4326V50.0659C25.9277 49.7427 25.7993 49.4327 25.5708 49.2041C25.3422 48.9756 25.0322 48.8472 24.709 48.8472C24.3858 48.8472 24.0758 48.9756 23.8472 49.2041C23.6186 49.4327 23.4902 49.7427 23.4902 50.0659V60.4326C23.4902 60.7558 23.6186 61.0658 23.8472 61.2944C24.0758 61.5229 24.3858 61.6514 24.709 61.6514ZM24.709 67.5404C25.0322 67.5404 25.3422 67.4119 25.5708 67.1834C25.7993 66.9548 25.9277 66.6448 25.9277 66.3216V65.9024C25.9277 65.5791 25.7993 65.2691 25.5708 65.0406C25.3422 64.812 25.0322 64.6836 24.709 64.6836C24.3858 64.6836 24.0758 64.812 23.8472 65.0406C23.6186 65.2691 23.4902 65.5791 23.4902 65.9024V66.3216C23.4902 66.6448 23.6186 66.9548 23.8472 67.1834C24.0758 67.4119 24.3858 67.5404 24.709 67.5404Z" fill="#3CB391"/>
  </svg>
</template>

<style lang="css" scoped>
</style>

<script>
export default {
  name: 'IconExpiredSession'
}
</script>
