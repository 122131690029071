<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="20" viewBox="0 0 29 20" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.83188 1.14177C6.44136 0.751246 5.8082 0.751246 5.41767 1.14177C5.02715 1.53229 5.02715 2.16546 5.41767 2.55598L21.72 18.8583C22.1105 19.2488 22.7437 19.2488 23.1342 18.8583C23.5247 18.4678 23.5247 17.8346 23.1342 17.4441L6.83188 1.14177ZM16.218 14.7706L18.8735 17.426C17.4185 17.8557 15.8749 18.1187 14.2759 18.1187C8.89486 18.1187 4.14085 15.1405 1.2804 12.8502C-0.4268 11.4832 -0.4268 9.03016 1.2804 7.6632C2.53194 6.66109 4.14596 5.52731 6.0164 4.56893L7.50942 6.06195C5.57182 6.98286 3.86732 8.15397 2.53046 9.2244C1.82318 9.79072 1.82318 10.7226 2.53046 11.289C4.31252 12.7159 6.74787 14.3217 9.51779 15.2753C8.18268 14.0127 7.34967 12.2247 7.34967 10.2421C7.34967 8.99354 7.68002 7.82217 8.25808 6.81061L9.74732 8.29985C9.4914 8.89579 9.34967 9.55237 9.34967 10.2421C9.34967 12.9627 11.5552 15.1682 14.2758 15.1682C14.9655 15.1682 15.6221 15.0265 16.218 14.7706ZM14.2759 2.39467C12.8227 2.39467 11.4152 2.61188 10.0784 2.97409L12.6833 5.57899C13.1829 5.40843 13.7185 5.31589 14.2758 5.31589C16.9965 5.31589 19.202 7.52141 19.202 10.2421C19.202 10.7994 19.1095 11.335 18.9389 11.8346L20.4629 13.3586C20.9357 12.4218 21.202 11.363 21.202 10.2421C21.202 8.28328 20.3889 6.51442 19.0818 5.2546C21.832 6.21013 24.2496 7.80569 26.0214 9.2244C26.7287 9.79072 26.7287 10.7226 26.0214 11.289C24.7646 12.2953 23.1827 13.3907 21.3878 14.2835L22.8727 15.7683C24.6002 14.8491 26.0958 13.7915 27.2715 12.8502C28.9787 11.4832 28.9787 9.03016 27.2715 7.6632C24.411 5.37282 19.657 2.39467 14.2759 2.39467ZM10.5503 10.2421C10.5503 9.89466 10.5979 9.55836 10.6868 9.23933L15.2786 13.8311C14.9596 13.9201 14.6233 13.9676 14.2759 13.9676C12.2183 13.9676 10.5503 12.2996 10.5503 10.2421ZM13.6698 6.56552L17.9524 10.8481C17.9847 10.6509 18.0015 10.4484 18.0015 10.2421C18.0015 8.18448 16.3335 6.51648 14.2759 6.51648C14.0695 6.51648 13.8671 6.53325 13.6698 6.56552Z" fill="#1B294A"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconShowPassword'
}
</script>
