<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1.21814" y="1.33685" width="17.3333" height="17.3333" rx="1.5" stroke="#1B294A"/>
    <path d="M5.74754 8.398C6.36754 8.398 6.84354 8.508 7.17554 8.728C7.50754 8.944 7.67354 9.3 7.67354 9.796C7.67354 10.296 7.50554 10.658 7.16954 10.882C6.83354 11.102 6.35354 11.212 5.72954 11.212H5.43554V12.604H4.49954V8.506C4.70354 8.466 4.91954 8.438 5.14754 8.422C5.37554 8.406 5.57554 8.398 5.74754 8.398ZM5.80754 9.196C5.73954 9.196 5.67154 9.198 5.60354 9.202C5.53954 9.206 5.48354 9.21 5.43554 9.214V10.414H5.72954C6.05354 10.414 6.29754 10.37 6.46154 10.282C6.62554 10.194 6.70754 10.03 6.70754 9.79C6.70754 9.674 6.68554 9.578 6.64154 9.502C6.60154 9.426 6.54154 9.366 6.46154 9.322C6.38554 9.274 6.29154 9.242 6.17954 9.226C6.06754 9.206 5.94354 9.196 5.80754 9.196ZM9.29687 11.842C9.34087 11.846 9.39087 11.85 9.44687 11.854C9.50687 11.854 9.57687 11.854 9.65687 11.854C10.1249 11.854 10.4709 11.736 10.6949 11.5C10.9229 11.264 11.0369 10.938 11.0369 10.522C11.0369 10.086 10.9289 9.756 10.7129 9.532C10.4969 9.308 10.1549 9.196 9.68687 9.196C9.62287 9.196 9.55687 9.198 9.48887 9.202C9.42087 9.202 9.35687 9.206 9.29687 9.214V11.842ZM12.0029 10.522C12.0029 10.882 11.9469 11.196 11.8349 11.464C11.7229 11.732 11.5629 11.954 11.3549 12.13C11.1509 12.306 10.9009 12.438 10.6049 12.526C10.3089 12.614 9.97687 12.658 9.60887 12.658C9.44087 12.658 9.24487 12.65 9.02087 12.634C8.79687 12.622 8.57687 12.594 8.36087 12.55V8.5C8.57687 8.46 8.80087 8.434 9.03287 8.422C9.26887 8.406 9.47087 8.398 9.63887 8.398C9.99487 8.398 10.3169 8.438 10.6049 8.518C10.8969 8.598 11.1469 8.724 11.3549 8.896C11.5629 9.068 11.7229 9.288 11.8349 9.556C11.9469 9.824 12.0029 10.146 12.0029 10.522ZM12.7847 12.604V8.446H15.5687V9.232H13.7207V10.102H15.3647V10.888H13.7207V12.604H12.7847Z" fill="black"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconPdf'
}
</script>
