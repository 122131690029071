<template>
  <div class="tab-record-box" v-loader.opacity.tab="loading">
    <div class="title-box rounded">
      <h2 class="title">{{ $t('medicalRecord.records') }}</h2>

      <button class="btn btn-outline-success rounded" type="button" name="button" data-test="btnNewMedicalRecord" @click="newMedicalRecord">
        <i class="fa fa-plus"></i>
        <span>{{ $t('medicalRecord.new') }}</span>
      </button>

      <button class="btn btn-primary rounded" type="button" name="button" data-test="btnPrintObj" v-if="!isSafari" v-print="printObj">
        <i class="fa fa-plus"></i>
        <span>{{ $t('medicalRecord.print') }}</span>
      </button>
      <button class="btn btn-primary rounded" type="button" name="button" data-test="btnPrint" v-else @click="doPrint">
        <i class="fa fa-plus"></i>
        <span>{{ $t('medicalRecord.print') }}</span>
      </button>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <hr/>
    <ul id="diagnosisList" class="record-list timeline">
      <template v-for="record of records" :key="record.id">
        <PatientMedicalRecordEntry
            :record="record"
            :data_path="dataPath"
            :patient_name="patient_name"
            @reloadRecords="reloadRecords">
         </PatientMedicalRecordEntry>
      </template>
    </ul>

  </div>
</template>

<style lang="scss" scoped>
.tab-record-box .title-box .btn {
  margin-bottom:                            0;
}

@media (max-width: 400px) {
  .tab-record-box .title-box {
    .title {
      text-align:                           center;
      width:                                100%;
      max-width:                            unset;
    }
    .btn {
      display:                              block;
      margin-right:                         auto !important;
      margin-left:                          auto !important;
    }
  }
}

@media screen and (min-width: 401px) and (max-width: 767px) {
  .tab-record-box .title-box .title {
    text-align:                             left;
    width:                                  calc(100% - 152px);
  }
}
</style>

<script>
import moment from 'moment';
import PatientMedicalRecordEntry from './PatientMedicalRecordEntry.vue'

export default {
  name: 'PatientMedicalRecordListTab',
  props: ['patient_id', 'patient_name'],
  components: {
    PatientMedicalRecordEntry
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    printObj: {
      id: null,
      popTitle: null,
    },
    entryTitleList: {},
    records: [],
    dataIsReady: false,
    isSafari: false,
    dataPath: null,
  }),
  computed: {
    tam() {
      return this.entryTitleList.length
    }
  },
  mounted() {
    this.fetchData()

    this.checkBrowser()

    this.printObj.id = 'diagnosisList'
    this.printObj.popTitle = this.patient_name
  },
  methods: {
    async fetchData() {
      this.alert.message = undefined;

      this.loading = true
      NProgress.start()
      try {
        let params = {
          id: this.patient_id
        }

        let response = await this.$medicalRecordService.list(params);
        this.records = response.result
        this.dataPath = response.dataPath

        // console.log(">>> RECORDS >>", this.records)
        this.dataIsReady = true // avoid multiple list loading
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    newMedicalRecord() {
      this.$router.push({name: 'patient-medical-record',
        params: {
          patientID: this.patient_id
        }
      })
    },
    isBoolean(value) {
      return value == true || value == false
    },
    formatDate(record) {
      return moment(record.date).format('DD/MM/yyyy')
    },

    /**
     * Returns whether the entry key was used as a title.
     * @param {string} entryKey The entryKey is like "e1", "e2" or for more complex cases "e4_s1".
     */
    titleWasShown(entryKey) {
      const mainKey = this.mainKey(entryKey)
      console.error(">> titleWasShown >>", entryKey)
      if (this.entryTitleList.includes(mainKey))
        return true

      this.entryTitleList.push(mainKey)
      return false
    },
    mainKey(value) {
      const parts = value.split('_')
      return parts.length > 0 ? parts[0] : parts
    },

    isFoo(value) {
      console.error(">>> ", this.entryTitleList[value])

      if (!this.entryTitleList[value]) {
        console.error("not found for", value)
        this.entryTitleList[value] = true
        return false
      }

      return true
      // console.error(Object.keys(this.entryTitleList).join(','))
      // console.error(">>>", value, this.entryTitleList[value])

      // return this.entryTitleList[value] // value == "e1" ? true : false
    },

    checkBrowser() {
      let navigationUserAgent = navigator.userAgent

      // Detect Safari
      let chromeAgent = navigationUserAgent.indexOf("Chrome") > -1;
      this.isSafari = navigationUserAgent.indexOf("Safari") > -1;

      // Discard Safari since it also matches Chrome
      if ((chromeAgent) && (this.isSafari)) this.isSafari = false;
    },

    doPrint() {
      document.execCommand('print', false, null);
    },

    reloadRecords() {
      this.fetchData()
    }
  }
}
</script>
