<template>
  <div class="" v-loader-eyerweb.opacity="loading">
    <div class="title-box clear">
      <h2 class="title">Full backup</h2>
    </div>

    <div class="info-text">
      <b>Disclaimer:</b>
      <p>
        It'll take a while to generate a single backup file, which will contains ONLY patient name's and ALL exams images
        splited by date of execution.
        <br>
        The download process may take time depending on your network conditions.
        <br>
        Once full backup is completed all data in Eyer can be safely removed.
      </p>
    </div>

    <button class="btn btn-outline-primary" type="button" name="button" data-test="btnFullBackup" @click="fullBackup">Start full backup</button>

  </div>
</template>

<style lang="scss" scoped>
.title-box {
  .btn-back {
    margin-right:                20px;
  }

  .title.sub {
    cursor:                      pointer;
  }
}

.info-text {
  // @include border_radius(10px);
  padding:                       10px;
  margin:                        15px;
  background:                    white;
  border:                        1px solid black;
}

.btn-outline-primary {
  display:                       block;
  margin:                        0 auto;
}
</style>

<script>
export default {
  name: 'eyerweb-full-backup',
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
  }),
  created() {
    this.$activeLink.value = 'backup'
    this.$eyerwebService.downloadSubject.subscribe(this.downloadCallback)
  },
  unmounted() {
    this.$eyerwebService.downloadSubject.unsubscribe(this.downloadCallback)
  },
  methods: {
    fullBackup() {
      NProgress.start()
      this.loading = true
      this.$eyerwebService.fullBackup()
    },
    downloadCallback(result) {
      this.loading = false
      NProgress.done(true)
      if (result == "error") {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
    }
  },
}
</script>
