<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="192" height="32" viewBox="0 0 192 32" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M110.571 25.7287V13.6527C110.571 13.6527 114.294 13.4054 114.295 13.3725C114.296 13.3395 114.518 10.4385 114.518 10.4385L106.796 11.0116V14.5566V25.7287H110.571Z" fill="#1B294A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M94.0432 25.9344C96.6092 25.9344 98.0197 25.6911 99.4011 25.4528L99.4011 25.4528C99.6096 25.4168 99.8175 25.3809 100.029 25.3461L100.395 22.4763C98.8284 22.6737 96.9537 22.8796 94.8993 22.8796C92.6993 22.8796 91.4324 22.2961 91.2954 19.9107C94.56 19.6261 101.018 19.2154 101.018 19.2154C101.018 19.2154 101.071 18.1946 101.071 17.7913C101.071 13.1663 99.9241 10.2402 94.36 10.2402C89.2581 10.2402 87.5203 12.5399 87.5203 18.083C87.5203 22.6136 88.8728 25.9344 94.0432 25.9344ZM91.1832 17.3025C91.1832 14.9085 91.5599 13.158 94.3591 13.158C96.5163 13.158 97.261 14.2563 97.261 16.2642C97.261 16.4358 97.2268 16.8735 97.2268 16.8735C97.2268 16.8735 94.4403 17.124 91.1832 17.3025Z" fill="#1B294A"/>
    <path d="M76.6152 19.877L72.7973 10.4468H68.7654L74.8946 24.6565L72.4977 31.358L76.2746 31.1063L83.4634 10.4468H79.5942L76.6152 19.877Z" fill="#1B294A"/>
    <path d="M53.8732 5.68457C52.8329 5.68457 52.0009 6.5191 52.0009 7.56185C52.0009 7.58392 52.002 7.62781 52.002 7.62781C52.002 7.62781 52.0009 18.4941 52.0009 23.9168C52.0009 23.9436 52.0012 23.9702 52.0019 23.9963C52.0012 24.0174 52.0009 24.0386 52.0009 24.0599C52.0009 25.1026 52.8442 25.9479 53.8844 25.9479C53.9325 25.9479 53.9802 25.9461 54.0274 25.9426H64.3644C64.3644 25.8078 64.3619 25.6793 64.3447 25.5566V22.4856H63.8862L63.8889 22.4868H57.3376C56.5183 22.4868 56.0428 21.7359 56.0428 21.1716C56.0428 19.8391 56.0413 18.5066 56.0413 17.1742H63.2237V13.9478H56.0413V13.8863L56.0371 13.9003V10.2254C56.0371 9.73087 56.4182 8.93294 57.3937 8.93294H64.3449C64.3449 8.93294 64.3276 6.19326 64.3447 6.07055C64.3619 5.94783 64.3449 5.68457 64.3449 5.68457H53.8732Z" fill="#1B294A"/>
    <path d="M131.177 23.3089L131.42 25.4224C129.955 25.7467 128.401 25.9089 126.757 25.9089C125.845 25.9089 125.029 25.8446 124.308 25.716C123.592 25.5874 122.955 25.4001 122.395 25.154C121.842 24.9024 121.364 24.5725 120.961 24.1644C120.559 23.7562 120.22 23.2949 119.946 22.7805C119.672 22.2661 119.452 21.6538 119.284 20.9437C119.122 20.2336 119.004 19.4788 118.932 18.6792C118.864 17.8741 118.831 16.9599 118.831 15.9367C118.831 15.1427 118.851 14.4186 118.89 13.7644C118.934 13.1103 119.004 12.484 119.099 11.8858C119.194 11.2819 119.317 10.7423 119.468 10.2671C119.625 9.7862 119.815 9.33889 120.039 8.92513C120.268 8.50578 120.533 8.14234 120.835 7.83482C121.137 7.52729 121.484 7.25332 121.875 7.01289C122.267 6.76687 122.706 6.57117 123.192 6.42579C123.679 6.27483 124.215 6.16021 124.802 6.08193C125.395 6.00365 126.047 5.96451 126.757 5.96451C128.428 5.96451 129.983 6.12386 131.42 6.44257L131.168 8.56449C129.838 8.35761 128.633 8.25417 127.553 8.25417C126.933 8.25417 126.368 8.29331 125.859 8.37158C125.356 8.44427 124.912 8.54492 124.526 8.67352C124.14 8.79653 123.799 8.97266 123.503 9.2019C123.212 9.43115 122.963 9.66319 122.756 9.89803C122.555 10.1273 122.381 10.4264 122.236 10.7954C122.096 11.1645 121.984 11.5111 121.901 11.8354C121.822 12.1597 121.761 12.5707 121.716 13.0683C121.677 13.566 121.652 14.0189 121.641 14.427C121.629 14.8296 121.624 15.3328 121.624 15.9367C121.624 16.6188 121.632 17.1864 121.649 17.6393C121.666 18.0922 121.702 18.5898 121.758 19.1321C121.82 19.6689 121.909 20.1134 122.026 20.4657C122.144 20.8123 122.298 21.173 122.488 21.5476C122.683 21.9222 122.927 22.2242 123.217 22.4534C123.514 22.6771 123.863 22.8867 124.266 23.0824C124.668 23.2725 125.144 23.4095 125.692 23.4934C126.239 23.5773 126.86 23.6192 127.553 23.6192C128.661 23.6192 129.868 23.5158 131.177 23.3089Z" fill="url(#paint0_linear_1096_2926)"/>
    <path d="M135.881 21.8412V5H138.448V21.6986C138.448 22.4422 138.54 23.0964 138.724 23.6611C138.909 24.2203 139.2 24.7598 139.597 25.2798L137.391 26.3701C136.882 25.7942 136.502 25.1484 136.25 24.4327C136.004 23.7115 135.881 22.8476 135.881 21.8412Z" fill="url(#paint1_linear_1096_2926)"/>
    <path d="M150.119 25.9089C148.888 25.9089 147.86 25.7747 147.032 25.5063C146.21 25.2323 145.534 24.785 145.002 24.1644C144.471 23.5437 144.091 22.7553 143.862 21.7992C143.633 20.8431 143.518 19.6549 143.518 18.2347C143.518 16.8145 143.633 15.6292 143.862 14.6786C144.091 13.7225 144.471 12.9341 145.002 12.3135C145.534 11.6928 146.213 11.2483 147.041 10.98C147.868 10.706 148.894 10.569 150.119 10.569C151.343 10.569 152.369 10.7088 153.197 10.9883C154.03 11.2679 154.709 11.7208 155.235 12.347C155.766 12.9733 156.146 13.7616 156.375 14.7122C156.605 15.6627 156.719 16.8369 156.719 18.2347C156.719 19.6326 156.605 20.8068 156.375 21.7573C156.146 22.7078 155.766 23.499 155.235 24.1308C154.709 24.757 154.03 25.2099 153.197 25.4895C152.369 25.7691 151.343 25.9089 150.119 25.9089ZM150.119 23.8121C151.17 23.8121 151.98 23.6416 152.551 23.3005C153.121 22.9594 153.529 22.3947 153.775 21.6063C154.021 20.8123 154.144 19.6885 154.144 18.2347C154.144 17.3625 154.102 16.6216 154.019 16.0122C153.935 15.4027 153.803 14.8771 153.624 14.4354C153.445 13.9937 153.194 13.647 152.87 13.3954C152.551 13.1438 152.171 12.9593 151.729 12.8419C151.287 12.7245 150.75 12.6657 150.119 12.6657C149.325 12.6657 148.682 12.7468 148.19 12.909C147.703 13.0655 147.298 13.3591 146.973 13.7896C146.649 14.2145 146.423 14.7793 146.294 15.4838C146.171 16.1827 146.11 17.0997 146.11 18.2347C146.11 19.3698 146.171 20.2896 146.294 20.9941C146.423 21.693 146.649 22.2577 146.973 22.6882C147.298 23.1188 147.703 23.4151 148.19 23.5773C148.682 23.7338 149.325 23.8121 150.119 23.8121Z" fill="url(#paint2_linear_1096_2926)"/>
    <path d="M161.79 20.4154V10.7703H164.356V20.0547C164.356 20.4573 164.368 20.804 164.39 21.0947C164.418 21.3855 164.463 21.6706 164.524 21.9502C164.591 22.2298 164.683 22.4618 164.801 22.6463C164.924 22.8308 165.08 23.0042 165.271 23.1663C165.466 23.3285 165.701 23.4543 165.975 23.5437C166.249 23.6332 166.573 23.7031 166.948 23.7534C167.323 23.8037 167.753 23.8289 168.24 23.8289C168.804 23.8289 169.414 23.7702 170.068 23.6528C170.728 23.5353 171.253 23.3844 171.645 23.1999C171.695 22.5457 171.72 21.816 171.72 21.0108V10.7703H174.278V21.9921C174.278 22.8923 174.245 23.773 174.178 24.634C172.332 25.4839 170.096 25.9089 167.468 25.9089C166.747 25.9089 166.101 25.8585 165.531 25.7579C164.96 25.6573 164.477 25.5259 164.08 25.3637C163.688 25.196 163.35 24.9779 163.065 24.7095C162.785 24.4355 162.562 24.156 162.394 23.8708C162.232 23.5857 162.103 23.2418 162.008 22.8392C161.919 22.4366 161.86 22.0536 161.832 21.6902C161.804 21.3267 161.79 20.9018 161.79 20.4154Z" fill="url(#paint3_linear_1096_2926)"/>
    <path d="M189.275 11.0554V5H191.833V22.0173C191.833 22.9119 191.8 23.7925 191.733 24.6592C190.95 25.0841 190.055 25.4 189.049 25.6069C188.048 25.8082 187.033 25.9089 186.004 25.9089C185.015 25.9089 184.156 25.8306 183.43 25.674C182.708 25.5119 182.071 25.2519 181.517 24.894C180.964 24.5306 180.516 24.0525 180.175 23.4599C179.84 22.8616 179.586 22.1347 179.412 21.2792C179.244 20.4237 179.161 19.4089 179.161 18.2347C179.161 16.7978 179.281 15.5928 179.521 14.6199C179.762 13.6414 180.145 12.8531 180.67 12.2548C181.201 11.6509 181.858 11.2204 182.641 10.9632C183.43 10.7004 184.4 10.569 185.551 10.569C186.726 10.569 187.967 10.7311 189.275 11.0554ZM189.275 13.127C188.845 12.9817 188.35 12.8614 187.791 12.7664C187.232 12.6657 186.717 12.6154 186.248 12.6154C185.795 12.6154 185.384 12.6406 185.015 12.6909C184.651 12.7412 184.324 12.8111 184.033 12.9006C183.748 12.99 183.494 13.1131 183.27 13.2696C183.047 13.4206 182.854 13.5827 182.692 13.7561C182.535 13.9294 182.398 14.1447 182.281 14.4019C182.169 14.6535 182.079 14.9051 182.012 15.1567C181.945 15.4083 181.892 15.7102 181.853 16.0625C181.814 16.4092 181.786 16.7474 181.769 17.0773C181.758 17.4072 181.752 17.793 181.752 18.2347C181.752 18.7603 181.766 19.2272 181.794 19.6354C181.822 20.0379 181.875 20.4433 181.953 20.8515C182.037 21.2541 182.146 21.6035 182.281 21.8999C182.415 22.1962 182.588 22.473 182.801 22.7302C183.019 22.9874 183.273 23.1971 183.564 23.3592C183.86 23.5214 184.212 23.6472 184.621 23.7366C185.029 23.8261 185.49 23.8708 186.004 23.8708C186.569 23.8708 187.142 23.8205 187.724 23.7199C188.305 23.6136 188.797 23.4654 189.2 23.2753C189.25 22.6156 189.275 21.8859 189.275 21.0863V13.127Z" fill="url(#paint4_linear_1096_2926)"/>
    <!-- <circle cx="15" cy="15" r="15" transform="rotate(-180 15 15)" fill="#8F8F8F"/> -->
    <!-- <path d="M7.25771 9.54325L12.7147 15L7.25771 20.4569" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M24.285 15L16.7188 15" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M24.285 20L14.4919 20" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M24.285 10L14.4919 10" stroke="white" stroke-width="1.5" stroke-linecap="round"/> -->
    <defs>
      <linearGradient id="paint0_linear_1096_2926" x1="155.332" y1="5" x2="155.332" y2="31.5315" gradientUnits="userSpaceOnUse">
        <stop stop-color="#3CB391"/>
        <stop offset="1" stop-color="#29D1DC"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1096_2926" x1="155.332" y1="5" x2="155.332" y2="31.5315" gradientUnits="userSpaceOnUse">
        <stop stop-color="#3CB391"/>
        <stop offset="1" stop-color="#29D1DC"/>
      </linearGradient>
      <linearGradient id="paint2_linear_1096_2926" x1="155.332" y1="5" x2="155.332" y2="31.5315" gradientUnits="userSpaceOnUse">
        <stop stop-color="#3CB391"/>
        <stop offset="1" stop-color="#29D1DC"/>
      </linearGradient>
      <linearGradient id="paint3_linear_1096_2926" x1="155.332" y1="5" x2="155.332" y2="31.5315" gradientUnits="userSpaceOnUse">
        <stop stop-color="#3CB391"/>
        <stop offset="1" stop-color="#29D1DC"/>
      </linearGradient>
      <linearGradient id="paint4_linear_1096_2926" x1="155.332" y1="5" x2="155.332" y2="31.5315" gradientUnits="userSpaceOnUse">
        <stop stop-color="#3CB391"/>
        <stop offset="1" stop-color="#29D1DC"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconEyerCloudLogo'
}
</script>
