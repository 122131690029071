<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" :id="offcanvas_id" :aria-labelledby="`${offcanvas_id}_label`">
    <div class="offcanvas-header">
      <button class="btn-back" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" :id="`${offcanvas_id}_label`">{{ title }}</h5>
    </div>
    <div class="offcanvas-body">
      <p v-html="text"></p>

      <!-- footer inside body to avoid empty space between body and footer -->
      <div class="offcanvas-footer">
        <button type="button" class="btn" :class="okButtonClass" data-bs-dismiss="offcanvas" data-test="btnConfirm" @click="doConfirm">{{ okButton }}</button>
        <button type="button" class="btn" :class="cancelButtonClass" data-bs-dismiss="offcanvas" v-show="cancelButton" data-test="btnCancel" @click="doCancel">{{ cancelButton }}</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  width:                                510px !important;
  padding:                              40px;

  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    p {
      color:                            var(--Brand-Primary, #1B294A);
      font-size:                        var(--font-size-14);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      margin-bottom:                    26px;
    }
  }

  .offcanvas-footer {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    .btn {
      font-size:                        var(--font-size-14);
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   4.2px;
      text-align:                       center;
      text-transform:                   uppercase;
      // gap:                              10px;
      // flex:                             1 0 0;
      width:                            100%;
      padding:                          20px;
    }

    .btn-success {
      color:                            var(--Shades-W, #FFF);
      // margin-top:                       26px;
    }
  }
}
</style>

<script>
export default {
  name: 'BaseGenericOffcanvas',
  props: ['offcanvas_id'],
  data: () => ({
    title: null,
    text: null,
    okButton: null,
    okButtonClass: null,
    cancelButton: null,
    cancelButtonClass: null,
    offcanvas_generic: null,
  }),
  mounted() {
  },
  methods: {
    show(options = {}) {
      this.title = options.title
      this.text = options.text
      this.okButton = options.okButton
      this.okButtonClass = options.okButtonClass
      if (options.cancelButton) {
        this.cancelButton = options.cancelButton
        this.cancelButtonClass = options.cancelButtonClass
      }

      console.log('options text', options.text);

      this.offcanvas_generic = new bootstrap.Offcanvas(document.getElementById(this.offcanvas_id)).show();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.offcanvas_generic = null;
      this.resolvePromise(true)
    },

    doCancel() {
      this.offcanvas_generic = null;
      this.resolvePromise(false)
    },
  }
}
</script>
