<template>
  <div class="quill-editor">
    <quill-editor
      ref="qlEditor"
      class="editor"
      v-model:content="newContent"
      :options="editorOption"
      content-type="html"
      @update:content="editorChanged()"
    />
    <!-- @input="editorChanged()" -->
    <!-- @textChange="editorChanged()" -->
  </div>
</template>

<style lang="scss" scoped>
body,
.quill-editor {
  font-family:                        'Ubuntu', sans-serif !important;
}

.quill-editor {
  /* max-height:                    100px; */
  height:                        100%;
  margin-top:                    6px;
}
</style>

<script>
const toolbarOptions = [
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }]
];

export default {
  name: 'BaseQLEditor',
  props: ['content'],
  data: () => ({
    editorOption: {
      theme: 'snow',
      modules: {
        toolbar: toolbarOptions,
        clipboard: {
          matchVisual: false
        },
      },
    },
    newContent: null,
  }),
  mounted() {
    if (this.content)
      this.$refs.qlEditor.setHTML(this.content)
  },
  watch: {
    content: {
      // immediate: true,
      handler: function (content) {
        if (content && !this.newContent) {
          this.newContent = content
          this.$refs.qlEditor.setHTML(this.newContent)
        }
        return
    }, deep: true }
  },
  computed: {
    editor() {
      return this.$refs.qlEditor.quill
    },
  },
  methods: {
    editorChanged() {
      this.$emit('content-changed', this.newContent);
    },
  }
}
</script>
