<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
    <path d="M9 1L11.6521 5.34972L16.6085 6.52786L13.2912 10.3943L13.7023 15.4721L9 13.512L4.29772 15.4721L4.70883 10.3943L1.39155 6.52786L6.34791 5.34972L9 1Z" stroke="#404040" stroke-linejoin="round"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconFavorite'
}
</script>
