<template>
  <div id="pdfvuer" class="scrollbar">
    <pdf-vuer :src="pdfData" v-for="i in numPages" :key="i" :id="i" :page="i"
      :scale.sync="scale" style="width:100%;margin:20px auto;"
        :annotation="true"
        :resize="true"
        @link-clicked="handle_pdf_link">
      <template slot="loading">
        loading content here...
      </template>
    </pdf-vuer>
  </div>
</template>

<style lang="scss" scoped>
.scrollbar {
  width:                            300px;
  margin:                           0 auto 26px;
}
</style>

<script>
import pdfvuer from 'pdfvuer'

export default {
  name: 'ExternalExamReportPreview',
  props: ['exam_id'],
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    page: 1,
    numPages: 0,
    pdfData: undefined,
    errors: [],
    scale: 'page-width'
  }),
  computed: {
    formattedZoom () {
      return Number.parseInt(this.scale * 100);
    },
  },
  watch: {
    show: function (s) {
      if(s) {
        this.getPdf();
      }
    },
    page: function (p) {
      if( window.pageYOffset <= this.findPos(document.getElementById(p)) || ( document.getElementById(p+1) && window.pageYOffset >= this.findPos(document.getElementById(p+1)) )) {
        // window.scrollTo(0,this.findPos(document.getElementById(p)));
        document.getElementById(p).scrollIntoView();
      }
    }
  },
  mounted() {
    console.log('this.exam_id', this.exam_id);
    // TODO YAMANAKA: precisa de um endpoint pra pegar um ultimo laudo em pdf

    this.fetchData()

    this.getPdf()
    // this.pdfSrc = 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf'
    // this.pdfSrc = 'http://localhost:1337/data/2022-09-21_ruthie_hartmann.pdf'
  },
  methods: {
    async fetchData() {
      this.alert.message = null;

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$externalService.getReportList({id: this.exam_id});
        console.log('response', response);

        if (response.result.length === 0) {
          // this.$message.popup(this, 'request_empty', 'operationWarning', 'warn')
          this.alert = this.$message.alert(this, 'request_empty', 'warning')
        }
        console.log('response.result[0].id', response.result[0].id);
        // this.generateUrlPDF(response.result[0].id)
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async generateUrlPDF(id) {
      this.urlPdf = null

      let params = {
        id: id
      }

      this.loading = true
      NProgress.start()
      try {
        // TODO Ajustar retorno para gerar o preview do pdf
        // let response = await this.$reportService.generateUrlPDF(params);
        // console.log('url pdf', response);

        this.urlPdf = 'https://jucisrs.rs.gov.br/upload/arquivos/201710/30150625-criacao-de-pdf-a.pdf'
        this.urlPdf.url = 'https://jucisrs.rs.gov.br/upload/arquivos/201710/30150625-criacao-de-pdf-a.pdf'
        this.urlPdf.id = item.id
        this.showModalPreview = true

        this.getPdf();
      } catch (err) {
        console.log('erro >>>', err);
      }
      NProgress.done(true)
      this.loading = false
    },
    handle_pdf_link: function (params) {
      // Scroll to the appropriate place on our page - the Y component of
      // params.destArray * (div height / ???), from the bottom of the page div
      var page = document.getElementById(String(params.pageNumber));
      page.scrollIntoView();
    },
    getPdf() {
      console.log('bateu aqui?!');
      var self = this;
      console.log('pdf prop', self.urlPdf);
      self.pdfData = pdfvuer.createLoadingTask('http://localhost:1337/data/2024-07-31_laurine_brown.pdf');
      // self.pdfData = pdfvuer.createLoadingTask(self.urlPdf);
      self.pdfData.then(pdf => {
        self.numPages = pdf.numPages;
        window.onscroll = function() {
          changePage()
          stickyNav()
        }

        // Get the offset position of the navbar
        var sticky = $('#buttons')[0].offsetTop

        // Add the sticky class to the self.$refs.nav when you reach its scroll position. Remove "sticky" when you leave the scroll position
        function stickyNav() {
          if (window.pageYOffset >= sticky) {
            $('#buttons')[0].classList.remove("hidden")
          } else {
            $('#buttons')[0].classList.add("hidden")
          }
        }

        function changePage () {
          var i = 1, count = Number(pdf.numPages);
          do {
            if(window.pageYOffset >= self.findPos(document.getElementById(i)) &&
                window.pageYOffset <= self.findPos(document.getElementById(i+1))) {
              self.page = i
            }
            i++
          } while ( i < count)
          if (window.pageYOffset >= self.findPos(document.getElementById(i))) {
            self.page = i
          }
        }
      });
    },
    findPos(obj) {
      return obj.offsetTop;
    },
    // async download() {
    //   let response = await this.$medicalRecordService.getMedicalDataURL(this.urlPdf.id)
    //   let downloadURL = response.downloadURL
    //   window.open(downloadURL)
    // },
  },
}
</script>
