<template>
  <div class="modal" id="modal_preview_image" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('medicalRecord.imagePreview') }}</h5>
          <button id="closeBtn" type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
        </div>
        <div class="modal-body">
          <img v-if="image_data_uuid" :src="dataPath +'/'+ image_data_uuid" alt="">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" data-test="btnCancel" @click="close">{{ $t('cancel') }}</button>
          <a href="#" class="btn btn-primary" data-test="btnDownload" @click="download()">Download</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .modal {
    .modal-dialog {
      width:                      100%;
      max-width:                  600px;
    }
    .modal-header {
      background-color:           $phelcom-light-blue;
      border-bottom:              1px solid $platinum;
      h5 {
        font-size:                18px;
      }
    }
    .modal-body {
      height:                     100%;
      max-height:                 60vh;
      overflow-y:                 auto;
      img {
        display:                  block;
        margin:                   0 auto;
        width:                    100%;
      }
    }
  }

  @media (max-width: 660px) {
    .modal .modal-dialog {
      width:                      calc(100% - 15px);
    }
  }
</style>

<script>
const IS_PRODUCTION = process.env.NODE_ENV == 'production'

export default {
  name: 'PreviewImageModal',
  props: ['image_data_id', 'image_data_uuid', 'image_data_path'],
  data: () => ({
    dataPath: null,
  }),
  mounted() {
    this.dataPath = this.image_data_path

    if (!IS_PRODUCTION) {
      // this.dataPath = `http://localhost:1337/data/examdata`
      this.dataPath = `http://localhost:1337${this.dataPath}`
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async download() {
      let response = await this.$medicalRecordService.getMedicalDataURL(this.image_data_id)
      let downloadURL = response.downloadURL
      window.open(downloadURL)
    },
  },
}
</script>
