<template>
  <div class="">
    <h3 class="mb-3">{{ $t('createAccount.confirmInformation') }}</h3>
    <p class="mb-3">{{ $t('createAccount.confirmCollaboratorInformation') }}</p>

    <p class="colaborator-info mb-3">
      <span>{{ $t('createAccount.colaborator') }}</span>
      {{ `${account.firstName} ${account.lastName}` }}
    </p>

    <p class="colaborator-info mb-3">
      <span>{{ $t('createAccount.telephone') }}</span>
      {{ account.telephone }}
    </p>

    <p class="colaborator-info mb-3">
      <span>{{ $t('user.email') }}</span>
      {{ account.email }}
    </p>

    <p class="colaborator-info mb-3">
      <span>{{ $t('user.document') }}</span>
      {{ account.document }}
    </p>

    <p class="colaborator-info mb-3">
      <span>{{ $t('report.npi') }}</span>
      {{ account.npi }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.colaborator-info {
  color:                         var(--Shades-80, #232323);
  font-size:                     var(--font-size-14);
  font-weight:                   500;
  line-height:                   normal;
  letter-spacing:                0.7px;
  display:                       flex;
  flex-direction:                column;
  justify-content:               center;
  flex-shrink:                   0;
  align-self:                    stretch;
  overflow:                      hidden;
  text-overflow:                 ellipsis;
  line-height:                   normal;
  white-space:                   nowrap;

  span {
    color:                       var(--Shades-50, #8F8F8F);
    font-size:                   var(--font-size-10);
    letter-spacing:              1.5px;
    text-transform:              uppercase;
    padding-bottom:              5px;
  }
}
</style>

<script>
export default {
  name: 'RegistrationUserFormStepThree',
  props: ['account'],
  data: () => ({
  }),
  mounted(){
  },
  methods: {
  }
}
</script>
