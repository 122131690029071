<template>
  <div class="medical-record-tabs">
    <!-- Exame Oftalmológico -->
    <div class="form-group">
      <label for="">{{ $t("medicalRecord.s2_e1") }}</label> <!-- Inspeção/Exame externo/Ectoscopia -->
      <input class="form-control" type="text" data-test="inputMedicalRecordS2_e1" v-model="medicalRecordData.s2.e1">
    </div>

    <div class="form-group">
      <label for="">{{ $t("medicalRecord.s2_e2") }}</label> <!-- Motilidade Ocular Extrínseca -->
      <input class="form-control" type="text" data-test="inputMedicalRecordS2_e2" v-model="medicalRecordData.s2.e2">
    </div>

    <div class="form-group">
      <label for="">{{ $t("medicalRecord.s2_e3") }}</label> <!-- Lensometria -->
      <input class="form-control" type="text" data-test="inputMedicalRecordS2_e3" v-model="medicalRecordData.s2.e3">
    </div>

    <div class="row">
      <div class="col-md-6">
        <h3 class="sub-title">{{ $t('medicalRecord.s2_e4') }}</h3> <!-- Acuidade Visual -->
        <div class="tab-internal">
          <ul class="nav nav-tabs">
            <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" href="#OD">{{ $t('quickReport.rightEye') }}</a></li>
            <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#OE">{{ $t('quickReport.leftEye') }}</a></li>
          </ul>

          <div class="tab-content">
            <div id="OD" class="tab-pane fade in active show">
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e4_s1") }}</label> <!-- Sem Correção (OD) -->
                <input class="form-control" type="text" name="" data-test="inputMedicalRecordS2_e4_s1" v-model="medicalRecordData.s2.e4_s1">
              </div>
              <h6 class="sub-title">{{ $t("medicalRecord.s2_e4n1") }}</h6> <!-- Com correção (OD) -->
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e4n1_s1") }}</label> <!-- Esférico -->
                <input class="form-control" type="text" name="" placeholder="0.00" v-maska maska-diopter data-test="inputMedicalRecordS2_e4n1_s1" v-model="medicalRecordData.s2.e4n1_s1">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e4n1_s2") }}</label> <!-- Cilíndrico -->
                <input class="form-control" type="text" name="" placeholder="0.00" v-maska maska-diopter data-test="inputMedicalRecordS2_e4n1_s2" v-model="medicalRecordData.s2.e4n1_s2">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e4n1_s3") }}</label> <!-- Eixo -->
                <input class="form-control" type="text" name="" placeholder="0.00" v-maska :data-maska="$t('inputMask.axis')" data-test="inputMedicalRecordS2_e4n1_s3" v-model="medicalRecordData.s2.e4n1_s3">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e4n1_s4") }}</label> <!-- Acuidade visual -->
                <input class="form-control" type="text" name="" data-test="inputMedicalRecordS2_e4n1_s4" v-model="medicalRecordData.s2.e4n1_s4">
              </div>
            </div>
            <div id="OE" class="tab-pane fade">
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e4_s2") }}</label> <!-- Sem Correção (OE) -->
                <input class="form-control" type="text" name="" data-test="inputMedicalRecordS2_e4_s2" v-model="medicalRecordData.s2.e4_s2">
              </div>
              <h6 class="sub-title">{{ $t("medicalRecord.s2_e4n2") }}</h6> <!-- Com correção (OE) -->
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e4n2_s1") }}</label> <!-- Esférico -->
                <input class="form-control" type="text" name="" placeholder="0.00" v-maska maska-diopter data-test="inputMedicalRecordS2_e4n2_s1" v-model="medicalRecordData.s2.e4n2_s1">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e4n2_s2") }}</label> <!-- Cilíndrico -->
                <input class="form-control" type="text" name="" placeholder="0.00" v-maska maska-diopter data-test="inputMedicalRecordS2_e4n2_s2" v-model="medicalRecordData.s2.e4n2_s2">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e4n2_s3") }}</label> <!-- Eixo -->
                <input class="form-control" type="text" name="" placeholder="0.00" v-maska :data-maska="$t('inputMask.axis')" data-test="inputMedicalRecordS2_e4n2_s3" v-model="medicalRecordData.s2.e4n2_s3">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e4n2_s4") }}</label> <!-- Acuidade visual -->
                <input class="form-control" type="text" name="" data-test="inputMedicalRecordS2_e4n2_s4" v-model="medicalRecordData.s2.e4n2_s4">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <h3 class="sub-title">{{ $t('medicalRecord.s2_e5') }}</h3> <!-- Refração -->
        <div class="tab-internal">
          <ul class="nav nav-tabs">
            <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" href="#foo">{{ $t('quickReport.rightEye') }}</a></li>
            <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#bar">{{ $t('quickReport.leftEye') }}</a></li>
          </ul>
          <div class="tab-content">
            <div id="foo" class="tab-pane fade in active show">
              <div class="row">
                <div class="col-md-6">
                  <h6 class="sub-title">{{ $t('medicalRecord.s2_e5n1') }}</h6> <!-- Dinâmica (OD) -->
                  <div class="form-group">
                    <label for="">{{ $t("medicalRecord.s2_e5n1_s1") }}</label> <!-- Esférico -->
                    <input class="form-control" type="text" placeholder="0.00" v-maska maska-diopter data-test="inputMedicalRecordS2_e5n1_s1" v-model="medicalRecordData.s2.e5n1_s1">
                  </div>
                  <div class="form-group">
                    <label for="">{{ $t("medicalRecord.s2_e5n1_s2") }}</label> <!-- Cilíndrico -->
                    <input class="form-control" type="text" placeholder="0.00" v-maska maska-diopter data-test="inputMedicalRecordS2_e5n1_s2" v-model="medicalRecordData.s2.e5n1_s2">
                  </div>
                  <div class="form-group">
                    <label for="">{{ $t("medicalRecord.s2_e5n1_s3") }}</label> <!-- Eixo -->
                    <input class="form-control" type="text" placeholder="0.00" v-maska :data-maska="$t('inputMask.axis')" data-test="inputMedicalRecordS2_e5n1_s3" v-model="medicalRecordData.s2.e5n1_s3">
                  </div>
                  <div class="form-group">
                    <label for="">{{ $t("medicalRecord.s2_e5n1_s4") }}</label> <!-- Acuidade visual -->
                    <input class="form-control" type="text" data-test="inputMedicalRecordS2_e5n1_s4" v-model="medicalRecordData.s2.e5n1_s4">
                  </div>
                </div>

                <div class="col-md-6">
                  <h6 class="sub-title">{{ $t('medicalRecord.s2_e5n2') }}</h6> <!-- Estática (OD) -->
                  <div class="form-group">
                    <label for="">{{ $t("medicalRecord.s2_e5n2_s1") }}</label> <!-- Esférico -->
                    <input class="form-control" type="text" placeholder="0.00" v-maska maska-diopter data-test="inputMedicalRecordS2_e5n2_s1" v-model="medicalRecordData.s2.e5n2_s1">
                  </div>
                  <div class="form-group">
                    <label for="">{{ $t("medicalRecord.s2_e5n2_s2") }}</label> <!-- Cilíndrico -->
                    <input class="form-control" type="text" placeholder="0.00" v-maska maska-diopter data-test="inputMedicalRecordS2_e5n2_s2" v-model="medicalRecordData.s2.e5n2_s2">
                  </div>
                  <div class="form-group">
                    <label for="">{{ $t("medicalRecord.s2_e5n2_s3") }}</label> <!-- Eixo -->
                    <input class="form-control" type="text" placeholder="0.00" v-maska :data-maska="$t('inputMask.axis')" data-test="inputMedicalRecordS2_e5n2_s3" v-model="medicalRecordData.s2.e5n2_s3">
                  </div>
                  <div class="form-group">
                    <label for="">{{ $t("medicalRecord.s2_e5n2_s4") }}</label> <!-- Acuidade visual -->
                    <input class="form-control" type="text" data-test="inputMedicalRecordS2_e5n2_s4" v-model="medicalRecordData.s2.e5n2_s4">
                  </div>
                </div>
              </div>
            </div>
            <div id="bar" class="tab-pane fade">
              <div class="row">
                <div class="col-md-6">
                  <h6 class="sub-title">{{ $t('medicalRecord.s2_e5n3') }}</h6> <!-- Dinâmica (OE) -->
                  <div class="form-group">
                    <label for="">{{ $t("medicalRecord.s2_e5n3_s1") }}</label> <!-- Esférico -->
                    <input class="form-control" type="text" v-maska maska-diopter data-test="inputMedicalRecordS2_e5n3_s1" v-model="medicalRecordData.s2.e5n3_s1">
                  </div>
                  <div class="form-group">
                    <label for="">{{ $t("medicalRecord.s2_e5n3_s2") }}</label> <!-- Cilíndrico -->
                    <input class="form-control" type="text" v-maska maska-diopter data-test="inputMedicalRecordS2_e5n3_s2" v-model="medicalRecordData.s2.e5n3_s2">
                  </div>
                  <div class="form-group">
                    <label for="">{{ $t("medicalRecord.s2_e5n3_s3") }}</label> <!-- Eixo -->
                    <input class="form-control" type="text" v-maska :data-maska="$t('inputMask.axis')" data-test="inputMedicalRecordS2_e5n3_s3" v-model="medicalRecordData.s2.e5n3_s3">
                  </div>
                  <div class="form-group">
                    <label for="">{{ $t("medicalRecord.s2_e5n3_s4") }}</label> <!-- Acuidade visual -->
                    <input class="form-control" type="text" data-test="inputMedicalRecordS2_e5n3_s4" v-model="medicalRecordData.s2.e5n3_s4">
                  </div>
                </div>

                <div class="col-md-6">
                  <h6 class="sub-title">{{ $t('medicalRecord.s2_e5n4') }}</h6> <!-- Estática (OE) -->
                  <div class="form-group">
                    <label for="">{{ $t("medicalRecord.s2_e5n4_s1") }}</label> <!-- Esférico -->
                    <input class="form-control" type="text" v-maska maska-diopter data-test="inputMedicalRecordS2_e5n4_s1" v-model="medicalRecordData.s2.e5n4_s1">
                  </div>
                  <div class="form-group">
                    <label for="">{{ $t("medicalRecord.s2_e5n4_s2") }}</label> <!-- Cilíndrico -->
                    <input class="form-control" type="text" v-maska maska-diopter data-test="inputMedicalRecordS2_e5n4_s2" v-model="medicalRecordData.s2.e5n4_s2">
                  </div>
                  <div class="form-group">
                    <label for="">{{ $t("medicalRecord.s2_e5n4_s3") }}</label> <!-- Eixo -->
                    <input class="form-control" type="text" v-maska :data-maska="$t('inputMask.axis')" data-test="inputMedicalRecordS2_e5n4_s3" v-model="medicalRecordData.s2.e5n4_s3">
                  </div>
                  <div class="form-group">
                    <label for="">{{ $t("medicalRecord.s2_e5n4_s4") }}</label> <!-- Acuidade visual -->
                    <input class="form-control" type="text" data-test="inputMedicalRecordS2_e5n4_s4" v-model="medicalRecordData.s2.e5n4_s4">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <h3 class="sub-title">{{ $t('medicalRecord.s2_e6') }}</h3> <!-- Biomicroscopia -->
        <div class="tab-internal">
          <ul class="nav nav-tabs">
            <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" href="#OD_s2_e6">{{ $t('quickReport.rightEye') }}</a></li>
            <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#OE_s2_e6">{{ $t('quickReport.leftEye') }}</a></li>
          </ul>

          <div class="tab-content">
            <div id="OD_s2_e6" class="tab-pane fade in active show"> <!-- Biomicroscopia (OD) -->
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e6n1_s1") }}</label> <!-- Pálpebras -->
                <input class="form-control" type="text" data-test="inputMedicalRecordS2_e6n1_s1" v-model="medicalRecordData.s2.e6n1_s1">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e6n1_s2") }}</label> <!-- Conjuntivas -->
                <input class="form-control" type="text" data-test="inputMedicalRecordS2_e6n1_s2" v-model="medicalRecordData.s2.e6n1_s2">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e6n1_s3") }}</label> <!-- Córnea -->
                <input class="form-control" type="text" data-test="inputMedicalRecordS2_e6n1_s3" v-model="medicalRecordData.s2.e6n1_s3">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e6n1_s4") }}</label> <!-- Câmara anterior -->
                <input class="form-control" type="text" data-test="inputMedicalRecordS2_e6n1_s4" v-model="medicalRecordData.s2.e6n1_s4">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e6n1_s5") }}</label> <!-- Cristalino -->
                <input class="form-control" type="text" data-test="inputMedicalRecordS2_e6n1_s5" v-model="medicalRecordData.s2.e6n1_s5">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e6n1_s6") }}</label> <!-- Pupila -->
                <input class="form-control" type="text" data-test="inputMedicalRecordS2_e6n1_s6" v-model="medicalRecordData.s2.e6n1_s6">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e6n1_s7") }}</label> <!-- Outros -->
                <input class="form-control" type="text" data-test="inputMedicalRecordS2_e6n1_s7" v-model="medicalRecordData.s2.e6n1_s7">
              </div>
            </div>
            <div id="OE_s2_e6" class="tab-pane fade"> <!-- Biomicroscopia (OE) -->
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e6n2_s1") }}</label> <!-- Pálpebras -->
                <input class="form-control" type="text" data-test="inputMedicalRecordS2_e6n2_s1" v-model="medicalRecordData.s2.e6n2_s1">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e6n2_s2") }}</label> <!-- Conjuntivas -->
                <input class="form-control" type="text" data-test="inputMedicalRecordS2_e6n2_s2" v-model="medicalRecordData.s2.e6n2_s2">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e6n2_s3") }}</label> <!-- Córnea -->
                <input class="form-control" type="text" data-test="inputMedicalRecordS2_e6n2_s3" v-model="medicalRecordData.s2.e6n2_s3">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e6n2_s4") }}</label> <!-- Câmara anterior -->
                <input class="form-control" type="text" data-test="inputMedicalRecordS2_e6n2_s4" v-model="medicalRecordData.s2.e6n2_s4">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e6n2_s5") }}</label> <!-- Cristalino -->
                <input class="form-control" type="text" data-test="inputMedicalRecordS2_e6n2_s5" v-model="medicalRecordData.s2.e6n2_s5">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e6n2_s6") }}</label> <!-- Pupila -->
                <input class="form-control" type="text" data-test="inputMedicalRecordS2_e6n2_s6" v-model="medicalRecordData.s2.e6n2_s6">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e6n2_s7") }}</label> <!-- Outros -->
                <input class="form-control" type="text" data-test="inputMedicalRecordS2_e6n2_s7" v-model="medicalRecordData.s2.e6n2_s7">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <h3 class="sub-title">{{ $t('medicalRecord.s2_e7') }}</h3> <!-- TONOMETRIA -->
        <div class="tab-internal">
          <ul class="nav nav-tabs">
            <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" href="#OD_s2_e7">{{ $t('quickReport.rightEye') }}</a></li>
            <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#OE_s2_e7">{{ $t('quickReport.leftEye') }}</a></li>
          </ul>

          <div class="tab-content">
            <div id="OD_s2_e7" class="tab-pane fade in active show">  <!-- TONOMETRIA (OD) -->
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e7n1_s1") }}</label>
                <input class="form-control" type="text" data-test="inputMedicalRecordS2_e7n1_s1" v-model="medicalRecordData.s2.e7n1_s1"> <!-- PRESSÃO INTRA-OCULAR -->
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e7n1_s2") }}</label> <!-- HORA -->
                <date-picker
                  @update:modelValue="setTimeForTonometriaOD"
                  v-model="tonometriaTimeOD"
                  :format="'HH:mm'"
                  :minute-step="30"
                  timePicker
                  :selectText="$t('createClinic.select')"
                  :cancelText="$t('cancel')"
                  placeholder="HH:mm"
                  data-test="datePickerTonometriaOD">
                </date-picker>
              </div>
            </div>
            <div id="OE_s2_e7" class="tab-pane fade"> <!-- TONOMETRIA (OE) -->
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e7n2_s1") }}</label> <!-- PRESSÃO INTRA-OCULAR -->
                <input class="form-control" type="text" data-test="inputMedicalRecordS2_e7n2_s1" v-model="medicalRecordData.s2.e7n2_s1">
              </div>
              <div class="form-group">
                <label for="">{{ $t("medicalRecord.s2_e7n2_s2") }}</label> <!-- HORA -->
                <date-picker
                  @update:modelValue="setTimeForTonometriaOE"
                  v-model="tonometriaTimeOE"
                  :minute-step="30"
                  timePicker
                  :selectText="$t('createClinic.select')"
                  :cancelText="$t('cancel')"
                  placeholder="HH:mm"
                  data-test="datePickerTonometriaOE">
                </date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <h6 class="sub-title">{{ $t('medicalRecord.s2_e8') }}</h6> <!-- Fundo de Olho/Mapeamento de Retina -->
      <div class="form-group">
        <label for="">{{ $t("medicalRecord.s2_e8_s1") }}</label> <!-- Olho direito -->
        <input class="form-control" type="text" data-test="inputMedicalRecordS2_e8_s1" v-model="medicalRecordData.s2.e8_s1">
      </div>
      <div class="form-group">
        <label for="">{{ $t("medicalRecord.s2_e8_s2") }}</label> <!-- Olho esquerdo -->
        <input class="form-control" type="text" data-test="inputMedicalRecordS2_e8_s2" v-model="medicalRecordData.s2.e8_s2">
      </div>
    </div>

    <div>
      <h6 class="sub-title">{{ $t('medicalRecord.s2_e9') }}</h6> <!-- Observações -->
      <div class="form-group">
        <label for="">{{ $t("medicalRecord.s2_e9_s1") }}</label> <!-- Gerais -->
        <input class="form-control" type="text" data-test="inputMedicalRecordS2_e9_s1" v-model="medicalRecordData.s2.e9_s1">
      </div>
      <div class="form-group">
        <label for="">{{ $t("medicalRecord.s2_e9_s2") }}</label> <!-- Olho direito -->
        <input class="form-control" type="text" data-test="inputMedicalRecordS2_e9_s2" v-model="medicalRecordData.s2.e9_s2">
      </div>
      <div class="form-group">
        <label for="">{{ $t("medicalRecord.s2_e9_s3") }}</label> <!-- Olho esquerdo -->
        <input class="form-control" type="text" data-test="inputMedicalRecordS2_e9_s3" v-model="medicalRecordData.s2.e9_s3">
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.quill-editor {
  width:                         100%;
}
</style>

<script>
import {MaskInput} from 'maska'

export default {
  name: 'OphthalmologicalExaminationSection',
  props: ["medicalRecordData"],
  data: () => ({
    tonometriaTimeOD: null,
    tonometriaTimeOE: null,
  }),
  mounted() {
    new MaskInput("[maska-diopter]", {
      mask: (val) => {
        return val.includes('-') ? `-${this.$i18n.t('inputMask.diopter')}` : this.$i18n.t('inputMask.diopter')
      }
    })
  },
  methods: {
    setTimeForTonometriaOD(value) {
      this.setTonometriaTimeFor('e7n1_s2', value)
    },
    setTimeForTonometriaOE(value) {
      this.setTonometriaTimeFor('e7n2_s2', value)
    },
    setTonometriaTimeFor(target, value) {
      const date = new Date().setHours(value.hours, value.minutes, value.seconds)
      this.medicalRecordData.s2[target] = moment(date).format('HH:mm')
    }
  }
}
</script>
