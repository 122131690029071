<template>
  <div class="">
    <div class="form-floating mb-3">
      <vee-field name="firstName" v-model="account.firstName" rules="required" v-slot="{ field, errorMessage }">
        <input v-bind="field" type="text" class="form-control" ref="firstName" id="firstName" :placeholder="$t('createAccount.name')" data-test="inputAccountFirstName">
        <span class="vee-error">{{ errorMessage }}</span>
      </vee-field>
      <label for="firstName">{{ $t('createAccount.name') }}</label>
    </div>
    <div class="form-floating mb-3">
      <vee-field name="lastName" v-model="account.lastName" rules="required" v-slot="{ field, errorMessage }">
        <input v-bind="field" type="text" class="form-control" id="lastName" :placeholder="$t('createAccount.surname')" data-test="inputAccountLastName">
        <span class="vee-error">{{ errorMessage }}</span>
      </vee-field>
      <label for="lastName">{{ $t('createAccount.surname') }}</label>
    </div>
    <div class="form-floating mb-3">
      <vee-field name="phone" v-model="account.telephone" rules="required" v-slot="{ field, errorMessage }">
        <input v-bind="field" type="text" class="form-control" id="phone" ref="phone" :placeholder="$t('createAccount.telephone')" data-test="inputAccountPhone">
        <span class="vee-error">{{ errorMessage }}</span>
      </vee-field>
      <label for="phone">{{ $t('createAccount.telephone') }}</label>
    </div>
    <!-- <div class="form-floating mb-3">
      <input class="form-control" type="text" id="email" name="email" v-model="currentEmail" readonly :placeholder="$t('user.email')" data-test="inputAccountEmail">
      <label for="email">{{ $t('user.email') }}</label>
    </div> -->
    <div class="form-floating mb-3">
      <vee-field name="document" v-model="account.document" rules="required" v-slot="{ field, errorMessage }">
        <input v-bind="field" type="text" class="form-control" id="document" v-maska :data-maska="$t('inputMask.document2')" :placeholder="$t('user.document')" data-test="inputAccountDocument">
        <span class="vee-error">{{ errorMessage }}</span>
      </vee-field>
      <label for="cpf">{{ $t('user.document') }}</label>
    </div>
    <div class="form-floating mb-3">
      <input class="form-control" type="text" name="crm" v-model="account.npi" :placeholder="$t('report.npi')" data-test="inputAccountNpi">
      <label for="crm">{{ $t('report.npi') }}</label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.vee-error {
  width:                        100% !important;
}
</style>

<script>
export default {
  name: 'RegistrationUserFormStepOne',
  props: ['account', 'email'],
  data: () => ({
    currentEmail: "",
  }),
  created() {
    this.currentEmail = this.email
  },
  mounted() {

    this.$nextTick(() => {
      this.$refs.firstName.focus()
    })
  }
}
</script>
