<template>
  <div class="" v-loader.opacity.tab="loading">
    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <vee-form v-slot="{ handleSubmit }" as="div">
      <form ref="patientForm" @submit.prevent.stop="handleSubmit(doUpdate)">
        <div class="action-buttons rounded d-block" style="position: relative" :class="isPowerfull ? 'text-end': ''">
          <!-- <BaseFeature feature="iaAdvancedFunctions">
            <button class="btn btn-outline-primary" :class="isPowerfull ? '': ''" type="button" name="button" data-test="btnPatientProgress" @click="toPatientProgression">
              <span>{{ $t("exam.examTypeName_patient_progression") }}</span>
            </button>
          </BaseFeature> -->

          <!-- <BaseFeature feature="patientAccess"> -->
            <teleport to="#btnSharePatientShowSlot" v-if="isPatientMounted">
              <button class="btn btn-outline-secondary" v-if="!isSpecialist" type="button" name="button" data-test="btnPatientShare" @click="shareInformation">
                <IconShare/>
                <span>{{ $t('externalPatient.shareSimple') }}</span>
              </button>
            </teleport>
          <!-- </BaseFeature> -->
        </div>

        <PatientFormData v-if="patient != null"
          :patient="patient"
          :rightDiopter="rightDiopter"
          :leftDiopter="leftDiopter"
          :underlying="underlying"
          :ophthalmic="ophthalmic"
          :underlyingChange="underlyingChange"
          :ophthalmicChange="ophthalmicChange">
        </PatientFormData>

        <div class="action-bar">
          <button class="btn btn-danger" v-if="!disabled" type="button" name="button" data-test="btnCancel" @click="doCancel">
            <span>{{ $t("cancel") }}</span>
          </button>
          <button class="btn btn-success" v-if="hasFormChanged" type="submit" name="button" data-test="btnUpdate">
            <span>{{ $t("patient.updateAction") }}</span>
          </button>

          <button class="btn btn-secondary" type="submit" name="button" data-test="btnDownload">
            <span>Download</span>
          </button>

          <button class="btn btn-outline-danger" v-if="isPowerfull" type="button" name="button" data-test="btnRemove" @click="openModalDelete">
            <span>{{ $t("remove") }}</span>
          </button>
        </div>
      </form>
    </vee-form>

    <teleport to="body">
      <BaseGenericOffcanvas :offcanvas_id="'offcanvasPatientInfoGeneric'" ref="offcanvasGeneric"/>
      <BasePatientAccessInfoOffcanvas :patient="patient" v-if="showAccessInfoOffcanvas" @close="showAccessInfoOffcanvas = false"/>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.action-bar {
  display:                         flex;
  justify-content:                 flex-start;
  align-items:                     center;
  gap:                             10px;
  height:                          64px;
  padding:                         10px 20px;
  border-top:                      1px solid var(--Shades-30);
}

.btn {
  @include                         border_radius(99px);
  font-size:                       var(--font-size-12);
  font-weight:                     400;
  letter-spacing:                  3.64px;
  text-transform:                  uppercase;
}

.btn-outline-danger {
  @include                         border_radius(99px);
  color:                           var(--Complementary-Orange);
  font-weight:                     700;
  padding:                         15px 20px;
  height:                          44px;
  margin:                          0 !important;
  border:                          1px solid var(--Complementary-Orange);
  background:                      #FFEBE8;
}

.btn-success {
  color:                           var(--Shades-W);
  font-weight:                     700;
  height:                          44px;
  padding:                         15px 20px;
  background:                      var(--Brand-Secondary);
  border:                          1px solid var(--Brand-Secondary);
}

.btn-secondary {
  color:                           var(--Brand-Primary);
  font-weight:                     700;
  height:                          44px;
  padding:                         15px 20px;
  background:                      var(--Shades-10);
  border:                          1px solid var(--Shades-10);
}

.title-box .btn-outline-secondary {
  font-size:                       var(--font-size-12);
  font-size:                       normal;
  font-weight:                     400;
  margin:                          0 0 0 18px;
  padding:                         11.5px 15px 11.5px 10px;
}
</style>

<script>
import { useUserStore } from '@/store/user'
import PatientFormData from '../PatientFormData.vue'

export default {
  name: 'patient-create',
  props: ['patient_id'],
  components: {
    PatientFormData
  },
  data: () => ({
    loading: false,
    disabled: false,
    alert: {
      message: null,
      class: null,
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    isSpecialist: null,
    isPowerfull: null,
    isEditionMode: false,
    patient: {
      id: null,
      firstName: '',
      lastName: '',
      mrn: '',
      birthday: '',
      birthdayAux: '',
      document0: '',
      document1: '',
      document2: '',
      document3: '',
      mothersName: '',
      gender: 'M',
      weight: '',
      height: '',
      telephone1: '',
      telephone2: '',
      email: '',
      address: '',
      addressNumber: '',
      district: '',
      postalCode: '',
      city: '',
      state: '',
      country: '',
      other: '',
    },
    rightDiopter: {
      spherical: '',
      cylindrical: '',
      axis: '',
    },
    leftDiopter: {
      spherical: '',
      cylindrical: '',
      axis: '',
    },
    underlying: {
      diabetes: false,
      hypertension: false,
      cholesterol: false,
      smoker: false,
      nwn_underlying: false,
    },
    ophthalmic: {
      diabeticRetinopathy: false,
      dmri: false,
      glaucoma: false,
      cataract: false,
      pterygium: false,
      lowVisualAcuity: false,
      nwn_ophthalmic: false,
    },
    mandatoryDocument: null,
    showAccessInfoOffcanvas: false,
    loadedPatient: null,
    hasFormChanged: false,
    initialForm: {},
    changedFormItems: [],
    isPatientMounted: false,
    underlyingChange: false,
    ophthalmicChange: false,
  }),
  watch: {
    patient_id: {
      immediate: true,
      handler: function (patient_id) {
        if (patient_id) {
          this.disabled = true
          this.isEditionMode = true
          this.fetchData()

          this.$nextTick(() => {
            this.hasFormChanged = false
            this.changedFormItems = []
          })
        }
    }, deep: true },
    patient: {
      immediate: true,
      handler: function(newValue) {
        this.checkFormChange(newValue, 'patient')
    }, deep: true},
    rightDiopter: {
      immediate: true,
      handler: function(newRightDiopter) {
        this.checkFormChange(newRightDiopter, 'rightDiopter')
    }, deep: true},
    leftDiopter: {
      immediate: true,
      handler: function(newLeftDiopter) {
        this.checkFormChange(newLeftDiopter, 'leftDiopter')
    }, deep: true},
    underlying: {
      immediate: true,
      handler: function(newUnderlying) {
        this.underlyingChange = false

        this.checkFormChange(newUnderlying, 'underlying')

        this.$nextTick(() => {
          this.underlyingChange = true
        })
    }, deep: true},
    ophthalmic: {
      immediate: true,
      handler: function(newOphthalmic) {
        this.ophthalmicChange = false

        this.checkFormChange(newOphthalmic, 'ophthalmic')

        this.$nextTick(() => {
          this.ophthalmicChange = true
        })
    }, deep: true},
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    // console.log('this.session', this.session);
    this.isSpecialist = this.session.isSpecialist
    this.isPowerfull = this.session.isPowerfull

    this.mandatoryDocument = this.session.currentClinic.mandatoryDocument

    this.$nextTick(() => {
      this.isPatientMounted = true
    })
  },
  methods: {
    checkFormChange(value, obj) {
      let hasObj = this.changedFormItems.includes(obj)
      if (JSON.stringify(value) === JSON.stringify(this.initialForm[obj])) {
        if (hasObj) {
          let index = this.changedFormItems.indexOf(obj)
          this.changedFormItems.splice(index, 1)
        }

        if (this.changedFormItems.length > 0)
          return

        this.hasFormChanged = false
      } else {
        if (!hasObj)
          this.changedFormItems.push(obj)

        this.hasFormChanged = true
      }
    },
    doCancel() {
      if (!this.disabled) {
        this.disabled = !this.disabled;
        // temporary solution to clear fields that have been changed
        this.fetchData()
      } else {
        this.$router.push({name: 'patient-list'})
      }
    },
    async fetchData() {
      this.alert.message = null
      this.initialForm = {}

      let params = {
        id: this.patient_id
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$patientService.getPatient(params)
        this.patient = response.patient
        this.loadedPatient = response.patient

        const DATE_FORMAT  = this.$i18n.t('dateFormat')
        this.patient.birthdayAux = this.patient.birthday ? moment.utc(this.patient.birthday).format(DATE_FORMAT) : ""
        // console.log('DATE_FORMAT', DATE_FORMAT);

        // this.rightDiopter = response.diopter.right
        // this.leftDiopter = response.diopter.left
        console.log('responseeeeeeeeeeee ', response.patient);

        if (!_.isEmpty(response.diopter.right))
          this.rightDiopter = response.diopter.right

        if (!_.isEmpty(response.diopter.left))
          this.leftDiopter = response.diopter.left

        let anamnesis = response.patient.anamnesis ? response.patient.anamnesis : {}

        for (let key of Object.keys(this.ophthalmic)) {
          if (key in anamnesis)
            this.ophthalmic[key] = anamnesis[key]
        }

        for (let key of Object.keys(this.underlying)) {
          if (key in anamnesis)
            this.underlying[key] = anamnesis[key]
        }

        const formWrap = {
          patient: response.patient,
          rightDiopter: this.rightDiopter,
          leftDiopter: this.leftDiopter,
          underlying: this.underlying,
          ophthalmic: this.ophthalmic
        }

        this.initialForm = _.cloneDeep(formWrap)

        let rawName = this.patient.firstName + ' ' + this.patient.lastName
        this.$emit('patient_name', rawName)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async doUpdate() {
      this.alert.message = null;
      console.log('clicou');
      try {
        // TODO FIX check empty mandatory document on clinic configuration (match Eyer attribute)
        const mandatoryDocumentWasSet = !_.isEmpty(this.mandatoryDocument) && this.mandatoryDocument != 'none'
        console.log('mandatoryDocumentWasSet', mandatoryDocumentWasSet);
        console.log(_.isEmpty(this.patient[this.mandatoryDocument]));
        if (mandatoryDocumentWasSet) {
          if (_.isEmpty(this.patient[this.mandatoryDocument]))
            return
        }

        this.patient.birthday = this.patient.birthdayAux

        let anamnesis = {}
        Object.assign(anamnesis, this.underlying)
        Object.assign(anamnesis, this.ophthalmic)

        let params = {
          patient: this.patient,
          anamnesis: anamnesis,
          rightDiopter: this.rightDiopter,
          leftDiopter: this.leftDiopter,
        }

        console.log(params);

        this.loading = true
        NProgress.start()
        let response = await this.$patientService.updatePatient(params)
        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')
        // this.disabled = true
        // this.isEditionMode = true
        this.fetchData()

        let data = this.$utils.dataQuery({
          patientName: response.result.rawName
        })

        this.$router.push({name: 'patient-show', params: {patientID: response.result.id}, query: {tab: 'data', data: data}})
      } catch(err) {
        console.log(err, 'err catc');
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async openModalDelete() {
      const ok = await this.$refs.offcanvasGeneric.show({
        title: this.$i18n.t('confirm'),
        text: this.$i18n.t('patient.removalConfirmation'),
        okButton: this.$i18n.t('remove'),
        okButtonClass: 'btn-danger',
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        this.doDelete()
      }
    },
    async doDelete() {
      this.alert.message = null

      let params = {
        id: this.patient_id
      }
      this.loading = true
      NProgress.start()
      try {
        await this.$patientService.deletePatient(params)
        this.$message.popup(this, 'general.removedSuccess', 'operationSuccess', 'success')

        this.$router.push({name: 'patient-list', params: {patientID: this.patient_id}})
      } catch (err) {
        this.$message.popup(this, 'general.removedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.removedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    toPatientProgression() {
      this.$router.push({name: 'patient-progression', params: {patientID: this.patient_id}})
    },
    shareInformation() {
      this.showAccessInfoOffcanvas = true

      setTimeout(function() {
        let accessInfoOffcanvas = document.getElementById('accessInfoOffcanvas');
        new bootstrap.Offcanvas(accessInfoOffcanvas).show();
      }, 100);
      // this.showAccessInfoModal = true
      //
      // setTimeout(function() {
      //   let access_info_modal = document.getElementById('access_info_modal');
      //   new bootstrap.Modal(access_info_modal).show();
      // }, 100);
    }
  }
}
</script>
