<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <circle cx="10.5" cy="10" r="9.66667" fill="#265D6E" stroke="#265D6E" stroke-width="0.666667"/>
    <path d="M14.4994 14.3334L6.5 6.33398" stroke="#A5EDD5" stroke-width="1.33333"/>
    <path d="M14.4994 6.33356L6.5 14.333" stroke="#A5EDD5" stroke-width="1.33333"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconCross'
}
</script>
