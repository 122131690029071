<template>
  <div class="flex">
    <h3 class="sub-title">{{ $t('patient.ophthalmicDiseases') }}</h3>
    <div class="form-check">
      <BaseSquareCheckBox id="diabeticRetinopathy" :item="'diabeticRetinopathy'" @checked="doCheck"/>
      <!-- <input class="form-check-input" type="checkbox" value="" id="diabeticRetinopathy" data-test="checkboxOphtalmicDiabetic" v-model="ophthalmic.diabeticRetinopathy" :disabled="disabled"> -->
      <label class="form-check-label" for="diabeticRetinopathy">
        {{ $t('disease.diabeticRetinopathy') }}
      </label>
    </div>
    <div class="form-check">
      <BaseSquareCheckBox id="dmri" :item="'dmri'" @checked="doCheck"/>
      <!-- <BaseCheckBox :id="item.name" :value="item.uuid" :item="item" @checked="doCheck"></BaseCheckBox> -->
      <!-- <input class="form-check-input" type="checkbox" value="" id="dmri" v-model="ophthalmic.dmri" data-test="checkboxOphtalmicDmri" :disabled="disabled"> -->
      <label class="form-check-label" for="dmri">
        {{ $t('disease.amd') }}
      </label>
    </div>
    <div class="form-check">
      <BaseSquareCheckBox id="glaucoma" :item="'glaucoma'" @checked="doCheck"/>
      <!-- <input class="form-check-input" type="checkbox" value="" id="glaucoma" v-model="ophthalmic.glaucoma" data-test="checkboxOphtalmicGlaucoma" :disabled="disabled"> -->
      <label class="form-check-label" for="glaucoma">
        {{ $t('disease.glaucoma') }}
      </label>
    </div>
    <div class="form-check">
      <BaseSquareCheckBox id="cataract" :item="'cataract'" @checked="doCheck"/>
      <!-- <input class="form-check-input" type="checkbox" value="" id="cataract" v-model="ophthalmic.cataract" data-test="checkboxOphtalmicCataract" :disabled="disabled"> -->
      <label class="form-check-label" for="cataract">
        {{ $t('disease.cataract') }}
      </label>
    </div>
    <div class="form-check">
      <BaseSquareCheckBox id="pterygium" :item="'pterygium'" @checked="doCheck"/>
      <!-- <input class="form-check-input" type="checkbox" value="" id="pterygium" v-model="ophthalmic.pterygium" data-test="checkboxOphtalmicPterygium" :disabled="disabled"> -->
      <label class="form-check-label" for="pterygium">
        {{ $t('disease.pterygium') }}
      </label>
    </div>
    <div class="form-check">
      <BaseSquareCheckBox id="lowVisualAcuity" :item="'lowVisualAcuity'" @checked="doCheck"/>
      <!-- <input class="form-check-input" type="checkbox" value="" id="lowVisualAcuity" v-model="ophthalmic.lowVisualAcuity" data-test="checkboxOphtalmicLowVisual" :disabled="disabled"> -->
      <label class="form-check-label" for="lowVisualAcuity">
        {{ $t('disease.lowVisualAcuity') }}
      </label>
    </div>
    <div class="form-check">
      <BaseSquareCheckBox id="nwn_ophthalmic" :item="'nwn_ophthalmic'" @checked="doCheck"/>
      <!-- <input class="form-check-input" type="checkbox" value="" id="nwn_ophthalmic" v-model="ophthalmic.nwn_ophthalmic" data-test="checkboxOphtalmicNwn" :disabled="disabled"> -->
      <label class="form-check-label" for="nwn_ophthalmic">
        {{ $t('disease.nwn_ophthalmic') }}
      </label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .flex {
    display:                 flex;
    flex-direction:          column;
    align-items:             flex-start;
    gap:                     19px;
    padding:                 33px 0;
  }

  .sub-title {
    color:                   var(--Shades-60, #727272);
    font-size:               16px;
    font-weight:             500;
    line-height:             normal;
    letter-spacing:          3.2px;
    text-transform:          uppercase;
    text-overflow:           ellipsis;
    white-space:             nowrap;
    overflow:                hidden;
    border:                  0;
    margin:                  0;
  }

  .form-check {
    @include                 border_radius(5px);
    display:                 flex;
    flex-direction:          row;
    justify-content:         center;
    align-items:             center;
    gap:                     10px;
    align-self:              stretch;
    padding:                 8px 12px;
    border:                  1px solid var(--Shades-30, #D8D8D8);
    background:              var(--Shades-W, #FFF);

    label {
      color:                 var(--Brand-Primary, #1B294A);
      font-size:             13px;
      font-weight:           400;
      line-height:           normal;
      letter-spacing:        1.95px;
      text-transform:        uppercase;
      margin:                0;
    }
  }
</style>

<script>
export default {
  name: 'PatientCreateOphthalmicDiseases',
  props: ['ophthalmic'],
  data: () => ({
  }),
  mounted() {
    // console.log('mounted ophthalmic');
    if (this.ophthalmic) {
      this.updateCheck()
    }
    // setTimeout(() => {
    // }, 100)
  },
  methods: {
    updateCheck() {
      // console.log('abriu check ophthalmic');
      for (const [key, value] of Object.entries(this.ophthalmic)) {
        if (value) {
          document.getElementById(key).click()
          // console.log('clicou? ophthalmic');
        }
      }
    },
    doCheck(item) {
      for (const [key, value] of Object.entries(this.ophthalmic)) {
        if (key == item.value) {
          this.ophthalmic[key] = item.checked
        }
      }
    }
  }
}
</script>
