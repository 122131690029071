const AI_CLASS_THRESHOLD = 0.2

const MULTIPLE_HEADER_CLASSES = ['c3', 'c4', 'c5']

class AIService {

  alterationScore(metadata) {
    if (!metadata)
      return null

    let data = JSON.parse(metadata)

    // TODO yamanaka ainda vamos utilizar o mcras???
    // if (data.mcRAS)
    //   return this.mcrasScore(data)

    return this.rasScore(data)
  }

  rasScore(metadata) {
    let scoreResult = {
      os: null,
      od: null,
      osText: null,
      odText: null,
      osStatus: null,
      odStatus: null
    }

    scoreResult.od = metadata.maxAlterationScoreOD
    scoreResult.os = metadata.maxAlterationScoreOS

    scoreResult.odText = scoreResult.od ? this.formatAlterationScore(scoreResult.od) : '-'
    scoreResult.osText = scoreResult.os ? this.formatAlterationScore(scoreResult.os) : '-'

    if (scoreResult.os >= 0.7) {
      scoreResult.osStatus = "red" // danger
    } else if (scoreResult.os < 0.7 && scoreResult.os > 0.3) {
      scoreResult.osStatus = "yellow" // warning
    } else if (scoreResult.os <= 0.3) {
      scoreResult.osStatus = "green" // success
    }

    if (scoreResult.od >= 0.7) {
      scoreResult.odStatus = "red" // danger
    } else if (scoreResult.od < 0.7 && scoreResult.od > 0.3) {
      scoreResult.odStatus = "yellow" // warning
    } else if (scoreResult.od <= 0.3) {
      scoreResult.odStatus = "green" // success
    }

    // console.log('score result ai service', scoreResult);

    return scoreResult
  }

  mcrasScore(metadata) {
    const mcRAS = metadata.mcRAS

    let scoreResult = {
      os: null,
      od: null,
      osText: null,
      odText: null,
      status: null
    }

    let maxScore = 0
    for (let i in mcRAS) {
      if (i == 0) // ignoring first class (normal)
        continue

      let value = mcRAS[i]
      if (value > maxScore)
        maxScore = value
    }

    if (maxScore < 0.2) {
      scoreResult.status = "green"
    } else if (maxScore >= 0.2 && maxScore < 0.6) {
      scoreResult.status = "yellow"
    } else {
      scoreResult.status = "red"
    }

    return scoreResult
  }


  mcRASHeader(exam) {
    const aiClasses = this.mcRASClasses(exam)

    if (aiClasses.length == 0)
      return ""

    if (aiClasses.length > 1)
      return "multiple"

    const singleClass = aiClasses[0]
    if (MULTIPLE_HEADER_CLASSES.includes(singleClass))
      return "multiple" // "Suspeita de uma das seguintes condições:"

    return "single"
  }

  mcRASClasses(exam) {
    let result = []
    try {
      if (!exam.metadata)
        return []

      let data = JSON.parse(exam.metadata)

      for (let i in data.mcRAS) {
        if (i == 0) // ignoring first class (normal)
          continue

        let value = data.mcRAS[i]
        if (value >= AI_CLASS_THRESHOLD)
          result.push(`c${i}`)
      }
    } catch(err) {
      console.error("Error parsing metadata.", err)
    }

    return result
  }

  hasMCRAS(exam) {
    return this.mcRASClasses(exam).length > 0
  }

  formatAlterationScore(score) {
    if (score === "LQ")
      return score

    let num = (score * 100).toString()
    let idx = num.indexOf('.')
    if (idx == -1) {
      idx = num.length
    }
    return `${num.substring(0, idx)}%`
  }


dragasClasses(exam) {
    let result = {
      os: [],
      od: []
    }
    let eyes = {
      os: null,
      od: null
    }

    try {
      if (!exam.metadata)
        return []

      let data = JSON.parse(exam.metadata)

      if (!data.dragas)
        return []

      let isDrHigh
      for (const obj in data.dragas) {
        if (obj) {
          for (const [key, value] of Object.entries(data.dragas[obj])) {
            let dragas = {
              text: null
            }
            if (key == 'noAlteration') // ignoring noAlteration class (normal)
              continue

            if (key == 'dr' && value >= AI_CLASS_THRESHOLD)
              isDrHigh = true

            if (value >= AI_CLASS_THRESHOLD) {
              if ((key == 'hasEdema' && value && !isDrHigh) || (key == 'hasEdema' && !value))
                continue

              dragas.text = key

              if (value >= AI_CLASS_THRESHOLD && value < 0.7) {
                eyes[obj] = eyes[obj] != 'red' ? 'yellow' : eyes[obj]
              } else {
                eyes[obj] = eyes[obj] == 'yellow' || eyes[obj] == 'green' || eyes[obj] == null ? 'red' : eyes[obj]
              }
            } else {
              eyes[obj] = eyes[obj] != 'yellow' && eyes[obj] != 'red' ? 'green' : eyes[obj]
            }

            if (dragas.text && result[obj] && !result[obj].includes(dragas.text))
              result[obj].push(dragas)
          }

          if (result[obj] && eyes)
              result[obj].push(eyes)
        }
      }
      return result
    } catch(err) {
      console.error("Error parsing dargas metadata.", err)
    }
  }
}

const aiService = new AIService()
export default aiService
