<template>
  <div class="">
    <div class="title-box info clear">
      <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
        <IconBack/>
      </button>

      <h2 class="title sub">
        {{ $t('patient.patient') }} |
        <span>{{ patientName }}</span>
      </h2>
    </div>

    <BaseTab :tabs="tabs" :initialtab="tabActive" :key="tabActive" @tab-activated="switchTab">
      <template v-slot:tab-head-data>
        {{ $t('patient.data') }}
      </template>
      <template v-slot:data>
        <EyerwebPatientInfoTab v-if="tabActive === 'data' && isDataReady" :patient_id="patientId" @patient_name="addPatientName"></EyerwebPatientInfoTab>
      </template>

      <template v-slot:tab-head-exams>
        {{ $t('patient.exams') }}
      </template>
      <template v-slot:exams>
        <EyerwebPatientExamTab v-if="tabActive === 'exams'" :patient_id="patientId" @patient_name="addPatientName" ></EyerwebPatientExamTab>
      </template>
    </BaseTab>
  </div>
</template>

<style lang="scss">
.title-box {
  .btn-back {
    margin-right:              20px;
  }
  .title.sub {
    cursor:                    pointer;
  }
}
</style>

<script>
import { useEyerwebStore } from '@/store/eyerweb'
import EyerwebPatientInfoTab from './EyerwebPatientInfoTab.vue'
import EyerwebPatientExamTab from './EyerwebPatientExamTab.vue'

export default {
  name: 'eyerweb-patient-show',
  components: {
    EyerwebPatientInfoTab,
    EyerwebPatientExamTab,
  },
  data: () => ({
    loading: null,
    alert: {
      message: null,
      class: null
    },
    tabActive: 'data',
    tabs: ['data'],
    patientName: null,
    isDataReady: false,
  }),
  watch: {
    patientId: {
      immediate: true,
      handler: function (patient_id) {
        console.log('watch handler')
        if (patient_id){
          this.addTabs()
        }
    }, deep: true }
  },
  computed: {
    patientId() {
      return this.$route.params.patientID;
    },
    tab() {
      return this.$route.query.tab;
    },
  },
  mounted() {
    this.$activeLink.value = 'patient'

    if (this.$route.query.data) {
      let data = this.$utils.parseDataQuery(this.$route.query.data)
      if (data.patientName)
        this.patientName = data.patientName
    }

    if (this.tab) {
      this.tabActive = this.tab;
    }

    this.isDataReady = true

  },
  methods: {
    switchTab(tab) {
      this.tabActive = tab;
    },
    goBack() {
      this.$router.push({name: 'eyerweb-patient-list'})
    },
    addTabs() {
      const eyerwebStore = useEyerwebStore()
      this.session = eyerwebStore.sessionInfo

      if (this.tabs.includes('exams') && this.tabs.includes('records'))
        return

      this.tabs.push('exams')
    },
    addPatientName(value) {
      this.patientName = value
    }
  }
}
</script>
