<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" :id="offcanvas_id" :aria-labelledby="`${offcanvas_id}_label`">
    <div class="offcanvas-header">
      <button class="btn-back" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" :id="`${offcanvas_id}_label`">{{ title }}</h5>
    </div>
    <div class="offcanvas-body">
      <vee-form v-slot="{ handleSubmit }" as="div">
        <form @submit.stop.prevent="handleSubmit(doConfirm)">
          <p>{{ $t('user.chooseTheAppropriatePermission') }}</p>

          <div class="form-floating v-select-floating">
            <v-select
              id="permission"
              class="v-select form"
              :placeholder="$t('user.permissions')"
              v-model="permissionSelected"
              data-test="vSelectPermission"
              :reduce="x => x.value"
              :options="permissionOptions">
            </v-select>
            <label for="permission">{{ $t('user.permissions') }}</label>
          </div>

          <!-- footer inside body to avoid empty space between body and footer -->
          <div class="offcanvas-footer">
            <button type="submit" class="btn" :class="okButtonClass" data-bs-dismiss="offcanvas" data-test="btnConfirm" @click="doConfirm">{{ okButton }}</button>
            <button type="button" class="btn" :class="cancelButtonClass" data-bs-dismiss="offcanvas" v-show="cancelButton" data-test="btnCancel" @click="doCancel">{{ cancelButton }}</button>
          </div>
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" data-test="btnCancel" @click="close()">{{ $t('user.cancel') }}</button>
            <button type="submit" class="btn btn-primary" data-test="btnSend">{{ $t('user.send') }}</button>
          </div> -->
        </form>
      </vee-form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  width:                                510px !important;
  padding:                              40px;

  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    p {
      color:                            var(--Brand-Primary, #1B294A);
      font-size:                        var(--font-size-14);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      margin-bottom:                    0;
    }

    form {
      display:                          flex;
      flex-direction:                   column;
      gap:                              26px;
    }
  }

  .offcanvas-footer {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    .btn {
      font-size:                        var(--font-size-14);
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   4.2px;
      text-align:                       center;
      text-transform:                   uppercase;
      // gap:                              10px;
      // flex:                             1 0 0;
      width:                            100%;
      padding:                          20px;
    }

    .btn-success {
      color:                            var(--Shades-W, #FFF);
      // margin-top:                       26px;
    }
  }
}
</style>

<script>
export default {
  name: 'BaseInvitationOffcanvas',
  props: ['offcanvas_id', 'user'],
  data: () => ({
    title: null,
    text: null,
    okButton: null,
    okButtonClass: null,
    cancelButton: null,
    cancelButtonClass: null,
    offcanvas_invitation: null,
    permissionOptions: [],
    permissionSelected: 'admin',
  }),
  mounted() {
    this.permissionOptions = [
      {value: 'admin', label: this.$i18n.t('admin')},
      {value: 'physician', label: this.$i18n.t('physician')},
      {value: 'technician', label: this.$i18n.t('technician')},
      {value: 'specialist', label: this.$i18n.t('specialist')}
    ];

    if (this.user) {
      this.permissionSelected = this.user.permissions
    }
    // this.permissionSelected = this.exam.status
  },
  methods: {
    show(options = {}) {
      if (options.title)
        this.title = options.title

      if (options.text)
        this.text = options.text

      if (options.okButton) {
        this.okButton = options.okButton
        this.okButtonClass = options.okButtonClass
      }

      if (options.cancelButton) {
        this.cancelButton = options.cancelButton
        this.cancelButtonClass = options.cancelButtonClass
      }

      this.offcanvas_invitation = new bootstrap.Offcanvas(document.getElementById(this.offcanvas_id)).show();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.doChange()
      // this.offcanvas_invitation = null;
      // this.resolvePromise(true)
    },

    doCancel() {
      this.offcanvas_invitation = null;
      this.resolvePromise(false)
    },

    async doChange() {
      NProgress.start()
      try {
        await this.$userService.changePermission(this.user.id, this.permissionSelected);
        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')

        this.offcanvas_invitation = null;
        this.resolvePromise(true)
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    },
  }
}
</script>
