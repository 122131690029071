<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 22" fill="none">
    <circle cx="10.1059" cy="6.1936" r="5.37692" stroke="#1B294A"/>
    <path d="M1.62674 18.4827C1.41856 18.3613 1.2528 18.2584 1.134 18.1824C1.27472 13.3479 5.23803 9.47198 10.1066 9.47198C15.2495 9.47198 18.1016 12.4544 18.3139 15.2399C18.4201 16.6323 17.883 18.0324 16.5856 19.0987C15.2797 20.172 13.1688 20.9318 10.1066 20.9318C7.58116 20.9318 5.28208 20.2109 3.60513 19.4816C2.76865 19.1178 2.09236 18.7543 1.62674 18.4827Z" stroke="#72DBB8"/>
    <circle cx="20.617" cy="10.4425" r="3.14625" stroke="#72DBB8"/>
    <path d="M15.4515 17.0735C15.3718 17.027 15.3024 16.985 15.2441 16.9489C15.3834 14.102 17.7357 11.8366 20.6171 11.8366C23.7372 11.8366 25.3978 13.6367 25.52 15.2399C25.5811 16.0412 25.2743 16.8501 24.5164 17.4729C23.7499 18.1029 22.4877 18.5671 20.6171 18.5671C19.0849 18.5671 17.6851 18.1294 16.659 17.6831C16.148 17.4609 15.7351 17.2389 15.4515 17.0735Z" stroke="#1B294A"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconUsers'
}
</script>
