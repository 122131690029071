<template>
  <div class="quick-report">
    <div id="OD" class="">
      <ReportQuickDiagnosisSelectionTab
        v-if="hasData"
        :laterality="'right'"
        :exam="exam"
        :entries="selectedEntries.right"
        @selected-entries="rightEyeSelection">
      </ReportQuickDiagnosisSelectionTab>
    </div>
    <div id="OE" class="">
      <ReportQuickDiagnosisSelectionTab
        v-if="hasData"
        :laterality="'left'"
        :exam="exam"
        :entries="selectedEntries.left"
        @selected-entries="leftEyeSelection">
      </ReportQuickDiagnosisSelectionTab>
    </div>

    <!-- <div class="form-floating">
      <textarea v-model="observationValue" @change="entriesChanged" class="form-control" name="observations" id="quickObservation"
                rows="4" :placeholder="$t('quickReport.observation')" data-test="textareaObservations">
      </textarea>
      <label for="quickObservation">{{ $t('quickReport.observation') }}</label>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.quick-report {
  display:                              flex;
  flex-direction:                       column;
  gap:                                  19px;
}
</style>

<script>
import ReportQuickDiagnosisSelectionTab from './ReportQuickDiagnosisSelectionTab.vue'

export default {
  name: 'ReportQuick',
  props: ['exam', 'observation', 'loaded_entries'],
  components: {
    ReportQuickDiagnosisSelectionTab,
  },
  data: () => ({
    loading: true,
    hasData: false,
    selectedEntries: {
      right: ['000_1'],
      left: ['000_1']
    },
    // observationValue: ""
  }),
  mounted () {
    const hasEntries = !_.isEmpty(this.loaded_entries.right) || !_.isEmpty(this.loaded_entries.left)
    if (hasEntries) {
      this.selectedEntries = this.loaded_entries
    }

    this.entriesChanged() // force parent update
    // this.observationValue = this.observation ? this.observation : ""

    this.hasData = true
  },
  methods: {
    rightEyeSelection(entries) {
      this.selectedEntries.right = entries.filter(x => !_.isEmpty(x)).sort()
      this.entriesChanged()
    },
    leftEyeSelection(entries) {
      this.selectedEntries.left = entries.filter(x => !_.isEmpty(x)).sort()
      this.entriesChanged()
    },
    entriesChanged() {
      let quickReportValues = {
        selectedEntries: this.selectedEntries,
        // observation: this.observationValue,
      }

      this.$emit('entries-changed', quickReportValues);
    }
  }
}
</script>
