<template>
  <div class="login-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <!-- <div class="">
            <h1 class="slogan-eyer d-inline-block">
              <span>potente</span>
              <span>versátil</span>
              <span>prático</span>
              <span>simples</span>
              <span>assim.</span>
            </h1>

            <img src="@/assets/images/phelcom/eyer2.png" alt="">
          </div>

          <div class="">
            <img src="@/assets/images/phelcom/eyer2_logo.svg" alt="">
            <button class="btn btn-success" type="button" name="button">saiba mais sobre</button>
          </div> -->
        </div>

        <div class="col-lg-5 flex-between">
          <div class=""></div>

          <div class="login-box">
            <img src="@/assets/images/phelcom/eyercloud_logo.svg" alt="">
            <div  v-if="login">
              <h5>{{ $t('login.accessAccount') }}</h5>

              <form class="">
                <div class="form-floating mb-3">
                  <input type="email" class="form-control" id="identifier" :placeholder="$t('login.email')" v-model="identifier" data-test="inputEmail">
                  <label for="identifier">{{ $t('login.email') }}</label>
                </div>

                <div class="form-floating mb-3">
                  <input type="password" class="form-control" id="password" :placeholder="$t('login.password')" v-model="password" data-test="inputPassword" @keyup.enter="doLogin()">
                  <label for="password">{{ $t('login.password') }}</label>
                  <button class="btn-icon" type="button" name="button" data-test="btnShowPassword" @click="showPassword">
                    <IconShowPassword/>
                  </button>
                </div>

                <a @click="doSwitch">{{ $t('login.forgotPassword') }}</a>

                <div>
                  <vue-recaptcha
                    v-if="isProduction"
                    ref="recaptcha"
                    @verify="onLoginVerify"
                    @expired="onExpired"
                    :sitekey=sitekey
                    size="invisible"
                    :load-recaptcha-script="true">
                  </vue-recaptcha>
                  <button class="btn btn-success" type="button" name="button" data-test="btnLogin" @click="doLogin()">{{ $t('login.login') }}</button>
                </div>

                <p>{{ $t('login.dontHaveAccount') }}</p>

                <button class="btn btn-default" type="button" name="button" data-test="btnAboutEyer">saber mais sobre o eyer</button>
              </form>
            </div>

            <div class="recovery-box" v-else>
              <div v-if="!isEmailSent">
                <h5>{{ $t('login.forgotPasswordInfo') }}</h5>

                <p class="text-start">{{ $t('login.forgotPasswordInstruction') }}</p>

                <form>
                  <div class="form-floating mb-3">
                    <input class="form-control ease-all" type="email" name="recoveryEmail" :placeholder="$t('login.email')" v-model="recoveryEmail" @keydown.enter="$event.preventDefault(); doRecoverPass()">
                    <label for="recoveryEmail">{{ $t('login.email') }}</label>
                  </div>
                </form>

                <div>
                  <vue-recaptcha
                    v-if="isProduction"
                    ref="recaptcha"
                    @verify="onPwdRecoverVerify"
                    @expired="onExpired"
                    :sitekey=sitekey
                    size="invisible"
                    :load-recaptcha-script="true">
                  </vue-recaptcha>
                  <button class="btn btn-success" type="button" name="button" data-test="btndoRevocerPass" @click.stop.prevent="doRecoverPass()">{{ $t('login.sendEmail') }}</button>
                </div>

                <p>{{ $t('login.rememberedPassword') }}</p>

                <button class="btn btn-default" type="button" name="button" data-test="btnDoLoginRecover" @click="doSwitch">{{ $t('login.doLogin') }}</button>
              </div>

              <div v-else>
                <h5>{{ $t('login.recoveryEmailSent') }}</h5>

                <p>{{ $t('login.recoveryEmailInstruction') }}</p>

                <button class="btn btn-success" type="button" name="button" data-test="btnBackToLogin" @click="doSwitch">{{ $t('login.backToLogin') }}</button>
              </div>
            </div>
          </div>

          <div class="powered-box">
            <p class="d-inline-block">POWERED BY</p>
            <a href="https://phelcom.com/" class="logo d-inline-block" target="_blank" data-test="anchorPhelcomLink">
              <img src="@/assets/images/phelcom/phelcom_logo_horizontal_blue.svg" alt="">
            </a>
            <p class="d-inline-block">v {{ appVersion }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .login-page {
    padding:                                 8px;
    height:                                  100vh;
    .container {
      height:                                100%;
      max-width:                             unset !important;
    }
    // .btn {
    //   @include                               border_radius(10px);
    //   font-size:                             var(--font-size-17);
    //   font-weight:                           700;
    //   letter-spacing:                        5px;
    //   line-height:                           normal;
    //   text-transform:                        uppercase;
    //   padding:                               20px;
    // }
    .row {
      height:                                100%;
    }
    .col-lg-7 {
      @include                               border_radius(10px);
      display:                               flex;
      flex-direction:                        column;
      justify-content:                       space-between;
      background:                            linear-gradient(252deg, #000 0%, #0D4636 100%);
      // background:                            url('@/assets/images/phelcom/eyer2_number.png') no-repeat right center, linear-gradient(252deg, #000 0%, #0D4636 100%);
      padding:                               5rem 3rem 3rem;
      > div {
        &:first-child {
          display:                           flex;
          justify-content:                   space-between;
          align-items:                       end;
          img {
            max-width:                       calc(100% - 277px);
            max-height:                      60vh;
          }
        }
        &:last-child {
          display:                           flex;
          align-items:                       center;
          justify-content:                   space-between;
        }
      }

      .slogan-eyer {
        font-family:                         'Flexo';
        line-height:                         normal;
        text-transform:                      uppercase;
        margin-bottom:                       4rem;
        span {
          background:                        linear-gradient(180deg, #FFF 0%, #BEBEBE 100%);
          background-clip:                   text;
          -webkit-background-clip:           text;
          -webkit-text-fill-color:           transparent;
          display:                           block;
          &:first-child {
            font-size:                       39.446px;
            font-weight:                     800;
            letter-spacing:                  13.6px;
          }
          &:nth-child(2) {
            font-size:                       40.411px;
            font-weight:                     550;
            letter-spacing:                  11.1px;
          }
          &:nth-child(3) {
            font-size:                       40.411px;
            font-weight:                     250;
            letter-spacing:                  17.17px;
          }
          &:nth-child(4),
          &:last-child {
            background:                      linear-gradient(180deg, #3CB391 38.01%, #3CB3B3 105.83%);
            background-clip:                 text;
            -webkit-background-clip:         text;
            -webkit-text-fill-color:         transparent;
            font-size:                       53.217px;
            font-weight:                     600;
            letter-spacing:                  6.9px;
          }
          &:last-child {
            letter-spacing:                  15.16px;
          }
        }
      }
    }

    .col-lg-5 {
      flex-direction:                        column;
      .login-box {
        width:                               70%;
        max-width:                           400px;
        p, a {
          font-size:                         var(--font-size-14);
          font-weight:                       400;
          line-height:                       normal;
          letter-spacing:                    0.7px;
          text-align:                        center;
          margin:                            1rem 0;
        }
        h5, a {
          color:                             var(--Brand-Primary, #1B294A);
        }
        h5 {
          font-size:                         var(--font-size-22);
          font-weight:                       300;
          margin:                            1.8rem 0;
        }
        p {
          color:                             var(--Shades-90, #101010);
          margin-top:                        1.5rem;
        }
        a {
          display:                           block;
          text-decoration:                   underline;
          cursor:                            pointer;
        }
        .btn {
          width:                             100%;
        }
        .recovery-box {
          p {
            &:not(.text-start) {
              color:                         var(--Shades-90, #101010);
            }
            &.text-start {
              font-weight:                   300;
              margin-bottom:                 1.8rem;
            }
          }
        }
      }
      .powered-box {
        display:                             flex;
        justify-content:                     flex-end !important;
        align-items:                         center;
        p {
          color:                             var(--Brand-Primary, #1B294A);
          font-size:                         var(--font-size-10);
          font-weight:                       400;
          letter-spacing:                    2.15px;
          margin:                            0;
        }
        .logo {
          color:                             #1B294A;
          width:                             101px;
          // font-size:                         1.25em;
          margin:                            0 15px;
          text-decoration:                   none;
        }
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .login-page {
      .col-lg-7 {
        padding-top:                         8rem;
        > div {
          &:first-child {
            img {
              max-width:                     calc(100% - 180px);
            }
          }
          &:last-child {
            .btn {
              width:                         250px;
            }
          }
        }
        .slogan-eyer {
          margin-bottom:                     2rem;
          span {
            &:first-child {
              font-size:                     25.446px;
            }
            &:nth-child(2) {
              font-size:                     26.411px;
            }
            &:nth-child(3) {
              font-size:                     23.411px;
            }
            &:nth-child(4),
            &:last-child {
              font-size:                     38.217px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1600px) {
    .login-page {
      .col-lg-7 {
        padding-top:                         4rem;
        > div {
          &:first-child {
            img {
              max-width:                     calc(100% - 230px);
            }
          }
          &:last-child {
            .btn {
              width:                         300px;
            }
          }
          .slogan-eyer {
            margin-bottom:                   2rem;
            span {
              &:first-child {
                font-size:                   29.446px;
              }
              &:nth-child(2) {
                font-size:                   30.411px;
              }
              &:nth-child(3) {
                font-size:                   28.411px;
              }
              &:nth-child(4),
              &:last-child {
                font-size:                   43.217px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1600px) {
    .login-page {
      .btn {
        padding:                             16px;
      }
      .col-lg-5 {
        .login-box {
          width:                             350px;
        }
      }
    }
  }
</style>

<script>
  import { VueRecaptcha } from 'vue-recaptcha';
  import { useUserStore } from '@/store/user'

  export default {
    name: 'auth-login',
    components: {
      'vue-recaptcha': VueRecaptcha,
    },
    data: () => ({
      loading: false,
      isProduction: false,
      login: true,
      msgBrowser: null,
      alert: {
        message: null,
        class: null,
      },
      identifier: null,
      password: null,
      timezone: null,
      recoveryEmail: null,
      sitekey: process.env.VUE_APP_SITE_KEY,
      appVersion: process.env.VUE_APP_VERSION,
      isEmailSent: false,
    }),
    mounted() {
      console.log('axios base url', this.$axios.defaults.baseURL);
      console.log(">>>>>>>>>>>>>>>>> ENV >>>>>>>>>>>>>>>", process.env)
      this.isProduction = process.env.NODE_ENV != "development"

      const userStore = useUserStore()
      userStore.reset() // avoid reusing other clinic info
    },
    methods: {
      doSwitch() {
        this.alert.message = null
        this.isEmailSent = false
        this.login = !this.login
      },
      async onLoginVerify(gtoken) {
        this.resetRecaptcha()
        this.alert.message = null;
        this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let params = {
          DEFAULT_TZ: this.timezone,
          identifier: this.identifier,
          password: this.password,
          recaptcha: gtoken
        }

        this.loading = true

        try {
          let sessionInfo = await this.$authService.doLogin(params)

          setTimeout(() => {
            this.loading = false
          }, 500)

          // this.allowSynchronization = sessionInfo.currentClinic.synchronizePatientData
          // if (!this.allowSynchronization) {
          //   this.$router.push("/clinic")
          // } else {
            this.$router.push("/exam")
          // }
        } catch(err) {
          if (err.response && err.response.status == 418) {
            this.$router.push("/home/limbo")
            return
          }

          this.$message.popup(this, 'login.wrongData', 'operationFail', 'error')
        }

      },
      onPwdRecoverVerify: async function(gtoken) {
        this.resetRecaptcha()
        this.alert.message = null;
        let params = {
          recoveryEmail: this.recoveryEmail,
          recaptcha: gtoken
        };

        if(!this.recoveryEmail) {
          this.$message.popup(this, 'login.emptyEmail', 'operationFail', 'error')
          // this.alert = this.$message.alert(this, 'login.emptyEmail', 'danger')
          return;
        }

        this.loading = true

        try {
          await this.$authService.resetPassword(params);
          this.isEmailSent = true
          // this.$message.popup(this, 'login.checkEmailMessage', 'operationSuccess', 'success')
        } catch(err) {
          this.$message.popup(this, 'operationFail', 'operationFail', 'error')
          // this.alert = this.$message.alert(this, 'operationFail', 'danger')
        }

        this.loading = false
      },
      doLogin: async function() {
        if (!this.identifier || !this.password) {
          this.$message.popup(this, 'login.emptyData', 'operationFail', 'error')
          // this.alert = this.$message.alert(this, 'login.emptyData', 'danger')
          return
        }

        if (!this.isProduction) {
          this.onLoginVerify()
          return
        }

        this.$refs.recaptcha.execute();
      },
      doRecoverPass: async function() {
        if (!this.recoveryEmail) {
          this.$message.popup(this, 'login.emptyEmail', 'operationFail', 'error')
          // this.alert = this.$message.alert(this, 'login.emptyEmail', 'danger')
          return;
        }

        if (!this.isProduction) {
          this.onPwdRecoverVerify()
          return
        }
        this.$refs.recaptcha.execute();
      },
      onExpired: function () {
        console.log('Recaptcha Expired')
      },
      resetRecaptcha() {
        if (!this.isProduction)
          return

        this.$refs.recaptcha.reset();
      },
      showPassword() {
        let password = document.getElementById('password')
        if (password.type == 'password') {
          password.type = 'text'
        } else {
          password.type = 'password'
        }
      }
    }
  }
</script>
