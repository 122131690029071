<template>
  <div class="main-box" v-loader.opacity="loading">
    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <BaseInfiniteTable class="no-border" v-if="tableDevice" :tabledata="tableDevice" @item-opened="toDetails">
    </BaseInfiniteTable>

    <teleport to="#settingsActionBar" v-if="isSettingsMenuMounted">
      <button class="btn btn-success" type="button" name="button" @click="newDevice" data-test="btnAdd">
        {{ $t('device.add') }}
      </button>
    </teleport>

    <DeviceAddNewOffcanvas :offcanvas_id="'newDevice'" ref="offcanvasNewDevice"/>
  </div>
</template>

<style lang="scss" scoped>
.action-bar {
  .btn {
    @include                         border_radius(99px);
    font-size:                       var(--font-size-12);
    font-weight:                     400;
    letter-spacing:                  3.64px;
    text-transform:                  uppercase;
  }

  .btn-success {
    color:                           var(--Shades-W);
    font-weight:                     700;
    height:                          44px;
    padding:                         15px 20px;
    background:                      var(--Brand-Secondary);
    border:                          1px solid var(--Brand-Secondary);
  }
}
</style>

<script>
import DeviceAddNewOffcanvas from './DeviceAddNewOffcanvas.vue'

export default {
  name: 'device-list',
  components: {
    DeviceAddNewOffcanvas
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null,
    },
    tableDevice: {},
    perPage: 20,
    currentPage: 1,
    totalPages: 0,
    isSettingsMenuMounted: false,
  }),
  mounted() {
    this.$activeLink.value = 'device'
    this.fetchData()
    this.isSettingsMenuMounted = true
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    fetchData: async function() {
      this.alert = {}
      let params = {}

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$deviceService.getList(params)
        this.tableDevice = {
          'items': response.result,
          'total': response.totalCount,
          'columns': [
            {name: 'Serial', type: 'string', value: 'serialNumber', width: '33.3%'},
            {name: this.$i18n.t('device.type'), type: 'string', value: 'type', width: '33.3%'},
            {name: this.$i18n.t('device.lastActivity'), type: 'string', value: 'lastActivity', width: '33.3%'}
          ]
        };
        this.totalPages = Math.ceil(response.totalCount / this.perPage);

        if (response.result.length === 0) {
          this.tableDevice = {};
          this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch (err) {
        console.log("error", err)
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    toDetails(item) {
      this.$router.push({name: 'device-show', params: {deviceID: item.id}, query: {tab: 'data'}})
    },
    // newDevice() {
    //   this.$router.push({name: 'device-create'})
    // },
    async newDevice() {
      const ok = await this.$refs.offcanvasNewDevice.show({
        // title: this.$i18n.t('warning'),
        // text: text,
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary',
        // cancelButton: this.$i18n.t('termsPatient.doNotAccept'),
        // cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        // this.$router.push({name: 'patient-create', params: {patientID: ''}})
      }
    },
  }
}
</script>
