<template>
  <div class="modal fade modal-print-report" id="modal_print_report" ref="modal_terms" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="printReportModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="printReportModalLabel">{{ $t('report.print') }}</h5>
          <button id="closeBtn" type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
        </div>
        <div class="modal-body" id="contentPrint">
          <div class="tab-content" id="contentPrint" v-html="contentPrint"></div>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-outline-secondary" name="button" data-bs-dismiss="modal" data-test="btnCancel" @click="close">{{ $t('cancel') }}</button>
          <button type="button" class="btn btn-primary" data-test="btnPrint" v-print="printObj">{{ $t('report.print') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-print-report {
  .modal-dialog {
    .modal-content {
      .modal-header {
        background-color:             $phelcom-light-blue;
        border-bottom:                1px solid $platinum !important;
        &.d-flex {
          display:                    flex !important;
        }
        h5 {
          color:                      white;
          font-size:                  18px;
        }
      }
    }
    .modal-body {
      height:                         100%;
      min-height:                     250px;
      max-height:                     60vh;
      overflow-y:                     scroll;
    }
  }
}

</style>

<script>
export default {
  name: 'ReportGeneralPrintModal',
  props: ['content_print', 'patient_name'],
  data: () => ({
    printObj: {
      id: 'contentPrint',
      popTitle: null,
    },
    contentPrint: null
  }),
  mounted() {
    this.contentPrint = this.content_print
    this.printObj.popTitle = this.patient_name
  },
  methods: {
    close() {
      this.$emit('close');
    },
  }
}
</script>
