<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11" fill="none">
    <path d="M6.62274 0.835083L1.90576 5.55206L6.62274 10.269" stroke="#8F8F8F" stroke-width="2"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconArrowBack'
}
</script>
