<template>
  <div class="flex">
    <h3 class="sub-title">{{ $t('patient.ophthalmicDiseases') }}</h3>
    <div class="form-check disabled">
      <BaseSquareCheckBox id="diabeticRetinopathy" :item="'diabeticRetinopathy'" @checked="doCheck"/>
      <label class="form-check-label" for="diabeticRetinopathy">
        {{ $t('disease.diabeticRetinopathy') }}
      </label>
    </div>
    <div class="form-check disabled">
      <BaseSquareCheckBox id="dmri" :item="'dmri'" @checked="doCheck"/>
      <label class="form-check-label" for="dmri">
        {{ $t('disease.amd') }}
      </label>
    </div>
    <div class="form-check disabled">
      <BaseSquareCheckBox id="glaucoma" :item="'glaucoma'" @checked="doCheck"/>
      <label class="form-check-label" for="glaucoma">
        {{ $t('disease.glaucoma') }}
      </label>
    </div>
    <div class="form-check disabled">
      <BaseSquareCheckBox id="cataract" :item="'cataract'" @checked="doCheck"/>
      <label class="form-check-label" for="cataract">
        {{ $t('disease.cataract') }}
      </label>
    </div>
    <div class="form-check disabled">
      <BaseSquareCheckBox id="pterygium" :item="'pterygium'" @checked="doCheck"/>
      <label class="form-check-label" for="pterygium">
        {{ $t('disease.pterygium') }}
      </label>
    </div>
    <div class="form-check disabled">
      <BaseSquareCheckBox id="lowVisualAcuity" :item="'lowVisualAcuity'" @checked="doCheck"/>
      <label class="form-check-label" for="lowVisualAcuity">
        {{ $t('disease.lowVisualAcuity') }}
      </label>
    </div>
    <div class="form-check disabled">
      <BaseSquareCheckBox id="nwn_ophthalmic" :item="'nwn_ophthalmic'" @checked="doCheck"/>
      <label class="form-check-label" for="nwn_ophthalmic">
        {{ $t('disease.nwn_ophthalmic') }}
      </label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .flex {
    display:                 flex;
    flex-direction:          column;
    align-items:             flex-start;
    gap:                     19px;
    padding:                 33px 0;
  }

  .sub-title {
    color:                   var(--Shades-60, #727272);
    font-size:               16px;
    font-weight:             500;
    line-height:             normal;
    letter-spacing:          3.2px;
    text-transform:          uppercase;
    text-overflow:           ellipsis;
    white-space:             nowrap;
    overflow:                hidden;
    border:                  0;
    margin:                  0;
  }
</style>

<script>
export default {
  name: 'ExamInfoOphthalmicDiseases',
  props: ['ophthalmic'],
  data: () => ({
  }),
  mounted() {
    if (this.ophthalmic) {
      this.updateCheck()
    }
  },
  methods: {
    updateCheck() {
      for (const [key, value] of Object.entries(this.ophthalmic)) {
        if (value) {
          document.getElementById(key).click()
        }
      }
    },
    doCheck(item) {
      for (const [key, value] of Object.entries(this.ophthalmic)) {
        if (key == item.value) {
          this.ophthalmic[key] = item.checked
        }
      }
    }
  }
}
</script>
