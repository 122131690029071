<template>
  <div>

    <h2 class="sub-title">{{ $t(`medicalRecord.${section}`)}}</h2>

    <ul>
      <li v-for="data of recordDataList" :key="data.id">
        <a href="#" style="font-size: 18px" @click="download(data)"> {{data.filename}} </a>
        <button class="no-btn" type="button" name="button" v-show="checkDataType(data.filename)" data-test="btnPreviewPdf" @click="generateUrlPDF(data)">
          <i class="fa fa-file-pdf-o"></i>
          Preview
        </button>
        <button class="no-btn" type="button" name="button" v-show="checkDataImageType(data.filename)" data-test="btnPreviewImage" @click="openImagePreview(data)">
          <i class="fa fa-file-image-o"></i>
          Preview
        </button>
        <span class="d-block" v-if="data.observation"><br/>{{data.observation}} </span>
      </li>
    </ul>

    <teleport to="body">
      <PreviewImageModal
          v-if="showModalPreviewImage"
          :image_data_id="imageData.id"
          :image_data_uuid="imageData.uuid"
          :image_data_path="data_path"
          @close="showModalPreviewImage = !showModalPreviewImage"></PreviewImageModal>

      <PreviewPdfModal v-if="showModalPreviewPdf" :pdf_url="urlPdf" :pdf_id="urlPdf.id" @close="showModalPreviewPdf = !showModalPreviewPdf"></PreviewPdfModal>
    </teleport>
  </div>
</template>

<script>
import PreviewPdfModal from '@/components/PreviewPdfModal.vue'
import PreviewImageModal from '@/components/PreviewImageModal.vue'

const regexImageType = new RegExp("(.*?)\.(apng|avif|gif|jpg|jpeg|jfif|pjpeg|pjp|png|webp|bmp|ico|cur|tif|tiff)$")

const DEBUG = true

export default {
  name: 'PatientMedicalRecordFileSection',
  props: ['section', 'record', 'data_path'],
  components: {
    PreviewImageModal,
    PreviewPdfModal,
  },
  data: () => ({
    recordDataList: [],
    showModalPreviewImage: false,
    showModalPreviewPdf: false,
    urlPdf: {
      url: null,
      id: null,
    },
    imageData: {
      id: null,
      uuid: null,
    },
  }),
  mounted() {
    this.recordDataList = this.record.recordData
  },
  methods: {
    checkDataType(type) {
      if (type.endsWith('.pdf'))
        return true
    },
    checkDataImageType(type) {
      if (regexImageType.test(type))
        return true
    },
    async download(data) {
      let response = await this.$medicalRecordService.getMedicalDataURL(data.id)
      let downloadURL = response.downloadURL
      window.open(downloadURL)
    },
    async generateUrlPDF(data) {
      console.log('data', data);
      this.urlPdf = null

      try {
        let response = await this.$medicalRecordService.getMedicalDataURL(data.id)

        this.urlPdf = response
        this.urlPdf.url = response.downloadURL
        this.urlPdf.id = data.id
        console.log('url pdf', this.urlPdf);
        this.showModalPreviewPdf = true

        setTimeout(function() {
          let modal_preview_pdf = document.getElementById("modal_preview_pdf");
          new bootstrap.Modal(modal_preview_pdf).show();
        }, 100);
      } catch (err) {
        console.log('erro >>>', err);
      }
    },
    openImagePreview(data) {
      this.imageData = data

      this.showModalPreviewImage = true

      console.log('this.imageData', this.imageData);

      setTimeout(function() {
        let modal_preview_image = document.getElementById("modal_preview_image");
        new bootstrap.Modal(modal_preview_image).show();
      }, 100);
    }
  },
}
</script>
