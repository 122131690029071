<template>
  <div class="flex">
    <h3 class="sub-title">{{ $t('patient.underlyingDisease') }}</h3>
    <div class="form-check">
      <BaseSquareCheckBox id="diabetes" :item="'diabetes'" @checked="doCheck"/>
      <!-- <input class="form-check-input" type="checkbox" value="" id="diabetes" v-model="underlying.diabetes" data-test="checkboxUnderlyingDiabetes" :disabled="disabled"> -->
      <label class="form-check-label" for="diabetes">
        {{ $t('disease.diabetesMellitus') }}
      </label>
    </div>
    <div class="form-check">
      <BaseSquareCheckBox id="hypertension" :item="'hypertension'" @checked="doCheck"/>
      <!-- <input class="form-check-input" type="checkbox" value="" id="hypertension" v-model="underlying.hypertension" data-test="checkboxUnderlyingHypertension" :disabled="disabled"> -->
      <label class="form-check-label" for="hypertension">
        {{ $t('disease.hypertension') }}
      </label>
    </div>
    <div class="form-check">
      <BaseSquareCheckBox id="cholesterol" :item="'cholesterol'" @checked="doCheck"/>
      <!-- <input class="form-check-input" type="checkbox" value="" id="cholesterol" v-model="underlying.cholesterol" data-test="checkboxUnderlyingCholesterol" :disabled="disabled"> -->
      <label class="form-check-label" for="cholesterol">
        {{ $t('disease.highCholesterol') }}
      </label>
    </div>
    <div class="form-check">
      <BaseSquareCheckBox id="smoker" :item="'smoker'" @checked="doCheck"/>
      <!-- <input class="form-check-input" type="checkbox" value="" id="smoker" v-model="underlying.smoker" data-test="checkboxUnderlyingSmoker" :disabled="disabled"> -->
      <label class="form-check-label" for="smoker">
        {{ $t('disease.smoker') }}
      </label>
    </div>
    <div class="form-check">
      <BaseSquareCheckBox id="nwn_underlying" :item="'nwn_underlying'" @checked="doCheck"/>
      <!-- <input class="form-check-input" type="checkbox" value="" id="nwn_underlying" v-model="underlying.nwn_underlying" data-test="checkboxUnderlyingNwn" :disabled="disabled"> -->
      <label class="form-check-label" for="nwn_underlying">
        {{ $t('disease.nwn_ophthalmic') }}
      </label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .flex {
    display:                 flex;
    flex-direction:          column;
    align-items:             flex-start;
    gap:                     19px;
    padding-top:             33px;
  }

  .sub-title {
    color:                   var(--Shades-60, #727272);
    font-size:               16px;
    font-weight:             500;
    line-height:             normal;
    letter-spacing:          3.2px;
    text-transform:          uppercase;
    text-overflow:           ellipsis;
    white-space:             nowrap;
    overflow:                hidden;
    border:                  0;
    margin:                  0;
  }

  .form-check {
    @include                 border_radius(5px);
    display:                 flex;
    flex-direction:          row;
    justify-content:         center;
    align-items:             center;
    gap:                     10px;
    align-self:              stretch;
    padding:                 8px 12px;
    border:                  1px solid var(--Shades-30, #D8D8D8);
    background:              var(--Shades-W, #FFF);

    label {
      color:                 var(--Brand-Primary, #1B294A);
      font-size:             13px;
      font-weight:           400;
      line-height:           normal;
      letter-spacing:        1.95px;
      text-transform:        uppercase;
      margin:                0;
    }
  }
</style>

<script>
export default {
  name: 'PatientCreateUnderlyingDiseases',
  props: ['underlying'],
  data: () => ({
  }),
  mounted() {
    this.$nextTick(() => {
      this.updateCheck()
    })
  },
  methods: {
    updateCheck() {
      for (const [key, value] of Object.entries(this.underlying)) {
        if (value) {
          document.getElementById(key).click()
          console.log('clicou? underlying');
        }
      }
    },
    doCheck(item) {
      for (const [key, value] of Object.entries(this.underlying)) {
        if (key == item.value) {
          this.underlying[key] = item.checked
        }
      }
      console.log('doCheck', this.underlying);
    }
  }
}
</script>
