<template>
  <div class="create-clinic-box" v-loader.opacity.tab="loading">
    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <vee-form v-slot="{ handleSubmit }" as="div">
      <form @submit.prevent.stop="handleSubmit(doSave)">
        <ClinicFormData v-if="clinic != null && isDataReady" :clinic="clinic" :is_creating="true" @option-selected="setSelectedOptions"></ClinicFormData>

        <div class="action-bar">
          <button class="btn btn-success" type="submit" name="button" data-test="btnSave" v-if="hasFormChanged">
            <span>{{ $t('createClinic.save') }}</span>
          </button>
          <button class="btn btn-secondary" type="button" name="button" data-test="btnCancel" @click="$router.back()">
            <span>{{ $t("cancel") }}</span>
          </button>
        </div>
      </form>
    </vee-form>

  </div>
</template>

<style lang="scss" scoped>
.action-bar {
  height:                            64px;
  padding:                           10px 20px;
  border-top:                        1px solid var(--Shades-30);
  display:                           flex;
  justify-content:                   flex-start;
  align-items:                       center;
  gap:                               10px;
  height:                            100%;

  > div {
  }
}

.btn {
  @include                         border_radius(99px);
  font-size:                       var(--font-size-12);
  font-weight:                     400;
  letter-spacing:                  3.64px;
  text-transform:                  uppercase;
}
.btn-success {
  color:                           var(--Shades-W);
  background:                      var(--Brand-Secondary);
  border:                          1px solid var(--Brand-Secondary);
}

.btn-secondary {
  color:                           var(--Brand-Primary);
  background:                      var(--Shades-10);
  border:                          unset;
  // border:                          1px solid var(--Shades-10);
}

.btn-secondary,
.btn-success {
  font-weight:                     700;
  height:                          44px;
  padding:                         15px 20px;
}
</style>

<script>
import ClinicFormData from '../ClinicFormData.vue'

export default {
  name: 'ClinicCreateTab',
  components: {
    ClinicFormData
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    clinic: {
      name: '',
      fantasyName: '',
      document: '',
      email: '',
      telephone1: '',
      telephone2: '',
      address: '',
      addressNumber: '',
      district: '',
      city: '',
      state: '',
    },
    isDataReady: false,
    hasFormChanged: false,
    changedFormItems: [],
    initialForm: {},
    parentClinic: null,
  }),
  watch: {
    clinic: {
      immediate: true,
      handler: function(newValue) {
        this.checkFormChange(newValue, 'clinic')
    }, deep: true},
  },
  mounted() {
    const formWrap = {
      clinic: this.clinic,
    }

    this.initialForm = _.cloneDeep(formWrap)

    this.$nextTick(() => {
      this.hasFormChanged = false
      this.changedFormItems = []
    })

    this.isDataReady = true
  },
  methods: {
    checkFormChange(value, obj) {
      let hasObj = this.changedFormItems.includes(obj)
      if (JSON.stringify(value) === JSON.stringify(this.initialForm[obj])) {
        if (hasObj) {
          let index = this.changedFormItems.indexOf(obj)
          this.changedFormItems.splice(index, 1)
        }

        if (this.changedFormItems.length > 0)
          return

        this.hasFormChanged = false
      } else {
        if (!hasObj)
          this.changedFormItems.push(obj)

        this.hasFormChanged = true
      }
    },
    setSelectedOptions(value) {
      this.clinic.country = value.country
      this.clinic.mandatoryDocument = value.mandatoryDocument
      this.clinic.locale = value.locale
      if (value.parentClinic)
        this.parentClinic = value.parentClinic
    },
    async doSave() {
      try {
        let params = {
          clinic: this.clinic
        }

        if (this.parentClinic) {
          params['parentClinic'] = this.parentClinic
        }
        console.log('ta entrando');

        this.loading = true
        NProgress.start()
        let response = await this.$clinicService.saveClinic(params);
        console.log('response criar clinica', response);
        this.$message.popup(this, 'clinic.createdSuccess', 'operationSuccess', 'success')

        this.$router.push({name: 'clinic-show', params: {clinicID: response.result.id}, query: {tab: 'data'}})
      } catch (err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    }
  }
}
</script>
