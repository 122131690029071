<template>
  <div class="">
    <div class="eyerweb-login container" v-if="!qrCodeError && !loading">
      <div class="row">
        <div class="col-md-6 d-flex justify-content-center align-items-center">
          <i class="icon-logo_eyerlink"></i>
          <div class="text-center">
            <p class="d-inline-block">POWERED BY</p>
            <a href="https://phelcom.com/" class="logo d-inline-block" target="_blank" data-test="anchorPhelcomLink">
              <i class="icon-phelcom_logo_horizontal_fit">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
                <span class="path6"></span>
                <span class="path7"></span>
                <span class="path8"></span>
                <span class="path9"></span>
                <span class="path10"></span>
                <span class="path11"></span>
              </i>
            </a>
          </div>
        </div>

        <div class="col-md-6 d-flex justify-content-center align-items-center text-center">
          <h1>{{ $t('eyerweb.logIn') }}</h1>
          <h5>{{ $t('eyerweb.logInInfo1') }}<br>{{ $t('eyerweb.logInInfo2') }}</h5>

          <div class="qrcode-box" v-if="!isTestEnv">
            <div id="qrCodeDiv" @dblclick="copyQRCode()"></div>
            <div class="d-none" @click.ctrl="doLogin()"></div>
          </div>
          <button class="btn btn-primary mt-3 mb-5" v-else data-test="btnLogin" @click="doLogin()">LOCAL LOGIN</button>

          <div class="support-box">
            <p>{{ $t('eyerweb.technicalSupport') }}</p>
            <a href="https://phelcom.com/pt-br/suporte/" target="_blank" class="btn btn-primary btn-support" data-test="anchorSupportLink">
              {{ $t('eyerweb.supportTeam') }}
            </a>
          </div>

        </div>
      </div>

      <footer class="eyerweb-footer" v-show="showDisclaimer">
        <div class="d-flex justify-content-center align-items-center text-center">
          <p class="information">
            <strong>{{ $t('eyerweb.disclaimer') }}</strong>
            {{ $t('eyerweb.disclaimerInfo') }}
          </p>
          <button class="btn btn-outline-primary" type="button" name="button" @click="showDisclaimer = false" data-test="btnDisclaimer">{{ $t('terms.close') }}</button>
        </div>
      </footer>
    </div>

    <div class="login sync d-flex justify-content-center align-items-center text-center" v-if="!qrCodeError && loading">
      <div class="">
        <h1>{{ $t('eyerweb.connecting') }}</h1>
        <p>{{ $t('eyerweb.connectingInfo') }}</p>

        <div class="progress" id="syncProgressBar" role="progressbar" aria-label="Sync" aria-valuenow="1" aria-valuemin="0" aria-valuemax="100">
          <div class="progress-bar" style="width: 1%"></div>
        </div>

        <div class="device-info-box">
          <div class="">
            <!-- <p>Eyer da clinica: </p> -->
            <p>{{ this.clinicName }}</p>
            <!-- <small>SERIAL: NTZ0001986</small> -->
          </div>
          <img src="@/assets/images/arrow_eyerweb.svg" class="arrow-eyerweb" alt="">
          <i class="icon-logo_eyerlink"></i>
        </div>
      </div>
    </div>

    <div class="login" v-if="qrCodeError">
      <div class="text-center" :class="{ 'server-error': qrCodeError }">
        <div class="server-error-box">
          <img src="@/assets/images/erjart/ico_cloud_error.svg" alt="" width="130">
          <!-- <i class="icon-ico_cloud_error">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
            <span class="path4"></span>
            <span class="path5"></span>
            <span class="path6"></span>
            <span class="path7"></span>
            <span class="path8"></span>
            <span class="path9"></span>
            <span class="path10"></span>
            <span class="path11"></span>
            <span class="path12"></span>
            <span class="path13"></span>
            <span class="path14"></span>
          </i> -->

          <h1>Opss.</h1>
          <h4>{{ $t('eyerweb.ourServer') }}<strong>{{ $t('eyerweb.unstable') }}</strong>.</h4>
          <p>{{ $t('eyerweb.tryAgain') }}</p>

          <a href="https://phelcom.com/pt-br/suporte/" target="_blank" class="btn btn-primary btn-support" data-test="anchorSupportTeam">
            {{ $t('eyerweb.supportTeam') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.eyerweb-login {
  color:                                        var(--brand-primary, #1B294A);
  font-family:                                  'Ubuntu', 'Arial', sans-serif;
  position:                                     relative;
  max-width:                                    unset !important;
  .row {
    .col-md-6 {
      flex-direction:                           column;
      height:                                   100vh;
      &:first-child {
        @include                                background_cover('@/assets/images/erjart/bg_menu.jpg');
        .icon-logo_eyerlink {
          font-size:                            7.16em;
          margin-bottom:                        56px;
        }
        .text-center {
          p,
          .logo {
            vertical-align:                     middle;
          }
          p {
            color:                              white;
            font-size:                          10.534px;
            font-weight:                        400;
            letter-spacing:                     1.791px;
            line-height:                        1;
            margin:                             0;
          }
          .logo {
            font-size:                          1.62em;
            margin:                             0 13px;
            text-decoration:                    none;
          }
        }
      }
      &:last-child {
        background-color:                       white;
        h1 {
          font-size:                            39px;
        }
        h5 {
          font-size:                            19px;
          margin-top:                           28px;
          margin-bottom:                        31px;
        }
        .support-box {
          display:                              flex;
          justify-content:                      space-between;
          align-items:                          center;
          p {
            text-align:                         left;
            margin:                             0;
            max-width:                          273px;
          }
          .btn {
            color:                              var(--shades-w, #FFF);
            font-size:                          14px !important;
            font-style:                         normal;
            font-weight:                        400;
            line-height:                        normal;
            letter-spacing:                     2.1px !important;
            width:                              225px;
            padding:                            10.5px 0 !important;
            margin-top:                         0 !important;
          }
        }
      }
    }
  }
}

.qrcode-box {
  @include                                      border_radius(11px);
  @include                                      box_shadow(0px 5px 25px 0px rgba(0, 0, 0, 0.15));
  padding:                                      10px;
  background:                                   var(--shades-w, #FFF);
  margin-bottom:                                160px;
}

.eyerweb-footer {
  position:                                     absolute;
  display:                                      inline-block;
  max-width:                                    1170px;
  left:                                         0;
  right:                                        0;
  bottom:                                       24px;
  margin-left:                                  auto;
  margin-right:                                 auto;
  @include                                      border_radius(5px);
  @include                                      box_shadow(0px 16px 32px 0px rgba(0, 0, 0, 0.08));
  border:                                       1px solid var(--shades-20, #F0F0F0);
  background:                                   var(--shades-w, #FFF);
  div {
    padding:                                    8px;
  }
  p {
    font-size:                                  12px;
    display:                                    inline-block;
    margin:                                     0;
  }
  .btn {
    color:                                      var(--brand-primary, #1B294A);
    font-size:                                  10px;
    font-weight:                                700;
    text-transform:                             uppercase;
    letter-spacing:                             1.5px;
    padding:                                    4px 12px;
    margin-left:                                15px;
    border-color:                               var(--brand-primary, #1B294A);
    &:hover,
    &:focus {
      color:                                    white;
      background-color:                         var(--brand-primary, #1B294A);
    }
  }
}

.login {
  &.sync {
    flex-direction:                             column;
    .progress {
      height:                                   25px;
      background-color:                         white;
      padding:                                  4.5px;
      .progress-bar {
        background:                             linear-gradient(90deg, #1B294A 0%, #3CB391 100%);
      }
      &, .progress-bar {
        @include                                border_radius(80px);
      }
    }
    h1, p {
      color:                                    var(--shades-w, #FFF);
      text-align:                               center;
      line-height:                              normal;
    }
    h1 {
      font-size:                                39px;
      font-weight:                              500;
    }
    p {
      display:                                  inline-block;
      font-size:                                14px;
      font-weight:                              400;
      max-width:                                303px;
      margin-top:                               15px;
      margin-bottom:                            30px;
    }
    .device-info-box {
      display:                                  flex;
      justify-content:                          center;
      align-items:                              center;
      margin-top:                               30px;
      > div {
        display:                                inline-block;
        p, small {
          color:                                var(--shades-w, #FFF);
          margin:                               0;
          text-align:                           right;
          display:                              block;
          font-weight:                          500;
          text-transform:                       uppercase;
        }
        p {
          font-size:                            15px;
        }
        small {
          font-size:                            12px;
          letter-spacing:                       1.44px;
        }
      }
      .arrow-eyerweb {
        width:                                  40px;
        margin:                                 0 11px;
      }
      .icon-logo_eyerlink {
        font-size:                              2.72em;
      }
    }
  }
}

.text-center.server-error {
  display:                                      flex;
  flex-direction:                               column;
  align-items:                                  center;
  justify-content:                              center;
  height:                                       100vh;
  // height:                                       calc(100% - 330px);
}

.server-error-box {
  max-width:                                    320px;
  color:                                        white;

  h1 {
    font-size:                                  6em;
    margin-top:                                 10px;
  }

  h4 {
    margin:                                     20px 0;
  }

  p {
    font-size:                                  1.2em;
  }

}

.btn-primary.btn-support {
  text-transform:                              uppercase;
  letter-spacing:                              3px;
  font-size:                                   1.3em;
  padding:                                     0.375rem 1.5rem;
  margin-top:                                  30px;
  background-color:                            $phelcom-blue;
  border-color:                                $phelcom-blue;
  @include                                     border_radius(8px);
}

@media (max-width: 500px) {
  .login {
    height:                                    100vh;

    .footer {
      position:                                absolute;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1450px) {
  .eyerweb-login {
    .row {
      .col-md-6 {
        &:last-child {
          h1 {
            font-size:                         30px;
          }
          h5 {
            font-size:                         16px;
            margin-top:                        20px;
            margin-bottom:                     24px;
          }
        }
      }
    }
  }
  .qrcode-box {
    margin-bottom:                             48px;
  }
}

@media screen and (min-width: 501px) and (max-width: 991px) {
  #qrCodeDiv canvas {
    // width: 250px !important;
    // height: 250px !important;
    aspect-ratio:                              auto 220 / 220 !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1600px) {
  #qrCodeDiv canvas {
    // width: 250px !important;
    // height: 250px !important;
    aspect-ratio:                              auto 250 / 250;
  }
}
</style>

<script>
import EyerwebFooter from './EyerwebFooter.vue';
import AuthHeader from '../auth/AuthHeader.vue';
import kjua from 'kjua'
import isTestEnv from '../../assets/eyerweb-data/env.js'
import EyerwebConstants from '../../assets/eyerweb-data/constants.js'
import storeResponse from '../../assets/eyerweb-data/store-response.js'
import { useEyerwebStore } from '@/store/eyerweb'


export default {
  name: 'eyerweb',
  components: {
    EyerwebFooter,
    AuthHeader,
  },
  data: () => ({
    loading: false,
    alert: {
      class: null,
      message: null
    },
    qrCode: null,
    qrCodeError: false,
    currentPeer: null,
    socket: null,
    userToSignal: '',
    url: '',
    hostInfo: '',
    isEyerSyncValid: '',
    examUuid: '',
    isTestEnv: false,
    showDisclaimer: true,
    clinicName: '',
  }),
  async created() {

    this.isTestEnv = isTestEnv
    // Adds CDN only when opening eyerweb page https://stackoverflow.com/questions/49330055/how-to-include-a-cdn-to-vuejs-cli-without-npm-or-webpack
    // CDN is used to prevent (window).process and (window).Buffer overwriting https://stackoverflow.com/questions/70899364/simple-peer-on-data-stream-throws-process-not-defined-error
    if (!document.getElementById('simple-peer')) {
      const scriptTag = document.createElement("script")
      scriptTag.src = "https://cdnjs.cloudflare.com/ajax/libs/simple-peer/9.11.1/simplepeer.min.js"
      scriptTag.id = 'simple-peer'
      document.getElementsByTagName('head')[0].appendChild(scriptTag)
    }
    this.hostInfo = await this.$eyerwebService.getQrCode()

    if (!this.isTestEnv) {
      this.$eyerwebService.connectionSubject.subscribe(this.connectionCallback)
      await this.$eyerwebService.webSocketInitialization(this.hostInfo);
    } else {
      let response = {
        data: storeResponse
      }
      const eyerwebStore = useEyerwebStore()
      let session = {
        currentClinic: response.data.clinic,
        features: response.data.features
      }
      eyerwebStore.sessionInfo = session
    }

  },
  async mounted() {
  },
  unmounted() {
    this.$eyerwebService.connectionSubject.unsubscribe(this.connectionCallback)
  },
  methods: {
    async connectionCallback({ event, examUuid }) {
      switch (event) {
        case EyerwebConstants.ACTION_CONNECTED:
          if (this.isEyerSyncValid)
            this.doLogin()
          break
        case EyerwebConstants.EVENT_QRCODE:
          this.examUuid =  examUuid
          const eyerwebStore = useEyerwebStore()
          console.log('eyerwebStore.sessionInfo: ', eyerwebStore.sessionInfo)

          if (eyerwebStore.sessionInfo.currentClinic)
            this.clinicName = eyerwebStore.sessionInfo.currentClinic.name

          this.loading = true
          NProgress.start()

          this.$nextTick(() => {
            $("#syncProgressBar .progress-bar").animate({
              width: "100%",
            }, 500);
          })

          break
        case EyerwebConstants.EVENT_SOCKET_ERROR:
          if (!this.qrCode)
            this.qrCodeError = true
          // this.qrCode = await this.bindQRCode('Error', 'red')
          break
        case EyerwebConstants.EVENT_SOCKET_CONNECTED:
          this.isEyerSyncValid = true
          if (!this.qrCode)
            this.qrCode = this.bindQRCode(this.hostInfo, 'black');
          break
        case EyerwebConstants.EVENT_ERROR_CONNECT:
          this.isEyerSyncValid = false
          NProgress.done(true)
          this.loading = false
          this.$message.popup(this, 'request_error', 'operationFail', 'error')
          this.$router.push('/link/')
          break

      }
    },
    async doLogin() {
      try {
        let isConnected = false
        if (this.isTestEnv)
          isConnected = true
        else
          isConnected = this.$eyerwebService.isConnected()

        if (isConnected == true) {
          if (this.isTestEnv){
            this.$router.push({name: 'eyerweb-exam-show', params: {examID: 'blabla-blabla-blabla-blabla'}})
          } else {
            if (this.examUuid)
              this.$router.push({name: 'eyerweb-exam-show', params: {examID: this.examUuid}})
          }
        }

      } catch (err) {
        console.log('Error: login eyerweb', err);
      }
      NProgress.done(true)
      this.loading = false
    },
    async bindQRCode(data, color) {
      this.qrCodeError = false
      this.qrCode = JSON.stringify(data)

      let options = {
        render: 'canvas', // render method: 'canvas', 'image' or 'div'
        crisp: true,
        // version range somewhere in 1 .. 40
        minVersion: 1,
        maxVersion: 40,
        // error correction level: 'L', 'M', 'Q' or 'H'
        ecLevel: 'L',
        // size in pixel
        size: 270,
        // pixel-ratio, null for devicePixelRatio
        ratio: null,
        // code color or image element
        fill: color,
        // background color or image element, null for transparent background
        background: null,
        // content
        text: this.qrCode,
        rounded: 0,
        // quiet zone in modules
        quiet: 0, data
        //mSize: 15,
        //mPosX: 50,
        //mPosY: 50,

        // label: 'Phelcom',
        // fontname: 'sans',
        // fontcolor: '#60bda2'
      }

      const qrCodeElement = kjua(options);
      qrCodeDiv.appendChild(qrCodeElement);

      return JSON.parse(this.qrCode)
    },
    copyQRCode() {
      navigator.clipboard.writeText(this.qrCode);
    }
  }
}
</script>
