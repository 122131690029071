<template>
  <div class="tab-box mobile d-flex align-items-start">
    <nav class="navbar navbar-expand-lg">
      <button class="navbar-toggler btn-outline-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#tabResponsive"
              aria-controls="tabResponsive" aria-expanded="false" aria-label="Toggle navigation" data-test="btnNavbarMobileTab" @click="open = !open">
        <i class="fa fa-bars" aria-hidden="true"></i>
      </button>
      <div class="collapse navbar-collapse" id="tabResponsive">
        <ul class="navbar-nav">
          <li class="nav-item" v-for="tab in tabs" :key="tab">
            <button class="nav-link" :class="{'active': activeTab === tab}" :id="tab" type="button" :data-test="`btnMobileTab_${tab}`" @click="switchTab(tab)">
              <slot :name="tabHeadName(tab)">{{ tab }}</slot>
            </button>
          </li>
        </ul>
      </div>
    </nav>

    <div class="tab-content">
      <div class="tab-pane fade" :class="{'show active':  activeTab === tabSlotName}" :id="tabSlotName">
        <slot :name="tabSlotName"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .tab-box.mobile {
    flex-direction:                   column;
    align-items:                      unset !important;
    .navbar {
      margin-left:                    10px;
      width:                          100%;
      z-index:                        999999;
      .navbar-nav {
        width:                        300px;
        .nav-link {
          width:                      100%;
        }
      }
      .navbar-toggler {
        border:                       1px solid #6c757d;
      }
      .navbar-collapse {
        @include                      box_shadow(0 2px 8px 1px #ccc);
        position:                     absolute;
        // right:                        0;
        top:                          40px;
      }
    }
    // .nav-item {
    //   flex-basis:                     0;
    //   flex-grow:                      1;
    //   text-align:                     center;
    // }
  }
</style>

<script>
export default {
  props: ['initialtab', 'tabs'],
  name: 'MobileTab',
  data() {
    return {
      activeTab: this.initialtab
    };
  },
  computed: {
    tabSlotName() {
      return `${this.activeTab}`;
    }
  },
  methods: {
    tabHeadName(tab) {
      return `tab-head-${tab}`;
    },

    switchTab(tab) {
      this.activeTab = tab;
      this.$emit('tab-activated', tab);
    }
  }
};
</script>
