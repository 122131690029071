<template>
  <BaseInfiniteTable class="no-border" v-if="table_exam" :tabledata="table_exam" @item-opened="$emit('item-opened')" @loadmore="$emit('loadmore')">
    <template v-slot:column_captures="{item}">
      <div class="preview-image-box" v-if="item.examImages.length" :data-title="$t('exam.iaText')">
        <ul>
          <template v-for="(image, index) in item.examImages">
            <li v-if="index < 3">
              <img :src="thumb_path +'/'+ image.name" alt="">
            </li>
          </template>
          <li class="counter" v-if="item.examImages.length > 2">+{{ item.examImages.length }}</li>
        </ul>
      </div>
      <p class="no-captures" v-else>{{ $t('exam.noCaptures') }}</p>
    </template>

    <template v-slot:column_content="{item}">
      <div class="d-inline-block">
        <span v-show="item.examType.key == 'patient_progression'" class="label label-blue-phelcom" :data-title="$t(`exam.examTypeName_${item.examType.key}`)">{{ $t(`exam.examType_${item.examType.key}`) }}</span>
        <i v-show="item.referred > 0" class="fa" :class="item.referred == 1 ? 'fa-tint' : 'fa-check-circle-o'" aria-hidden="true"></i>
        <button class="no-btn" type="button" name="button" data-title="Download" :data-test="`btnExamDownload_${item.uuid}`" @click.stop.prevent="doDownload(item)">
          <IconDownload/>
        </button>
        <button class="no-btn" :disabled="!item.hasImage" type="button" name="button" :data-title="$t('tooltips.image')" :data-test="`btnExamHasImage_${item.uuid}`">
          <IconImage/>
        </button>
        <button class="no-btn" :disabled="!item.hasReport" type="button" name="button" :data-title="$t('tooltips.report')" :data-test="`btnExamHasReport_${item.uuid}`">
          <IconPdf/>
        </button>
      </div>
    </template>

    <template v-slot:column_ai="{item}">
      <div class="ai-box" v-if="item.scoreResult" :data-title="$t('exam.iaText')">
        <p>AI</p>
        <span :class="item.scoreResult ? item.scoreResult.odStatus : ''"></span>
        <span :class="item.scoreResult ? item.scoreResult.osStatus : ''"></span>
      </div>
    </template>
  </BaseInfiniteTable>
</template>

<style lang="scss" scoped>
.preview-image-box {
  ul {
    display:                        flex;
    align-items:                    center;
    flex-direction:                 row;
    list-style-type:                none;
    padding:                        0;
    margin:                         0;

    li {
      width:                        31px;
      height:                       26px;

      img {
        @include                    border_radius(99px);
        width:                      100%;
        height:                     100%;
        border:                     2px solid var(--Shades-W, #FFF);
      }

      &:not(:first-child) {
        margin-left:                -5px;
      }

      &.counter {
        @include                    border_radius(99px);
        color:                      var(--Shades-60, #727272);
        text-align:                 center;
        font-size:                  12px;
        font-weight:                700;
        line-height:                2;
        letter-spacing:             0.6px;
        border:                     1px solid var(--Shades-40, #B7B7B7);
        background:                 var(--Shades-W, #FFF);
      }
    }
  }
}
</style>

<script>
export default {
  name: 'ExamTableList',
  props: ['table_exam', 'thumb_path'],
  methods: {
    doDownload(exam) {
      this.alert.message = null;
      try {
        NProgress.start()
        this.$examService.download(exam.id)
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
    },
  }
}
</script>
