<template>
  <div class="external-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <div class="">
            <h6>{{ $t('createAccount.welcome') }}</h6>
            <img src="@/assets/images/phelcom/eyercloud_logo_white.svg" alt="Eyercloud logo">
          </div>
        </div>

        <div class="col-lg-5 flex-center">
          <div class="external-content">
            <h3 class="mb-3">{{ $t('createAccount.confirmedRegistry') }}</h3>

            <div v-if="isVerifying">
              <center>
                <img src="@/assets/images/loading_points.gif"/>
              </center>
            </div>

            <div v-if="isTicketValid == true">
              <h3>
                <span>{{ firstName }}</span>,
                {{ $t('createAccount.congratulation') }}
              </h3>

              <h3>{{ $t('createAccount.toCreateClinic') }}:</h3>
              <button class="btn btn-success" data-test="btnConfirm" @click="confirm()">{{ $t('createAccount.createClinic') }}</button>
            </div>

            <div v-if="isTicketValid == false">
              <h3>
                <span>{{ firstName }}</span>,
                {{ $t('createAccount.invalidTicket') }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .col-lg-5 {
    h3 {
      span {
        font-weight:              700;
      }
    }
    .flex-between {
      gap:                        20px;
      margin:                     45px 0;
    }
    .form-control {
      @include                    border_radius(5px);
      height:                     67px;
      border:                     1px solid #DCDCDC;
      background:                 #FFF;
    }
  }
</style>

<script>
  import AuthFooter from '../../auth/AuthFooter.vue'
  import AuthHeader from '../../auth/AuthHeader.vue'

  export default {
    name: 'registration-account-activation',
    components: {
      AuthFooter,
      AuthHeader,
    },
    data: () => ({
      firstName: "",
      ticket: {},
      isTicketValid: null,
      isVerifying: true,
    }),
    async mounted() {
      const data = this.$utils.parseDataQuery(this.$route.query._data)
      this.firstName = data.firstName
      this.ticket = data.ticket

      let params = {
        activationCode: this.ticket.activationCode
      }
      try {
        await this.$registrationService.checkTicket(params)
        this.isTicketValid = true
      } catch(err) {
        this.isTicketValid = false
      }
      this.isVerifying = false
    },
    methods: {
      confirm: async function() {
        let data = this.$utils.dataQuery({
          firstName: this.firstName,
          ticket: this.ticket
        })

        this.$router.push({name: 'registration-clinic', query: {data: data}})
      }
    }
  }
</script>
