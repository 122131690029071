<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" :id="offcanvas_id" :aria-labelledby="`${offcanvas_id}_label`">
    <div class="offcanvas-header">
      <button class="btn-back" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" :id="`${offcanvas_id}_label`">{{ title }}</h5>
    </div>
    <div class="offcanvas-body">
      <vee-form v-slot="{ handleSubmit }" as="div">
        <form @submit.prevent.stop="handleSubmit(doConfirm)">
            <!-- <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
              <span v-html="alert.message"></span>
              <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
            </div> -->

            <div class="form-floating">
              <vee-field name="currentPassword" v-model="currentPassword" rules="required" v-slot="{ field, errorMessage }">
                <input v-bind="field" type="password" class="form-control" ref="currentPassword" id="currentPassword" :placeholder="$t('user.currentPassword')" data-test="inputCurrentPassword">
                <span class="vee-error">{{ errorMessage }}</span>
              </vee-field>
              <label for="currentPassword">{{ $t('user.currentPassword') }}</label>
            </div>
            <div class="form-floating">
              <vee-field name="newPassword" v-model="newPassword" rules="required|min:4" v-slot="{ field, errorMessage }">
                <input v-bind="field" :type="showNewPassword ? 'text' : 'password'"
                       class="form-control" ref="newPassword" id="newPassword"
                       @keyup="checkPassword(newPassword)" data-test="inputNewPassword" :placeholder="$t('user.newPassword')">

                <button class="no-btn" type="button" name="button" data-test="btnShowNewPassword" @click="showNewPassword = !showNewPassword">
                  <i class="fa" :class="showNewPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                </button>
                 <small class="strength-password" :class="strengthInfo" v-show="strengthInfo">{{ strengthInfo ? $t(`strengthPassword.${strengthInfo}`) : "" }}</small>
                 <span class="vee-error">{{ errorMessage ? errorMessage : $t(`${strengthPasswordMessage}`) }}</span>
              </vee-field>
              <label for="newPassword">{{ $t('user.newPassword') }}</label>
            </div>
            <div class="form-floating">
              <vee-field name="confirmNewPassword" v-model="newPasswordConfirmation" rules="required|confirmed:newPassword" v-slot="{ field, errorMessage }">
                <input v-bind="field" :type="showConfirmNewPassword ? 'text' : 'password'"
                         class="form-control" ref="passwordConfirmation" id="passwordConfirmation" data-test="inputConfirmPassword" :placeholder="$t('user.passwordConfirmation')">
                <button class="no-btn" type="button" name="button" data-test="btnShowConfirmPassword" @click="showConfirmNewPassword = !showConfirmNewPassword">
                    <i class="fa" :class="showConfirmNewPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
                </button>
                <span class="vee-error">{{ errorMessage }}</span>
              </vee-field>
              <label for="passwordConfirmation">{{ $t('user.passwordConfirmation') }}</label>
            </div>
          <!-- <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" data-test="btnCancel" @click="close()">{{ $t('user.cancel') }}</button>
            <button type="submit" class="btn btn-primary" data-test="btnSave" :disabled="disabled">{{ $t('user.modify') }}</button>
          </div> -->
          <div class="offcanvas-footer">
            <button type="submit" class="btn" :class="okButtonClass" data-test="btnConfirm" @click="doConfirm">{{ okButton }}</button>
            <button type="button" class="btn" :class="cancelButtonClass" data-bs-dismiss="offcanvas" v-show="cancelButton" data-test="btnCancel" @click="doCancel">{{ cancelButton }}</button>
          </div>
        </form>
      </vee-form>

      <!-- footer inside body to avoid empty space between body and footer -->
      <!-- <div class="offcanvas-footer">
        <button type="button" class="btn" :class="okButtonClass" data-bs-dismiss="offcanvas" data-test="btnConfirm" @click="doConfirm">{{ okButton }}</button>
        <button type="button" class="btn" :class="cancelButtonClass" data-bs-dismiss="offcanvas" v-show="cancelButton" data-test="btnCancel" @click="doCancel">{{ cancelButton }}</button>
      </div> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  width:                                510px !important;
  padding:                              40px;

  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    form {
      display:                          flex;
      flex-direction:                   column;
      gap:                              26px;
    }

    .vee-error {
      width:                            100% !important;
    }
  }

  .offcanvas-footer {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    .btn {
      font-size:                        var(--font-size-14);
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   4.2px;
      text-align:                       center;
      text-transform:                   uppercase;
      // gap:                              10px;
      // flex:                             1 0 0;
      width:                            100%;
      padding:                          20px;
    }

    .btn-success {
      color:                            var(--Shades-W, #FFF);
      // margin-top:                       26px;
    }
  }
}
</style>

<script>
import utilService from '../../../../services/util-service.js';
import { useUserStore } from '@/store/user'

export default {
  name: 'UserChangePasswordOffcanvas',
  props: ['offcanvas_id'],
  data: () => ({
    title: null,
    text: null,
    okButton: null,
    okButtonClass: null,
    cancelButton: null,
    cancelButtonClass: null,
    offcanvas_password: null,
    currentPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
    showNewPassword: false,
    showConfirmNewPassword: false,
    strengthPasswordMessage: 'zxcvbnMessages.default',
    strengthInfo: null,
    password: "",
    confirmPassword: "",
    disabled: false,
    account: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      pin: "",
    },
  }),
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    if (this.session) {
      this.account.firstName = this.session.collaborator.firstName
      this.account.lastName = this.session.collaborator.lastName
      this.account.email = this.session.collaborator.email
    }

    if (this.external) {
      this.checkEmail()
    }

    this.$nextTick(() => {
      currentPassword.focus()
    })

    this.disableCopyPaste();
  },
  methods: {
    show(options = {}) {
      if (options.title)
        this.title = options.title

      if (options.text)
        this.text = options.text

      if (options.okButton) {
        this.okButton = options.okButton
        this.okButtonClass = options.okButtonClass
      }

      if (options.cancelButton) {
        this.cancelButton = options.cancelButton
        this.cancelButtonClass = options.cancelButtonClass
      }

      this.offcanvas_password = new bootstrap.Offcanvas(document.getElementById(this.offcanvas_id)).show();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.changePassword()
    },

    doCancel() {
      this.offcanvas_password = null;
      this.resolvePromise(false)
    },

    async checkPassword(password) {

      this.account.password = password

      let params = {
        account: this.account
      }

      let isValidPassword = await utilService.validatePassword(params.account)

      switch (isValidPassword.score) {
        case 0:
        case 1:
        case 2:
          this.strengthInfo = 'danger'
          break;
        case 3:
          this.strengthInfo = 'warning'
          break;
        case 4:
          this.strengthInfo = 'success'
          break;
      }

      if (isValidPassword.valid) {
        this.strengthPasswordMessage = 'zxcvbnMessages.default'
      } else {
        this.strengthPasswordMessage = isValidPassword.warning ? 'zxcvbnMessages.warnings.' + isValidPassword.warning :
                                                            'zxcvbnMessages.suggestions.' + isValidPassword.suggestion[0]
      }
    },
    async changePassword() {

      try {
        let params = {
          account: this.account,
        }
        let responseValidate = await utilService.validatePassword(params.account)

        if (responseValidate.valid == false){
          console.log("errror")
          this.$message.popup(this, 'zxcvbnMessages.error', 'operationFail', 'error')
          return
        }

        NProgress.start()

        let result = await this.$userService.changePassword(this.newPassword, this.currentPassword)

        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')

        this.$nextTick(() => {
          this.offcanvas_password = null;
          this.resolvePromise(true)
        });
      } catch(err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    },
    async checkEmail() {
      try {
        let result = await this.$externalService.checkEmail()
        console.log('result check email', result);

        if (!result.isEmailValid) {
          this.disabled = true;
          this.alert = {
            message: this.$i18n.t('user.validEmail'),
            class: 'alert-warning'
          }
        }
      } catch (err) {
        console.log('erro check email', err);
      }
    },
    toggleShow() {
      this.showNewPassword = !this.showNewPassword;
    },
    toggleShowConfirm() {
      this.showConfirmNewPassword = !this.showConfirmNewPassword;
    },
    disableCopyPaste() {
      let inputArray = [this.$refs.newPassword, this.$refs.passwordConfirmation]
      inputArray.forEach((element) => {
        element.addEventListener('contextmenu', (e) => e.preventDefault()); // Disable right-click menu
        element.addEventListener('cut', (e) => e.preventDefault()); // Disable cut
        element.addEventListener('copy', (e) => e.preventDefault()); // Disable copy
        element.addEventListener('paste', (e) => e.preventDefault()); // Disable paste
      })
    },
  }
}
</script>
