<template>
  <nav class="navbar mob navbar-expand-lg">
    <div class="container-fluid">
      <a href="/" class="navbar-brand" data-test="anchorNavar">
        <i class="icon-logo_eyerlink"></i>
      </a>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#linksResponsiveMenu" data-test="btnNavbar"
              aria-controls="linksResponsiveMenu" aria-expanded="false" aria-label="Toggle navigation" @click="open = !open">
        <i class="icon-menu_hamburguer" aria-hidden="true"></i>
      </button>
      <div class="collapse navbar-collapse" id="linksResponsiveMenu">
        <ul class="navbar-nav">
          <li class="nav-item" :class="active === 'exam' ? 'active' : ''" data-test="anchorMenuExams">
            <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="open = false; examMenu();">
              <i class="icon-exam"></i>
              <span>{{ $t('menu.exams') }}</span>
            </a>
          </li>
          <li class="nav-item" :class="active === 'patient' ? 'active' : ''" data-test="anchorMenuPatients">
            <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="open = false; patientMenu()">
              <i class="icon-pacientes"></i>
              <span>{{ $t('menu.patients') }}</span>
            </a>
          </li>
          <li><hr class="dropdown-divider"></li>
          <!-- <li class="nav-item" :class="active === 'backup' ? 'active' : ''">
            <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="open = false; backupMenu()">
              <i class="fa fa-download" aria-hidden="true"></i>
              <span>{{ $t('menu.backup') }}</span>
            </a>
          </li> -->
          <li class="nav-item" data-test="anchorMenuLogout" @click="logout()">
            <a class="nav-link log" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
              <i class="fa fa-power-off" aria-hidden="true"></i>
              <span>{{ $t('menu.disconnect') }}</span>
            </a>
          </li>
        </ul>
        <p class="version">J.1.7.4</p>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
  .navbar {
    @include                              background_cover('@/assets/images/erjart/bg_menu.jpg');
    @include                              box_shadow(0 3px 5px rgb(108 108 108));
    position:                             fixed;
    width:                                100vw;
    border:                               0;
    padding:                              0;
    z-index:                              999;
    .navbar-toggler {
      @include                            box_shadow(unset);
      margin:                             0;
      padding:                            0;
      i {
        color:                            white;
        font-size:                        1.35em;
      }
    }
    .navbar-collapse {
      @include                            box_shadow(unset);
      border:                             0;
      .version {
        position:                         absolute;
        bottom:                           0;
        color:                            white;
        margin-bottom:                    0;
        left:                             unset;
        right:                            10px;
      }
    }
    .navbar-brand {
      font-size:                          unset;
      line-height:                        unset;
      height:                             unset;
      width:                              calc(100% - 102px);
      padding:                            9px 0;
      max-height:                         50px;
      i {
        color:                            white;
        font-size:                        2.55em;
      }
    }
    .navbar-nav {
      margin-top:                         0;
      margin:                             7.5px -15px;
      .nav-item {
        .nav-link {
          font-size:                      12px;
          font-weight:                    400;
          color:                          white;
          line-height:                    32px;
          text-transform:                 uppercase;
          letter-spacing:                 3px;
          display:                        block;
          padding:                        5px 15px;
          text-decoration:                none;
          cursor:                         pointer;
          i {
            font-size:                    2em;
            vertical-align:               middle;
            margin-right:                 5px;
            &.fa {
              text-align:                 center;
              -webkit-text-stroke:        1px $eucalyptus;
              color:                      transparent;
              width:                      27px;
            }
          }
        }
        &:hover,
        &:focus {
          .nav-link {
            color:                        $pale-green;
          }
        }
        &:active,
        &.active {
          .nav-link {
            font-weight:                  bold;
            &,{
              color:                      white;
            }
            i {
              &.fa {
                -webkit-text-stroke:      1px white;
              }
              &:not(.fa):before {
                color:                    white;
              }
            }
          }
        }
      }
    }
    .btn-icon {
      margin-bottom:                      0;
      padding:                            0;
      border:                             0;
      i {
        font-size:                        2em;
      }
    }
  }
</style>

<script>
import { useEyerwebStore } from '@/store/eyerweb'

export default {
  name: 'EyerwebMenuMobileLinks',
  props: ['isResize'],
  data: () => ({
    active: null,
    open: true,
    session: {
      collaborator: {},
    },
  }),
  watch: {
    '$activeLink.value': {
      handler: function () {
        console.log('watch', this.$activeLink.value);
        this.active = this.$activeLink.value
    }, deep: true }
  },
  mounted() {
    const eyerwebStore = useEyerwebStore()
    this.session = eyerwebStore.sessionInfo
    this.active = this.$activeLink.value ? this.$activeLink.value : null
  },
  methods: {
    backupMenu: async function() {
      // this.goToLink(this, 'backup', 'eyerweb-full-backup')
    },
    examMenu: async function() {
      this.session.examCurrentPage = 1
      this.goToLink(this, 'exam', 'eyerweb-exam-list')
    },
    patientMenu: async function() {
      this.session.patientCurrentPage = 1
      this.goToLink(this, 'patient', 'eyerweb-patient-list')
    },
    toggleMenu(val) {
      this.open = val
    },
    async logout() {
      this.$emit('logout')
    },
    goToLink(self, active, link) {
      this.toggleMenu(false);
      self.active = active
      self.$router.push({name: link, query: { ...self.$route.query, t: Date.now() }})
    },
  }
}

</script>
