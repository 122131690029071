<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <path d="M27 12.0381L23.7033 15.3348L20.4067 12.0381" stroke="#3CB391" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M23.7027 14.2643C23.7027 9.31388 19.6896 5.30078 14.7392 5.30078C11.1803 5.30078 8.10593 7.37481 6.65869 10.3801" stroke="#3CB391" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M2.73633 18.6309L6.033 15.3342L9.32967 18.6309" stroke="#3CB391" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M6.0334 16.4047C6.03339 21.3551 10.0465 25.3682 14.9969 25.3682C18.7105 25.3682 21.8966 23.1099 23.2568 19.8917" stroke="#3CB391" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconChange'
}
</script>
