<template>
  <div class="" v-loader.opacity="loading">
    <div class="sub-header-box">
      <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>

      <nav style="--bs-breadcrumb-divider: '';" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/home/" data-test="btnRouter">
              {{ $t('home') }}
            </router-link>
          </li>
          <li class="breadcrumb-item" aria-current="page">
            <router-link to="/settings/" data-test="btnRouter">
              {{ $t('createClinic.configuration') }}
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ $t('user.user') }}</li>
        </ol>
      </nav>
    </div>

    <div class="title-box">
      <div class="flex-start">
        <h2 class="title">MFA</h2>
      </div>
    </div>

    <div class="inside-box">
      <div class="row justify-content-md-start">
        <div class="col-md-4">
        </div>

        <div class="col-md-8 offset-md-4 scrollbar">
          <h3 class="sub-title">{{ $t('mfa.accountSecurity') }}</h3>

          <section>
            <div class="form-floating">
              <input type="text" class="form-control" id="mfaEmail" name="mfaEmail" :placeholder="$t('user.mfaEmail')" v-model="mfa.email.value" data-test="inputMfaEmail" disabled>
              <label for="mfaEmail">{{ $t('user.mfaEmail') }}</label>

              <span class="label" :class="labelMfaDetailsClass.email">{{ mfaDetails('email') }}</span>
            </div>
            <!-- <div class="col-lg-6">
              <button id="setupMFAEmail" type="button" @click="mfaSetup('email')" class="btn btn-info" data-test="btnMfaSetupEmail">{{ $t('user.mfaSetup') }}</button>
              <button id="removeMFAEmail" type="button" v-if="wasVerified('email')" @click="removeMFA('email')" class="btn btn-danger" data-test="btnMfaRemoveEmail">{{ $t('user.mfaRemoval') }}</button>
            </div> -->

            <div class="form-floating">
              <input type="text" class="form-control" id="mfaMobile" name="mfaMobile" :placeholder="$t('user.mfaMobile')" v-model="mfa.mobile.value" data-test="inputMfaMobile" disabled>
              <label for="mfaMobile">{{ $t('user.mfaMobile') }}</label>

              <span class="label" :class="labelMfaDetailsClass.mobile">{{ mfaDetails('mobile') }}</span>
            </div>
          <!-- <div class="col-lg-6">
            <button id="setupMFAMobile" type="button" class="btn btn-info" data-test="btnMfaSetupMobile" @click="mfaSetup('mobile')">{{ $t('user.mfaSetup') }}</button>
            <button id="removeMFAMobile" type="button" v-if="wasVerified('mobile')" class="btn btn-danger" data-test="btnMfaRemoveMobile" @click="removeMFA('mobile')">{{ $t('user.mfaRemoval') }}</button>
          </div> -->
          </section>

          <section>
            <h3 class="sub-title">{{ $t('mfa.connectedDevices') }}</h3>

            <table>
              <tbody>
                <tr v-for="device of mfaDevices" :key="device.id">
                  <td>
                    <p class="no-wrap">
                      <strong>{{ device.browser }} - {{ device.os }}</strong> -
                      <span>{{ formatDate(device.createdAt) }}</span>
                    </p>
                  </td>
                  <td>
                    <button class="btn btn-danger btn-sm" :data-test="`btnMfaDeviceRemove_${device.id}`" @click="removeMFADevice(device.id)">
                      <i class="fa fa-trash-o"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </div>

    <div class="action-bar">
      <button class="btn btn-success" id="setupMFAEmail" type="button" @click="mfaSetup('email')" data-test="btnMfaSetupEmail">{{ $t('user.mfaEmailSetup') }}</button>
      <button class="btn btn-outline-danger" id="removeMFAEmail" type="button" v-if="wasVerified('email')" @click="removeMFA('email')" data-test="btnMfaRemoveEmail">{{ $t('user.mfaEmailRemoval') }}</button>

      <button class="btn btn-success" id="setupMFAMobile" type="button" data-test="btnMfaSetupMobile" @click="mfaSetup('mobile')">{{ $t('user.mfaMobileSetup') }}</button>
      <button class="btn btn-outline-danger" id="removeMFAMobile" type="button" v-if="wasVerified('mobile')" data-test="btnMfaRemoveMobile" @click="removeMFA('mobile')">{{ $t('user.mfaMobileRemoval') }}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.inside-box {
  height:                             calc(100vh - 250px);
  max-height:                         calc(100vh - 250px);
}

.action-bar {
  display:                            flex;
  justify-content:                    flex-start;
  align-items:                        center;
  gap:                                10px;
  height:                             64px;
  padding:                            10px 20px;
  border-top:                         1px solid var(--Shades-30);
  // height:                           100%;
}

.btn {
  @include                            border_radius(99px);
  font-size:                          var(--font-size-12);
  font-weight:                        400;
  letter-spacing:                     3.64px;
  text-transform:                     uppercase;
}

.btn-outline-danger {
  @include                            border_radius(99px);
  color:                              var(--Complementary-Orange);
  font-weight:                        700;
  padding:                            15px 20px;
  height:                             44px;
  margin:                             0 !important;
  border:                             1px solid var(--Complementary-Orange);
  background:                         #FFEBE8;
}

.btn-success {
  color:                              var(--Shades-W);
  font-weight:                        700;
  height:                             44px;
  padding:                            15px 20px;
  background:                         var(--Brand-Secondary);
  border:                             1px solid var(--Brand-Secondary);
}

.sub-header-box {
  display:                            flex;
  flex-direction:                     row;
  justify-content:                    flex-start;
  align-items:                        center;
  gap:                                20px;
  width:                              100%;
  padding:                            15px 20px;

  ol {
    margin:                           0;
  }
}

.title-box {
  display:                            flex;
  flex-direction:                     row;
  align-items:                        center;
  justify-content:                    space-between;
  height:                             55px;
  margin:                             0;
  padding:                            10px 25px;
  // margin:                             2.3rem 0 1.7rem;

  > div {
    width:                            auto !important;
  }
}

.sub-title {
  color:                              var(--Shades-60, #727272);
  font-size:                          16px;
  font-weight:                        500;
  line-height:                        normal;
  letter-spacing:                     3.2px;
  text-transform:                     uppercase;
  text-overflow:                      ellipsis;
  white-space:                        nowrap;
  overflow:                           hidden;
  border:                             0;
  margin:                             0 0 19px;
}

.form-floating {
  span {
    @include                          border_radius(0 5px 5px 0);
    position:                         absolute;
    display:                          flex;
    align-items:                      center;
    right:                            0;
    top:                              0;
    height:                           55px;
  }
}

.col-md-4 {
  position:                          fixed;
  display:                           flex;
  justify-content:                   flex-end;
}

.col-md-8 {
  overflow-y:                        auto;
  height:                            100%;
  max-height:                        calc(100vh - 348px);
  // max-height:                        calc(100vh - 165px);
  // max-height:                        calc(100vh - 217px);

  section {
    display:                         flex;
    flex-direction:                  column;
    gap:                             19px;
    max-width:                       550px;
    width:                           100%;
    padding-bottom:                  33px;

    &:last-child {
      padding-bottom:                4rem;
    }
  }
}

@media (max-width: 1500px) {
  .inside-box {
    height:                          calc(100vh - 246px);
    max-height:                      calc(100vh - 246px);
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import User from '@/model/User'

export default {
    name: "user-mfa",
    data: () => ({
      loading: false,
      user: new User(),
      mfa: { // TODO REFACTOR to mfaOptions
        email: {},
        mobile: {}
      },
      labelMfaDetailsClass: {
        email: null,
        mobile: null
      },
      mfaDevices: [],
      countryDdi: null,
      session: {
        collaborator: {},
        currentClinic: {}
      },
    }),
    async created() {
      const userStore = useUserStore()
      this.session = userStore.sessionInfo
      this.countryDdi = this.$utils.getCountryDdi(this.session.currentClinic.country)
      console.log('this.session', this.session);
      console.log('ddi', this.countryDdi);

      try {
        const userID = this.$route.params.userID
        let response = await this.$userService.getMFAInformation(userID)

        this.mfa = Object.assign(this.mfa, response.mfa)
        this.user.mfa = this.mfa
        this.mfaDevices = response.mfaDevices
      } catch(err) {
        this.$message.popup(this, err.message , 'Ops...', 'error')
      }
    },
    methods: {
      goBack() {
        const userID = this.$route.params.userID
        this.$router.push({name: 'user-show', params: {userID: userID}, query: {tab: 'data'}})
      },
      async mfaSetup(target) {
        try {
          let _self = this
          const mfaDeviceText = this.$i18n.t(`mfa.${target}`) // mobile or email
          const enterMFADevicePrompt = this.$i18n.t('mfa.enterMFADevicePrompt', {mfaDevice: mfaDeviceText})
          const {value: mfaOptionValue} = await this.$swal.fire({
            title: 'MFA',
            html: `
              <div class="form-group">
                <label>${enterMFADevicePrompt}</label>
                <input type="${mfaDeviceText == 'email' ? 'email' : ''}"
                       class="form-control" ref="${target}" id="${target}"
                       value="${target == 'mobile' ? _self.countryDdi : ""}">
                 <span class="vee-error" id="veeError">${ _self.$i18n.t('invalidEmail') }</span>
              </div>
            `,
            // input: mfaDeviceText == 'email' ? 'email' : 'text', // TODO email x mobile
            inputLabel: enterMFADevicePrompt,
            inputValue: target == 'mobile' ? this.countryDdi : "",
            confirmButtonColor: '#0d6efd',
            confirmButtonText: this.$i18n.t('save'),
            showCancelButton: true,
            customClass: {
              inputLabel: 'normal-label',
              title: 'info-title',
              cancelButton: 'btn-outline-secondary'
            },
            didOpen: () => {
              if (target == 'email') {
                document.getElementById('email').addEventListener("keyup", emailValidate);

                function emailValidate(event) {
                  let errorMessage = document.getElementById('veeError')
                  let confirmButton = document.querySelector('.swal2-confirm')

                  if (!/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(event.target.value)) {
                    errorMessage.style.display = 'block'
                    confirmButton.disabled = true
                  } else {
                    errorMessage.style.display = 'none'
                    confirmButton.disabled = false
                  }
                }
              }
            },
            inputValidator: async (value) => {
              if (!value)
                return this.$i18n.t(`mfa.invalidInput`)
            },
          })

          if (!mfaOptionValue)
            return

          const setupData = await this.$userService.mfaSetup(target, mfaOptionValue)
          await this._readConfirmationCode(target, setupData)

        } catch(err) {
          this.$message.popup(this, err.message , 'Ops...', 'error')
        }
      },

      async _readConfirmationCode(target, setupData) {
        console.log('target', target);
        const mfaDeviceText = this.$i18n.t(`mfa.${target}`)
        const enterConfirmationCodeMessage = this.$i18n.t('mfa.enterConfirmationCode', {mfaDevice: mfaDeviceText})
        const {value: confirmationCode} = await this.$swal.fire({
          title: 'MFA',
          input: 'text',
          inputLabel: enterConfirmationCodeMessage,
          inputValue: "",
          confirmButtonColor: '#0d6efd',
          confirmButtonText: this.$i18n.t('save'),
          showCancelButton: true,
          customClass: {
            inputLabel: 'normal-label',
            title: 'info-title',
            cancelButton: 'btn-outline-secondary'
          },
          inputValidator: (value) => {
            if (!value)
              return this.$i18n.t('mfa.codeWasNotProvided')
          }
        })

        if (!confirmationCode)
          return


        try {
          const confirmationData = await this.$userService.confirmMFACode(target, setupData.transactionCode, confirmationCode)

          this.$authService.sessionChanged(confirmationData.sessionInfo)
          this.mfa = confirmationData.mfa
          this.mfaDevices = confirmationData.mfaDevices
          this.$message.popup(this, 'operationSuccess', 'successTitle', 'success')
        } catch(err) {
          this.$message.popup(this, err.message , 'Ouch...', 'error')
        }
      },
      wasVerified(type) {
        return this.mfa[type].wasVerified
      },
      mfaDetails(type) {
        let mfaData = this.mfa[type]
        if (!this.wasVerified(type)) {
          this.labelMfaDetailsClass[type] = 'label-danger'
          return this.$i18n.t('mfa.notActive')
        }

        const DATE_FORMAT  = this.$i18n.t('dateTimeFormat')
        let creationDate = moment(mfaData.createdAt).format(DATE_FORMAT)
        this.labelMfaDetailsClass[type] = 'label-success'
        return this.$i18n.t('mfa.createdAt', {creationDate: creationDate})
      },
      async removeMFA(type) {
        try {
          const mfaDeviceText = this.$i18n.t(`mfa.${type}`) // mobile or email
          const mfaDeviceRemovalPrompt = this.$i18n.t('mfa.mfaDeviceRemovalPrompt', {mfaDevice: mfaDeviceText})

          const {value: confirmed} = await this.$swal.fire({
            title: this.$i18n.t('user.mfaRemoval'),
            text: mfaDeviceRemovalPrompt,
            confirmButtonColor: '#0d6efd',
            confirmButtonText: this.$i18n.t('mfa.yes'),
            cancelButtonText: this.$i18n.t('mfa.no'),
            showCancelButton: true,
            customClass: {
              title: 'danger-title',
              cancelButton: 'btn-outline-danger'
            }
          })

          if (!confirmed)
            return

          let data = await this.$userService.removeMFA(type)

          this.user = new User(data.user)
          this.$authService.sessionChanged(data.sessionInfo)
          this.mfa = this.user.mfa
        } catch(err) {
          this.$message.popup(this, 'general.removedError', 'operationFail', 'error')
        }
      },
      async removeMFABrowser(id) {
        try {
          const mfaBrowserRemovalText = this.$i18n.t('mfa.mfaBrowserRemovalText')

          const {value: confirmed} = await this.$swal.fire({
            title: this.$i18n.t('user.mfaRemoval'),
            text: mfaBrowserRemovalText,
            confirmButtonColor: '#0d6efd',
            confirmButtonText: this.$i18n.t('mfa.yes'),
            cancelButtonText: this.$i18n.t('mfa.no'),
            showCancelButton: true,
            customClass: {
              title: 'danger-title',
              cancelButton: 'btn-outline-danger'
            }
          })

          if (!confirmed)
            return

          let data = await this.$userService.removeMFADevice(id)
          this.mfaDevices = data.mfaDevices
        } catch(err) {

        }
      },
      formatDate(date) {
        const DATE_FORMAT  = this.$i18n.t('dateTimeFormat')
        return moment(date).format(DATE_FORMAT)
      }
    }
}

</script>
