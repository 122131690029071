<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M1 14.8457L15 0.845703" stroke="#3CB391" stroke-width="2"/>
    <path d="M1 0.845703L15 14.8457" stroke="#3CB391" stroke-width="2"/>
  </svg>
</template>

<style lang="css" scoped>
</style>

<script>
export default {
  name: 'IconClose'
}
</script>
