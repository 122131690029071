<template>
  <div class="external-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <div class="">
            <h6>{{ $t('createAccount.welcome') }}</h6>
            <img src="@/assets/images/phelcom/eyercloud_logo_white.svg" alt="Eyercloud logo">
          </div>
        </div>
        <div class="col-lg-5 flex-center">
          <div class="external-content">
            <h3 class="mb-3">{{ $t('createAccount.success') }}</h3>

            <h3 class="mb-0">
              <span>{{ user.collaborator.firstName }}</span>,
              {{ $t('createAccount.emailSent') }}
              <span> {{ user.email }}</span>.
            </h3>

            <!-- <div class="flex-between">
              <input class="form-control" type="text" name="" value="">
              <input class="form-control" type="text" name="" value="">
              <input class="form-control" type="text" name="" value="">
              <input class="form-control" type="text" name="" value="">
              <input class="form-control" type="text" name="" value="">
              <input class="form-control" type="text" name="" value="">
            </div>

            <button class="btn btn-success mb-4" type="button" name="button">Ativar</button>

            <p>Não recebeu? <a>Reenvie o e-mail clicando aqui</a> </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.col-lg-5 {
  h3 {
    span {
      font-weight:              700;
    }
  }
  p {
    a {
      text-decoration-line:     underline;
    }
  }
  .flex-between {
    gap:                        20px;
    margin:                     45px 0;
  }
  .form-control {
    @include                    border_radius(5px);
    height:                     67px;
    border:                     1px solid #DCDCDC;
    background:                 #FFF;
  }
}
</style>

<script>
  import AuthFooter from '../../auth/AuthFooter.vue'
  import AuthHeader from '../../auth/AuthHeader.vue'

  export default {
    name: 'registration-account-created',
    components: {
      AuthFooter,
      AuthHeader,
    },
    data: () => ({
      user: {
        collaborator: {}
      }
    }),

    mounted() {
      try {
        let data = this.$utils.parseDataQuery(this.$route.query.data)
        this.user = data.user
      } catch(err) {
        this.$message.popup(this, err.message, 'operationFail', 'error')
        console.log("error:", err)
      }
    },
  }
</script>
