<template>
  <div class="audit-main-box" v-loader.opacity="loading">

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <BaseInfiniteTable class="no-border" v-if="tableAudit" :tabledata="tableAudit">
    </BaseInfiniteTable>

    <teleport to="#settingsActionBar">
      <audit-filter @do-filter="fetchData"></audit-filter>
    </teleport>

    <!-- <div class="row align-items-center page-row" v-if="tableAudit">
      <div class="col">
        <p>{{ $t("pagination.totalEntries") }} {{ tableAudit.total }}</p>
      </div>
      <div class="col-auto">
        <BasePagination :total-pages="totalPages" :total="tableAudit.total" :per-page="perPage" :current-page="currentPage" @pagechanged="onPageChange"/>
      </div>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.page-row,
.page-row p,
.table {
  margin-bottom:                 0 !important;
}

.page-row {
  margin-top:                    20px;
  p,
  .page-row .pagination {
    padding-right:               10px;
    padding-left:                10px;
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import AuditFilter from './AuditFilter.vue'

export default {
  name: 'audit-list',
  components: {
    AuditFilter,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    tableAudit: null,
    perPage: 20,
    currentPage: 1,
    filter: {
    },
    totalPages: 0,
  }),
  mounted() {
    this.$activeLink.value = 'audit'

    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.currentPage > 1 ? this.fetchData(this.filter) : this.fetchData()
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
      this.session.auditCurrentPage = page;
      this.filter.pagination = true
      this.filter.newRequest = false
      this.fetchData();
    },
    async fetchData(isFilter) {
      this.alert = {}

      if (isFilter) {
        this.filter = isFilter;
        this.filter.statusFilter = isFilter.filterSelected

        if (!isFilter.pagination)
        this.currentPage = this.session.auditCurrentPage ? this.session.auditCurrentPage : 1;

        if (isFilter.newRequest) {
          this.session.auditCurrentPage = 1
          this.currentPage = 1
        }
      }

      this.filter.page = this.currentPage

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$auditService.getList(this.filter)

        this.tableAudit = {
          'items': response.result.audits,
          'total': response.result.audits.length,
          'columns': [
            {name: this.$i18n.t('audit.entry'), type: 'string', value: 'createdAt', width: '20%'},
            {name: this.$i18n.t('audit.action'), type: 'string', value: 'action', width: '20%'},
            {name: this.$i18n.t('audit.agent'), type: 'string', value: 'agent', width: '20%'},
            {name: this.$i18n.t('audit.agentType'), type: 'string', value: 'agentType', width: '20%'},
            {name: this.$i18n.t('audit.message'), type: 'string', value: 'message', width: '20%'},
          ]
        };
        this.totalPages = Math.ceil(response.result.meta.paginate.totalCount / this.perPage);

        if (response.result.meta.paginate.totalCount === 0) {
          this.tableAudit = {};
          this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true);
      this.loading = false
    },
  },
}
</script>
