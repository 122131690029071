<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none">
    <circle cx="11.5522" cy="8.39192" r="7.31028" stroke="#1B294A"/>
    <path d="M14.5959 8.39194C14.5959 4.65404 11.5658 1.62387 7.82788 1.62387C4.08998 1.62387 1.05981 4.65404 1.05981 8.39194" stroke="#3CB391"/>
    <path d="M20.9744 5.87695C20.9744 9.61485 17.9442 12.645 14.2063 12.645C10.4684 12.645 7.43823 9.61485 7.43823 5.87695" stroke="#1B294A"/>
    <path d="M15.7681 13.8462L21.9461 20.0245" stroke="#3CB391" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconAudit'
}
</script>
