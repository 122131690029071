<template>
  <div class="modal fade" :id="modal_id"
       data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="modal_generic_label" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" :class="modalType">
          <h5 class="modal-title" id="modal_generic_label">{{ title }}</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p v-html="text"></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn" :class="cancelButtonClass" data-bs-dismiss="modal" v-show="cancelButton" data-test="btnCancel" @click="doCancel">{{ cancelButton }}</button>
          <button type="button" class="btn btn-primary" :class="okButtonClass" data-bs-dismiss="modal" data-test="btnConfirm" @click="doConfirm">{{ okButton }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-dialog {
  .modal-header {
    display:                    flex;
    h5 {
      font-size:                18px;
    }
    &.warning {
      background-color:         $yellow-phelcom;
      border-bottom:            1px solid $platinum;
      h5 {
        color:                  white;
      }
    }
    &.danger {
      background-color:         $phelcom-red;
      border-bottom:            1px solid $platinum;
    }
    &.info {
      background-color:         $phelcom-light-blue;
      border-bottom:            1px solid $platinum;
    }
  }
  .modal-body {
    p {
      font-size:                14px;
      margin:                   0;
      &::first-letter{
        text-transform:         capitalize;
      }
    }
  }
  .modal-footer {
    .btn-outline-secondary {
      border-color:             $gray;
    }
  }
}
</style>

<script>
export default {
  name: 'BaseGenericModal',
  props: ['modal_id'],
  data: () => ({
    modalType: null,
    title: null,
    text: null,
    okButton: null,
    okButtonClass: null,
    cancelButton: null,
    cancelButtonClass: null,
    modal_generic: null,
  }),
  mounted() {
  },
  methods: {
    show(options = {}) {
      this.modalType = options.modalType
      this.title = options.title
      this.text = options.text
      this.okButton = options.okButton
      this.okButtonClass = options.okButtonClass
      if (options.cancelButton) {
        this.cancelButton = options.cancelButton
        this.cancelButtonClass = options.cancelButtonClass
      }

      this.modal_generic = new bootstrap.Modal(document.getElementById(this.modal_id)).show();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.modal_generic = null;
      this.resolvePromise(true)
    },

    doCancel() {
      this.modal_generic = null;
      this.resolvePromise(false)
    },
  }
}
</script>
