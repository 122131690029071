<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <rect x="2.3335" y="2.33337" width="17.3333" height="17.3333" rx="1.5" stroke="#1B294A"/>
    <path d="M3.2085 18.7917L13.2918 8.70837L19.2502 14.6667" stroke="#1B294A" stroke-linejoin="round"/>
    <circle cx="7.33333" cy="7.33333" r="1.83333" fill="#1B294A"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconImage'
}
</script>
