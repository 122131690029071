import grapesjs from 'grapesjs'
import pt from 'grapesjs/src/i18n/locale/pt';
import en from 'grapesjs/src/i18n/locale/en';
import es from 'grapesjs/src/i18n/locale/es';
import grapesjsPresetNewsletter from 'grapesjs-preset-newsletter'
import { useUserStore } from '@/store/user'
import ReportClinicHeader from './ReportClinicHeader.vue'
import ReportGeneralPrintModal from './ReportGeneralPrintModal.vue'

const IS_PRODUCTION = process.env.NODE_ENV == 'production'
let editor // BEWARE avoid adding proxy stuff into it (browser freezes!)

export default {
  name: 'report-general',
  components: {
    ReportClinicHeader,
    ReportGeneralPrintModal
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    clinic: {
      name: null,
      address: null,
      addressNumber: null,
      city: null,
      clinicLogo: null,
      date: null,
      district: null,
      state: null,
      telephone1: null,
      telephone2: null,
      time: null,
    },
    userSignature: null,
    patient: {fullName: "Ouch! Refresh can kill me.. oh boy!"},
    exam: null,
    examData: [],
    dataPath: null,
    contentPrint: null,
    showModalPrint: false,
    currentReport: {
      exam: null,
      type: null,
      customTemplate: null,
    },
    reportID: null,
  }),
  async created() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    const params = this.$route.params
    console.log('params', params);
    this.reportID = params.id
    this.exam = {id: params.examID}

    // TODO @dimas create new endpoint -> patient/general-report/create (exam x patient)
    // return available images and PATIENT info (params: {patientID: patient.id} or {examID: exam.id})
    await this.fetchReportInfoData()

    this.initializeGjs()
    editor.BlockManager.getAll().reset();
    this.addBlocks()

    if (this.reportID) {
      this.loadReport(this.reportID)
    }

    editor.on('change:changesCount', () => {
      this.currentReport.customTemplate = editor.getProjectData();
      this.addGjsPage()
    });

    editor.on('asset:open', () => {
        this.fetchData()
    });

    this.$nextTick(() => {
      editor.BlockManager.render();
    })
  },
  mounted() {
    // this.doCollapse()
  },
  methods: {
    goBack() {
      editor.destroy();
      this.$router.push({name: 'exam-show', params: {examID: this.exam.id}, query: {tab: 'report'}})
    },
    async doCreate() {
      let newReport = Object.assign({}, this.currentReport)
      newReport['exam'] = this.exam.id
      newReport['type'] = 'general'
      newReport['customTemplate'] = JSON.stringify(editor.getProjectData());

      // edition
      // if (this.currentReport.id) {
      //   newReport.id = this.currentReport.id;
      // }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$reportService.saveReport(newReport);
        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')
        setTimeout(() => {
          this.goBack()
        }, 500);
      } catch (err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger');
      }
      NProgress.done(true)
      this.loading = false
    },
    async loadReport(reportID) {
      this.loading = true
      NProgress.start()
      try {
        let params = {id: reportID}
        let response = await this.$reportService.getReport(params)
        console.log('response load report', response);
        this.currentReport = response.report
        this.patient = response.patient

        editor.loadProjectData(JSON.parse(this.currentReport.customTemplate));
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger');
      }
      NProgress.done(true)
      this.loading = false
    },
    async fetchReportInfoData() {
      this.alert.message = null

      let params = {
        id: this.$route.params.patientID
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$medicalRecordService.createGeneralReport(params);
        this.patient = response.patient
        this.userSignature = response.userSignature

        this.clinic = response.clinic
        this.clinic.clinicLogo = response.clinicLogo
        this.clinic.date = moment(new Date()).format(this.$i18n.t('dateFormat'))
        this.clinic.time = moment(new Date()).format(this.$i18n.t('timeFormat'))
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async fetchData() {
      this.alert.message = null

      let params = {
        id: this.exam.id
      }

      this.loading = true
      NProgress.start()
      try {
        let images = new Array()
        let response = await this.$examDataService.list(params);
        this.examData = response.examDataList

        // TODO REFACTOR use service to retrieve both dataPath and thumbDataPath from a response
        this.dataPath = `${response.dataPath}`
        if (!IS_PRODUCTION) {
          this.dataPath = `http://localhost:1337${response.dataPath}`
        }

        this.examData.forEach(o => {
          let image = this.dataPath+'/'+o.name
          images.push(image)
        })

        editor.Assets.clear().add(images)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    initializeGjs() {
      editor = grapesjs.init({
        container: '#gjs',
        fromElement: true,
        type: 'local',
        height: '600px',
        plugins: [grapesjsPresetNewsletter],
        i18n: {
          locale: 'en', // default locale
          detectLocale: true, // by default, the editor will detect the language
          localeFallback: 'en', // default fallback
          messages: { pt, en, es },
        },
        deviceManager: {
          height: '500px'
        },
        storageManager: false,
        style: `
          body {
            font-family:       'Ubuntu', sans-serif !important;
            color:             black;
            counter-reset:     pagination;
            background-color:  #444;
          }
          .gjs-page {
            position:          relative;
            counter-increment: pagination;
            height:            964px;
            margin-top:        20px;
            border-color:      #0d6efd !important;
            page-break-after:  always;
          }
          .gjs-page.first {
            margin-top:        0;
            height:            660px;
          }
          .gjs-page::after {
            content:           counter(pagination);
            right:             0;
            bottom:            0;
            position:          absolute;
          }
          [data-gjs-type="wrapper"] {
            padding:           35px 0;
          }
          [data-gjs-type="wrapper"] .col-3,
          [data-gjs-type="wrapper"] .col-4,
          [data-gjs-type="wrapper"] .col-6,
          [data-gjs-type="wrapper"] .col-12,
          [data-gjs-type="wrapper"] .page-break,
          [data-gjs-type="wrapper"] .gjs-page {
            border:            1px dashed #ddd;
          }
          .row {
            display:           flex;
            flex-wrap:         wrap;
            margin-top:        10px;
          }
          .row & > * {
            flex-shrink:       0;
            max-width:         100%;
          }
          .col-3,
          .col-4,
          .col-6,
          .col-12 {
            padding:           15px 0;
            min-height:        200px;
          }
          .row & > .col-3 {
            width:             25%;
          }
          .row & > .col-4 {
            width:             33.33%;
          }
          .row & > .col-6 {
            width:             50%;
          }
          .row & > .col-12 {
            width:             100%;
          }
          .page-break {
            height:            0;
            width:             100%;
            margin:            0;
            page-break-before: always;
          }
          .col-3 {
            flex:              0 0 auto;
            width:             25%;
            height:            100%;
          }
          .col-4 {
            flex:              0 0 auto;
            width:             33.33%;
            height:            100%;
          }
          .col-6 {
            flex:              0 0 auto;
            width:             50%;
            height:            100%;
          }
          .col-12 {
            flex:              0 0 auto;
            width:             100%;
            height:            100%;
          }
          img {
            max-width:         95%;
            max-height:        100%;
            display:           block;
            height:            auto;
            margin-left:       auto;
            margin-right:      auto;
          }
          .col-3 img,
          .col-4 img,
          .col-6 img,
          .col-12 img {
            -o-object-fit:     contain;
            object-fit:        contain;
          }
          p, h1 {
            color:             black;
          }
          .clinic-header {
            padding:           15px;
          }
          .clinic-header img {
            width:             180px;
            display:           inline-block;
          }
          .clinic-header .clinic-header-content {
            width:             calc(100% - 185px);
            display:           inline-block;
            padding-left:      10px;
            vertical-align:    top;
          }
          .clinic-header .clinic-header-content p {
            margin:            0 0 5px;
          }
          .clinic-header .clinic-header-content .content-data {
            float:             right;
          }
          .clinic-header .clinic-header-content .content-data p {
            display:           inline-block;
            margin:            0 10px;
          }
          .gjs-title,
          .heading,
          .paragraph {
            margin:            0;
          }
          .gjs-title,
          .heading {
            padding:           0.67em 0;
          }
          .paragraph {
            padding:           1em 0;
          }
          .gjs-page,
          .gjs-title,
          .clinic-header {
            background-color:  white;
          }
          .signature {
            width:             300px;
            float:             right;
          }
          .signature img {
            width:             100%;
          }
          .signature p {
            text-align:        center;
            margin:            0;
          }
        `,
        // Avoid any default panel
        panels: { defaults: [] },
        blockManager: {
          appendTo: '#blocks',
        },
        assetManager: {
          assets: [],
        },
        styleManager: {
          appendTo: '#blocks',
          sectors: [],
        },
      });
    },
    addBlocks() {
      let signature = this.userSignature;
      let userName = this.session.collaborator.firstName + ' ' + this.session.collaborator.lastName
      let _self = this

      editor.BlockManager.add('column', {
        label: `<b>${_self.$i18n.t('grapesEditor.column')}</b>`,
        media: `<div class="gjs-fonts gjs-f-b1"></div>`,
        content: `
          <div class="row" data-gjs-droppable=".col-12" data-gjs-custom-name="Row">
            <div class="col-12" data-gjs-type="column-type"></div>
          </div>
        `,
        activate: true,
      });
      editor.BlockManager.add('twoColumns', {
        label: `<b>${_self.$i18n.t('grapesEditor.twoColumns')}</b>`,
        media: `<div class="gjs-fonts gjs-f-b2"></div>`,
        content: `
          <div class="row" data-gjs-droppable=".col-6" data-gjs-custom-name="Row">
            <div class="col-6" data-gjs-type="column-type"></div>
            <div class="col-6" data-gjs-type="column-type"></div>
          </div>
        `,
        activate: true,
      });
      editor.BlockManager.add('threeColumns', {
        label: `<b>${_self.$i18n.t('grapesEditor.threeColumns')}</b>`,
        media: `<div class="gjs-fonts gjs-f-b3"></div>`,
        content: `
          <div class="row" data-gjs-droppable=".col-4" data-gjs-custom-name="Row">
            <div class="col-4" data-gjs-type="column-type"></div>
            <div class="col-4" data-gjs-type="column-type"></div>
            <div class="col-4" data-gjs-type="column-type"></div>
          </div>
        `,
        activate: true,
      });
      editor.BlockManager.add('fourColumns', {
        label: `<b>${_self.$i18n.t('grapesEditor.fourColumns')}</b>`,
        media: `<div class="custom-icon"><i class="icon-four-columns"></i></div>`,
        content: `
          <div class="row" data-gjs-droppable=".col-3" data-gjs-custom-name="Row">
            <div class="col-3" data-gjs-type="column-type"></div>
            <div class="col-3" data-gjs-type="column-type"></div>
            <div class="col-3" data-gjs-type="column-type"></div>
            <div class="col-3" data-gjs-type="column-type"></div>
          </div>
        `,
        activate: true,
      });
      editor.BlockManager.add('text', {
        label: `<b>${_self.$i18n.t('grapesEditor.text')}</b>`,
        media: `<div class="gjs-fonts gjs-f-text"></div>`,
        content: '<div data-gjs-type="text">Insert your text here</div>',
        activate: true,
      });
      editor.BlockManager.add('text-sect', {
        label: `<b>${_self.$i18n.t('grapesEditor.textSection')}</b>`,
        media: `<div class="gjs-fonts gjs-f-h1p"></div>`,
        content: '<h1 class="heading">Insert title here</h1><p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>',
        activate: true,
      });
      editor.BlockManager.add('image', {
        label: `<b>${_self.$i18n.t('grapesEditor.image')}</b>`,
        media: `<div class="gjs-fonts gjs-f-image"></div>`,
        content: { type: 'image' },
        activate: true,
      });
      editor.BlockManager.add('signature', {
        label: `<b>${_self.$i18n.t('grapesEditor.signature')}</b>`,
        media: `<div class="custom-icon mt-1"><i class="icon-signature"></i></div>`,
        content: `
          <div class="signature">
            <img id="signature" src="`+signature+`" alt="" width="180">
            <p>____________________________________</p>
            <p>`+userName+`</p>
          </div>
        `,
        activate: true,
      });
      editor.BlockManager.add('page-break', {
        label: '<b>Page-break</b>',
        media: `<svg viewBox="0 0 24 24">
          <path d="M 20,18 V 15 H 4 v 3 H 2 v -5 h 20 v 5 M 20,9 H 4 V 6 H 2 v 5 H 22 V 6 h -2 z"></path>
        </svg>`,
        content: '<div class="page-break"></div>',
        activate: true,
      });
    },
    doPrint() {
      this.contentPrint = editor.runCommand('gjs-get-inlined-html');
      this.showModalPrint = true;

      setTimeout(function() {
        let modal_print_report = document.getElementById('modal_print_report');
        new bootstrap.Modal(modal_print_report).show();
      }, 100);
    },
    closePrintModal() {
      this.contentPrint = null
      this.showModalPrint = false
    },
    // doCollapse() {
    //   if (window.screen.width > 767)
    //     $('#btnCollapseMenu').click()
    // },
    clearAssets() {
      const assetManager = editor.AssetManager
      let allAssets = assetManager.getAll()
      const keys = allAssets.models.map(x => x.attributes.src)
      for (let k of keys) {
        assetManager.remove(k)
      }
    },
    addGjsPage() {
      let page = editor.DomComponents.getWrapper().find('.gjs-page')
      let $element = page[page.length-1].view.el
      let $previousElement,
          wrapper;

      let emptyArea = this.checkEmptyArea($element)

      if (emptyArea < 100) {
        wrapper = editor.DomComponents.getWrapper()
        wrapper.append(`<div id="page-${page.length}" class="gjs-page page-${page.length}"></div>`)
      }

      if (emptyArea == 964 && page.length > 1) {
        $previousElement = page[page.length-2].view.el

        let previousEmptyArea = this.checkEmptyArea($previousElement)

        if (previousEmptyArea > 110) {
          wrapper = editor.DomComponents.getWrapper().view.el.lastChild
          wrapper.remove()
        }
      }

      if (page.length > 2) {
        for (let index = 0; index < page.length; index++) {
          if (index + 1 === page.length)
            return

          let $actualElement = page[index].view.el
          let actualEmptyArea = this.checkEmptyArea($actualElement)

          if (actualEmptyArea == 964 && index > 0 && index < page.length) {
            wrapper = $actualElement
            wrapper.remove()
            return
          }
        }
      }
    },
    checkEmptyArea(element) {
      let area = element.offsetHeight
      let subarea = 0

      element.children.forEach(o => {
        subarea += o.offsetHeight
      });

      return area - subarea
    }
  },
  unmounted() {
    editor.destroy();
  }
}
