<template>
  <div class="flex-center">
    <div class="import-box">
      <h1>{{ $t('csv.csvPatients') }}</h1>
      <p>
        {{ $t('csv.csvMessageInfo1') }}
        <a href="#">{{ $t('csv.csvMessageInfo2') }}</a>
        {{ $t('csv.csvMessageInfo3') }}
        <a href="#">{{ $t('csv.csvMessageInfo4') }}</a>.
      </p>

      <div class="alert alert-warning" role="alert">
        <h4>{{ $t('csv.important') }}</h4>
        <p>
          {{ $t('csv.importantMessage') }}
        </p>
      </div>

      <div class="form-floating">
        <input class="form-control form-control-lg" id="formFile" type="file" ref="formFile" accept=".csv" v-on:change="handleFileUpload()">
      </div>

      <button class="btn btn-success" type="button" name="button" :disabled="!isValidCsv || !formData.file" @click="canStartNewUpload()">{{ $t('csv.send') }}</button>
    </div>

    <ClinicCsvOffcanvas :offcanvas_id="'clinicCsvOffcanvas'" ref="clinicCsvOffcanvas"/>
  </div>
</template>

<style lang="scss" scoped>
.flex-center {
  height: 100%;
}

.import-box {
  text-align: center;
  width:                             100%;
  max-width:                         617px;
  display: flex;
  flex-direction: column;
  gap: 18px;

  h1,
  h4,
  p,
  .alert {
    color: var(--Brand-Primary);
    margin: 0;
  }

  h1 {
    font-size: 38px;
    font-weight: 300;
    line-height: normal;
  }

  p {
    font-size: var(--font-size-14);
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    padding: 0 1.2rem;

    a {
      color: inherit;
    }
  }

  .alert {
    @include border_radius(10px);
    padding: 10px;
    background: #FFEBCB;
    border: 1px #D1A660;
    h4 {
      font-size: var(--font-size-14);
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      letter-spacing: 2.8px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    p {
      font-size: var(--font-size-12);
      padding: 0;
    }
  }
}

.form-control.form-control-lg {
  color: var(--Shades-50, #8F8F8F);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1.95px;
  text-transform: uppercase;
  line-height: 2.5;
  position: relative;
  padding-top: 0.625rem !important;

  &::file-selector-button {
    @include border_radius(99px);
    color: var(--Shades-W, var(--W, #FFF));
    font-size: var(--font-size-12);
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.6px;
    text-transform: uppercase;
    padding: 10px;
    background: var(--Brand-Secondary, #3CB391);
    border: 0;
    position: absolute;
    right: 0;
    top: 19px;
  }
}

.btn-success {
  color: var(--Shades-W, var(--W, #FFF));
  text-align: center;
  font-size: var(--font-size-14);
  font-weight: 700;
  line-height: normal;
  letter-spacing: 4.2px;
  text-transform: uppercase;
  width: 100%;
  padding: 20px;
}
</style>

<script>
import ClinicCsvOffcanvas from './ClinicCsvOffcanvas.vue'

export default {
  name: 'ClinicimportTab',
  props: ['clinic_id'],
  components: {
    ClinicCsvOffcanvas
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    clinic: {},
    formData: {},
    isValidCsv: true,
  }),
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData: async function() {
      let params = {
        id: this.clinic_id
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$clinicService.getClinic(params);
        this.clinic = response.clinic
      } catch (err) {
        this.$message.popup(this, `Error: ${err.message}`, 'operationFail', 'error')
      }
      NProgress.done(true)
      this.loading = false
    },
    handleFileUpload() {
      this.isValidCsv = true
      this.formData.file = this.$refs.formFile.files[0]
      this.formData.clinic = this.clinic
      let regexCsv = new RegExp("(.*?)\.(csv)$");

      console.log('this.formData.file.name.toLowerCase()', this.formData.file.name);

      if (!regexCsv.test(this.formData.file.name)) {
        this.isValidCsv = false
        this.$message.popup(this, 'csv.invalidCsv', 'operationFail', 'error')
      }

      if (this.formData.file.size > 1048576) { // validando 1mb
        this.isValidCsv = false
        this.$message.popup(this, 'csv.fileMaxSize', 'operationFail', 'error')
      }
    },

    canStartNewUpload: async function() {
        this.loading = true
        NProgress.start()
        let check
        console.log('entrou');
        try{
            check = await this.$clinicService.canStartNewImport({clinicId: this.clinic_id})
            if (check.status == true){
                let uploadResult
                try {
                    uploadResult = await this.$clinicService.uploadPatientCsvFile(this.formData)
                    this.doImportCSV(uploadResult.filename)
                }catch (error){
                    this.$message.popup(this, 'csv.errorUpload', 'operationFail', 'error')
                }
            }else{
                this.$message.popup(this, 'csv.errorForbidenMessage', 'operationFail', 'error')
            }
        }catch(error){
            this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        }
        NProgress.done(true)
        this.loading = false
    },

    doImportCSV: async function(filename) {
      this.loading = true
      NProgress.start()
      try{
        let params = {
            clinic:  this.clinic,
            filename: filename
        }
        let responseValidate = await this.$clinicService.validateCsvFile(params)
        if (responseValidate){
            this.$message.popup(this, 'csv.validData', 'operationSuccess', 'success')
            try {
                await this.$clinicService.importPatientsToClinic(params)
                this.$message.popup(this, 'csv.updateTable', 'operationSuccess', 'success')
            } catch (err) {
                this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
            }
        }

      } catch (err) {
        NProgress.done(true)
        this.loading = false

        const ok = await this.$refs.clinicCsvOffcanvas.show({
            title: this.$i18n.t('csv.errorInfoTitle'),
            text: err.response.data.invalidRows,
            okButton: 'OK',
            okButtonClass: 'btn-success',
        })
        if (ok) {
            this.$refs.formFile.value = ''
            this.formData = {}
        }
      }
      NProgress.done(true)
      this.loading = false
    },


    async doDownloadDefaultCsv() {
      //let row = ["first_name", "last_name", "birthday", "cellphone", "email", "medical_record_number", "document0", "document1", "document2", "document3"];
      let row = this.getRowbyLocale(this.clinic.locale)
      let csvContent = row.join(",");

      let encodedUri = await encodeURI(csvContent)
      console.log('csv', csvContent);
      let blob = new Blob([encodedUri], { type: "data:text/csv;charset=utf-8," });
      let url = URL.createObjectURL(blob);

      let link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',`template_import_patients_phelcom_${this.clinic.locale}.csv`);
      link.click();
    },
  }
}
</script>
