<template>
  <div class="base-rounded-checkbox" :id="id" :class="{'checked': checked}" data-test="checkboxBase" @click.stop.prevent="doCheck"></div>
</template>

<style scoped lang="scss">
.base-rounded-checkbox {
  @include                         border_radius(50%);
  position:                        relative;
  width:                           35px;
  height:                          35px;
  padding:                         10px 12px;
  // margin-top:                      0.25em;
  vertical-align:                  top;
  background:                      #D8D8D8;
  background-repeat:               no-repeat;
  background-position:             center;
  background-size:                 contain;
  border:                          1px solid var(--Shades-30, #727272);
  -webkit-appearance:              none;
  -moz-appearance:                 none;
  appearance:                      none;
  -webkit-print-color-adjust:      exact;
  color-adjust:                    exact;

  &:after {
    @include                       rotate(45deg);
    content:                       "";
    position:                      absolute;
    left:                          10px;
    top:                           6px;
    width:                         11px;
    height:                        17px;
    border:                        solid var(--Shades-30, #B7B7B7);
    border-width:                  0 3px 3px 0;
  }

  &.checked {
    background-color:              #3CB391;
    &:after {
      @include                     rotate(45deg);
      content:                     "";
      position:                    absolute;
      left:                        10px;
      top:                         6px;
      width:                       11px;
      height:                      17px;
      border:                      solid #FFFFFF;
      border-width:                0 3px 3px 0;
    }
  }
}
</style>

<script>
export default {
  name: 'BaseRoundedCheckbox',
  props: ['id', 'item'],
  data: () => ({
    checked: false,
  }),
  mounted() {
    // console.log('this.item', this.item);
  },
  methods: {
    doCheck() {
      this.checked = !this.checked

      let value = {
        img: this.item,
        uuid: this.id
      }

      if (this.item.type)
        value.type = this.item.type

      if (this.item.imageLaterality)
        value.imageLaterality = this.item.imageLaterality

      this.$emit('checked', value)
    }
  }
}
</script>
