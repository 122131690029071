<template>
  <div class="outside-box" v-loader.opacity="loading">
    <AuthHeader></AuthHeader>

    <div class="row justify-content-center">
      <div class="col-sm-8 col-lg-7">
        <div class="registration-info">
          <h2 class="registration-title">
            {{ $t('createAccount.colaboratorRegistration') }}
            <small>{{ $t('createAccount.firstAccessTitle') }}</small>
          </h2>

          <hr>

          <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
            <span v-html="alert.message"></span>
          </div>

          <div v-else>
            <h3>
              {{ $t('createAccount.invitationBy') }}
              <span>{{`${owner.firstName} ${owner.lastName}`}}</span>
              {{ $t('createAccount.toColaborateWith') }}
              <span>{{clinic.name}}</span>.
            </h3>
            <button class="btn btn-success" type="button" name="button" data-test="btnRegistration" @click="processRegistration">{{ $t('continue') }}</button>
          </div>
        </div>
      </div>
    </div>

    <AuthFooter></AuthFooter>
  </div>
</template>

<style lang="scss" scoped>
  .registration-info .btn {
    display:                          block;
  }
</style>

<script>
import AuthFooter from '../../auth/AuthFooter.vue'
import AuthHeader from '../../auth/AuthHeader.vue'

export default {
  name: 'invitation-index',
  components: {
    AuthFooter,
    AuthHeader,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null,
    },
    clinic: {},
    owner: {},
    fullName: null,
    code: null,
    email: null,
    invitation: {},
    existingUser: null
  }),
  mounted() {
    const data = this.$utils.parseDataQuery(this.$route.query._data)
    this.code = data.code
    this.email = data.email

    this.processInvitation()
  },
  methods: {
    /**
     *
     */
    processRegistration: async function() {
      if (this.existingUser) {
        this.loading = true
        NProgress.start()
        try {
          await this.$registrationService.registerExistingUser({code: this.code})
        } catch(err) {
          this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
          // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
        }
        NProgress.done(true)
        this.loading = false
        this.$router.push({name: 'invitation-done', params: {}})
        return
      }

      let data = this.$utils.dataQuery({
        code: this.code,
        email: this.email,
        fullName: this.fullName
      })

      this.$router.push({name: 'invitation-form', query: {data: data}})
    },
    async processInvitation() {
      this.loading = true
      NProgress.start()
      try {
        let result = await this.$registrationService.invitation({code: this.code})
        this.existingUser = result.existingUser

        this.invitation = result.invitation
        this.owner = this.invitation.owner
        this.clinic = this.invitation.clinic
        this.fullName = this.owner.firstName + ' ' + this.owner.lastName
      } catch (err) {
        this.$message.popup(this, 'createAccount.invalidLink', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'createAccount.invalidLink', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    }
  }
}
</script>
