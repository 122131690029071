<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <path d="M2 15H28" stroke="#3CB391" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M2 8L28 8" stroke="#3CB391" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M2 22L28 22" stroke="#3CB391" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconHamburger'
}
</script>
