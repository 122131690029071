<template>
  <div class="tab-exam-box" v-loader-eyerweb.opacity.tab="loading">
    <div class="title-box rounded">
      <h2 class="title">{{ $t('exam.examsList') }}</h2>
    </div>

    <div class="text-end">
      <button class="btn btn-primary" :disabled="selectedExams.length < 1" type="button" name="button"
       data-test="btnDownloadExams" @click="downloadSelectedExams">{{ $t('eyerweb.downloadExams') }}</button>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <BaseTable class="white-table" v-if="tableExams" :tabledata="tableExams" @item-opened="examSelected">
      <template v-slot:column_check="{ item }">
        <div class="position-relative" :id="`box_${item.uuid}`">
          <BaseCheckBox :id="item.uuid" :value="item.uuid" :item="item" @checked="doCheck"></BaseCheckBox>
        </div>
      </template>
      <template v-slot:column_status="{item}">
        <span class="label" :class="item.status == 'closed' ? 'label-closed' : 'label-open'">
          {{ item.status }}
        </span>
      </template>
    </BaseTable>
  </div>
</template>

<style lang="scss" scoped>
.tab-exam-box .title-box .btn {
  margin-bottom:                     0;
}

.white-table {
  .table {
    > thead > tr > th {
      border-top:                    0;
    }
  }
  .vtable {
    > tbody > tr {
      > td {
        .no-btn {
          margin:                    0;
          padding:                   0 7px;
        }
      }
    }
  }
  .base-check-box {
    margin-top:                      0 !important;

    &.checked:after {
      top:                           3px !important;
    }
  }
}

@media (max-width: 400px) {
  .tab-exam-box .title-box {
    .title {
      text-align:                    center;
      width:                         100%;
      max-width:                     unset;
    }
    .btn {
      display:                       block;
      margin-right:                  auto !important;
      margin-left:                   auto !important;
    }
  }
}

@media screen and (min-width: 401px) and (max-width: 767px) {
  .tab-exam-box .title-box .title {
    text-align:                      left;
    width:                           calc(100% - 152px);
  }
}

@media (min-width: 767px) {
  .icon-phelcom-ai {
    vertical-align:                  top;
    margin-top:                      1px;
  }
}
</style>

<script>
export default {
  name: 'EyerwebPatientExamTab',
  props: ['patient_id', 'force_fetch'],
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    tableExams: {
      items: [],
      total: 0,
      columns: []
    },
    selectedExams: [],
    examDate: '',
    examContent: '',
    examStatus: '',
    examName: '',
    patientName: ''
  }),
  watch: {
    selectedExams: {
      deep: true,
      handler(value) {
        if (value.length > 4) {
          let _self = this
          let elements = document.querySelectorAll(`.base-check-box:not(.checked)`)
          elements.forEach(o => {
            this.enableDisableCheckBox(o, 'add')
          })
        } else if (value.length == 4) {
          let elements = document.querySelectorAll(`.base-check-box.disabled`)
          elements.forEach(o => {
            this.enableDisableCheckBox(o)
          })
        }
      }
    },
  },
  mounted() {
    this.examDate = this.$i18n.t('exam.date')
    this.examContent = this.$i18n.t('exam.content')
    this.examStatus = this.$i18n.t('exam.status')
    this.examName = this.$i18n.t('exam.name')
    this.$eyerwebService.examSubject.subscribe((result) => {
      this.loadTableData(result)
      this.loading = false
      NProgress.done(true)
    })

    if (this.$route.query.data) {
      let data = this.$utils.parseDataQuery(this.$route.query.data)
      if (data.patientName)
        this.$emit('patient_name', data.patientName)
        this.patientName = data.patientName
    }

    this.fetchData()

    this.$eyerwebService.downloadSubject.subscribe((result) => {
      this.loading = false
      NProgress.done(true)
      if (result == "error"){
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
    })
  },
  methods: {
    async fetchData() {
      this.alert.message = null;

      this.loading = true
      NProgress.start()

      let params = {
        id: this.patient_id
      }

      try {
        this.$eyerwebService.listPatientsExams(params)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
    },
    loadTableData(response) {

      this.tableExams = {
        'items': response.result,
          'columns': [
            { key: "check", name: '', type: 'custom', value: 'actions', width: '2%' },
            {name: this.examDate, type: 'date', value: 'date', width: '55%'},
            //{key:"content", name: this.examContent , type: 'custom', value: 'content', width: '30%'},
            {key: 'status', name: this.examStatus , type: 'custom', value: 'status', width: '15%'},
          ]
      }

      this.totalPages = Math.ceil(response.totalLenght / this.perPage);

      if (response.result.length === 0) {
        this.tableExams = {};
        this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
      }
    },
    examSelected(item) {
      if (!item) {
        return
      }

      let data = this.$utils.dataQuery({
        previous: 'eyerweb-patient',
        patientName: this.patientName,
        patientID: this.patient_id
      })

      this.$router.push({name: 'eyerweb-exam-show', params: {examID: item.uuid}, query: {data: data}})
      // console.log('patient_name: ', this.patient_name)

      // this.$router.push({name: 'eyerweb-exam-show', params: {examID: item.uuid,  patientID: this.patient_id, patientName: this.patientName,  previous: 'eyerweb-patient'}})
    },
    doCheck: async function (value) {

      let examUuid = value.uuid
      const index = this.selectedExams.indexOf(examUuid)
      if (index !== -1) {
        document.querySelector(`#box_${examUuid} > .base-check-box`).classList.remove('checked')
        this.selectedExams.splice(index, 1);
      } else {
        if (this.selectedExams.length == 5) {
          this.$message.popup(this, 'eyerweb.maxExamsSelected', 'operationFail', 'error')
          return
        }
        document.querySelector(`#box_${examUuid} > .base-check-box`).classList.add('checked')
        this.selectedExams.push(examUuid);
      }
    },

    disableButtons(){
      this.buttonEnable = true
      document.querySelector('.menu-links li:first-child').classList.add('disabled')
      document.querySelector('.menu-links li:nth-child(2)').classList.add('disabled')
      document.querySelector('.btn-back').classList.add('disabled')
    },

    async downloadSelectedExams() {

      try {
        this.$eyerwebService.downloadExamsFiles(this.selectedExams);
        this.loading = true
        NProgress.start()

        this.$nextTick(() => {
          this.disableButtons()
        })

      } catch (err) {
        this.loading = false
        NProgress.done(true)
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
    },
    activeAiClasses(exam) {
      return this.$aiService.mcRASClasses(exam)
    },
    enableDisableCheckBox(element, action) {
      let _self = this

      if (action) {
        let parentElement = element.parentElement
        element.classList.add('disabled', 'pe-none')

        parentElement.addEventListener('click',
          function (event) {
            event.preventDefault()
            event.stopPropagation()
            _self.$message.popup(_self, 'eyerweb.maxExamsSelected', 'operationFail', 'error')
          }, false
        );
      } else {
        let parentElement = element.parentElement
        element.classList.remove('disabled', 'pe-none')
        parentElement.removeEventListener('click', function () {}, false);
      }
    }
  }
}
</script>
