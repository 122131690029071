<template>
  <div class="main-menu">
    <ul class="menu-links">
      <div class="">
        <!-- <li v-if="!isSpecialist" :class="active === 'dashboard' ? 'active' : ''">
          <a data-test="anchorMenuDesktopDashboard" @click="dashboardMenu()">
            <IconDashboard/>
            <span>{{ $t('menu.dashboard') }}</span>
          </a>
        </li> -->
        <div v-show="allowSynchronization">
          <li :class="active === 'exam' ? 'active' : ''">
            <a data-test="anchorMenuDesktopExam" @click="examMenu()">
              <IconExams/>
              <span>{{ $t('menu.exams') }}</span>
            </a>
          </li>
          <li :class="active === 'patient' ? 'active' : ''">
            <a data-test="anchorMenuDesktopPatients" @click="patientMenu()">
              <IconPatients/>
              <span>{{ $t('menu.patients') }}</span>
            </a>
          </li>
        </div>
        <BaseFeature feature="backoffice">
          <li v-show="!isSpecialist" :class="active === 'backoffice' ? 'active' : ''">
            <a data-test="anchorMenuDesktopBackoffice" @click="backofficeMenu()">
              <IconEye/>
              <span>Backoffice</span>
            </a>
          </li>
        </BaseFeature>
      </div>

      <li :class="active === 'settings' ? 'active' : ''">
        <a data-test="anchorMenuDesktopSettings" @click="settingsMenu()">
          <IconSettings/>
          <span>{{ $t('menu.settings') }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  .main-menu {
    height:                             calc(100% - 30px); // 171
  }
  .menu-links {
    display:                            flex !important;
    justify-content:                    space-between;
    flex-direction:                     column;
    list-style:                         none;
    padding:                            30px 0 0 0;
    height:                             100%;
    gap:                                10px;
    li {
      @include                          border_radius(99px);
      margin-bottom:                    10px;
      border:                           1px solid #DCDCDC;
      background:                       var(--Shades-W);
      &:hover,
      &:focus,
      &:active,
      &.active {
        border:                         1px solid var(--Brand-Secondary);
        background:                     #CAF1E6;
        a, i:before {
          color:                        var(--Brand-Secondary);
        }
      }
      a {
        font-size:                      12px;
        font-weight:                    500;
        color:                          var(--Shades-50);
        text-transform:                 uppercase;
        letter-spacing:                 2.58px;
        display:                        flex;
        align-items:                    center;
        padding:                        5px;
        text-decoration:                none;
        cursor:                         pointer;
      }
    }
    > li {
      a {
        svg {
          * {
            stroke: inherit;
          }
        }
      }
    }
    img {
      width:                            35px;
      height:                           35px;
    }
    i {
      font-size:                        2em;
      vertical-align:                   middle;
      margin-right:                     10px;
    }
    img,
    svg {
      margin-right:                     10px;
    }
    svg {
      width:                            25px;
      height:                           25px;
    }
  }

  .main.retract {
    .main-menu {
      // height:                           calc(100% - 201px);
    }
    .menu-links {
      li {
        width:                          35px;
        height:                         35px;
        a {
          display: flex;
        }
        span {
          display:                      none;
        }
        svg {
          margin:                       0;
        }
        img {
          display:                      block;
          width:                        25px;
          height:                       25px;
          margin:                       0 auto;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .menu-links {
      a span {
        display:                        none;
      }
      li a {
        padding:                        10px;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1500px) {
    .menu-links {
      li a {
        // padding:                        7px 15px;
      }
      img, svg {
        max-height:                     22px;
      }
    }
  }

  @media screen and (min-width: 800px) and (max-width: 991px) {
    @media screen and (min-height: 600px) and (max-height: 768px) {
      .menu-links {
        li a {
          font-size:                    10px;
          // padding:                      10px 15px;
        }
        img, svg {
          max-height:                   18px;
        }
      }
    }
  }

  @media (min-width: 1651px) {
    .main-menu {
      // height:                           calc(100% - 162px);
    }
  }
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'MenuDesktopLinks',
  data: () => ({
    active: null,
    showModalWarning: false,
    allowSynchronization: true,
    isSpecialist: null,
    isPowerfull: null,
    session: null,
  }),
  watch: {
    '$activeLink.value': {
      handler: function () {
        this.active = this.$activeLink.value
    }, deep: true }
  },
  mounted: function() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.isSpecialist = this.session.isSpecialist
    this.isPowerfull = this.session.isPowerfull
    this.active = this.$activeLink.value ? this.$activeLink.value : null

    this.allowSynchronization = this.session.currentClinic.synchronizePatientData
    if (this.allowSynchronization == undefined)
      this.allowSynchronization = true
  },
  methods: {
    dashboardMenu: async function() {
      this.goToLink(this, 'dashboard', 'dashboard')
    },
    examMenu: async function() {
      this.session.examCurrentPage = 1
      this.goToLink(this, 'exam', 'exam-list')
    },
    patientMenu: async function() {
      this.session.patientCurrentPage = 1
      this.goToLink(this, 'patient', 'patient-list')
    },
    settingsMenu: async function() {
      this.goToLink(this, 'settings', 'settings')
    },
    backofficeMenu() {
      const BASE_URL = process.env.VUE_APP_API_URL
      window.location = `${BASE_URL}/backoffice`
    },
    goToLink(self, active, link) {
      self.active = active

      self.$router.push({name: 'proxy-router', params: {
        target: link,
        data: JSON.stringify({})
      }})
    }
  }
}
</script>
