<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H13C14.3807 0.5 15.5 1.61929 15.5 3V4.30086V12C15.5 13.3807 14.3807 14.5 13 14.5H3C1.61929 14.5 0.5 13.3807 0.5 12V4.30086V3Z" stroke="#727272" stroke-linecap="round"/>
    <circle cx="2.8" cy="9.23677" r="0.8" fill="#727272"/>
    <circle cx="2.8" cy="11.673" r="0.8" fill="#727272"/>
    <circle cx="5.6125" cy="9.23652" r="0.8" fill="#727272"/>
    <circle cx="5.6125" cy="11.673" r="0.8" fill="#727272"/>
    <circle cx="8.42305" cy="9.23652" r="0.8" fill="#727272"/>
    <circle cx="11.2355" cy="6.8" r="0.8" fill="#727272"/>
    <circle cx="11.2355" cy="9.23652" r="0.8" fill="#727272"/>
    <circle cx="14.0461" cy="6.8" r="0.8" fill="#727272"/>
    <circle cx="14.0461" cy="9.23652" r="0.8" fill="#727272"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconCalendar'
}
</script>
