<template>
  <div class="">
    <div class="sub-header-box">
      <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>

      <nav style="--bs-breadcrumb-divider: '';" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/home/" data-test="btnRouter">
              {{ $t('home') }}
            </router-link>
          </li>
          <li class="breadcrumb-item" aria-current="page">
            <router-link to="/settings/" data-test="btnRouter">
              {{ $t('createClinic.configuration') }}
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ $t('clinic.clinic') }}</li>
        </ol>
      </nav>
    </div>

    <div class="title-box">
      <div class="flex-start">
        <h2 class="title">{{ clinic.name }}</h2>
      </div>
    </div>

    <BaseTab :tabs="tabs" :initialtab="tabActive" :key="tabActive" @tab-activated="switchTab">
      <template v-slot:tab-head-data>
        {{ $t('clinic.data') }}
      </template>
      <template v-slot:data>
        <ClinicInfoTab v-if="tabActive === 'data' && clinic && clinic.id"
          :clinic_id="clinic.id"
          :logomsg="alert ? alert : undefined"/>
      </template>

      <template v-slot:tab-head-templates>
        {{ $t('clinic.template') }} ({{ $t('clinic.report') }})
      </template>
      <template v-slot:templates>
        <ClinicTemplateTab v-if="tabActive === 'templates'"/>
      </template>

      <template v-slot:tab-head-import>
        {{ $t('clinic.import') }}
      </template>
      <template v-slot:import>
        <ClinicImportTab v-if="tabActive === 'import'" :clinic_id="clinic.id"/>
      </template>

      <template v-slot:tab-head-mutirao v-if="showMutirao">
        {{ $t('clinic.attendance') }}
      </template>
      <template v-slot:mutirao v-if="showMutirao">
        <ClinicMutiraoTab v-if="tabActive === 'mutirao'" :clinic="clinic"/>
      </template>

      <template v-slot:tab-head-config>
        {{ $t('createClinic.configuration') }}
      </template>
      <template v-slot:config v-if="clinic.id">
        <ClinicConfigurationTab v-if="tabActive === 'config'" :clinic_id="clinic.id"/>
      </template>
    </BaseTab>

    <!-- <div class="action-bar">
      <div id="clinicActionBar"></div>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.tab-pane {
  height:                            100%;
}
// .action-bar {
//   height:                            64px;
//   padding:                           10px 20px;
//   border-top:                        1px solid var(--Shades-30);
//
//   > div {
//     display:                         flex;
//     justify-content:                 flex-start;
//     align-items:                     center;
//     gap:                             10px;
//     height:                          100%;
//   }
// }

.sub-header-box {
  display:                           flex;
  flex-direction:                    row;
  justify-content:                   flex-start;
  align-items:                       center;
  gap:                               20px;
  width:                             100%;
  padding:                           15px 20px;

  ol {
    margin:                          0;
  }
}

.title-box {
  display:                            flex;
  flex-direction:                     row;
  align-items:                        center;
  justify-content:                    space-between;
  height:                             55px;
  padding:                            10px 25px;
  // margin:                             2.3rem 0 1.7rem;

  > div {
    width:                            auto !important;
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import ClinicConfigurationTab from './ClinicConfigurationTab.vue'
import ClinicImportTab from './ClinicImportTab.vue'
import ClinicInfoTab from './ClinicInfoTab.vue'
import ClinicMutiraoTab from './ClinicMutiraoTab.vue'
import ClinicTemplateTab from './ClinicTemplateTab.vue'

export default {
  name: 'clinic-show',
  components: {
    ClinicConfigurationTab,
    ClinicImportTab,
    ClinicInfoTab,
    ClinicMutiraoTab,
    ClinicTemplateTab,
  },
  data: () => ({
    clinic: {}, // TODO NEXT
    alert: {},
    msgError: null,
    session: {
      collaborator: {},
      currentClinic: {}
    },
    isSpecialist: null,
    showMutirao: false,
    isCurrentClinic: false,
    selectedNotification: null,
    tabActive: 'data',
    tabs: ['data'],
  }),
  computed: {
    tab() {
      return this.$route.query.tab;
    },
  },
  created() {
    // watch param clinicID coming from notifications to force reload data
    this.$watch( // TODO REFACTOR KISS
      () => this.$route.params.clinicID,
      (toParams, previousParams) => {
        if (toParams != previousParams && this.$route.name == 'clinic-show')
          this.fetchData()
      }
    )
  },
  async mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.isSpecialist = this.session.isSpecialist
    this.$activeLink.value = 'clinic'

    this.fetchData()

    if (!this.isSpecialist) {
      this.tabs.push('templates')
      this.tabs.push('import')
    }

    this.showMutirao = this.session.features.includes('mutirao')
    if (this.showMutirao && !this.isSpecialist) {
      this.tabs.push('mutirao')
      this.tabs.push('config')
    }

    if (this.tab) {
      this.tabActive = this.tab;
    }
  },
  methods: {
    switchTab(tab) {
      this.tabActive = tab;
    },
    goBack() {
      this.$router.push({name: 'settings'})
    },
    async fetchData() {
      let params = {
        id: this.$route.params.clinicID
      }

      try {
        let response = await this.$clinicService.getClinic(params);
        this.clinic = response.clinic

        const _clinic = this.clinic
        this.isCurrentClinic = _clinic.id === this.session.currentClinic.id
        if (!this.isCurrentClinic)
          return
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
    }
  }
}
</script>
