<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
    <path d="M9.17578 5.44531L3.10474 11.5164" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.80393 12.8528L5.53799 12.2813L2.37535 9.1187L1.80393 12.8528Z" fill="black"/>
    <path d="M10.8237 9.55518L16.8948 3.48413" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.1956 2.14773L14.4615 2.71915L17.6242 5.88179L18.1956 2.14773Z" fill="black"/>
  </svg>
</template>

<style lang="css" scoped>
</style>

<script>
export default {
  name: 'IconShare'
}
</script>
