<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
    <path d="M6 3.35715V9.78572M6 9.78572L3.5 7.28572M6 9.78572L8.5 7.28572" stroke="#727272"/>
    <path d="M1 4.42858V3.21429C1 2.10972 1.89543 1.21429 3 1.21429H9C10.1046 1.21429 11 2.10972 11 3.21429V4.42858" stroke="#727272"/>
  </svg>
</template>

<style lang="css" scoped>
</style>

<script>
export default {
  name: 'IconDownloadPatient'
}
</script>
