<template>
  <div class="btn-group patient">
    <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="patientFilter" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
      {{ $t('patient.patient') }}
    </button>
    <div class="dropdown-menu" aria-labelledby="patientFilter">
      <p>{{ $t('patientSearch.patientSearch') }}</p>

      <div class="form-floating mb-3">
        <input class="form-control" type="text" id="searchPatient" placeholder="Digite o nome..." v-model="search" @keypress.enter="doSearch">
        <label for="searchPatient">{{ $t('patientSearch.patientSearchPlaceholder') }}</label>
        <button class="btn-icon" type="button" name="button" data-test="btnSearchPatient" @click="fetchData">
           <!-- pedir novo icone pro edgard -->
          <IconSearch/>
        </button>
      </div>

      <p>{{ $t('patientSearch.patientSearchSelect') }}</p>

      <ul>
        <!-- precisa de um novo endpoint trazendo todos os pacientes sem paginar -->
        <li v-for="(item, index) in patientList" @click="toDetails(item)">
          {{ item.fullName }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropdown-toggle {
  &.show {
    z-index: 3 !important;
  }
  &:after {
    display: none;
  }
}

.dropdown-menu {
  @include border_radius(25px);
  @include box_shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.08));
  // display: inline-flex;
  padding: 56px 15px 15px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border: 1px solid var(--Shades-30, #D8D8D8);
  background: var(--Shades-W, #FFF);
  z-index: 1;
  top: -50px !important;
  left: -10px !important;
}

.btn-group {
  &.patient {
    .dropdown-menu {
      width: 416px;
    }
    .btn-icon {
      padding: 0;
      border: 0;
    }
    ul {
      list-style-type: none;
      max-height: 220px;
      height: 100%;
      padding: 0 10px 0 0;
      margin-bottom: 0;
      overflow-y: auto;

      li {
        @include border_radius(5px);
        color: var(--Brand-Primary, #1B294A);
        font-size: var(--font-size-14);
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.7px;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid var(--Shades-30, #D8D8D8);
        cursor: pointer;
      }
    }
  }
}
</style>

<script>
export default {
  name: 'BasePatientFilter',
  props: [''],
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    search: null,
    patientList: {},
  }),
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData(pageValue) {
      console.log('fetchdata');
      this.alert = {}

      // TODO yamanaka: endpoint trazendo uma lista completa dos pacientes ? ou usar scroll infinito ?
      let params = {
        page: 1
      }

      if (this.search)
        params.q = this.search

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$patientService.getList(params)
        this.patientList = response.result

        if (response.result.length === 0) {
          this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async doSearch() {
      this.alert = {}

      let params = {
        // page: this.currentPage
        q: this.search
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$patientService.getList(params)
        this.patientList = response.result

        if (response.result.length === 0) {
          // this.alert = this.$message.alert(this, 'request_notfound', 'warning')
          this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
        }
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
      }
      NProgress.done(true)
      this.loading = false
    },
    toDetails(item) {
      this.$router.push({name: 'patient-show', params: {patientID: item.id}, query: {tab: 'data'}})
    },
  }
}
</script>
