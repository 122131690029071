<template>
  <div class="clinic-form-box">
    <div class="row justify-content-md-start">
      <div class="col-md-4">
        <ul class="anchor-menu">
          <li :class="{'active': activeAnchor === 'general'}">
            <a href="#general" @click="setActiveAnchor('general')">{{ $t('createClinic.generalData') }}</a>
          </li>
          <li :class="{'active': activeAnchor === 'localization'}">
            <a href="#localization" @click="setActiveAnchor('localization')">{{ $t('createClinic.localization') }}</a>
          </li>
          <li :class="{'active': activeAnchor === 'configuration'}">
            <a href="#configuration" @click="setActiveAnchor('configuration')">{{ $t('createClinic.configuration') }}</a>
          </li>
          <li :class="{'active': activeAnchor === 'locale'}">
            <a href="#locale" @click="setActiveAnchor('locale')">{{ $t('createClinic.locale') }}</a>
          </li>
          <li :class="{'active': activeAnchor === 'security'}">
            <a href="#security" @click="setActiveAnchor('security')">{{ $t('createClinic.security') }}</a>
          </li>
        </ul>
      </div>

      <div class="col-md-8 offset-md-4 scrollbar" id="sectionsBox" v-if="clinic">
        <section id="general">
          <h3 class="sub-title" ref="targets">{{ $t('createClinic.generalData') }}</h3>
          <div class="form-floating">
            <vee-field name="name" v-model="clinic.name" rules="required" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="text" class="form-control" ref="name" id="name" :placeholder="$t('createClinic.name')" data-test="inputClinicName" autofocus >
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="name">{{ $t('createClinic.name') }}*</label>
          </div>
          <div class="form-floating">
            <vee-field name="fantasyName" v-model="clinic.fantasyName" rules="required" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="text" class="form-control" ref="fantasyName" id="fantasyName" :placeholder="$t('createClinic.nameExhibition')" data-test="inputClinicFantasyName" >
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="fantasyName">{{ $t('createClinic.nameExhibition') }}*</label>
          </div>
          <div class="form-floating">
            <vee-field name="document" v-model="clinic.document" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="text" class="form-control" ref="document" id="document" v-maska
                     :data-maska="$t('inputMask.clinicDocument')" :placeholder="$t('createClinic.document')" data-test="inputClinicDocument" >
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="document">{{ $t('createClinic.document') }}</label>
          </div>
          <!-- end verify -->
          <div class="form-floating">
            <vee-field name="email" v-model="clinic.email" rules="email" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="text" class="form-control" ref="email" id="email" :placeholder="$t('createClinic.email')" data-test="inputClinicEmail" >
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="email">{{ $t('createClinic.email') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="telephone1" v-model="clinic.telephone1" :placeholder="$t('createClinic.telephone1')" data-test="inputClinicPhone1" >
            <label for="telephone1">{{ $t('createClinic.telephone1') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="telephone2" v-model="clinic.telephone2" :placeholder="$t('createClinic.telephone2')" data-test="inputClinicPhone2" >
            <label for="telephone2">{{ $t('createClinic.telephone2') }}</label>
          </div>

          <div class="form-floating v-select-floating">
            <v-select
              class="v-select form filter"
              v-model="parentClinicSelected"
              data-test="vSelectClinic"
              :placeholder="$t('createClinic.parentClinic')"
              :reduce="x => x.value"
              :options="parentClinicOptions">
            </v-select>
            <label for="title">{{ $t('createClinic.parentClinic') }}</label>
          </div>
        </section>

        <section id="localization">
          <h3 class="sub-title" ref="targets">{{ $t('createClinic.localization') }}</h3>
          <div class="form-floating">
            <input type="text" class="form-control" id="address" v-model="clinic.address" :placeholder="$t('createClinic.address')" data-test="inputClinicAddress" >
            <label for="address">{{ $t('createClinic.address') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="number" v-model="clinic.addressNumber" :placeholder="$t('createClinic.number')" data-test="inputClinicNumber" >
            <label for="number">{{ $t('createClinic.number') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="district" v-model="clinic.district" :placeholder="$t('createClinic.district')" data-test="inputClinicDistrict" >
            <label for="district">{{ $t('createClinic.district') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="city" v-model="clinic.city" :placeholder="$t('createClinic.city')" data-test="inputClinicCity" >
            <label for="city">{{ $t('createClinic.city') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="state" v-model="clinic.state" :placeholder="$t('createClinic.state')" data-test="inputClinicState" >
            <label for="state">{{ $t('createClinic.state') }}</label>
          </div>
          <div class="form-floating v-select-floating">
            <v-select
              class="v-select form"
              id="countrySelected"
              :placeholder="$t('createClinic.country')"
              v-model="countrySelected"
              data-test="vSelectCountry"
              :reduce="x => x.value"
              :options="countryOptions"
              @option:selected="emitOptions">
            </v-select>
            <label for="countrySelected">{{ $t('createClinic.country') }}</label>
          </div>
        </section>

        <section id="configuration">
          <h3 class="sub-title" ref="targets">{{ $t('createClinic.configuration') }}</h3>
          <div class="form-floating v-select-floating">
            <v-select
              label="label"
              class="v-select form"
              id="mandatoryDocument"
              :placeholder="$t('createClinic.mandatoryDocument')"
              v-model="mandatoryDocument"
              data-test="vSelectMandatoryDocument"
              :reduce="x => x.value"
              :options="configOptions"
              @option:selected="emitOptions">
            </v-select>
            <label for="mandatoryDocument">{{ $t('createClinic.mandatoryDocument') }}</label>
          </div>
        </section>

        <section id="locale">
          <h3 class="sub-title" ref="targets">{{ $t('createClinic.locale') }}</h3>
          <div class="form-floating v-select-floating">
            <v-select
              class="v-select form"
              id="languageSelected"
              :placeholder="$t('createClinic.language')"
              :reduce="x => x.value"
              v-model="languageSelected"
              data-test="vSelectLanguage"
              :options="languageOptions"
              @option:selected="emitOptions">
            </v-select>
            <label for="languageSelected">{{ $t('createClinic.language') }}</label>
          </div>
        </section>

        <section id="security">
          <h3 class="sub-title" ref="targets">{{ $t('createClinic.security') }}</h3>

          <div class="form-floating v-select-floating">
            <v-select
              label="label"
              class="v-select form"
              id="mfaMode"
              :placeholder="$t('createClinic.mfaMode')"
              v-model="mfaMode"
              data-test="vSelectMfa"
              :reduce="x => x.value"
              :options="mfaOptions"
              @option:selected="mfaModeChanged">
            </v-select>
            <label for="mfaMode">{{ $t('createClinic.mfaMode') }}</label>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sub-title {
  color:                         var(--Shades-60, #727272);
  font-size:                     16px;
  font-weight:                   500;
  line-height:                   normal;
  letter-spacing:                3.2px;
  text-transform:                uppercase;
  text-overflow:                 ellipsis;
  white-space:                   nowrap;
  overflow:                      hidden;
  border:                        0;
  margin:                        0;
}

.anchor-menu {
  display:                       flex;
  flex-direction:                column;
  align-items:                   flex-start;
  gap:                           20px;
  padding:                       0;

  li {
    display:                     flex;
    width:                       auto;

    a {
      @include                   border_radius(99px);
      color:                     var(--Shades-60, #727272);
      font-size:                 var(--font-size-14);
      font-weight:               500;
      line-height:               normal;
      letter-spacing:            2.8px;
      text-decoration:           none;
      text-transform:            uppercase;
      text-overflow:             ellipsis;
      overflow:                  hidden;
      padding:                   10px 20px;
      background:                var(--Shades-10, #FAFAFA);
      border:                    1px solid var(--Shades-30, #D8D8D8);
    }

    &:target,
    &:hover,
    &:focus,
    &:active,
    &.active {
      a {
        color:                   var(--Support-Success, #81BAA7);
        border:                  1px solid var(--Support-Success, #81BAA7);
      }
    }
  }
}

.col-md-4 {
  position:                      fixed;
  display:                       flex;
  justify-content:               flex-end;
}

.col-md-8 section,
.col-lg-6 {
  display:                       flex;
  gap:                           19px;
  flex-direction:                column;
}

.col-md-8 {
  overflow-y:                    auto;
  height:                        100%;
  max-height:                    calc(100vh - 324px);
  // max-height:                    calc(100vh - 348px);
  // max-height:                    calc(100vh - 165px);
  // max-height:                    calc(100vh - 217px);

  section {
    max-width:                   550px;
    width:                       100%;
    padding-bottom:              33px;

    &:last-child {
      padding-bottom:            4rem;
    }
  }
}

.clinic-form-box .dropdown-menu.show,
.vee-error {
  width:                         100% !important;
}

@media screen and (min-width: 500px) and (max-width: 991px) {
  .clinic-form-box .col-lg-6 > .row > .col-md-6 {
    width:                       50%;
  }
}

@media (max-width: 991px) {
  .clinic-form-box {
    .form-group {
      label {
        width:                   100%;
      }
      .form,
      .clinic-form-box .form-group input[type="text"] {
        width:                   100% !important;
      }
    }
  }
}

@media (max-width: 1500px) {
  .col-md-8 {
    max-height:                  calc(100vh - 318px);
  }
}
</style>

<script>
export default {
  name: 'ClinicFormData',
  props: ['clinic', 'disabled', 'is_creating'],
  data: () => ({
    countryOptions: [],
    configOptions: [],
    mfaOptions: [],
    languageOptions: [],
    countrySelected: '',
    mandatoryDocument: 'none',
    languageSelected: '',

    mfaMode: null,
    security: {},
    activeAnchor: 'general',
    parentClinicOptions: [
      {value: null, label: 'None'}
    ],
    clinicTemplate: null,
    parentClinicSelected: null,
  }),
  watch: {
    disabled: function(newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.$refs.name.focus()
        })
      }
    },
  },
  mounted() {
    this.configOptions = [
      {value: 'none', label: this.$i18n.t('none')},
      {value: 'mrn', label: this.$i18n.t('mrn')},
      {value: 'document0', label: this.$i18n.t('document0')},
      {value: 'document1', label: this.$i18n.t('document1')},
      {value: 'document2', label: this.$i18n.t('document2')},
      {value: 'document3', label: this.$i18n.t('document3')},
    ];

    this.mfaOptions = [
      {value: 'disabled', label: this.$i18n.t('mfa.disabled')},
      {value: 'optional', label: this.$i18n.t('mfa.optional')},
      {value: 'mandatory', label: this.$i18n.t('mfa.mandatory')}
    ]

    this.languageOptions = [
      {value: 'pt-br', label: this.$i18n.t('locales.pt-br')},
      {value: 'en', label: this.$i18n.t('locales.en')},
      {value: 'es', label: this.$i18n.t('locales.es')},
    ];

    this.setLocaleConfig()

    // from clinic template or current clinic
    this.countrySelected = this.clinic.country
    this.mandatoryDocument = this.clinic.mandatoryDocument
    this.languageSelected = this.clinic.locale

    console.log('is creating', this.is_creating);
    if (this.is_creating)
      this.initialize()

    let security = this.$clinicService.getSecurityConfiguration(this.clinic)
    this.mfaMode = security.mfaMode

    const divElement = document.getElementById('sectionsBox')
    divElement.addEventListener('scroll', this.updateActiveAnchor);
    this.updateActiveAnchor(); // Initialize the active section
  },
  beforeUnmount() {
    const divElement = document.getElementById('sectionsBox')
    divElement.removeEventListener('scroll', this.updateActiveAnchor);
  },
  methods: {
    setActiveAnchor(anchor) {
      this.activeAnchor = anchor;
    },
    async initialize() {
      this.loading = true
      NProgress.start()
      try {
        let response = await this.$clinicService.create();
        this.clinicTemplate = response.clinicTemplate

        response.parentClinics.forEach(async (o) => {
          o.value = o.id;
          o.label = o.name;
          await this.parentClinicOptions.push(o)
        })
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      this.loading = false
      NProgress.done(true)
    },
    async setLocaleConfig() {
      if (this.clinic.locale) {
        console.log('this locale', this.clinic.locale);
      }
      let locale = this.clinic.locale
      console.log('localeeeeeeeeeeeeeee <><><><><><><><>', locale);

      const countryCodeList = await this.$utils.countryCodeList(locale)

      if (locale) {
        for (let code of countryCodeList) {
          this.countryOptions.push({
            value: code,
            label: this.$i18n.t(`country.${code}`)
          })
        }
      }
    },
    emitOptions() {
      let options = {
        country: this.countrySelected,
        mandatoryDocument: this.mandatoryDocument,
        locale: this.languageSelected,
      }

      if (this.is_creating)
        options.parentClinic = this.parentClinicSelected

      if (!this.disabled) {
        this.$emit('option-selected', options)
      }
    },
    mfaModeChanged() {
      this.$emit('security-changed', {
        mfaMode: this.mfaMode,
      })
    },
    updateActiveAnchor() {
      const sections = ['general', 'localization', 'configuration', 'locale', 'security'];
      const divElement = document.getElementById('sectionsBox')
      const divHeight = divElement.offsetHeight
      let currentActive;

      for (const section of sections) {
        const element = document.getElementById(section);
        if (!element) continue;

        const rect = element.getBoundingClientRect();
        if (rect.top <= divHeight / 2 && rect.bottom >= divHeight / 2) {
          currentActive = section;
          break;
        }
      }

      if (currentActive && currentActive !== this.activeAnchor) {
        this.activeAnchor = currentActive;
      }
    }
  },
}
</script>
