<template>
  <div class="">
    <div class="form-floating mb-3">
      <vee-field name="street" v-model="clinic.street" rules="required" v-slot="{ field, errorMessage }">
        <input v-bind="field" type="text" class="form-control" ref="street" id="street" :placeholder="$t('createClinic.address')" data-test="inputClinicStreet">
        <span class="vee-error">{{ errorMessage }}</span>
      </vee-field>
      <label for="address">{{ $t('createClinic.address') }}</label>
    </div>
    <div class="form-floating mb-3">
      <vee-field name="city" v-model="clinic.city" rules="required" v-slot="{ field, errorMessage }">
        <input v-bind="field" type="text" class="form-control" ref="city" id="city" :placeholder="$t('createClinic.city')" data-test="inputClinicCity">
        <span class="vee-error">{{ errorMessage }}</span>
      </vee-field>
      <label for="city">{{ $t('createClinic.city') }}</label>
    </div>
    <div class="form-floating mb-3">
      <vee-field name="state" v-model="clinic.state" rules="required" v-slot="{ field, errorMessage }">
        <input v-bind="field" type="text" class="form-control" ref="state" id="state" :placeholder="$t('createClinic.state')" data-test="inputClinicState">
        <span class="vee-error">{{ errorMessage }}</span>
      </vee-field>
      <label for="state">{{ $t('createClinic.state') }}</label>
    </div>
    <div class="form-floating v-select-floating mb-3">
      <vee-field name="country" rules="required" v-slot="{ field, errorMessage }">
        <v-select
          v-bind="field"
          class="v-select form"
          v-model="clinic.country"
          label="label"
          :reduce="x => x.value"
          :options="countryOptions"
          :placeholder="$t('createClinic.country')"
          @option:selected="setCountry"
          data-test="vSelectClinicCountry">
          <template #search="{attributes, events}">
            <input
              class="vs__search"
              :required="!clinic.country"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </v-select>
        <span class="vee-error">{{ errorMessage }}</span>
      </vee-field>
      <label for="country">{{ $t('createClinic.country') }}</label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.vee-error {
  width:                        100% !important;
}
</style>

<script>

export default {
  name: 'RegistrationClinicStepTwo',
  props: ['clinic'],
  data: () => ({
    countryOptions: []
  }),
  created() {
    let locale = this.$utils.getCurrentLanguage()
    const countryCodeList = this.$utils.countryCodeList(locale)
    this.countryOptions = []
    for (let code of countryCodeList) {
      this.countryOptions.push({
        value: code,
        label: this.$i18n.t(`country.${code}`)
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.street.focus()
    })
  },
  methods: {
    setCountry(country) {
      this.clinic.country = country.value
    }
  }
}
</script>
