<template>
  <div class="modal fade" id="modal_new_notification" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="modalNewNotificationLabel" aria-hidden="true" @self.click="close()">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalNewNotificationLabel">
            {{ $t('dashboard.notification') }}
          </h5>
          <button id="closeButton" type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </div>
        <div class="modal-body">
          <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
            <span v-html="alert.message"></span>
            <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="input-group">
                <label for="name">{{ $t('clinicConfig.userName') }}</label>
                <input class="form-control" type="text" ref="name" name="name" v-model="notification.name" data-test="inputNotificationName">
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group">
                <label for="typeSelected">{{ $t('clinicConfig.type') }}</label>
                <v-select
                  class="v-select form"
                  v-model="notification.type"
                  :reduce="x => x.value"
                  :options="typeOptions"
                  data-test="vSelectNotificationType">
                </v-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="input-group">
                <label for="triggerSelected">{{ $t('clinicConfig.trigger') }}</label>
                <v-select
                  class="v-select form"
                  v-model="notification.trigger.method"
                  :reduce="x => x.value"
                  :options="triggerOptions"
                  data-test="vSelectNotificationTriggerMethod">
                </v-select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group" v-if="notification.trigger.method == 'threshold'">
                <label for="ruleSelected">{{ $t('clinicConfig.rule') }}</label>
                <v-select
                  class="v-select form"
                  v-model="notification.trigger.rule"
                  :reduce="x => x.value"
                  :options="ruleOptions"
                  data-test="vSelectNotificationTriggerRule">
                </v-select>
              </div>

              <div class="input-group" v-if="notification.trigger.method == 'mcRAS'">
                <label for="ruleSelected">{{ $t('clinicConfig.mcRAS') }}</label>
                <v-select
                  multiple
                  class="v-select form grown"
                  v-model="notification.trigger.classes"
                  :reduce="x => x.value"
                  :options="mcRASOptions"
                  data-test="vSelectNotificationTriggerClasses">
                </v-select>
              </div>

              <div class="input-group" v-if="notification.trigger.rule || notification.trigger.classes">
                <label for="triggerThreshold">{{ $t('clinicConfig.value') }}</label>
                <input class="form-control" type="text" name="triggerThreshold" v-model="notification.trigger.threshold" data-test="InputNotificationTriggerThreshold">
              </div>

            </div>
          </div>

          <hr>

          <div class="recipient-box" v-if="notification.type">
            <h4 class="sub-title flex-between">{{ $t('clinicConfig.destination') }}</h4>

            <div class="row">
              <div class="col-md-5">
                <div class="input-group">
                  <label for="targetName">{{ $t('clinicConfig.userName') }}</label>
                  <input class="form-control" type="text" ref="targetName" name="targetName" v-model="targetName" data-test="inputNotificationUserName">
                </div>
              </div>
              <div class="col-md-5">
                <div class="input-group">
                  <label for="targetRecipient">{{ notification.type === 'sms' ? $t('patient.telephone1') : $t('user.email') }}</label>
                  <input class="form-control" type="text" name="targetRecipient" v-model="targetRecipient" data-test="inputNotificationRecipient" @keypress.enter="addTarget">
                </div>
              </div>
              <div class="col-md-2 text-end">
                <button class="btn btn-success btn-sm" type="button" name="button" data-test="btnNotificationAdd" @click="addTarget">
                  <i class="fa fa-check"></i>
                </button>
              </div>
            </div>

            <BaseTable class="white-table small" v-if="targetListTable.items.length" :tabledata="targetListTable">
              <template v-slot:column_actions="{item}">
                <button class="btn btn-danger btn-sm" type="button" name="button" data-title="Delete" data-test="btnNotificationRemove" @click="deleteTarget(item.destination)">
                  <i class="fa fa-trash-o"></i>
                </button>
              </template>
            </BaseTable>
          </div>

        </div>
        <div class="modal-footer">
          <button class="btn btn-outline-secondary" type="button" data-bs-dismiss="modal" data-test="btnClose" @click="close()">{{ $t('cancel') }}</button>
          <button class="btn btn-primary" type="button" name="button" data-test="btnSave" @click="saveOrUpdate()">{{ $t('save') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .modal {
    .modal-dialog {
      .modal-header {
        display:                      flex !important;
        background-color:             $phelcom-light-blue;
        border-bottom:                1px solid #e5e5e5 !important;
        h5 {
          color:                      white;
          font-size:                  18px;
        }
      }
      .modal-body {
        label {
          max-width: 100%;
          margin-bottom: 5px;
          font-weight: bold;
        }
        .input-group,
        .form-control {
          width:                      100%;
        }
        .form {
          width:                      100% !important;
        }
        .input-group {
          margin-bottom:              10px;
        }
        .recipient-box {
          .row {
            align-items:              end;
            .input-group {
              margin-bottom:          0;
            }
            .btn.btn-success {
              font-size:              1.5em;
              padding:                1.6px 6.5px;
            }
          }
        }
        .sub-title {
          text-transform:             capitalize;
        }
        .white-table {
          .table-responsive,
          .vtable-mob {
            max-height:               200px;
            overflow-y:               auto;
          }
          .table-responsive {
            overflow-x:               hidden;
          }
        }
      }
      .modal-footer .btn-outline-secondary {
        border-color:                 #6c757d;
      }
    }
  }
</style>

<script>
import { useUserStore } from '@/store/user'

const TAG = "[NewNotificationModal.js]"

export default {
  name: 'NewNotificationModal',
  props: ['clinic', 'selected_notification'],
  data: () => ({
    alert: {},
    session: {
      collaborator: {},
      currentClinic: {}
    },

    systemConfiguration: {
      preAnalysis: {},
      notifications: []
    },

    currentNotification: {},

    targetName: null,
    targetRecipient: null,

    typeOptions: [],
    triggerOptions: [],
    ruleOptions: [],
    mcRASOptions: [],

    notification: {
      uuid: null,
      type: null,
      name: null,
      isEnabled: true,
      trigger: {
        method: null,
        threshold: null,
        rule: null,
        classes: null
      },
      targets: []
    },
    targetListTable: {},
  }),
  created() {
    this.typeOptions = [
      {value: null, label: this.$i18n.t('createClinic.select')},
      {value: 'email', label: this.$i18n.t('user.email')},
      // TODO later... {value: 'sms', name: 'SMS'},
    ]

    this.triggerOptions = [
      {value: null, label: this.$i18n.t('createClinic.select')},
      {value: 'threshold', label: this.$i18n.t('clinicConfig.threshold')},
      {value: 'mcRAS', label: this.$i18n.t('clinicConfig.mcRAS')},
    ]

    this.ruleOptions = [
      {value: null, label: this.$i18n.t('createClinic.select')},
      {value: 'geThan', label: this.$i18n.t('clinicConfig.greaterOrEqual')}
    ]

    this.mcRASOptions = [ // mcRAS classes
      {value: 'c1', label: this.$i18n.t('clinicConfig.mcRAS_c1')},
      {value: 'c2', label: this.$i18n.t('clinicConfig.mcRAS_c2')},
      {value: 'c3', label: this.$i18n.t('clinicConfig.mcRAS_c3')},
      {value: 'c4', label: this.$i18n.t('clinicConfig.mcRAS_c4')},
      {value: 'c5', label: this.$i18n.t('clinicConfig.mcRAS_c5')},
      {value: 'c6', label: this.$i18n.t('clinicConfig.mcRAS_c6')}
    ]
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    try {
      this.$nextTick(() => {
        this.$refs.name.focus();
      })

      if (this.clinic.systemConfiguration)
        this.systemConfiguration = this.clinic.systemConfiguration

      console.log(`${TAG} systemConfiguration >>>`, this.systemConfiguration)

      this.targetListTable = {
        'items': new Array(),
        'columns': [
          {name: this.$i18n.t('clinicConfig.userName'), value: 'name', width: '45%'},
          {name: this.$i18n.t('clinicConfig.destination'), value: 'destination', width: '45%'},
          {key: 'actions', name: '', value: 'actions', type: 'custom', width: '10%', align: 'text-center'},
        ]
      };

      if (!this.selected_notification.uuid)
        return

      // load selected notification
      this.notification = Object.assign({}, this.selected_notification)
      this.targetListTable.items = this.notification.targets
    } catch(err) {
      console.log("unexpected error:", err)
    }
  },
  methods: {
    async saveOrUpdate() {
      let allNotifications = this.systemConfiguration.notifications

      if (!this.notification.uuid) {
        this.notification.uuid = this.$utils.uuidv4()
        allNotifications.push(this.notification)
      } else {
        for (let n in allNotifications) {
          if (allNotifications[n].uuid == this.notification.uuid) {
            allNotifications[n] = this.notification
            break
          }
        }
      }
      this.systemConfiguration.notifications = allNotifications

      let params = {
        configuration: {
          preAnalysis: this.systemConfiguration.preAnalysis,
          notifications: allNotifications
        }
      }

      NProgress.start()
      try {
        await this.$clinicService.saveConfiguration(this.session, params);
        this.clinic.systemConfiguration = params.configuration
        this.$message.popup(this, 'general.createdSuccess', 'operationSuccess', 'success')
        this.$nextTick(() => {
          closeButton.click();
        });
      } catch (err) {
        console.log("unexpected error:", err)
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
      }
      NProgress.done(true)
    },
    deleteTarget(targetDestination) {
      let filtered = this.targetListTable.items.filter(function(el) { return el.destination !== targetDestination; });
      this.targetListTable.items = filtered
      this.notification.targets = filtered
    },
    addTarget() {
      let target = {
        name: this.targetName,
        destination: this.targetRecipient
      }

      let targets = this.notification.targets
      if (targets.some(el => el.destination === target.destination)) {
        this.$message.popup(this, 'clinicConfig.alreadyRegistered', 'operationWarning', 'warn')
        return
      }

      targets.push(target)

      this.notification.targets = [...new Set(targets)]
      this.targetListTable.items = [...new Set(targets)]

      this.targetName = null
      this.targetRecipient = null
      this.$refs.targetName.focus()
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
