<template>
  <div class="">
    <div class="form-floating mb-3">
      <vee-field name="clinicName" v-model="clinic.clinicName" rules="required" v-slot="{ field, errorMessage }">
        <input v-bind="field" type="text" class="form-control" ref="clinicName" id="clinicName" :placeholder="$t('createClinic.clinicName')" data-test="inputClinicName">
        <span class="vee-error">{{ errorMessage }}</span>
      </vee-field>
      <label for="clinicName">{{ $t('createClinic.clinicName') }}</label>
    </div>
    <div class="form-floating mb-3">
      <vee-field name="email" v-model="clinic.email" rules="required" v-slot="{ field, errorMessage }">
        <input v-bind="field" type="text" class="form-control" ref="email" id="email" :placeholder="$t('createAccount.emailContact')" data-test="inputClinicEmail">
        <span class="vee-error">{{ errorMessage }}</span>
      </vee-field>
      <label for="email">{{ $t('createAccount.emailContact') }}</label>
    </div>
    <div class="form-floating mb-3">
      <vee-field name="phone" v-model="clinic.telephone" rules="required" v-slot="{ field, errorMessage }">
        <input v-bind="field" type="text" class="form-control" ref="phone" id="phone" :placeholder="$t('createAccount.telephone')" data-test="inputClinicPhone">
        <span class="vee-error">{{ errorMessage }}</span>
      </vee-field>
      <label for="phone">{{ `${$t('createAccount.telephone')}/${$t('createAccount.mobile')}` }}</label>
    </div>
    <!-- <div class="form-floating mb-3">
      <vee-field name="mobile" v-model="clinic.mobile" v-slot="{ field, errorMessage }">
        <input v-bind="field" type="text" class="form-control" ref="mobile" id="mobile" :placeholder="$t('createAccount.mobile')" data-test="inputClinicMobile">
        <span class="vee-error">{{ errorMessage }}</span>
      </vee-field>
      <label for="mobile">{{ $t('createAccount.mobile') }}</label>
    </div> -->
    <!-- <div class="form-floating mb-3">
      <vee-field name="telephone3" v-model="clinic.telephone3" v-slot="{ field, errorMessage }">
        <input v-bind="field" type="text" class="form-control" ref="telephone3" id="telephone3" :placeholder="$t('createAccount.telephone3')" data-test="inputClinicPhone3">
        <span class="vee-error">{{ errorMessage }}</span>
      </vee-field>
      <label for="telephone3">{{ $t('createAccount.telephone3') }}</label>
    </div> -->
    <div class="form-floating v-select-floating mb-3">
      <v-select
        id="mandatoryDocument"
        class="v-select form"
        v-model="mandatorySelected"
        :reduce="x => x.value"
        :options="mandatoryOptions"
        :placeholder="$t('createClinic.mandatoryDocument')"
        @option:selected="refreshMandatoryDocument"
        data-test="vSelectClinicMandatoryDocument">
      </v-select>
      <label for="mandatoryDocument">{{ $t('createClinic.mandatoryDocument') }}</label>
    </div>
    <div class="form-floating v-select-floating mb-3">
      <v-select
        id="language"
        class="v-select form"
        v-model="clinicLocale"
        :reduce="x => x.value"
        :options="localeOptions"
        :placeholder="$t('createClinic.language')"
        @option:selected="setLocale"
        data-test="vSelectClinicLocale">
      </v-select>
      <label for="language">{{ $t('createClinic.language') }}</label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.vee-error {
  width:                        100% !important;
}
</style>

<script>
export default {
  name: 'RegistrationClinicStepOne',
  props: ['clinic'],
  data: () => ({
    mandatoryOptions: [],
    localeOptions: [],
    mandatorySelected: 'none',
    clinicLocale: null,
  }),
  created() {
    this.mandatoryOptions = [
      {value: 'none', label: this.$i18n.t('none')},
      {value: 'mrn', label: this.$i18n.t('mrn')},
      {value: 'document0', label: this.$i18n.t('document0')},
      {value: 'document1', label: this.$i18n.t('document1')},
      {value: 'document2', label: this.$i18n.t('document2')},
      {value: 'document3', label: this.$i18n.t('document3')},
    ];
    this.localeOptions = [
      {value: 'pt-br', label: this.$i18n.t('locales.pt-br')},
      {value: 'en', label: this.$i18n.t('locales.en')},
      {value: 'es', label: this.$i18n.t('locales.es')},
    ];
  },
  mounted() {
    let language = this.currentLanguage()
    this.clinicLocale = language
    if (this.clinic.mandatoryDocument)
      this.mandatorySelected = this.clinic.mandatoryDocument

    if (this.clinic.locale)
      this.clinicLocale = this.clinic.locale

    this.$nextTick(() => {
      this.$refs.clinicName.focus()
    })
  },
  methods: {
    refreshMandatoryDocument(document) {
      if (document == undefined)
        return

      this.clinic.mandatoryDocument = document.value
    },
    setLocale(locale) {
      if (locale == undefined)
        return

      this.clinic.locale = locale.value
    },
    currentLanguage() {
      return this.$utils.getCurrentLanguage()
    }
  }
}
</script>
