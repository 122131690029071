<template>
  <div class="dashboard-bar progress-box">
    <div class="progress" role="progressbar" aria-label="Basic example" :aria-valuenow="usagePercentage" aria-valuemin="0" aria-valuemax="100">
      <div class="progress-bar" :class="getPercentageClass()" :style="`width: ${usagePercentage}%`"></div>
    </div>
    <router-link to="/dashboard">
      {{ usagePercentage }}% {{ $t('dashboard.imageTotalUsage') }} <span>{{ $t('dashboard.imageTotalCount', {imageTotal: imageTotal, quotaAvailable, quotaAvailable}) }}</span>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.progress-box {
  text-align:                           center;
  margin-bottom:                        5px;
  .progress {
    height:                             10px;
    border:                             0.5px solid $gainsboro;
  }
  a {
    color:                              var(--Brand-Primary, #1B294A) !important;
    font-size:                          var(--font-size-10);
    font-weight:                        500;
    text-transform:                     uppercase;
    span {
      font-weight:                      400;
      text-transform:                   lowercase;
    }
  }
}

header {
  .progress-box {
    a {
      span {
        display:                        none;
      }
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'MenuStorageBar',
  data: () => ({
    clinicUsage: {},
    usagePercentage: null,
    session: {
      collaborator: {},
      currentClinic: {}
    },
    imageTotal: 5000,
    quotaAvailable: 5000
  }),
  created() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      let params = {
        id: this.session.currentClinic.id
      }

      NProgress.start()
      try {
        let response = await this.$dashboardService.getClinicUsage(params);
        this.clinicUsage = response.clinicUsage

        this.getPercentageValue()
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true);
    },
    getPercentageValue() {
      this.usagePercentage = this.$dashboardService.getPercentageValue(this.clinicUsage.examTotal)
    },
    getPercentageClass() {
      return this.$dashboardService.getPercentageClass(this.usagePercentage)
    }
  },
}
</script>
