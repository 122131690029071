<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
    <path d="M7.96969 26.0872C7.70011 25.93 7.49084 25.7991 7.34897 25.7076C7.48997 19.9776 12.1791 15.376 17.943 15.376C23.9973 15.376 27.3864 18.8912 27.6393 22.2094C27.7658 23.8683 27.125 25.5347 25.5845 26.8007C24.0354 28.0739 21.5421 28.9668 17.943 28.9668C14.97 28.9668 12.2657 28.1184 10.2954 27.2615C9.3123 26.834 8.51732 26.4066 7.96969 26.0872Z" stroke="#72DBB8"/>
    <circle cx="17.9426" cy="12.4152" r="6.38208" stroke="white"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconPatients'
}
</script>
