<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8872 3.2183C21.2095 4.0926 24.4634 7.91263 24.4634 12.4927L24.4632 12.5555V22.6338H24.9354C25.3496 22.6338 25.6854 22.9696 25.6854 23.3838C25.6854 23.798 25.3496 24.1338 24.9354 24.1338H24.4632V24.2167H22.9632H7.03848H5.53848V24.1338H5.06445C4.65024 24.1338 4.31445 23.798 4.31445 23.3838C4.31445 22.9696 4.65024 22.6338 5.06445 22.6338H5.53848V13.9932V12.4932H5.53868V12.4927V12.4921C5.53893 7.91247 8.79262 4.09279 13.1145 3.21838C13.1303 3.0218 13.1767 2.82857 13.2524 2.64579C13.3476 2.41619 13.4869 2.20756 13.6627 2.03183C13.8384 1.8561 14.047 1.7167 14.2766 1.62159C14.5062 1.52649 14.7523 1.47754 15.0009 1.47754C15.2494 1.47754 15.4955 1.52649 15.7251 1.62159C15.9547 1.7167 16.1633 1.8561 16.339 2.03183C16.5148 2.20756 16.6542 2.41619 16.7493 2.64579C16.825 2.82855 16.8714 3.02175 16.8872 3.2183ZM22.9632 12.5458H22.9632V12.5555V22.6338H7.03848V13.9932H7.03876L7.03868 12.4931V12.4927C7.03868 8.09517 10.6036 4.53029 15.001 4.53029C19.3985 4.53029 22.9634 8.09517 22.9634 12.4927L22.9632 12.5458ZM17.8253 25.6964C17.8253 26.0676 17.7521 26.4352 17.6101 26.7781C17.4681 27.121 17.2599 27.4326 16.9974 27.6951C16.7349 27.9575 16.4233 28.1657 16.0804 28.3078C15.7375 28.4498 15.37 28.5229 14.9988 28.5229C14.6276 28.5229 14.2601 28.4498 13.9171 28.3078C13.5742 28.1657 13.2626 27.9575 13.0002 27.6951C12.7377 27.4326 12.5295 27.121 12.3875 26.7781C12.2454 26.4352 12.1723 26.0676 12.1723 25.6964H14.9988H17.8253Z" fill="#3CB391"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconAlert'
}
</script>
