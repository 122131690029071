<template>
  <div class="eyerweb">
    <div class="date-box">
      <label for="">{{ $t('exam.from') }}</label>
      <div class="input-group">
        <date-picker
          v-model="startDate"
          :locale="locale"
          value-type="date"
          :selectText="$t('select')"
          :cancelText="$t('cancel')"
          :format="$t('dateFormatFns')"
          :inputFormat="$t('dateFormatFns')"
          :enableTimePicker="false"
          :disabled-date="disabledDate"
          data-test="datePickerEyerwebStart">
        </date-picker>
      </div>
    </div>

    <div class="date-box">
      <label for="">{{ $t('exam.to') }}</label>
      <div class="input-group">
        <date-picker
          v-model="endDate"
          :locale="locale"
          value-type="date"
          :selectText="$t('select')"
          :cancelText="$t('cancel')"
          :format="$t('dateFormatFns')"
          :inputFormat="$t('dateFormatFns')"
          :enableTimePicker="false"
          :disabled-date="disabledDate"
          data-test="datePickerEyerwebEnd">
        </date-picker>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .date-box {
    position:                        relative;
  }

  @media (min-width: 400px) {
    .date-box {
      &:first-child:not(:last-child) {
        margin-right:                5px;
      }
    }
  }

  @media (max-width: 767px) {
    .date-box {
      max-width:                     130px !important;
      margin:                        0 5px;
    }
  }
</style>

<script>
import { useEyerwebStore } from '@/store/eyerweb'
import { enUS, ptBR, es } from 'date-fns/locale'

export default {
  name: 'EyerwebExamDateFilter',
  data: () => ({
    startDate: new Date(),
    endDate: new Date(),
    session: {
      collaborator: {},
      currentClinic: {}
    },
  }),
  computed: {
    locale() {
      let locale = this.session.currentClinic.locale
      if (locale){
        if (locale == 'pt-br') {
          return 'pt-BR'
        } else if (locale == 'es') {
          return 'es'
        } else {
          return 'en-US'
        }
      }
      return 'en-US'
    }
  },
  watch: {
    startDate: function (val) {
      this.setDate();
    },
    endDate: function (val) {
      this.setDate();
    }
  },
  mounted() {
    const eyerwebStore = useEyerwebStore()
    this.session = eyerwebStore.sessionInfo
    console.log('this.session', this.session);
    let today = new Date()

    this.startDate = new Date('01/01/2018')
  },
  methods: {
    disabledDate(date) {
      const today = moment();
      return date > today
    },
    setDate() {
      let filterDate = {
        startDate: this.$utils.formatDate(this, this.startDate),
        endDate: this.$utils.formatDate(this, this.endDate)
      };

      this.$emit('change-date', filterDate);
    }
  },
  destroyed() {
    let currentDate = new Date();
    this.startDate = this.endDate.setMonth(this.endDate.getMonth() - 3);
  }
}
</script>
