import eyerCloud from '@/config/eyercloud'
let PAID_TRESHOLD = 5000

class DashboardService {
  async getClinicUsage(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/usage/get`, params)
    return response.data
  }

  getPercentageValue(value, isPaid) {   
    if (isPaid)
      PAID_TRESHOLD = 50000

    let percentage = ((value / PAID_TRESHOLD) * 100).toFixed(2);
    return percentage > 100 ? 100 : percentage
  }

  getPercentageClass(percentage) {
    if (percentage > 60 && percentage < 80)
      return 'warning'
    else if (percentage >= 80)
      return 'danger'
  }

}
const dashboardService = new DashboardService();
export default dashboardService;
