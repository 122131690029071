<template>
  <div class="modal fade" id="change_email_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="changeEmailLabel" aria-hidden="true" @self.click="close()">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="changeEmailLabel">
            <span>{{ $t('email.changeEmail') }}</span>
          </h5>
          <button id="closeBtn" type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </div>
        <vee-form v-slot="{ handleSubmit }" as="div">
          <form @submit.prevent.stop="handleSubmit(changeEmail)">
            <div class="modal-body">
              <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
                <span v-html="alert.message"></span>
                <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
              </div>

              <div v-if="!conrifmEmail">
                <div class="form-group">
                  <label for="newEmail">{{ $t('email.newEmail') }}</label>
                  <vee-field name="newEmail" v-model="newEmail" rules="required|email" v-slot="{ field, errorMessage }">
                    <input v-bind="field" type="email" class="form-control" id="newEmail" data-test="inputPatientNewEmail">
                    <span class="vee-error">{{ errorMessage }}</span>
                  </vee-field>
                </div>
                <div class="form-group">
                  <label for="currentPassword">{{ $t('user.currentPassword') }}</label>
                  <vee-field name="currentPassword" v-model="currentPassword" rules="required" v-slot="{ field, errorMessage }">
                    <input v-bind="field" type="password" class="form-control" id="currentPassword" data-test="inputPatientCurrentPassword">
                    <span class="vee-error">{{ errorMessage }}</span>
                  </vee-field>
                </div>
              </div>

              <div v-else>
                <p>{{ $t('email.linkSent') }} <strong>{{ newEmail }}</strong>.</p>
                <p>{{ $t('email.insertCode') }}</p>
                <div class="form-group">
                  <label for="verificationCode">{{ $t('email.verificationCode') }}</label>
                  <vee-field name="verificationCode" v-model="verificationCode" rules="required" v-slot="{ field, errorMessage }">
                    <input v-bind="field" type="password" class="form-control" id="verificationCode" data-test="inputPatientVerificationCode">
                    <span class="vee-error">{{ errorMessage }}</span>
                  </vee-field>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" data-test="btnCancel" @click="close()">{{ $t('user.cancel') }}</button>
              <button type="submit" class="btn btn-primary" @click="registerNewEmail" v-if="!conrifmEmail" data-test="btnModifyRegister">{{ $t('user.modify') }}</button>
              <button type="submit" class="btn btn-primary" @click="confirmNewEmail" v-else data-test="btnModifyConfirm">{{ $t('user.modify') }}</button>
            </div>
          </form>
        </vee-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .modal {
    .modal-dialog {
      .modal-header {
        background-color:               $phelcom-light-blue;
      }
      .modal-body {
        .form-group {
          display:                      block;
          label {
            width:                      100%;
          }
        }
        div {
          p:last-child {
            margin-bottom:              5px;
          }
        }
        .vee-error {
          width:                        100% !important;
        }
      }
    }
  }
</style>

<script>
export default {
  name: 'ExternalPatientChangeEmailModal',
  data: () => ({
    alert: {
      message: null,
      class: null,
    },
    currentPassword: "",
    newEmail: "",
    conrifmEmail: false,
    activationCode: null,
    verificationCode: null,
  }),
  mounted() {
    setTimeout(() => {
      newEmail.focus()
    }, 1000)
  },
  methods: {
    close() {
      this.conrifmEmail = false
      let values = {
        alert: this.alert,
        type: 'email'
      }
      this.$emit('close', values)
    },
    async registerNewEmail() {
      let params = {
        email: this.newEmail,
        password: this.currentPassword
      }

      NProgress.start()
      try {
        let result = await this.$externalService.registerNewEmail(params)
        console.log('result', result);
        this.conrifmEmail = true
        this.activationCode = result.activationCode;
      } catch(err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    },
    async confirmNewEmail() {
      let params = {
        activationCode: this.activationCode,
        verificationCode: this.verificationCode
      }

      NProgress.start()
      try {
        let result = await this.$externalService.confirmNewEmail(params)

        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')

        setTimeout(() => {
          $('#closeBtn').click();
        }, 1000)
      } catch(err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    },
  }
}
</script>
