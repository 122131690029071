<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M1 1.26184L12.4763 12.7382" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M1 12.7382L12.4763 1.26183" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconCloseHamburger'
}
</script>
