<template>
  <div class="registration-page" v-loader.opacity="loading">
    <div class="container">
      <img src="@/assets/images/phelcom/eyercloud_logo.svg" alt="Eyercloud logo">

      <div class="row" v-if="!isPasswordChanged">
        <div class="col-lg-6 flex-center">
          <div class="">
            <h1>
              {{ $t('resetPassword.title') }}
            </h1>

            <p>
              {{ $t('resetPassword.hello') }}
              <span v-if="firstName"> {{ firstName }}</span>
              {{ $t('resetPassword.forgotMessage') }}
            </p>
          </div>
        </div>
        <div class="col-lg-6 flex-center">
          <div>
            <h3 class="mb-3">{{ $t('resetPassword.instructionsMessage') }}</h3>
            <p class="mb-3">{{ $t('resetPassword.instructionsMessagePassword') }}</p>

            <vee-form v-slot="{ handleSubmit }" as="div">
              <form @submit.prevent.stop="handleSubmit(changePassword)">
                <div class="form-floating mb-3">
                  <vee-field name="newPassword" v-model="newPassword" rules="required|min:4" v-slot="{ field, errorMessage }">
                    <input v-bind="field" :type="showPassword ? 'text' : 'password'" data-test="inputnewPassword"
                           new-password class="form-control" ref="newPassword" id="newPassword" :placeholder="$t('user.password')" @keyup="checkPassword(newPassword)">

                    <button class="btn-icon" type="button" name="button" data-test="btnShowPassword" @click="showPassword = !showPassword">
                      <IconShowPassword/>
                    </button>
                    <small class="strength-password" :class="strengthInfo" v-show="strengthInfo">{{ strengthInfo ? $t(`strengthPassword.${strengthInfo}`) : "" }}</small>

                    <span class="vee-error">{{ errorMessage ? errorMessage : $t(`${strengthPasswordMessage}`) }}</span>
                  </vee-field>
                  <label for="password">{{ $t('user.password') }}</label>
                </div>
                <div class="form-floating mb-3">
                  <vee-field name="confirmPassword" v-model="confirmPassword" rules="required|confirmed:newPassword" v-slot="{ field, errorMessage }">
                    <input v-bind="field" :type="showConfirmPassword ? 'text' : 'password'" data-test="inputAccountConfirmPassword"
                           class="form-control" ref="confirmPassword" id="confirmPassword" :placeholder="$t('user.passwordConfirmation')">

                    <button class="btn-icon" type="button" name="button" data-test="btnShowConfirmPassword" @click="showConfirmPassword = !showConfirmPassword">
                      <IconShowPassword/>
                    </button>
                    <span class="vee-error">{{ errorMessage }}</span>
                  </vee-field>
                  <label for="confirmPassword">{{ $t('user.passwordConfirmation') }}</label>
                </div>

                <p class="mb-3">{{ $t('createAccount.setPin') }}</p>

                <!-- precisa aceitar um novo pin na troca, fazer backend -->
                <div class="form-floating mb-3">
                  <vee-field name="pin" v-model="newPin" rules="required|min:6" v-slot="{ field, errorMessage }">
                    <input v-bind="field" type="text" class="form-control" ref="pin" id="pin" v-only-numbers :placeholder="$t('user.devicePIN')" data-test="inputAccountPin">
                    <span class="vee-error">{{ errorMessage }}</span>
                  </vee-field>
                  <label for="pin">{{ $t('user.devicePIN') }}</label>
                </div>

                <button class="btn btn-success" type="submit" name="button" data-test="btnResetPassword" :disabled="loading">
                  {{ $t('resetPassword.changePassword') }}
                </button>
              </form>
            </vee-form>
          </div>
        </div>
      </div>

      <div class="flex-between" v-else>
        <h1>{{ $t('resetPassword.successResetTitle') }}</h1>

        <p class="mb-0">
          <span>{{ firstName }}</span>
          {{ $t('resetPassword.successResetMessage') }}
        </p>

        <a href="/" class="btn btn-success">{{ $t('createAccount.clinicAccess') }}</a>
      </div>

      <div class="powered-box flex-center">
        <p class="d-inline-block">POWERED BY</p>
        <a href="https://phelcom.com/" class="logo d-inline-block" target="_blank" data-test="anchorPhelcomLink">
          <img src="@/assets/images/phelcom/phelcom_logo_horizontal_blue.svg" alt="">
        </a>
        <p class="d-inline-block">v {{ appVersion }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.vee-error {
  width:                          100% !important;
}

.registration-page {
  height:                         100vh;
}

.container {
  display:                        flex;
  justify-content:                space-between;
  align-items:                    center;
  flex-direction:                 column;
  height:                         100%;
  padding-top:                    2rem;
  padding-bottom:                 1rem;
  > img {
    display:                      block;
    margin-left:                  auto;
    margin-right:                 auto;
  }
}

.col-lg-6 {
  display:                        flex;
  justify-content:                flex-start;
  align-items:                    center;
  > div {
    width:                        70%;
  }
  h1 {
    color:                        var(--Brand-Primary, #1B294A);
    font-size:                    38px;
    font-weight:                  300;
    line-height:                  normal;
  }
  &:first-child {
    justify-content:              flex-end;
  }
  &:last-child {
    gap:                          18px;
  }
}

.flex-between {
  width:                          40%;
  min-width:                      350px;
  max-width:                      400px;
  gap:                            28px;
  text-align:                     center;
  flex-direction:                 column;

  h1 {
    color:                        var(--Brand-Primary, #1B294A);
    font-size:                    38px;
    font-weight:                  300;
    line-height:                  normal;
  }

  > p {
    color:                        var(--Brand-Primary, #1B294A);
    font-size:                    var(--font-size-14);
    font-weight:                  300;
    line-height:                  140%;
    span {
      font-weight:                700;
    }
  }
}

.powered-box {
  flex-direction:                 row;
  gap:                            10px;
  p {
    color:                        var(--Brand-Primary, #1B294A);
    font-size:                    var(--font-size-8);
    font-weight:                  400;
    letter-spacing:               1.52px;
    margin:                       0;
  }
  .logo {
    color:                        #1B294A;
    text-decoration:              none;
    width:                        90px;
    // margin:                       0 10px;
    img {
      width:                      100%;
    }
  }
}

.btn {
  width:                          100%;
}

.strength-password {
  position:                       absolute;
  color:                          white;
  font-size:                      0.7em;
  text-align:                     center;
  height:                         12px;
  width:                          100%;
  top:                            -10px;
  &.danger {
    background:                   $phelcom-red;
  }
  &.warning {
    background:                   $yellow-phelcom;
  }
  &.success {
    background:                   $phelcom-green;
  }
}
</style>

<script>
import AuthFooter from './AuthFooter.vue'
import AuthHeader from './AuthHeader.vue';
import utilService from '../../services/util-service.js';

export default {
  name: 'auth-password-reset',
  components: {
    AuthFooter,
    AuthHeader,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null,
    },
    firstName: "",
    ticket: {},
    newPassword: null,
    confirmPassword: null,
    newPin: null,
    account: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      pin: "",
    },
    showPassword: false,
    showConfirmPassword: false,
    confirmPassword: "",
    strengthPasswordMessage: 'zxcvbnMessages.default',
    strengthInfo: null,
    isPasswordChanged: false,
    appVersion: process.env.VUE_APP_VERSION,
  }),
  mounted() {
    const data = JSON.parse(this.$utils.b64_to_utf8(this.$route.query._data))
    console.log('data', data);
    this.firstName = data.firstName
    this.ticket = data.ticket
    this.account.firstName = this.firstName
    this.disableCopyPaste();
  },
  methods: {
    async checkPassword(newPassword) {
      this.account.password = newPassword

      let params = {
        account: this.account
      }

      let isValidPassword = await utilService.validatePassword(params.account)

      switch (isValidPassword.score) {
        case 0:
        case 1:
        case 2:
          this.strengthInfo = 'danger'
          break;
        case 3:
          this.strengthInfo = 'warning'
          break;
        case 4:
          this.strengthInfo = 'success'
          break;
      }

      if (isValidPassword.valid) {
        this.strengthPasswordMessage = 'zxcvbnMessages.default'
      } else {
        this.strengthPasswordMessage = isValidPassword.warning ? 'zxcvbnMessages.warnings.' + isValidPassword.warning :
                                                            'zxcvbnMessages.suggestions.' + isValidPassword.suggestion[0]
      }
    },
    async changePassword() {
      this.alert.message = null

      this.account.pin = this.newPin // will this work? test to see

      let passwordParams = {
          account: this.account,
      }
      let responseValidate = await utilService.validatePassword(passwordParams.account)

      if (responseValidate.valid == false){
        this.$message.popup(this, 'zxcvbnMessages.error', 'operationFail', 'error')
        return
      }

      let params = {
        code: this.ticket.activationCode,
        newPassword: this.newPassword
      }

      this.loading = true
      NProgress.start()
      try {
        await this.$authService.changePassword(params)
        this.$message.popup(this, 'resetPassword.successResetMessage', 'operationSuccess', 'success')

        this.isPasswordChanged = true

        setTimeout(function(){
          window.location = "/";
        }, 30000);

      } catch (err) {
        err.response.data.code ?
              this.$message.popup(this, err.response.data.code, 'operationFail', 'error')
                : this.$message.popup(this, err.response.data.errorMessage, 'operationFail', 'error')
        // this.alert = err.response.data.code ?
        //               this.$message.alert(this, err.response.data.code, 'danger')
        //                 : this.$message.alert(this, err.response.data.errorMessage, 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    disableCopyPaste() {
      let inputArray = [this.$refs.newPassword, this.$refs.confirmPassword]
      inputArray.forEach((element) => {
        element.addEventListener('contextmenu', (e) => e.preventDefault()); // Disable right-click menu
        element.addEventListener('cut', (e) => e.preventDefault()); // Disable cut
        element.addEventListener('copy', (e) => e.preventDefault()); // Disable copy
        element.addEventListener('paste', (e) => e.preventDefault()); // Disable paste
      })
    },
  }
}
</script>
