<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" :id="offcanvas_id" :aria-labelledby="`${offcanvas_id}_label`">
    <!-- <div class="offcanvas-header">
      <button class="btn-back" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" :id="`${offcanvas_id}_label`">{{ title }}</h5>
    </div> -->
    <div class="offcanvas-body">
      <div class="text-center">
        <h1>{{ $t('createDevice.title') }}</h1>
        <div id="qrCodeDiv" @dblclick="copyQRCode()"></div>
        <p>{{ $t('createDevice.info') }}</p>
      </div>
    </div>
    <div class="offcanvas-footer">
      <button type="button" class="btn" :class="okButtonClass" data-bs-dismiss="offcanvas" v-if="okButton" data-test="btnConfirm" @click="doConfirm">{{ okButton }}</button>
      <button type="button" class="btn" :class="cancelButtonClass" data-bs-dismiss="offcanvas" v-if="cancelButton" data-test="btnCancel" @click="doCancel">{{ cancelButton }}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  width:                                510px !important;

  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    display:                            flex;
    flex-direction:                     column;
    justify-content:                    center;
    align-items:                        center;
    gap:                                19px;
    padding:                            40px 40px 0;

    h1 {
      font-size:                        26px;
      max-width:                        311px;
      margin:                           0 auto;
    }

    p {
      font-size:                        var(--font-size-14);
    }

    h1,
    p {
      color:                            var(--Brand-Primary);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      margin-bottom:                    0;
    }

    #qrCodeDiv {
      margin:                           10px;
    }
  }

  .offcanvas-footer {
    padding:                            20px 40px;
    border-top:                         1px solid var(--Shades-30);

    .btn {
      font-size:                        var(--font-size-14);
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   4.2px;
      text-align:                       center;
      text-transform:                   uppercase;
      // gap:                              10px;
      // flex:                             1 0 0;
      // width:                            100%;
      margin:                           0 auto;
      padding:                          20px;
    }

    .btn-success {
      color:                            var(--Shades-W, #FFF);
      // margin-top:                       26px;
    }
  }
}
</style>

<script>
import kjua from 'kjua'

export default {
  name: 'BaseGenericOffcanvas',
  props: ['offcanvas_id'],
  data: () => ({
    title: null,
    text: null,
    okButton: null,
    okButtonClass: null,
    cancelButton: null,
    cancelButtonClass: null,
    offcanvas_device: null,
    qrCode: null,
  }),
  mounted() {
    this.fetchData()
  },
  methods: {
    show(options = {}) {
      if (options.title)
        this.title = options.title

      if (options.text)
        this.text = options.text

      if (options.okButton) {
        this.okButton = options.okButton
        this.okButtonClass = options.okButtonClass
      }

      if (options.cancelButton) {
        this.cancelButton = options.cancelButton
        this.cancelButtonClass = options.cancelButtonClass
      }

      this.offcanvas_device = new bootstrap.Offcanvas(document.getElementById(this.offcanvas_id)).show();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.offcanvas_device = null;
      this.resolvePromise(true)
    },

    doCancel() {
      this.offcanvas_device = null;
      this.resolvePromise(false)
    },

    async fetchData() {
      this.loading = true
      NProgress.start()
      try {
        let response = await this.$deviceService.registerDevice()

        this.initializeWebsocket(response.qrcode);
        this.bindQRCode(response.qrcode);
      } catch (err) {
        console.log(err)
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },

    initializeWebsocket(data) {
      const io = this.$io
      console.log("webSocketInitialization() isConnected?", io.socket.isConnected())

      for (let e of ['registrationStarted', 'deviceRegistration']) {
        console.log('[new-device] unlisten to ws event:', e)
        io.socket.off(e);
      }

      if (io.socket.isConnected()) {
        console.log("io socket connection active, listening now!")
        this.listenToWebsocketEvents(data)
        return
      }

      io.socket.on('connect', () => {
        this.listenToWebsocketEvents(data);
      });

      io.socket.on('disconnect', () => {
        console.log("DICONNECT")
      });

      this.listenToWebsocketEvents(data)
    },
    listenToWebsocketEvents(data) {
      const io = this.$io

      io.socket.get('/online/deviceRegistration?id=' + data.sessionKey, function gotResponse(body, response) {
        console.log('device registration response > ', response);
        console.log('device registration body > ', body);
      });

      io.socket.on('registrationStarted', function(data) {
        console.log("Registration started: " + JSON.stringify(data));
  		});

      io.socket.on('deviceRegistration', data => {
        console.log("deviceRegistration data:", data);

        if (data.error) {
          this.$message.popup(this, 'operationFail', 'operationFail', 'error')
          // this.alert = this.$message.alert(this, 'operationFail', 'danger')
        } else {
          this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')
          this.$router.push({name: 'device-list', query: { ...this.$route.query, t: Date.now() }})
        }
  		});
    },
    bindQRCode(data) {
      this.qrCode = JSON.stringify(data)
      let options = {
        render: 'canvas', // render method: 'canvas', 'image' or 'div'
        crisp: true,
        // version range somewhere in 1 .. 40
        minVersion: 1,
        maxVersion: 40,
        // error correction level: 'L', 'M', 'Q' or 'H'
        ecLevel: 'L',
        // size in pixel
        size: 200,
        // pixel-ratio, null for devicePixelRatio
        ratio: null,
        // code color or image element
        fill: '#000',
        // background color or image element, null for transparent background
        background: null,
        // content
        text: this.qrCode,
        rounded: 0,
        // quiet zone in modules
        quiet: 0,

        mode: "label",

        mSize: 15,
        mPosX: 50,
        mPosY: 50,

        label: 'Eyer',
        fontname: 'sans',
        fontcolor: '#60bda2'
      }

      const qrCodeElement = kjua(options);
      qrCodeDiv.appendChild(qrCodeElement);
    },
    copyQRCode() {
      navigator.clipboard.writeText(this.qrCode);
    }
  }
}
</script>
