<template>
  <div class="" v-show="tabledata">
    <div class="vtable-grid inside-box" ref="tableContainer" @scroll="handleScroll()">
      <ul class="">
        <li v-for="(item, index) in tabledata.items" @click="selectRow(item)">
          <template :class="`${column.type}-line`" v-for="(column, indexColumn) in tabledata.columns" :key="indexColumn">
            <slot v-if="column.key == 'name'" :name="`column_${column.key}`" :item="item"/>
            <slot v-if="column.type == 'custom'" :name="`column_${column.key}`" :item="item"/>
            <!-- <span v-if="column.type == 'date'" :class="column.type">{{ formatDate(item, column.value) }}</span> -->
            <span v-if="column.type == undefined || column.type == 'string' && column.key != 'name'" v-html="getValue(item, column.value)" onclick="return false"></span>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'BaseTable',
  props: ['tabledata'],
  data: () => ({
    dateFormat: "",
  }),
  mounted: function() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    this.dateFormat = this.$i18n.t('dateTimeFormat')
  },
  methods: {
    getValue(data, key) {
      return _.get(data, key)
    },
    formatDate(data, key) {
      let value = _.get(data, key)
      let result = moment(value).format(this.dateFormat);
      return result
    },
    checkColumn(column){
      // if (column !== 'createdAt' && column !== 'updatedAt' && column !== 'id') return true;
    },
    selectRow(item) {
      this.$emit('item-opened', item);
    },
    // @deprecated
    checkStatus(status) {
      if (status === 'Closed' || status === 'Fechado' || status === 'closed' || status === 'fechado')
        return 'label-closed';

      return 'label-open';
    },
    handleScroll() {
      const tableContainer = this.$refs.tableContainer;
      const scrollTop = tableContainer.scrollTop;
      const scrollHeight = tableContainer.scrollHeight;
      const clientHeight = tableContainer.clientHeight;

      if (scrollHeight - scrollTop - clientHeight < clientHeight * 0.1) {
        // this.$emit('loadmore');
        this.debounce()
      }
    },
    debounce: _.debounce(function() {
      this.$emit('loadmore');
    }, 250, { 'maxWait': 100 }),
  },
  beforeDestroy() {
    this.$refs.scrollContainer.removeEventListener('scroll', this.onScroll);
  }
}
</script>
