<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <nav role="navigation">
        <div class="menu-toggle" id="menuToggle">
          <input type="checkbox" data-test="checkboxMenuToggle" />
          <span class="menu-toggle-bar"></span>
          <span class="menu-toggle-bar"></span>
          <span class="menu-toggle-bar"></span>

          <ul class="menu-toggle-list" id="menu">
            <div class="">
              <MenuProfile></MenuProfile>

              <li class="nav-item" :class="active === 'dashboard' ? 'active' : ''">
                <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" data-test="anchorMenuMobileDashboard" @click="open = false; dashboardMenu()">
                  <IconDashboard/>
                  <span>{{ $t('menu.dashboard') }}</span>
                </a>
              </li>
              <li class="nav-item" :class="active === 'exam' ? 'active' : ''">
                <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" data-test="anchorMenuMobileExam" @click="open = false; examMenu();">
                  <IconExams/>
                  <span>{{ $t('menu.exams') }}</span>
                </a>
              </li>
              <li class="nav-item" :class="active === 'patient' ? 'active' : ''" v-show="allowSynchronization">
                <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" data-test="anchorMenuMobilePatients" @click="open = false; patientMenu()">
                  <IconPatients/>
                  <span>{{ $t('menu.patients') }}</span>
                </a>
              </li>
              <BaseFeature feature="backoffice">
                <li v-show="!isSpecialist" :class="active === 'backoffice' ? 'active' : ''">
                  <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" data-test="anchorMenuMobileBackoffice" @click="open = false; backofficeMenu()">
                    <IconEye/>
                    <span>Backoffice</span>
                  </a>
                </li>
              </BaseFeature>
            </div>

            <div class="">
              <li :class="active === 'config' ? 'active' : ''">
                <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" data-test="anchorMenuMobileSettings" @click="open = false; settingsMenu()">
                  <IconSettings/>
                  <span>{{ $t('createClinic.configuration') }}</span>
                </a>
              </li>
              <div class="powered-box">
                <p class="d-inline-block">POWERED BY</p>
                <a href="https://phelcom.com/" class="logo d-inline-block" target="_blank">
                  <img src="@/assets/images/phelcom/phelcom_logo_horizontal_fit.svg" alt="">
                </a>
                <p class="d-inline-block">v {{ appVersion }}</p>
              </div>
            </div>
          </ul>
        </div>

        <a href="/" class="navbar-brand" data-test="anchorMenuMobileLogo">
          <img src="@/assets/images/phelcom/eyercloud_logo_white.svg" alt="Eyercloud logo white">
        </a>

        <button class="btn-icon btn-notif" type="button" name="button" id="notifications" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-test="btnMenuMobileNotifications">
          <!-- <i class="icon-bell"></i> -->
          <IconAlert/>
          <span id="notification-count" class="notification-count">{{ notificationCount }}</span>
        </button>
        <Notifications :notification="notification"></Notifications>
      </nav>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.container-fluid {
  & > nav {
    display:                                flex;
    justify-content:                        space-between;
    width:                                  100%;

    .profile {
      margin-top:                           0;
      margin-bottom:                        20px;
    }

    .powered-box {
      text-align:                           center;
      margin-top:                           20px;
      p {
        color:                              var(--Shades-W, #FFF);
        font-size:                          var(--font-size-8);
        font-weight:                        400;
        letter-spacing:                     2.15px;
        margin:                             0;
      }
      .logo {
        color:                              #1B294A;
        width:                              101px;
        // font-size:                          1.25em;
        margin:                             0 15px;
        text-decoration:                    none;
        img {
          width:                            101px !important;
        }
      }
    }
  }
}

.menu-toggle {
  @include                                  border_radius(50%);
  display:                                  flex;
  flex-direction:                           column;
  height:                                   35px;
  width:                                    35px;
  margin:                                   0;
  padding:                                  12px 7px 5px;
  background-color:                         #727272;
  -webkit-user-select:                      none;
  user-select:                              none;

  .menu-toggle-bar {
    @include                                transition_three(transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease);
    position:                               relative;
    display:                                flex;
    height:                                 2px;
    width:                                  20px;
    margin-bottom:                          2px;
    background:                             #ffffff;
    border-radius:                          3px;
    z-index:                                1;
    transform-origin:                       5px 0px;

    &:first-child {
      transform-origin:                     0% 0%;
    }
    &:nth-last-child(2) {
      transform-origin:                     0% 100%;
    }
  }

  input {
    position:                               absolute;
    display:                                flex;
    height:                                 32px;
    width:                                  40px;
    cursor:                                 pointer;
    opacity:                                0;
    z-index:                                2;

    &:checked ~ .menu-toggle-bar {
      @include                              transform(rotate(45deg) translate(-3px, -1px));
      opacity:                              1;
      background:                           #ffffff;
      top:                                  4px;
      left:                                 4px;

      &:nth-last-child(3) {
        @include                            transform(rotate(0deg) scale(0.2, 0.2));
        opacity:                            0;
      }

      &:nth-last-child(2) {
        @include                            transform(rotate(-45deg) translate(0, -1px));
      }

      & ~ ul {
        transform:                          none !important;
      }
    }
  }

  .menu-toggle-list {
    @include                                border_radius(0 10px 10px 0);
    @include                                transform(translate(-100%, 0));
    @include                                transition(transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0));
    position:                               absolute;
    display:                                flex;
    justify-content:                        space-between;
    flex-direction:                         column;
    list-style:                             none;
    width:                                  357.75px;
    height:                                 100vh;
    margin:                                 -27px 0 0 -32px;
    padding:                                18px 18px 18px 28px;
    padding-top:                            74px;
    background:                             linear-gradient(6deg, #3CB391 -31.44%, #000 24.53%);
    -webkit-font-smoothing:                 antialiased;
    transform-origin:                       0% 0%;

    li {
      @include                              border_radius(5px);
      margin-bottom:                        10px;
      background:                           var(--Shades-80, #232323);
      border-left:                          4px solid var(--Shades-80, #232323);
      transition-delay:                     2s;
      &:hover,
      &:focus,
      &:active,
      &.active {
        border-color:                       var(--Support-Success, #81BAA7);
        background:                         linear-gradient(93deg, #25A480 0%, #09C891 100%);
        a, i:before {
         color:                             white;
       }
      }
      a {
        font-size:                          var(--font-size-14);
        font-weight:                        500;
        color:                              #72DBB8;
        text-transform:                     uppercase;
        letter-spacing:                     3px;
        display:                            block;
        padding:                            10px 10px 10px 6px;
        text-decoration:                    none;
        cursor:                             pointer;
      }
    }

    div:last-child {
      li {
        margin-bottom:                      0;
      }
    }
    img {
      width:                                35px;
      height:                               35px;
    }
    i {
      font-size:                            2em;
      vertical-align:                       middle;
      margin-right:                         10px;
    }
    img,
    svg {
      margin-right:                         10px;
    }
  }
}

.navbar {
  @include                                  border_radius(0 0 10px 10px);
  position:                                 fixed;
  // width:                                    calc(100% - 30px);
  border:                                   0;
  padding:                                  0;
  width:                                    100%;
  height:                                   65px;
  top:                                      0;
  background:                               linear-gradient(0deg, #3CB391 -93.93%, #000 100%);
  z-index:                                  999;
  .container-fluid {
    padding-left:                           15px;
    padding-right:                          15px;
  }
  .navbar-brand {
    font-size:                              unset;
    line-height:                            unset;
    height:                                 unset;
    margin-right:                           0;
    z-index:                                2;
    // width:                                  calc(100% - 150px);
    // padding:                                12px 0;
    max-height:                             50px;
    i {
      color:                                white;
      font-size:                            2.3em;
    }
  }
  .btn-icon {
    margin-bottom:                          0;
    height:                                 35px;
    width:                                  35px;
    padding:                                5px;
    background-color:                       #727272;
    border:                                 0;
    &.btn-notif {
      padding:                              4px;
      position:                             relative;
      z-index:                              0;
      .notification-count {
        @include                            border_radius(50%);
        font-size:                          12px;
        position:                           absolute;
        top:                                -4px;
        background-color:                   $arylide;
        right:                              -4px;
        width:                              16px;
        height:                             16px;
      }
    }
    i {
      font-size:                            2em;
    }
  }
}

.progress-box {
  margin-bottom:                            15px;
}
</style>

<script>
import { useUserStore } from '@/store/user'
import MenuProfile from './MenuProfile.vue'
import MenuStorageBar from './MenuStorageBar.vue'
import Notifications from './Notifications.vue'
import profile_img from "@/assets/images/phelcom/profile_img.png";

export default {
  name: 'MenuMobileLinks',
  props: ['isResize'],
  components: {
    MenuProfile,
    MenuStorageBar,
    Notifications
  },
  data: () => ({
    active: null,
    open: true,
    openNotif: false,
    allowSynchronization: true,
    isSpecialist: null,
    isPowerfull: null,
    session: {
      collaborator: {},
      currentClinic: {}
    },
    notificationData: {
      count: 0,
      events: []
    },
    notificationCount: 0,
    notification: [],
    appVersion: process.env.VUE_APP_VERSION,
  }),
  watch: {
    '$activeLink.value': {
      handler: function () {
        console.log('watch', this.$activeLink.value);
        this.active = this.$activeLink.value
    }, deep: true }
  },
  computed: {
    avatar() {
      return this.session.userPhoto ? this.session.userPhoto : profile_img;
    }
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.currentClinicID = this.session.currentClinic.id

    this.isSpecialist = this.session.isSpecialist
    this.isPowerfull = this.session.isPowerfull
    this.active = this.$activeLink.value ? this.$activeLink.value : null

    this.allowSynchronization = this.session.currentClinic.synchronizePatientData
    if (this.allowSynchronization == undefined)
      this.allowSynchronization = true

    this.$nextTick(() => {
      this.reloadNotifications()
    })

    // called from notificationService (logic is there)
    this.emitter.on("newNotification", event => {
      this.notification.push(event)
      this.notificationCount += 1 // view logic
    })
  },
  methods: {
    profileMenu: async function() {
      this.session.activeLink = 'users'
      this.$router.push(`/user/${this.session.user.id}`)
    },
    auditMenu: async function() {
      this.goToLink(this, 'audit', 'audit-list')
    },
    clinicsMenu: async function() {
      this.goToLink(this, 'clinic', 'clinic-list')
    },
    dashboardMenu: async function(useRouter) {
      this.goToLink(this, 'dashboard', 'dashboard')
    },
    deviceMenu: async function() {
      this.goToLink(this, 'device', 'device-list')
    },
    examMenu: async function() {
      this.session.examCurrentPage = 1
      this.goToLink(this, 'exam', 'exam-list')
    },
    patientMenu: async function() {
      this.session.patientCurrentPage = 1
      this.goToLink(this, 'patient', 'patient-list')
    },
    usersMenu: async function() {
      this.goToLink(this, 'users', 'user-list')
    },
    toggleMenu(val) {
      this.open = val
    },
    async logout() {
      await this.$authService.logout(this)
      window.open('/', '_self');
    },
    goToLink(self, active, link) {
      this.toggleMenu(false);
      self.active = active

      self.$router.push({name: 'proxy-router', params: {
        target: link,
        data: JSON.stringify({})
      }})
    },

    reloadNotifications() {
      if (!this.currentClinicID)
        return

      const clinicData = this.$notificationService.reloadNotifications(this.currentClinicID)
      for (let e of clinicData.events) {
        this.addNotification(e)
      }

      this.notificationCount = clinicData.count
    },
    // bell icon clicked
    resetNotifications() {
      this.notificationCount = 0
      this.$notificationService.resetCount(this.currentClinicID)
      this.$notificationService.enable() // force notification activation
    },
    addNotification(data) {
      try {
        this.notification.unshift(data)
      } catch (e) {
        console.log('error(addNotification)', e);
      }
    },
    notificationClicked: async function(examid) {
      try {
        let params = {
          id: examid,
        }

        let response = await this.$examService.get(params);

        let data = this.$utils.dataQuery({
          exam: JSON.stringify(response.exam)
        })

        this.$router.push({name: 'exam-show', params: {examID: examid}, query: {tab: 'images', data: data}})
        // this.$router.push({name: 'proxy-router', params:{
        //   target: 'exam-show', data: {exam: response.exam, tab: null, examid: examid}
        // }})
      } catch(err) {
        this.alert = {
          message: `${this.$i18n.t('request_error')} <strong>${err}</strong>`,
          class: 'alert-danger'
        }
      }
    },
  }
}

</script>
