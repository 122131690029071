<template>
  <div class="footer">
    <div class="dashboard-bar progress-box">
      <div class="progress" role="progressbar" aria-label="Basic example" :aria-valuenow="usagePercentage" aria-valuemin="0" aria-valuemax="100">
        <div class="progress-bar" :class="getPercentageClass()" :style="`width: ${usagePercentage}%`"></div>
      </div>

      <p>
        {{ usagePercentage }}% {{ $t('dashboard.imageTotalUsage') }}
        <span>{{ $t('dashboard.imageTotalCount', {imageTotal: clinicUsage.imageTotal, quotaAvailable: isPaidService ? 50000 : 5000}) }}</span>
      </p>
    </div>

    <div>
      <p class="d-inline-block">POWERED BY</p>
      <a href="https://phelcom.com/" class="logo d-inline-block" target="_blank" data-test="anchorPhelcomLink">
        <img src="@/assets/images/phelcom/phelcom_logo_horizontal_blue.svg" alt="">
      </a>
      <p class="d-inline-block">v {{ appVersion }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  display:                                  flex;
  justify-content:                          space-between;
  align-items:                              center;
  // height: 22px;
  // padding-left: 20px;
  padding:                                  10px 0 0 20px;

  .dashboard-bar {
    display:                                flex;
    align-items:                            center;
    .progress {
      width:                                105px;
      height:                               10px;
      margin-right:                         10px;
    }

    p {
      color:                                var(--Shades-90, #101010);
      font-size:                            var(--font-size-10); // need create 11
      letter-spacing:                       2.365px;
      line-height:                          normal;
      text-transform:                       uppercase;
      display:                              inline-block;
      margin:                               0;
      span {
        font-weight:                        400;
        letter-spacing:                     normal;
        text-transform:                     lowercase;
      }
    }
  }

  > div {
    display:                                inline-block;
    &:last-child {
      p {
        color:                              var(--Brand-Primary, #1B294A);
        font-size:                          var(--font-size-8);
        font-weight:                        400;
        letter-spacing:                     2.15px;
        margin:                             0;
      }
      .logo {
        color:                              #1B294A;
        width:                              101px;
        // font-size:                          1.25em;
        margin:                             0 15px;
        text-decoration:                    none;
      }
    }
  }
}

@media (max-width: 991px) {
  .footer {
    > div {
      &:last-child {
        display:                            none;
      }
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'HomeFooter',
  components: {
  },
  data: () => ({
    clinicUsage: {},
    usagePercentage: null,
    session: {
      collaborator: {},
      currentClinic: {}
    },
    isPaidService: false,
    quotaAvailable: 0,
    appVersion: process.env.VUE_APP_VERSION,
  }),
  created() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    // this.isPaidService = this.$utils.getIsPaidService()
    this.quotaAvailable = this.isPaidService ? 50000 : 5000
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      let params = {
        id: this.session.currentClinic.id
      }

      NProgress.start()
      try {
        let response = await this.$dashboardService.getClinicUsage(params);
        this.clinicUsage = response.clinicUsage
        //debug > 80%
        // this.clinicUsage.imageTotal = 3001

        this.getPercentageValue()
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true);
    },
    getPercentageValue() {
      this.usagePercentage = this.$dashboardService.getPercentageValue(this.clinicUsage.imageTotal, this.isPaidService)
    },
    getPercentageClass() {
      return this.$dashboardService.getPercentageClass(this.usagePercentage)
    }
  }
}
</script>
