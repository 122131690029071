<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
    <rect x="3.42383" y="1.09717" width="18.2534" height="23.6787" rx="4.5" stroke="#1B294A"/>
    <rect x="0.723633" y="7.09351" width="17.1479" height="12.8042" rx="2.5" stroke="#3CB391"/>
    <path d="M8.47266 4.3938H16.5996" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.47266 22.2971H24.2373" stroke="#3CB391" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconDevices'
}
</script>
