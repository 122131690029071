<template>
  <div class="modal" id="modal_preview_pdf" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('medicalRecord.pdfPreview') }}</h5>
          <button id="closeBtn" type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
        </div>
        <div class="modal-body">
          <div id="pdfvuer">
            <pdf-vuer :src="pdfData" v-for="i in numPages" :key="i" :id="i" :page="i"
              :scale.sync="scale" style="width:100%;margin:20px auto;"
                :annotation="true"
                :resize="true"
                @link-clicked="handle_pdf_link">
              <template slot="loading">
                loading content here...
              </template>
            </pdf-vuer>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" data-test="btnClose" @click="close">{{ $t('cancel') }}</button>
          <button type="button" class="btn btn-primary" data-test="btnDownload" @click="download">Download</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .modal {
    .modal-header {
      background-color:           $phelcom-light-blue;
      border-bottom:              1px solid $platinum;
      h5 {
        font-size:                18px;
      }
    }
    .modal-body {
      height:                     100%;
      max-height:                 60vh;
      overflow-y:                 auto;
    }
  }
</style>

<script>
import pdfvuer from 'pdfvuer'

export default {
  name: 'PreviewPdfModal',
  props: ['pdf_url', 'pdf_id'],
  data: () => ({
    page: 1,
    numPages: 0,
    pdfData: undefined,
    errors: [],
    scale: 'page-width'
  }),
  computed: {
    formattedZoom () {
      return Number.parseInt(this.scale * 100);
    },
  },
  watch: {
    show: function (s) {
      if(s) {
        this.getPdf();
      }
    },
    page: function (p) {
      if( window.pageYOffset <= this.findPos(document.getElementById(p)) || ( document.getElementById(p+1) && window.pageYOffset >= this.findPos(document.getElementById(p+1)) )) {
        // window.scrollTo(0,this.findPos(document.getElementById(p)));
        document.getElementById(p).scrollIntoView();
      }
    }
  },
  created() {
    this.getPdf()
    // this.pdfSrc = 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf'
    // this.pdfSrc = 'http://localhost:1337/data/2022-09-21_ruthie_hartmann.pdf'
  },
  methods: {
    close() {
      this.$emit('close')
    },
    handle_pdf_link: function (params) {
      // Scroll to the appropriate place on our page - the Y component of
      // params.destArray * (div height / ???), from the bottom of the page div
      var page = document.getElementById(String(params.pageNumber));
      page.scrollIntoView();
    },
    async getPdf() {
      var self = this;
      console.log('pdf prop', self.pdf_url);
      // self.pdfData = pdfvuer.createLoadingTask('http://localhost:1337/data/2022-09-22_ruthie_hartmann.pdf');
      self.pdfData = await pdfvuer.createLoadingTask(self.pdf_url);
      self.pdfData.then(pdf => {
        self.numPages = pdf.numPages;
        window.onscroll = function() {
          changePage()
          stickyNav()
        }

        // Get the offset position of the navbar
        var sticky = $('#buttons')[0].offsetTop

        // Add the sticky class to the self.$refs.nav when you reach its scroll position. Remove "sticky" when you leave the scroll position
        function stickyNav() {
          if (window.pageYOffset >= sticky) {
            $('#buttons')[0].classList.remove("hidden")
          } else {
            $('#buttons')[0].classList.add("hidden")
          }
        }

        function changePage () {
          var i = 1, count = Number(pdf.numPages);
          do {
            if(window.pageYOffset >= self.findPos(document.getElementById(i)) &&
                window.pageYOffset <= self.findPos(document.getElementById(i+1))) {
              self.page = i
            }
            i++
          } while ( i < count)
          if (window.pageYOffset >= self.findPos(document.getElementById(i))) {
            self.page = i
          }
        }
      });
    },
    findPos(obj) {
      return obj.offsetTop;
    },
    async download() {
      let response = await this.$medicalRecordService.getMedicalDataURL(this.pdf_id)
      let downloadURL = response.downloadURL
      window.open(downloadURL)
    },
  },
}
</script>
