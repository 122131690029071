<template>
  <div class="row">
    <div class="col flex-center">
      <div class="">
        <h1>{{ $t('externalPatient.termsOfUse') }}</h1>

        <p class="mb-0">
          {{ $t('externalPatient.termsOfUseInfo1') }}
          <b>{{ $t('externalPatient.termsOfUseInfo2') }}</b>
          {{ $t('externalPatient.termsOfUseInfo3') }}
        </p>

        <button class="btn btn-success" type="submit" name="button" data-test="btnAccept" @click="doAccept">{{ $t('termsPatient.accept') }}</button>
        <button class="btn btn-default" type="button" name="button" data-test="btnDontAccept" @click="close">{{ $t('termsPatient.doNotAccept') }}</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.col {
  flex-direction:                 column;
  > div:not(.flex-center) {
    display:                      flex;
    flex-direction:               column;
    align-items:                  flex-start;
    gap:                          26px;
    width:                        70%;
    max-width:                    340px;
    padding-bottom:               2rem;
    padding-top:                  1rem;
  }
  h1 {
    color:                        var(--Brand-Primary, #1B294A);
    font-size:                    38px;
    font-weight:                  300;
    line-height:                  normal;
  }
  p {
    color:                        var(--Brand-Primary, #1B294A);
    font-size:                    var(--font-size-14);
    font-weight:                  300;
    line-height:                  140%; /* 19.6px */
  }
}

.btn {
  width:                          100%;
}
</style>

<script>
import { useExternalStore } from '@/store/external'

export default {
  name: 'ExternalTerms',
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    acceptanceTerm: null,
    wasAccepted: false,
    session: {
      patient: {},
    },
  }),
  mounted() {
    console.log('oi terms?');
    this.reloadSessionInfo()
  },
  methods: {
    close() {
      // this.$router.push("/external/exam");
    },
    async doAccept() {
      let params = {
        accepted: true
      }
      NProgress.start()
      try {
        let data = await this.$externalService.acceptTerms(params)
        this.session = data

        console.log('session', this.session);

        const externalStore = useExternalStore()
        externalStore.termAccepted(data)
        this.reloadSessionInfo()

        this.$nextTick(() => {
          // this.close()
        });
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    },
    reloadSessionInfo() {
      const externalStore = useExternalStore()
      this.session = externalStore.sessionInfo

      this.acceptanceTerm = this.session.patient.acceptanceTerm // is null when not toggled
      // this.wasAccepted = this.acceptanceTerm ? this.acceptanceTerm.accepted : false
      this.wasAccepted = false
    }
  }
}
</script>
