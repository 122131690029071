<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <path d="M17.8911 3.88574H8.99853C7.34168 3.88574 5.99854 5.22888 5.99854 6.88574V23.1138C5.99854 24.7707 7.34168 26.1138 8.99853 26.1138H17.8911" stroke="#3CB391" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M12.6387 14.9395H22.7465" stroke="#3CB391" stroke-width="1.5"/>
    <path d="M19.5752 10.5146L24.0003 14.9398L19.5752 19.365" stroke="#3CB391" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconLogout'
}
</script>
