<template>
  <div class="general-report" v-loader.opacity="loading">
    <div class="action-buttons rounded text-end">
      <button class="btn btn-outline-primary" type="button" name="button" data-test="btnPrint" @click="doPrint">
        <span>{{ $t('grapesEditor.printPreview') }}</span>
      </button>
    </div>

    <div class="gjs-box">
      <div class="gjs-content" id="gjs-record">
        <ReportClinicHeader v-if="clinic" :clinic="clinic"></ReportClinicHeader>

        <h1>{{ $t('grapesEditor.patient') }}: {{patient.fullName}}</h1>
      </div>
      <div class="gjs-blocks" id="blocks-record"></div>
    </div>

    <teleport to="body">
      <ReportGeneralPrintModal v-if="showModalPrint" @close="closePrintModal"
                          :content_print="contentPrint" :patient_name="patient.fullName"></ReportGeneralPrintModal>
    </teleport>
  </div>
</template>

<style lang="scss"></style>

<script>
import grapesjs from 'grapesjs'
import pt from 'grapesjs/src/i18n/locale/pt';
import en from 'grapesjs/src/i18n/locale/en';
import es from 'grapesjs/src/i18n/locale/es';

import grapesjsPresetNewsletterRecord from 'grapesjs-preset-newsletter'
import { useUserStore } from '@/store/user'
import ReportClinicHeader from '@/views/account/report/report-general/ReportClinicHeader.vue'
import ReportGeneralPrintModal from '@/views/account/report/report-general/ReportGeneralPrintModal.vue'

const IS_PRODUCTION = process.env.NODE_ENV == 'production'
let editor // BEWARE avoid adding proxy stuff into it (browser freezes!)

export default {
  name: 'ReportGeneralSection',
  props: ["medicalRecordData"],
  components: {
    ReportClinicHeader,
    ReportGeneralPrintModal
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    clinic: {},
    userSignature: null,
    patient: {
      fullName: ""
    },
    dataPath: null,
    contentPrint: null,
    showModalPrint: false,
    patientID: null
  }),
  async created() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.patientID = this.$route.params.patientID

    await this.fetchReportInfoData()

    this.initializeGrapeJS()
    editor.BlockManager.getAll().reset();
    this.addBlocks()

    const hasContent = Object.keys(this.medicalRecordData.s8).length > 0
    if (hasContent) {
      editor.loadProjectData(this.medicalRecordData.s8);
    }

    editor.on('change:changesCount', () => {
      this.medicalRecordData.s8 = editor.getProjectData();
    });

    editor.on('asset:open', () => {
        this.fetchData()
    });

    this.$nextTick(() => {
      editor.BlockManager.render();
    })
  },
  methods: {
    async fetchReportInfoData() {
      this.alert.message = null

      let params = {
        id: this.patientID
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$medicalRecordService.createGeneralReport(params);
        this.patient = response.patient
        this.userSignature = response.userSignature

        this.clinic = response.clinic
        this.clinic.clinicLogo = response.clinicLogo
        this.clinic.date = moment(new Date()).format(this.$i18n.t('dateFormat'))
        this.clinic.time = moment(new Date()).format(this.$i18n.t('timeFormat'))
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      // this.loading = false
    },
    async fetchData() {
      this.alert.message = null

      this.loading = true
      NProgress.start()
      try {
        this.clearAssets()

        let response = await this.$medicalRecordService.getPatientAssets(this.patientID)
        console.log("patient assets >>>> ", response)
        const assetList = response.examDataList

        for (let ed of assetList) {
          editor.AssetManager.add({
            uuid: ed.uuid,
            src: ed.url
          })
        }
      } catch (err) {
        console.log(err)
        this.$message.popup(this, 'general.operationError', 'operationFail', 'error')
      }
      NProgress.done(true)
      this.loading = false
    },
    initializeGrapeJS() {
      editor = grapesjs.init({
        container: '#gjs-record',
        fromElement: true,
        height: '600px',
        plugins: [grapesjsPresetNewsletterRecord],
        i18n: {
          locale: 'en', // default locale
          detectLocale: true, // by default, the editor will detect the language
          localeFallback: 'en', // default fallback
          messages: { pt, en, es },
        },
        deviceManager: {
          height: '500px'
        },
        storageManager: false,
        style: `
          body {
            font-family:       'Ubuntu', sans-serif !important;
            color:             black;
          }
          [data-gjs-type="wrapper"] {
            padding:           35px 0;
            border:            1px solid #444;
          }
          [data-gjs-type="wrapper"] .col-3,
          [data-gjs-type="wrapper"] .col-4,
          [data-gjs-type="wrapper"] .col-6,
          [data-gjs-type="wrapper"] .col-12,
          [data-gjs-type="wrapper"] .page-break,
          [data-gjs-type="wrapper"] .gjs-page {
            border:            1px dashed #ddd;
          }
          .row {
            display:           flex;
            flex-wrap:         wrap;
            margin-top:        10px;
          }
          .row & > * {
            flex-shrink:       0;
            max-width:         100%;
          }
          .col-3,
          .col-4,
          .col-6,
          .col-12 {
            padding:           15px 0;
            min-height:        200px;
          }
          .row & > .col-3 {
            width:             25%;
          }
          .row & > .col-4 {
            width:             33.33%;
          }
          .row & > .col-6 {
            width:             50%;
          }
          .row & > .col-12 {
            width:             100%;
          }
          .page-break {
            height:            0;
            width:             100%;
            margin:            0;
            page-break-before: always;
          }
          .col-3 {
            flex:              0 0 auto;
            width:             25%;
            height:            100%;
          }
          .col-4 {
            flex:              0 0 auto;
            width:             33.33%;
            height:            100%;
          }
          .col-6 {
            flex:              0 0 auto;
            width:             50%;
            height:            100%;
          }
          .col-12 {
            flex:              0 0 auto;
            width:             100%;
            height:            100%;
          }
          img {
            max-width:         95%;
            display:           block;
            height:            auto;
            margin-left:       auto;
            margin-right:      auto;
          }
          .col-3 img,
          .col-4 img,
          .col-6 img,
          .col-12 img {
            -o-object-fit:     contain;
            object-fit:        contain;
          }
          p, h1 {
            color:             black;
          }
          .clinic-header {
            padding:           15px;
          }
          .clinic-header img {
            width:             180px;
            display:           inline-block;
          }
          .clinic-header .clinic-header-content {
            width:             calc(100% - 185px);
            display:           inline-block;
            padding-left:      10px;
            vertical-align:    top;
          }
          .clinic-header .clinic-header-content p {
            margin:            0 0 5px;
          }
          .clinic-header .clinic-header-content .content-data {
            float:             right;
          }
          .clinic-header .clinic-header-content .content-data p {
            display:           inline-block;
            margin:            0 10px;
          }
          .signature {
            width:             300px;
            float:             right;
          }
          .signature img {
            width:             100%;
          }
          .signature p {
            text-align:        center;
            margin:            0;
          }
        `,
        // Avoid any default panel
        panels: { defaults: [] },
        blockManager: {
          appendTo: '#blocks-record',
        },
        assetManager: {
          assets: [],
        },
        styleManager: {
          appendTo: '#blocks-record',
          sectors: []
        },
      })
    },
    addBlocks() {
      let signature = this.userSignature;
      let userName = `${this.session.collaborator.firstName} ${this.session.collaborator.lastName}`
      let _self = this

      editor.BlockManager.add('column', {
        label: `<b>${_self.$i18n.t('grapesEditor.column')}</b>`,
        media: `<div class="gjs-fonts gjs-f-b1"></div>`,
        content: `
          <div class="row" data-gjs-droppable=".col-12" data-gjs-custom-name="Row">
            <div class="col-12" data-gjs-type="column-type"></div>
          </div>
        `,
        activate: true,
      });
      editor.BlockManager.add('twoColumns', {
        label: `<b>${_self.$i18n.t('grapesEditor.twoColumns')}</b>`,
        media: `<div class="gjs-fonts gjs-f-b2"></div>`,
        content: `
          <div class="row" data-gjs-droppable=".col-6" data-gjs-custom-name="Row">
            <div class="col-6" data-gjs-type="column-type"></div>
            <div class="col-6" data-gjs-type="column-type"></div>
          </div>
        `,
        activate: true,
      });
      editor.BlockManager.add('threeColumns', {
        label: `<b>${_self.$i18n.t('grapesEditor.threeColumns')}</b>`,
        media: `<div class="gjs-fonts gjs-f-b3"></div>`,
        content: `
          <div class="row" data-gjs-droppable=".col-4" data-gjs-custom-name="Row">
            <div class="col-4" data-gjs-type="column-type"></div>
            <div class="col-4" data-gjs-type="column-type"></div>
            <div class="col-4" data-gjs-type="column-type"></div>
          </div>
        `,
        activate: true,
      });
      editor.BlockManager.add('fourColumns', {
        label: `<b>${_self.$i18n.t('grapesEditor.fourColumns')}</b>`,
        media: `<div class="custom-icon"><i class="icon-four-columns"></i></div>`,
        content: `
          <div class="row" data-gjs-droppable=".col-3" data-gjs-custom-name="Row">
            <div class="col-3" data-gjs-type="column-type"></div>
            <div class="col-3" data-gjs-type="column-type"></div>
            <div class="col-3" data-gjs-type="column-type"></div>
            <div class="col-3" data-gjs-type="column-type"></div>
          </div>
        `,
        activate: true,
      });
      editor.BlockManager.add('text', {
        label: `<b>${_self.$i18n.t('grapesEditor.text')}</b>`,
        media: `<div class="gjs-fonts gjs-f-text"></div>`,
        content: '<div data-gjs-type="text">Insert your text here</div>',
        activate: true,
      });
      editor.BlockManager.add('text-sect', {
        label: `<b>${_self.$i18n.t('grapesEditor.textSection')}</b>`,
        media: `<div class="gjs-fonts gjs-f-h1p"></div>`,
        content: '<h1 class="heading">Insert title here</h1><p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>',
        activate: true,
      });
      editor.BlockManager.add('image', {
        label: `<b>${_self.$i18n.t('grapesEditor.image')}</b>`,
        media: `<div class="gjs-fonts gjs-f-image"></div>`,
        content: { type: 'image' },
        activate: true,
      });
      editor.BlockManager.add('signature', {
        label: `<b>${_self.$i18n.t('grapesEditor.signature')}</b>`,
        media: `<div class="custom-icon mt-1"><i class="icon-signature"></i></div>`,
        content: `
          <div class="signature">
            <img id="signature" src="${signature}" alt="" width="180">
            <p>____________________________________</p>
            <p>${userName}</p>
          </div>
        `,
        activate: true,
      });
      editor.BlockManager.add('page-break', {
        label: `<b>${_self.$i18n.t('grapesEditor.pageBreak')}</b>`,
        media: `<svg viewBox="0 0 24 24">
          <path d="M 20,18 V 15 H 4 v 3 H 2 v -5 h 20 v 5 M 20,9 H 4 V 6 H 2 v 5 H 22 V 6 h -2 z"></path>
        </svg>`,
        content: '<div class="page-break"></div>',
        activate: true,
      });
    },
    doPrint() {
      this.contentPrint = editor.runCommand('gjs-get-inlined-html');
      this.showModalPrint = true;

      setTimeout(function() {
        let modal_print_report = document.getElementById('modal_print_report');
        new bootstrap.Modal(modal_print_report).show();
      }, 100);
    },
    closePrintModal() {
      this.contentPrint = null
      this.showModalPrint = false
    },
    clearAssets() {
      const assetManager = editor.AssetManager
      let allAssets = assetManager.getAll()
      const keys = allAssets.models.map(x => x.attributes.src)
      for (let k of keys) {
        assetManager.remove(k)
      }
    }
  },
  unmounted() {
    editor.destroy();
  }
}
</script>
