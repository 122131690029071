<template>
  <div class="patients-main">
    <div class="sub-header-box">
      <button class="btn-back" type="button" name="button" data-test="btnBack" @click="$router.back()">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>

      <nav style="--bs-breadcrumb-divider: '';" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/patient/" data-test="btnRouter">
              {{ $t('home') }}
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ $t('patient.patient') }}</li>
        </ol>
      </nav>
    </div>

    <div class="title-box">
      <div class="flex-start">
        <h2 class="title">{{ $t('patient.newPatient') }}</h2>
      </div>
    </div>

    <BaseTab :tabs="tabs" :initialtab="tabActive" @tab-activated="switchTab">
      <template v-slot:tab_head_data>
        {{ $t('patient.data') }}
      </template>
      <template v-slot:data>
        <PatientCreateTab v-if="tabActive == 'data'"></PatientCreateTab>
      </template>
    </BaseTab>

    <!-- <div class="action-bar">
      <div id="patientCreateActionBar"></div>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.sub-header-box {
  display:                           flex;
  flex-direction:                    row;
  justify-content:                   flex-start;
  align-items:                       center;
  gap:                               20px;
  width:                             100%;
  padding:                           15px 20px;

  ol {
    margin:                          0;
  }
}

.title-box {
  display:                            flex;
  flex-direction:                     row;
  align-items:                        center;
  justify-content:                    space-between;
  height:                             55px;
  padding:                            10px 25px;
  // margin:                             2.3rem 0 1.7rem;

  > div {
    width:                            auto !important;
  }
}
</style>

<script>
import PatientCreateTab from './PatientCreateTab.vue'

export default {
  name: 'patient-create',
  components: {
    PatientCreateTab,
  },
  data: () => ({
    tabActive: 'data',
    tabs: ['data'],
    isHeaderMounted: false,
  }),
  mounted() {
    this.isHeaderMounted = true
  },
  methods: {
    switchTab(tab) {
      this.tabActive = tab;
    },
    goBack() {
      this.$router.push({name: 'patient-list'})
    },
  }
}
</script>
