<template>
  <div class="base-square-checkbox" :id="checkbox_id" :class="{'checked': checked}" data-test="BaseSquareCheckbox" @click.stop.prevent="doCheck"></div>
</template>

<style scoped lang="scss">
.base-square-checkbox {
  @include                         border_radius(5px);
  position:                        relative;
  width:                           25px;
  height:                          25px;
  padding:                         10px 12px;
  // margin-top:                      0.25em;
  vertical-align:                  top;
  background:                      var(--Shades-W, #FFF);
  background-repeat:               no-repeat;
  background-position:             center;
  background-size:                 contain;
  border:                          1px solid var(--Shades-30, #D8D8D8);
  -webkit-appearance:              none;
  -moz-appearance:                 none;
  appearance:                      none;
  -webkit-print-color-adjust:      exact;
  color-adjust:                    exact;
  &.checked {
    background-color:              var(--Shades-W, #FFF);
    &:after {
      @include                     rotate(45deg);
      content:                     "";
      position:                    absolute;
      left:                        7px;
      top:                         3px;
      width:                       9px;
      height:                      14px;
      border:                      solid var(--Shades-30, #D8D8D8);
      border-width:                0 2px 2px 0;
    }
  }
}
</style>

<script>
export default {
  name: 'BaseSquareCheckbox',
  props: ['item', 'checkbox_id'],
  data: () => ({
    checked: false,
  }),
  methods: {
    doCheck() {
      this.checked = !this.checked

      let item = {
        value: this.item,
        checked: this.checked
      }

      this.$emit('checked', item)
    }
  }
}
</script>
