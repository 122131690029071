<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path d="M0.123047 16.5884H14.2814" stroke="#3CB391" stroke-width="2"/>
    <path d="M0.123047 9.95654H4.84249" stroke="#3CB391" stroke-width="2"/>
    <path d="M9.56104 9.95654H18.9999" stroke="#3CB391" stroke-width="2"/>
    <path d="M6.72998 12.3159L6.72998 7.59648" stroke="#3CB391" stroke-width="2"/>
    <path d="M16.1704 5.99072L16.1704 0.327397" stroke="#3CB391" stroke-width="2"/>
    <path d="M17.1143 18.9897L17.1143 13.3264" stroke="#3CB391" stroke-width="2"/>
    <path d="M0.123047 3.59082H13.3375" stroke="#3CB391" stroke-width="2"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconFilter'
}
</script>
