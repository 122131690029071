<template>
  <div class="create-clinic-box" v-loader.opacity.tab="loading">
    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <vee-form v-slot="{ handleSubmit }" as="div">
      <form @submit.prevent.stop="handleSubmit(doSave)" @keydown.enter="$event.preventDefault()">

        <PatientFormData v-if="patient != null"
          :patient="patient"
          :rightDiopter="rightDiopter"
          :leftDiopter="leftDiopter"
          :underlying="underlying"
          :ophthalmic="ophthalmic"
          :mandatoryDocument="mandatoryDocument"
          :underlyingChange="underlyingChange"
          :ophthalmicChange="ophthalmicChange">
        </PatientFormData>

        <div class="action-bar">
          <button class="btn btn-success" type="submit" name="button" data-test="btnSave" v-if="hasFormChanged">
            <span>{{ $t('save') }}</span>
          </button>
          <button class="btn btn-secondary" type="button" name="button" data-test="btnCancel" @click="$router.back()">
            <span>{{ $t("cancel") }}</span>
          </button>
        </div>
      </form>
    </vee-form>

  </div>
</template>

<style lang="scss" scoped>
.action-bar {
  display:                         flex;
  justify-content:                 flex-start;
  align-items:                     center;
  gap:                             10px;
  height:                          64px;
  padding:                         10px 20px;
  border-top:                      1px solid var(--Shades-30);
}

.btn {
  @include                         border_radius(99px);
  font-size:                       var(--font-size-12);
  font-weight:                     400;
  letter-spacing:                  3.64px;
  text-transform:                  uppercase;
}
.btn-success {
  color:                           var(--Shades-W);
  background:                      var(--Brand-Secondary);
  border:                          1px solid var(--Brand-Secondary);
}

.btn-secondary {
  color:                           var(--Brand-Primary);
  background:                      var(--Shades-10);
  border:                          unset;
  // border:                          1px solid var(--Shades-10);
}

.btn-secondary,
.btn-success {
  font-weight:                     700;
  height:                          44px;
  padding:                         15px 20px;
}
</style>

<script>
import PatientFormData from '../PatientFormData.vue'

export default {
  name: 'PatientCreateTab',
  components: {
    PatientFormData
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    patient: {
      id: null,
      firstName: '',
      lastName: '',
      mrn: '',
      birthday: '',
      birthdayAux: '',
      document0: '',
      document1: '',
      document2: '',
      document3: '',
      mothersName: '',
      gender: 'M',
      weight: '',
      height: '',
      telephone1: '',
      telephone2: '',
      email: '',
      address: '',
      addressNumber: '',
      district: '',
      postalCode: '',
      city: '',
      state: '',
      country: '',
      other: '',
    },
    rightDiopter: {
      spherical: '',
      cylindrical: '',
      axis: '',
    },
    leftDiopter: {
      spherical: '',
      cylindrical: '',
      axis: '',
    },
    underlying: {
      diabetes: false,
      hypertension: false,
      cholesterol: false,
      smoker: false,
      nwn_underlying: false,
    },
    ophthalmic: {
      diabeticRetinopathy: false,
      dmri: false,
      glaucoma: false,
      cataract: false,
      pterygium: false,
      lowVisualAcuity: false,
      nwn_ophthalmic: false,
    },
    mandatoryDocument: null,
    hasFormChanged: false,
    initialForm: {},
    changedFormItems: [],
    underlyingChange: true,
    ophthalmicChange: true,
    isDataReady: false
  }),
  watch: {
    patient: {
      immediate: true,
      handler: function(newValue) {
        this.checkFormChange(newValue, 'patient')
        // if (newValue) {
        // }
    }, deep: true},
    rightDiopter: {
      immediate: true,
      handler: function(newRightDiopter) {
        console.log('newRightDiopter', newRightDiopter);
        console.log('this.rightDiopter', this.rightDiopter);
        this.checkFormChange(newRightDiopter, 'rightDiopter')
    }, deep: true},
    leftDiopter: {
      immediate: true,
      handler: function(newLeftDiopter) {
        this.checkFormChange(newLeftDiopter, 'leftDiopter')
    }, deep: true},
    underlying: {
      immediate: true,
      handler: function(newUnderlying) {
        this.checkFormChange(newUnderlying, 'underlying')
    }, deep: true},
    ophthalmic: {
      immediate: true,
      handler: function(newOphthalmic) {
        this.checkFormChange(newOphthalmic, 'ophthalmic')
    }, deep: true},
  },
  mounted() {
    const formWrap = {
      patient: this.patient,
      rightDiopter: this.rightDiopter,
      leftDiopter: this.leftDiopter,
      underlying: this.underlying,
      ophthalmic: this.ophthalmic
    }

    this.initialForm = _.cloneDeep(formWrap)

    this.hasFormChanged = false
    this.changedFormItems = []

    this.isDataReady = true
  },
  methods: {
    checkFormChange(value, obj) {
      let hasObj = this.changedFormItems.includes(obj)
      if (JSON.stringify(value) === JSON.stringify(this.initialForm[obj])) {
        if (hasObj) {
          let index = this.changedFormItems.indexOf(obj)
          this.changedFormItems.splice(index, 1)
        }

        if (this.changedFormItems.length > 0)
          return

        this.hasFormChanged = false
      } else {
        if (!hasObj)
          this.changedFormItems.push(obj)

        this.hasFormChanged = true
      }
    },
    async doSave() {
      try {
        this.alert.message = null;

        // TODO FIX check empty mandatory document on clinic configuration (match Eyer attribute)
        const mandatoryDocumentWasSet = !_.isEmpty(this.mandatoryDocument) && this.mandatoryDocument != 'none'
        if (mandatoryDocumentWasSet) {
          if (_.isEmpty(this.patient[this.mandatoryDocument]))
            return
        }

        this.patient.birthday = this.patient.birthdayAux

        let anamnesis = {}
        Object.assign(anamnesis, this.underlying)
        Object.assign(anamnesis, this.ophthalmic)

        let params = {
          patient: this.patient,
          anamnesis: anamnesis,
          rightDiopter: this.rightDiopter,
          leftDiopter: this.leftDiopter,
        }

        this.loading = true
        NProgress.start()
        let response = await this.$patientService.save(params)
        this.$message.popup(this, 'general.createdSuccess', 'operationSuccess', 'success')

        let data = this.$utils.dataQuery({
          patientName: response.result.rawName
        })

        this.$router.push({name: 'patient-show', params: {patientID: response.result.id}, query: {tab: 'data', data: data}})
      } catch(err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
  }
}
</script>
