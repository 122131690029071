<template>
  <div class="">
    <div class="date-box exam-filter">
      <div class="form-floating mb-3">
        <date-picker
          id="startDate"
          :placeholder="$t('exam.from')"
          v-model="startDate"
          :locale="locale"
          value-type="date"
          :selectText="$t('select')"
          :cancelText="$t('cancel')"
          :format="$t('dateFormatFns')"
          :inputFormat="$t('dateFormatFns')"
          :enableTimePicker="false"
          :disabled-date="disabledDate"
          data-test="datePickerBaseFilterStart">
        </date-picker>
        <label for="startDate">{{ $t('exam.from') }}</label>
      </div>
    </div>

    <div class="date-box">
      <div class="form-floating">
        <date-picker
          id="endDate"
          :placeholder="$t('exam.to')"
          v-model="endDate"
          :locale="locale"
          value-type="date"
          :selectText="$t('select')"
          :cancelText="$t('cancel')"
          :format="$t('dateFormatFns')"
          :inputFormat="$t('dateFormatFns')"
          :enableTimePicker="false"
          :disabled-date="disabledDate"
          data-test="datePickerBaseFilterEnd">
        </date-picker>
        <label for="endDate">{{ $t('exam.to') }}</label>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .date-box {
    position:                        relative;
    max-width:                       191px;
    margin:                          0;

    &:last-child {
    }
  }

  // @media (min-width: 400px) {
  //   .date-box {
  //     &:first-child:not(:last-child) {
  //       margin-right:                5px;
  //     }
  //   }
  // }
  //
  // @media (max-width: 767px) {
  //   .date-box {
  //     max-width:                     130px !important;
  //     margin:                        0 5px;
  //   }
  // }
</style>

<script>
import { useUserStore } from '@/store/user'
import { enUS, ptBR, es } from 'date-fns/locale' // BEWARE this loads the datepicker translations

export default {
  name: 'BaseDateFilter',
  props: ['query_dates'],
  data: () => ({
    startDate:  null,
    endDate: null,
    session: {
      collaborator: {},
      currentClinic: {}
    },
  }),
  computed: {
    locale() {
      return this.$utils.datePickerLocale()
    }
  },
  watch: {
    startDate: function (value) {
      this.setDate()
    },
    endDate: function (value) {
      this.setDate()
    },
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.startDate = moment().subtract(3, 'month').toDate()
    this.endDate = moment().toDate()

    this.setDate()

    if (this.$route.query.data) {
      let data = this.$utils.parseDataQuery(this.$route.query.data)

      this.startDate = moment(data.startDate).toDate()
      this.endDate = moment(data.endDate).toDate()
    }
  },
  methods: {
    disabledDate(date) {
      const today = moment();
      return date > today
    },
    setDate() {
      let filterDate = {
        startDate: this.$utils.formatDate(this, this.startDate),
        endDate: this.$utils.formatDate(this, this.endDate)
      };
      this.$emit('change-date', filterDate);
    }
  },
  destroyed() {
    let currentDate = new Date();
    this.startDate = this.endDate.setMonth(this.endDate.getMonth() - 3);
  }
}
</script>
