import { useNotificationStore } from '@/store/notification'
import { Howl } from 'howler';
import eyerCloud from '@/config/eyercloud'

import bellSound from "@/assets/sounds/bell.mp3";
import phelcom_notification from "@/assets/images/phelcom/phelcom_notification.png";
import aiService from './ai-service';

const TAG = "[notification-service]"
const MAX_EVENT_COUNT = 10
const MAX_NOTIFICATION_TIME = 60 // minutes
class NotificationService {

  constructor() {
  }

  enable() {
    let isSafari = this.checkBrowser()

    console.log(`${TAG} enable...`)
    if (!("Notification" in window)) {
      console.log(`${TAG} this browser do not supports desktop notifications.`);
      return
    }

    if (Notification.permission !== 'denied' && !isSafari) {
      Notification.requestPermission().then(_ => {
        // zzz
      }).catch(err => {
        console.log('Error while requesting notification permission.')
      })
    }
  }

  createNewNotification(self, event) { // event was "data"
    console.log("createNewNotification()", event)

    const store = useNotificationStore()
    // store.createNewNotification(data)
    let notificationData = store.getNotificationData(event.clinic.id) // BEWARE, was global
    console.log("NOTIFICATION DATA >>> >> >", notificationData)

    if (notificationData.events.length >= MAX_EVENT_COUNT) {
      console.log("too much notifications found... some shall be removed!")
      const theEvent = notificationData.events.shift()
      store.updateNotificationData(notificationData, event.clinic.id)

      // TODO VIEW remove first entry
      //     let eventKey = `event_${theEvent.uuid}`
      //     // console.log("removing Event from list...", eventKey)
      //     $("#" + eventKey).remove();
    }

    event.scoreResult = aiService.alterationScore(event.exam.metadata)
    // console.log('eventttttt >>>>>>>>> ', event.scoreResult);

    // TODO DATA
    notificationData.count += 1
    notificationData.events.push(event)
    store.updateNotificationData(notificationData, event.clinic.id)

    self.emitter.emit("newNotification", event);
    this.showNativeNotification(self)
    this.playNotificationSound()
  }

  showNativeNotification(self) {
    if (Notification.permission !== 'granted')
      return

    let titleMessage = `${self.$i18n.t('clinicConfig.notificationNewExam')} (${moment().format('HH:mm:ss')})`
    $.titleAlert(titleMessage, {
      requireBlur: true,
      stopOnFocus: true,
      // duration:4000,
      // interval:700
    });

    new Notification(`${self.$i18n.t('exam.newExam')} (${moment().format('HH:mm:ss')})`, {
      icon: phelcom_notification,
      body: self.$i18n.t('clinicConfig.newExamAvailable'),
    });
  }

  reloadNotifications(clinicID) {
    const store = useNotificationStore()
    let notificationData = store.getNotificationData(clinicID)

    let lastEvents = []
    for (let e of notificationData.events) {
      let timeDiff = this.getTimeDiff(e.date)
      if (timeDiff > MAX_NOTIFICATION_TIME)
        continue

      lastEvents.push(e)
    }

    // update the event list using only events in time range (last 1h)
    notificationData.events = lastEvents
    if (lastEvents.length == 0) {
      notificationData.count = 0
    }
    store.updateNotificationData(notificationData, clinicID)

    return notificationData
  }

  resetCount(clinicID) {
    console.log("resetCount >>>", clinicID)
    const store = useNotificationStore()
    let notificationData = store.getNotificationData(clinicID)
    notificationData.count = 0
    store.updateNotificationData(notificationData, clinicID)
  }

  getTimeDiff(date) {
    let now = moment()
    let eventDate = moment(date)
    return now.diff(eventDate, 'minutes')
  }

  playNotificationSound() {
    let nSound = new Howl({
      src: [bellSound]
    });

    nSound.play()
  }

  checkBrowser() {
    let navigationUserAgent = navigator.userAgent

    let safariAgent = navigationUserAgent.indexOf("Safari") > -1;
    if ((safariAgent))
      return true
  }

  async addFakeNotifications() {
    let response = await eyerCloud.post('/api/v2/eyercloud/clinic/notification/fake')
    console.log(response, ' fakeeeeeeeeeeeeeeeee ');
    return response.data
  }
}

const notificationService = new NotificationService();
export default notificationService;
