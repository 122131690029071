<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M4.5 5.87732H16.5063V16.4628C16.5063 17.8435 15.3871 18.9628 14.0063 18.9628H7C5.61929 18.9628 4.5 17.8435 4.5 16.4628V5.87732Z" stroke="#EE4930"/>
    <path d="M7.49219 8.06006V15.8744" stroke="#EE4930" stroke-linecap="round"/>
    <path d="M10.4941 8.06006V15.8744" stroke="#EE4930" stroke-linecap="round"/>
    <path d="M13.478 8.06006V15.8744" stroke="#EE4930" stroke-linecap="round"/>
    <rect x="2.46582" y="3.08472" width="16.085" height="2.78809" rx="0.5" stroke="#EE4930"/>
    <rect x="8.50928" y="1.06238" width="3.96945" height="1.979" rx="0.5" stroke="#EE4930"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconTrash'
}
</script>
