<template>
  <div class="">
    <div class="row">
      <div class="col-lg-6">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="serial" v-model="serialNumber" :placeholder="Serial" data-test="inputSerialNumber" disabled>
          <label for="serial">Serial</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeviceDataTab',
  props: ['serial'],
  data: () => ({
    disabled: true,
    serialNumber: ""
  }),
  created() {
    this.serialNumber = this.serial
  }
}
</script>
