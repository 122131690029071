<template>
  <div class="report-box" v-loader.opacity="loading">
    <!-- <div class="sub-header-box">
      <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>

      <nav style="--bs-breadcrumb-divider: '';" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/patient/" data-test="btnRouter">
              {{ $t('home') }}
            </router-link>
          </li>
          <li class="breadcrumb-item" aria-current="page" v-if="patient">{{ patient.fullName }}</li>
          <li class="breadcrumb-item active" aria-current="page" v-if="exam">{{ `${$t('user.exam')} ${formatDate(exam.createdAt)}` }}</li>
        </ol>
      </nav>
    </div> -->

    <!-- <div class="title-box clear">
      <h2 class="title">
        <span v-if="currentReport.id">{{ $t('exam.reportEdition') }}</span>
        <span v-else>{{ $t('report.newReport') }}</span>
      </h2>
    </div> -->

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <div class="diagnosis-box">
      <div class="content-images">
        <h2>
          <span>1</span>

          {{ $t('report.selectCaptures') }}
        </h2>

        <div class="flex-start">
          <input type="radio" class="btn-check" name="options-outlined" id="select-all" autocomplete="off" data-test="radioSelectAll">
          <label class="btn btn-outline-secondary" for="select-all" @click="selectAll('check')">{{ $t('exam.all') }}</label>

          <input type="radio" class="btn-check" name="options-outlined" id="select-none" autocomplete="off" data-test="radioSelectNone">
          <label class="btn btn-outline-secondary" for="select-none" @click="selectAll">{{ $t('exam.none') }}</label>
        </div>

        <div class="alert" :class="alertImages.class" role="alert" v-if="alertImages.message">
          <span v-html="alertImages.message"></span>
          <button type="button" class="btn-close" aria-label="Close" @click="alertImages.message = null"></button>
        </div>

        <div class="diagnosis-gallery scrollbar" v-else>
          <lightgallery-vue class="ease-all" ref="lGalleryDiagnosis" id="lGalleryDiagnosis" :settings="lGalleryDiagnosisSettings" :onInit="onInit" :onAfterOpen="extraCustomization">
             <template v-for="(item, index) in examImages" :key="index">
               <a v-show="!item.type.endsWith('VIDEO')" class="thumbnail-box loading-eye" onclick="return false" :href="dataPath +'/'+ item.name" :data-test="`anchorImage_${index}`">
                 <img class="lg-selector ease-all" alt="" :src="thumbDataPath +'/'+ item.name" @load="removeLoading($event)"/>
                 <div class="checkbox">
                   <BaseRoundedCheckBox :id="item.uuid" :item="item" @checked="doCheck"/>
                 </div>
               </a>
             </template>
          </lightgallery-vue>
        </div>
      </div>

      <div class="content-diagnosis">
        <h2>
          <span>2</span>

          {{ $t('report.reportInformation') }}
        </h2>

        <form class="scrollbar" id="formCreateDiagnosis">
          <div>
            <h3 class="mb-0">{{ $t('report.captures') }}</h3>
          </div>

          <div class="form-floating v-select-floating">
            <v-select
              class="v-select form"
              id="selectColumns"
              v-model="currentReport.columns"
              :reduce="x => x.value"
              :options="columnOptions"
              :placeholder="'Número de colunas de capturas'"
              data-test="vSelectColumns">
            </v-select>
            <label for="selectColumns">{{ $t('report.columnNumber') }}</label>
          </div>

          <div class="form-floating">
            <div class="form-check form-switch">
              <label class="form-check-label" for="fullWidthPanoramic">{{ $t('exam.panoProcessOnReport') }}</label>
              <input class="form-check-input" type="checkbox" role="switch" id="fullWidthPanoramic" v-model="currentReport.fullWidthPanoramic" data-test="checkboxFullWidthPanoramic">
            </div>
          </div>

          <div>
            <h3>{{ $t('report.model') }}</h3>
            <p>{{ $t('report.modelChoose') }}</p>
          </div>

          <ul class="nav nav-tabs">
            <li class="nav-item" role="presentation">
              <button class="nav-link" :class="{'active': tabActive == 'quickReport'}" id="quickReport-tab" data-bs-toggle="tab" data-bs-target="#quickReport"
                      type="button" role="tab" aria-controls="quickReport" aria-selected="false" @click="tabActive = 'quickReport'">{{ $t('report.simplified') }}</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" :class="{'active': tabActive == 'completeReport'}" id="completeReport-tab" data-bs-toggle="tab" data-bs-target="#completeReport"
                      type="button" role="tab" aria-controls="completeReport" aria-selected="false" @click="tabActive = 'completeReport'">{{ $t('report.complete') }}</button>
            </li>
          </ul>

          <div>
            <h3>{{ $t('report.diagnosis') }}</h3>
            <p>{{ $t('report.fillEachEye') }}</p>
          </div>

          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade" :class="{'show active': tabActive == 'quickReport'}" id="quickReport" role="tabpanel" aria-labelledby="profile-tab">
              <ReportQuick v-if="isLoaded"
                :exam="exam"
                :observation="quickReportObservation"
                :loaded_entries="loadedDiagnosisEntries"
                @entries-changed="setQuickReportData">
              </ReportQuick>
            </div>

            <div class="tab-pane fade" :class="{'show active': tabActive == 'completeReport'}" id="completeReport" role="tabpanel" aria-labelledby="home-tab">
              <div class="form-floating">
                <select id="diagnosis_select" class="js-example-basic-multiple js-states form-control" name="diagnosis_select" multiple="multiple"
                        style="width:100%;" :placeholder="$t('exam.diagnosisCID10')" data-test="selectDiagnosis"></select>
                <label for="diagnosis_select">{{ $t('exam.diagnosisCID10') }}</label>
                <div class="selected-diagnosis-box" id="selectedDiagnosis" v-if="showSelectedDiagnosisBox"></div>
              </div>

              <div class="form-floating v-select-floating">
                <v-select
                  class="v-select form"
                  id="selectTemplate"
                  v-model="selectedTemplate"
                  label="name"
                  :reduce="x => x.id"
                  :options="templateOptions"
                  @option:selected="switchTemplate"
                  :placeholder="'Template'"
                  data-test="vSelectTemplate">
                </v-select>
                <label for="selectTemplate">Template</label>
              </div>

              <ReportForm v-if="isLoaded" ref="reportForm"
                :content="customTemplate"
                @custom-template="setCustomTemplate">
              </ReportForm>

              <!-- <div class="form-floating">
                <textarea class="form-control" id="observation" name="name" rows="3" v-model="currentReport.observation"
                          :placeholder="$t('exam.observations')" data-test="textareaObservations"></textarea>
                <label for="observation">{{ $t('exam.observations') }}</label>
              </div> -->
            </div>
          </div>

          <div class="form-floating">
            <textarea class="form-control" id="observation" name="name" rows="3" v-model="currentReport.observation"
                      :placeholder="$t('exam.observations')" data-test="textareaObservations"></textarea>
            <label for="observation">{{ $t('exam.observations') }}</label>
          </div>
        </form>
      </div>
    </div>

    <!-- <div class="action-bar"> -->
    <div class="action-bar">
      <button class="btn btn-success" type="button" name="button" data-test="btnSave" @click="doCreate">{{ $t(currentReport.id ? 'updateAction' : 'create') }}</button>
      <button class="btn btn-outline-secondary" type="button" name="button" data-test="btnDownloadPdf" v-if="currentReport.id" @click="doDownloadPdf">{{ $t('Download') }}</button>
      <button class="btn btn-secondary" type="button" name="button" data-test="btnBack" @click="goBack">{{ $t('cancel') }}</button>
    </div>
    <!-- </div> -->

  </div>
</template>

<style src="./report.scss" lang="scss" scoped></style>
<script src="./report.js"></script>
