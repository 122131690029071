<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <circle cx="7.96269" cy="8.77567" r="6.96269" stroke="#3CB391" stroke-width="2"/>
    <path d="M13.27 13.1958L18.5785 18.5043" stroke="#3CB391" stroke-width="2"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconSearchFilter'
}
</script>
