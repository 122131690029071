<template>
  <div class="">
    <h3 class="mb-3">{{ $t('createAccount.registerPassword') }}</h3>
    <p class="mb-3">{{ $t('resetPassword.instructionsMessagePassword')}}</p>

    <div class="form-floating mb-3">
      <vee-field name="password" v-model="account.password" rules="required|min:4" v-slot="{ field, errorMessage }">
        <input v-bind="field" :type="showPassword ? 'text' : 'password'" data-test="inputAccountPasssword"
            new-password class="form-control" ref="password" id="password" :placeholder="$t('user.password')" @keyup="checkPassword(account.password)">

        <button class="btn-icon" type="button" name="button" data-test="btnShowPassword" @click="showPassword = !showPassword">
          <IconShowPassword/>
        </button>
        <small class="strength-password" :class="strengthInfo" v-show="strengthInfo">{{ strengthInfo ? $t(`strengthPassword.${strengthInfo}`) : "" }}</small>

        <span class="vee-error">{{ errorMessage ? errorMessage : $t(`${strengthPasswordMessage}`) }}</span>
      </vee-field>
      <label for="password">{{ $t('user.password') }}</label>
    </div>
    <div class="form-floating mb-3">
      <vee-field name="confirmPassword" v-model="account.confirmPassword" rules="required|confirmed:password" v-slot="{ field, errorMessage }">
        <input v-bind="field" :type="showConfirmPassword ? 'text' : 'password'" data-test="inputAccountConfirmPassword"
               class="form-control" ref="confirmPassword" id="confirmPassword" :placeholder="$t('user.passwordConfirmation')">
        <button class="btn-icon" type="button" name="button" data-test="btnShowConfirmPassword" @click="showConfirmPassword = !showConfirmPassword">
          <IconShowPassword/>
        </button>
        <span class="vee-error">{{ errorMessage }}</span>
      </vee-field>
      <label for="confirmPassword">{{ $t('user.passwordConfirmation') }}</label>
    </div>

    <p class="mb-3">{{ $t('createAccount.setPin') }}</p>

    <div class="form-floating mb-3">
      <vee-field name="pin" v-model="account.pin" rules="required|min:6" v-slot="{ field, errorMessage }">
        <input v-bind="field" type="text" class="form-control" ref="pin" id="pin" v-only-numbers :placeholder="$t('user.devicePIN')" data-test="inputAccountPin">
        <span class="vee-error">{{ errorMessage }}</span>
      </vee-field>
      <label for="pin">{{ $t('user.devicePIN') }}</label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-control {
  position:                     relative;
}

.btn-icon {
  right:                        12px;
}

.strength-password {
  position:                     absolute;
  color:                        white;
  font-size:                    0.7em;
  text-align:                   center;
  height:                       12px;
  width:                        100%;
  top:                          -10px;
  &.danger {
    background:                 $phelcom-red;
  }
  &.warning {
    background:                 $yellow-phelcom;
  }
  &.success {
    background:                 $phelcom-green;
  }
}

.vee-error {
  width:                        100% !important;
}
</style>

<script>
import utilService from '../../../../services/util-service.js';

export default {
  name: 'RegistrationUserFormStepTwo',
  props: ['account', 'email'],
  data: () => ({
    showPassword: false,
    showConfirmPassword: false,
    password: "",
    confirmPassword: "",
    strengthPasswordMessage: 'zxcvbnMessages.default',
    strengthInfo: null,
  }),
  mounted(){
     this.disableCopyPaste();
  },
  created() {
    this.$nextTick(() => {
      this.$refs.password.focus()
    })
  },
  methods: {
    async checkPassword(password) {
      this.account.password = password
      this.account.email = this.email
      let params = {
        account: this.account
      }

      let isValidPassword = await utilService.validatePassword(params.account)

      switch (isValidPassword.score) {
        case 0:
        case 1:
        case 2:
          this.strengthInfo = 'danger'
          break;
        case 3:
          this.strengthInfo = 'warning'
          break;
        case 4:
          this.strengthInfo = 'success'
          break;
      }

      if (isValidPassword.valid) {
        this.strengthPasswordMessage = 'zxcvbnMessages.default'
      } else {
        this.strengthPasswordMessage = isValidPassword.warning ? 'zxcvbnMessages.warnings.' + isValidPassword.warning :
                                                            'zxcvbnMessages.suggestions.' + isValidPassword.suggestion[0]
      }
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShowConfirm() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    disableCopyPaste() {
      let inputArray = [this.$refs.password, this.$refs.confirmPassword, this.$refs.pin]
      inputArray.forEach((element) => {
        element.addEventListener('contextmenu', (e) => e.preventDefault()); // Disable right-click menu
        element.addEventListener('cut', (e) => e.preventDefault()); // Disable cut
        element.addEventListener('copy', (e) => e.preventDefault()); // Disable copy
        element.addEventListener('paste', (e) => e.preventDefault()); // Disable paste
      })
    },
  }
}
</script>
