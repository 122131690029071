<template>
  <div class="">
    <div class="row justify-content-md-start">
      <div class="col-md-4">
        <ul class="anchor-menu">
          <li :class="{'active': activeAnchor === 'general'}">
            <a href="#general" @click="setActiveAnchor('general')">{{ $t('patient.patientInfo') }}</a>
          </li>
          <li :class="{'active': activeAnchor === 'underlying'}">
            <a href="#underlying" @click="setActiveAnchor('underlying')">{{ $t('patient.underlyingDisease') }}</a>
          </li>
          <li :class="{'active': activeAnchor === 'diopter'}">
            <a href="#diopter" @click="setActiveAnchor('diopter')">{{ $t('patient.otherInformation') }}</a>
          </li>
          <!-- <li :class="{'active': activeAnchor === 'ophthalmic'}">
            <a href="#ophthalmic" @click="setActiveAnchor('ophthalmic')">{{ $t('patient.ophthalmicDiseases') }}</a>
          </li>
          <li :class="{'active': activeAnchor === 'diopter'}">
            <a href="#diopter" @click="setActiveAnchor('diopter')">{{ $t('patient.diopter') }}</a>
          </li>
          <li :class="{'active': activeAnchor === 'localization'}">
            <a href="#localization" @click="setActiveAnchor('localization')">{{ $t('patient.localization') }}</a>
          </li> -->
        </ul>
      </div>

      <div class="col-md-8 offset-md-4 scrollbar" id="sectionsBox">
        <section id="general">
          <h3 class="sub-title">{{ $t('patient.patientInfo') }}</h3>
          <div class="form-floating">
            <vee-field name="firstName" v-model="patient.firstName" rules="required" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="text" class="form-control" id="firstName" :placeholder="$t('patient.firstName')" data-test="inputPatientFirstName">
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="firstName">{{ $t('patient.firstName') }}*</label>
          </div>
          <div class="form-floating">
            <vee-field name="lastName" v-model="patient.lastName" rules="required" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="text" class="form-control" id="lastName" :placeholder="$t('patient.lastName')" data-test="inputPatientLastName">
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="lastName">{{ $t('patient.lastName') }}*</label>
          </div>
          <div class="form-floating">
            <vee-field name="birthday" v-model="patient.birthdayAux" :rules="`date:${$t('dateFormat')}`" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="text" class="form-control"  v-maska :data-maska="patient.birthdayAux ? $t('inputMask.date') : ''" :placeholder="$t('patient.birthday')" data-test="inputPatientBirthday">
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="birthday">{{ $t('patient.birthday') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="telephone1" v-model="patient.telephone1" :placeholder="$t('patient.telephone1')" data-test="inputPatientPhone1">
            <label for="telephone1">{{ $t('patient.telephone1') }}</label>
          </div>
          <div class="form-floating">
            <vee-field name="email" v-model="patient.email" rules="email" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="text" class="form-control" ref="email" id="email" :placeholder="$t('createClinic.email')" data-test="inputPatientEmail">
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="email">{{ $t('createClinic.email') }}</label>
          </div>
          <div class="form-floating">
            <vee-field name="document2" v-model="patient.document2" :rules="`${mandatoryDocument === 'document2' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="text" class="form-control" id="document1" v-maska :data-maska="$t('inputMask.document2')" :placeholder="$t('document2')" data-test="inputPatientDocument2">
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="document2">
              {{ $t('document2') }}
              <span v-if="mandatoryDocument === 'document2'">*</span>
            </label>
          </div>
          <div class="form-floating">
            <vee-field name="mrn" v-model="patient.mrn" :rules="`${mandatoryDocument === 'mrn' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="text" class="form-control" id="mrn" :placeholder="$t('mrn')" data-test="inputPatientMrn">
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="mrn">
              {{ $t('mrn') }}
              <span v-if="mandatoryDocument === 'mrn'">*</span>
            </label>
          </div>
        </section>

        <section id="underlying" v-if="underlyingChange">
          <PatientCreateUnderlyingDiseases :underlying="underlying"/>
        </section>

        <section id="ophthalmic" v-if="ophthalmicChange">
          <PatientCreateOphthalmicDiseases :ophthalmic="ophthalmic"/>
        </section>

        <section id="diopter">
          <div class="row">
            <!-- TODO componente -> dioptria: map{foo: bar, foo1: bar1} -->
            <div class="col-lg-6">
              <h3 class="sub-title">{{ $t('patient.rightEyeDiopter') }}</h3>
              <div class="form-floating">
                <input type="text" class="form-control" id="right_spherical" v-maska maska-diopter v-model="rightDiopter.spherical" :placeholder="$t('spherical')" data-test="inputPatientRightDiopterSpherical">
                <label for="right_spherical">{{ $t('spherical') }}</label>
              </div>
              <div class="form-floating">
                <input type="text" class="form-control" id="right_cylindrical" v-maska maska-diopter v-model="rightDiopter.cylindrical" :placeholder="$t('cylindrical')" data-test="inputPatientRightDiopterCylindrical">
                <label for="right_cylindrical">{{ $t('cylindrical') }}</label>
              </div>
              <div class="form-floating">
                <input type="text" class="form-control" id="right_axis" v-maska :data-maska="$t('inputMask.axis')" v-model="rightDiopter.axis" :placeholder="$t('axis')" data-test="inputPatientRightDiopterAxis">
                <label for="right_axis">{{ $t('axis') }}</label>
              </div>
            </div>

            <div class="col-lg-6">
              <h3 class="sub-title">{{ $t('patient.leftEyeDiopter') }}</h3>
              <div class="form-floating">
                <input type="text" class="form-control" id="left_spherical" v-maska maska-diopter v-model="leftDiopter.spherical" :placeholder="$t('spherical')" data-test="inputPatientLeftDiopterSpherical">
                <label for="left_spherical">{{ $t('spherical') }}</label>
              </div>
              <div class="form-floating">
                <input type="text" class="form-control" id="left_cylindrical" v-maska maska-diopter v-model="leftDiopter.cylindrical" :placeholder="$t('cylindrical')" data-test="inputPatientLeftDiopterCylindrical">
                <label for="left_cylindrical">{{ $t('cylindrical') }}</label>
              </div>
              <div class="form-floating">
                <input type="text" class="form-control" id="left_axis" v-maska :data-maska="$t('inputMask.axis')" v-model="leftDiopter.axis" :placeholder="$t('axis')" data-test="inputPatientLeftDiopterAxis">
                <label for="left_axis">{{ $t('axis') }}</label>
              </div>
            </div>
          </div>
        </section>

        <section id="localization">
          <h3 class="sub-title">{{ $t('patient.localization') }}</h3>
          <div class="form-floating">
            <input type="text" class="form-control" id="address" v-model="patient.address" :placeholder="$t('patient.address')" data-test="inputPatientAddress">
            <label for="address">{{ $t('patient.address') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="addressNumber" v-model="patient.addressNumber" :placeholder="$t('patient.addressNumber')" data-test="inputPatientAddressNumber">
            <label for="addressNumber">{{ $t('patient.addressNumber') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="district" v-model="patient.district" :placeholder="$t('patient.district')" data-test="inputPatientDistrict">
            <label for="district">{{ $t('patient.district') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="postalCode" v-model="patient.postalCode" :placeholder="$t('patient.postalCode')" data-test="inputPatientPostalCode">
            <label for="postalCode">{{ $t('patient.postalCode') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="city" v-model="patient.city" :placeholder="$t('patient.city')" data-test="inputPatientCity">
            <label for="city">{{ $t('patient.city') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="state" v-model="patient.state" :placeholder="$t('patient.state')" data-test="inputPatientState">
            <label for="state">{{ $t('patient.state') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="country" v-model="patient.country" :placeholder="$t('patient.country')" data-test="inputPatientCountry">
            <label for="country">{{ $t('patient.country') }}</label>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sub-title {
  color:                         var(--Shades-60, #727272);
  font-size:                     16px;
  font-weight:                   500;
  line-height:                   normal;
  letter-spacing:                3.2px;
  text-transform:                uppercase;
  text-overflow:                 ellipsis;
  white-space:                   nowrap;
  overflow:                      hidden;
  border:                        0;
  margin:                        0;
}

.anchor-menu {
  display:                       flex;
  flex-direction:                column;
  align-items:                   flex-start;
  gap:                           20px;
  padding:                       0;
  li {
    display:                     flex;
    width:                       auto;
    a {
      @include                   border_radius(99px);
      color:                     var(--Shades-60, #727272);
      font-size:                 var(--font-size-14);
      font-weight:               500;
      line-height:               normal;
      letter-spacing:            2.8px;
      text-decoration:           none;
      text-transform:            uppercase;
      text-overflow:             ellipsis;
      overflow:                  hidden;
      padding:                   10px 18px;
      background:                var(--Shades-10, #FAFAFA);
      border:                    1px solid var(--Shades-30, #D8D8D8);
    }

    &:target,
    &:hover,
    &:focus,
    &:active,
    &.active {
      a {
        color:                   var(--Support-Success, #81BAA7);
        border:                  1px solid var(--Support-Success, #81BAA7);
      }
    }
  }
}

.col-md-4 {
  position:                      fixed;
  display:                       flex;
  justify-content:               flex-end;
}

.col-lg-6 {
  padding-bottom:                33px;
}

.col-md-8 section,
.col-lg-6 {
  display:                       flex;
  gap:                           19px;
  flex-direction:                column;
}

.col-md-8 {
  overflow-y:                    auto;
  height:                        100%;
  max-height:                    calc(100vh - 324px);
  // max-height:                    calc(100vh - 165px);
  // max-height:                    calc(100vh - 217px);

  section {
    max-width:                   550px;
    width:                       100%;

    &:last-child {
      padding-bottom:            4rem;
    }
  }
}

.action-buttons {
  min-height:                  39px;
}

.vee-error {
  width:                       100% !important; 
}

@media screen and (min-width: 992px) and (max-width: 1050px) {
  .sub-title {
    font-size:                 1em;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .panel-content .col-lg-6 .col-lg-6 .form-group label {
    width:                     100px;
  }
  .panel-content .col-lg-6 .col-lg-6 .form-group textarea,
  .panel-content .col-lg-6 .col-lg-6 .form-group input[type="text"] {
    width:                     calc(100% - 103px) !important;
  }
  .form-check-inline {
    width:                     100px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .panel-content .form-group label {
    width:                     110px;
  }
  .panel-content .form-group textarea,
  .panel-content .form-group input[type="text"],
  .vee-error {
    width:                     calc(100% - 110px) !important;
  }
}

@media (min-width: 1601px) {
  .vee-error {
    width:                     calc(100% - 190px) !important;
  }
}

@media (max-width: 1500px) {
  .col-md-8 {
    max-height:                calc(100vh - 318px);
  }
}
</style>

<script>
  import { useUserStore } from '@/store/user'
  import {MaskInput} from 'maska'
  import PatientCreateOphthalmicDiseases from './PatientCreateOphthalmicDiseases.vue'
  import PatientCreateUnderlyingDiseases from './PatientCreateUnderlyingDiseases.vue'

  export default {
    name: 'PatientFormData',
    props: ['patient',
            'rightDiopter',
            'leftDiopter',
            'underlying',
            'ophthalmic',
            'disabled',
            'underlyingChange',
            'ophthalmicChange'
          ],
    components: {
      PatientCreateOphthalmicDiseases,
      PatientCreateUnderlyingDiseases,
    },
    data: () => ({
      mandatoryDocument: null,
      session: {
        collaborator: {},
        currentClinic: {}
      },
      activeAnchor: 'general'
      // disabled: false,
    }),
    mounted() {
      const userStore = useUserStore()
      this.session = userStore.sessionInfo

      this.mandatoryDocument = this.session.currentClinic.mandatoryDocument

      new MaskInput("[maska-diopter]", {
        mask: (val) => {
          if (val.includes('-')) {
            return val.length == 4 ? `-${this.$i18n.t('inputMask.shortDiopter')}` : `-${this.$i18n.t('inputMask.diopter')}`
          } else {
            return val.length == 3 ? this.$i18n.t('inputMask.shortDiopter') : this.$i18n.t('inputMask.diopter')
          }
          // return val.includes('-') ? `-${this.$i18n.t('inputMask.diopter')}` : this.$i18n.t('inputMask.diopter')
        }
      })

      this.$nextTick(() => {
        const divElement = document.getElementById('sectionsBox')
        divElement.addEventListener('scroll', this.updateActiveAnchor);
        this.updateActiveAnchor(); // Initialize the active section
      })

    },
    beforeUnmount() {
      const divElement = document.getElementById('sectionsBox')
      divElement.removeEventListener('scroll', this.updateActiveAnchor);
    },
    methods: {
      setActiveAnchor(anchor) {
        this.activeAnchor = anchor;
      },
      updateActiveAnchor() {
        const sections = ['general', 'underlying', 'ophthalmic'];
        const divElement = document.getElementById('sectionsBox')
        const divHeight = divElement.offsetHeight
        let currentActive;

        for (const section of sections) {
          // console.log('section', section);
          const element = document.getElementById(section);
          if (!element) continue;

          const rect = element.getBoundingClientRect();
          // console.log('rect.top <= divHeight / 2 && rect.bottom >= divHeight / 2', rect.top <= divHeight / 2 && rect.bottom >= divHeight / 2);
          if (rect.top <= divHeight / 2 && rect.bottom >= divHeight / 2) {
            currentActive = section;
            break;
          }
        }

        if (currentActive && currentActive !== this.activeAnchor) {
          this.activeAnchor = currentActive;
        }
      }
    }
  }
</script>
