<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <rect x="9.29688" y="8.94922" width="12.5512" height="12.5512" rx="4.5" stroke="#3CB391"/>
    <path d="M14.9219 12.3037H19.9064" stroke="#3CB391" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.9219 15.2246H19.9064" stroke="#3CB391" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.9219 18.1454H19.9064" stroke="#3CB391" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="6.78019" cy="7.28214" r="2.95988" stroke="#1B294A"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconExams'
}
</script>
