<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path d="M6.5 12.5L6.5 1.5" stroke="#1B294A" stroke-width="2"/>
    <path d="M1.5 6.5L6.5 1.5L11.5 6.5" stroke="#1B294A" stroke-width="2"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconArrowUp'
}
</script>
