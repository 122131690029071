import eyerCloud from '@/config/eyercloud'

const DEFAULT_SYSTEM_CONFIGURATION = {
  preAnalysis: {
    threshold: 0.3,
    nextScreen: "examList", // quickReport
    autoReferrerMode: null, // "auto",
    alertMode: "thresholdClosedExam" // closedExam
  },
  notifications:[
  ],
  security: {}
}

class ClinicService {

  async getList() {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/list`)
    return response.data
  }

  async getClinic(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/get`, params)
    let clinic = response.data.clinic
    if (!clinic.systemConfiguration) {
      clinic.systemConfiguration = {
        preAnalysis: {},
        notifications: [],
        security: {}
      }
    }

    return response.data
  }

  // TODO use 'switch' instead
  async changeClinic(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/change`, params)
    // TODO do it here this.$authService.sessionChanged(params)

    return response.data
  }

  async create() {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/create`)
    return response.data
  }

  async saveClinic(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/save`, params)
    return response.data
  }

  async updateClinic(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/update`, params)
    return response.data
  }

  async uploadLogo(params) {
    let formData = new FormData()
    formData.append('id', params.id)
    formData.append('data',  params.logo) // binary data ALWAYS at the end

    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/update-logo`, formData)
    return response.data
  }

  async listTemplates(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/template/list`, params)
    return response.data
  }

  async getTemplate(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/template/get`, params)
    return response.data
  }

  async saveTemplate(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/template/save`, params)
    return response.data
  }

  async deleteNotification(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/notification/remove`, params)
    return response.data
  }

  async saveConfiguration(session, params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/configuration/save`, params)
    session.currentClinic.systemConfiguration = response.data.result

    return response.data
  }

  getSystemConfiguration(clinic) {
    return clinic.systemConfiguration ? clinic.systemConfiguration : DEFAULT_SYSTEM_CONFIGURATION
  }

  getSecurityConfiguration(clinic) {
    let systemConfiguration = this.getSystemConfiguration(clinic)
    let security = systemConfiguration.security ? systemConfiguration.security : {}
    return security
  }

  async getClinicUsage(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/usage/get`, params)
    console.log('response getClinicUsage <><><><>', response);
    return response.data
  }

  async uploadPatientCsvFile(params) {
    let formData = new FormData()
    const theFile = params.file
    formData.append("params", JSON.stringify(params))
    formData.append("data", theFile)
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/patient/upload`, formData)
    return response.data
  }

  async validateCsvFile(params) {
    console.log("params: ", params)
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/patient/validate`, params)
    return response.data
  }

  async importPatientsToClinic(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/patient/import`, params)
    return response.data
  }

  async canStartNewImport(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/patient/batch-status`, params)
    return response.data
  }

  async generateClinicReportsByDate(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/clinic/usage/report`, params)
    return response.data
  }

}
const clinicService = new ClinicService();
export default clinicService;
