<template>
  <div class="medical-record-tabs">
    <div class="form-group">
      <label for="">{{ $t("medicalRecord.s7") }}</label>
      <BaseQLEditor :content="medicalRecordData.s7.e1" data-test="qlEditorMedicalRecordS7_e1" @content-changed="setTextForS7E1"></BaseQLEditor>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'DocumentsCertificatesSection',
  props: ["medicalRecordData"],
  mounted() {
  },
  methods: {
    setTextForS7E1(value) {
      this.medicalRecordData.s7.e1 = value
    }
  }
}
</script>
