<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="22" viewBox="0 0 13 22" fill="none">
    <path d="M1.13379 1L11.1338 11L1.13379 21" stroke="#F0F0F0" stroke-width="2"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconArrowRight'
}
</script>
