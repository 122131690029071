<template>
  <div class="flex">
    <h3 class="sub-title">{{ $t('patient.underlyingDisease') }}</h3>
    <div class="form-check disabled">
      <BaseSquareCheckBox id="diabetes" :item="'diabetes'" @checked="doCheck"/>
      <label class="form-check-label" for="diabetes">
        {{ $t('disease.diabetesMellitus') }}
      </label>
    </div>
    <div class="form-check disabled">
      <BaseSquareCheckBox id="hypertension" :item="'hypertension'" @checked="doCheck"/>
      <label class="form-check-label" for="hypertension">
        {{ $t('disease.hypertension') }}
      </label>
    </div>
    <div class="form-check disabled">
      <BaseSquareCheckBox id="cholesterol" :item="'cholesterol'" @checked="doCheck"/>
      <label class="form-check-label" for="cholesterol">
        {{ $t('disease.highCholesterol') }}
      </label>
    </div>
    <div class="form-check disabled">
      <BaseSquareCheckBox id="smoker" :item="'smoker'" @checked="doCheck"/>
      <label class="form-check-label" for="smoker">
        {{ $t('disease.smoker') }}
      </label>
    </div>
    <div class="form-check disabled">
      <BaseSquareCheckBox id="nwn_underlying" :item="'nwn_underlying'" @checked="doCheck"/>
      <label class="form-check-label" for="nwn_underlying">
        {{ $t('disease.nwn_ophthalmic') }}
      </label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .flex {
    display:                 flex;
    flex-direction:          column;
    align-items:             flex-start;
    gap:                     19px;
    padding-top:             33px;
  }

  .sub-title {
    color:                   var(--Shades-60, #727272);
    font-size:               16px;
    font-weight:             500;
    line-height:             normal;
    letter-spacing:          3.2px;
    text-transform:          uppercase;
    text-overflow:           ellipsis;
    white-space:             nowrap;
    overflow:                hidden;
    border:                  0;
    margin:                  0;
  }
</style>

<script>
export default {
  name: 'ExamInfoUnderlyingDiseases',
  props: ['underlying'],
  data: () => ({
  }),
  mounted() {
    this.$nextTick(() => {
      this.updateCheck()
    })
  },
  methods: {
    updateCheck() {
      for (const [key, value] of Object.entries(this.underlying)) {
        if (value) {
          document.getElementById(key).click()
        }
      }
    },
    doCheck(item) {
      for (const [key, value] of Object.entries(this.underlying)) {
        if (key == item.value) {
          this.underlying[key] = item.checked
        }
      }
    }
  }
}
</script>
