<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path d="M4.95312 12.6635L11.1664 16.7907M11.1664 16.7907L17.3798 12.6635M11.1664 16.7907V5.0752" stroke="#1B294A"/>
    <rect x="2.5" y="2.5" width="17.3333" height="17.3333" rx="1.5" stroke="#1B294A"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconDownload'
}
</script>
