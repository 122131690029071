<template>
   <!-- data-bs-backdrop="static" -->
  <!-- <div class="offcanvas offcanvas-end" :class="offcanvasStateClass" -->
  <div class="sidenav" :class="offcanvasStateClass"
       data-bs-backdrop="static" tabindex="0" id="offCanvasNewExam" aria-labelledby="offCanvasNewExamLabel">
    <div class="offcanvas-header">
      <button class="btn-back" id="closeBtn" type="button" name="button" data-test="btnCloseOffcanvas"
              data-bs-dismiss="offcanvas" aria-label="Close" @click="close">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" id="offCanvasNewExamLabel">{{ $t('exam.addExam') }}</h5>
    </div>
    <div class="offcanvas-body">
      <p>{{ $t('exam.addExamInfo') }}</p>

      <p class="patient-selected" v-if="patient.fullName">{{ patient.fullName }}</p>

      <button class="btn btn-dashed" type="button" name="button" @click="openOffcanvasAddPatient" v-else>{{ $t('exam.addPatient') }}</button>

      <div class="date-box">
        <div class="form-floating">
          <date-picker
            menuClassName="dp-date-time"
            v-model="exam.date"
            :placeholder="$t('exam.dateAndTime')"
            :locale="locale"
            value-type="date"
            :selectText="$t('createClinic.select')"
            :cancelText="$t('cancel')"
            minimumView="time"
            :format="$t('dateTimeFormatFns')"
            :inputFormat="$t('dateTimeFormatFns')"
            :min-date="new Date()"
            data-test="datePickerModalNewExam">
          </date-picker>
          <label for="examDate">{{ $t('exam.dateAndTime') }}</label>
        </div>
      </div>

      <!-- footer inside body to avoid empty space between body and footer -->
      <div class="offcanvas-footer">
        <button type="button" class="btn btn-success" data-test="btnSave" @click="doSave">{{ $t('exam.addExam') }}</button>
      </div>
    </div>

    <teleport to="body">
      <div v-if="offcanvasStateClass" class="offcanvas-backdrop fade show"></div>
      <ExamPatientAddOffcanvas v-if="isPatientOpen" ref="sidenav" @offcanvas-patient="closePatient"/>
      <!-- <ExamPatientAddOffcanvas v-if="offcanvasStateClass" ref="sidenav" @close-patient="offcanvasStateClass = false"/> -->
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
// .offcanvas {
.sidenav {
  color:                                var(--bs-offcanvas-color);
  position:                             absolute;
  display:                              flex;
  height:                               0;
  flex-direction:                       column;
  width:                                0 !important;
  padding:                              0;
  background-color:                     white;
  background-clip:                      padding-box;
  z-index:                              1050;
  visibility:                           hidden;
  transform:                            translateX(100%);
  transition:                           transform 0.3s ease-in-out;
  background-clip: padding-box;
  outline: 0;
  max-width: 100%;

  * {
    display:                            none;
  }

  .offcanvas-header {
    display:                            none !important;
  }

  &.open {
    @include                            transition(transform 0.3s ease-in-out);
    visibility:                         visible;
    height:                             100vh;
    padding:                            40px;
    width:                              510px !important;
    border-left:                        var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    right:                              0;
    bottom:                             0;
    top:                                0;
    pointer-events:                     auto;
    transform:                          none;

    * {
      display:                          flex;
    }

    .offcanvas-header,
    .date-box * {
      width:                            100%;
      display:                          block !important;
    }
  }


  &.increase-width {
    width:                              618px !important;
    visibility:                         visible;
    transform:                          none;
    pointer-events:                     none;
    z-index:                            100;
  }

  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    p:not(.patient-selected) {
      color:                            var(--Brand-Primary, #1B294A);
      font-size:                        var(--font-size-14);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      margin-bottom:                    0;
    }

    .patient-selected {
      @include                          border_radius(99px);
      color:                            var(--Brand-Primary, #1B294A);
      text-align:                       center;
      font-size:                        18px;
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   0.9px;
      display:                          flex;
      justify-content:                  center;
      align-items:                      center;
      height:                           55px;
      margin-bottom:                    0;
      padding:                          20px;
      border:                           2px solid var(--Brand-Primary, #1B294A);
      background:                       var(--Shades-10, #FAFAFA);
    }
  }

  .btn {
    font-size:                          var(--font-size-14);
    font-weight:                        700;
    line-height:                        normal;
    letter-spacing:                     4.2px;
    text-align:                         center;
    text-transform:                     uppercase;
    width:                              100%;
    margin:                             0;
    padding:                            20px;
  }

  .offcanvas-footer {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    .btn-success {
      color:                            var(--Shades-W, #FFF);
    }
  }
}

.date-box {
  width: 100%;
  max-width:                            unset;
  margin:                               0;
}
</style>

<script>
import { useUserStore } from '@/store/user'
import { enUS, ptBR, es } from 'date-fns/locale'
import ExamPatientAddOffcanvas from './ExamPatientAddOffcanvas.vue'

export default {
  name: 'ExamNewOffcanvas',
  // props: ['patient_id', 'patient_name'],
  components: {
    ExamPatientAddOffcanvas
  },
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    exam: {
      date: new Date(),
      mydriasis: false
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    offcanvasStateClass: '',
    examNewOffcanvas: null,
    patient: {},
    isPatientOpen: false,
  }),
  computed: {
    locale() {
      return this.$utils.datePickerLocale()
    }
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.examNewOffcanvas = new bootstrap.Offcanvas(offCanvasNewExam)
  },
  methods: {
    show() {
      this.offcanvasStateClass = 'open'
      // this.offcanvasStateClass = 'open ease-width'
    },
    openOffcanvasAddPatient() {
      this.isPatientOpen = true
      this.offcanvasStateClass = 'increase-width open'
      // this.offcanvasStateClass = 'increase-width open ease-width'

      this.$nextTick(() => {
        this.$refs.sidenav.openPatient()
      })
    },
    close() {
      this.offcanvasStateClass = ''
      this.patient = {}

      this.$emit('close')
    },
    closePatient(patient) {
      this.isPatientOpen = false
      this.offcanvasStateClass = 'open'
      // this.offcanvasStateClass = 'open ease-width'

      if (patient) {
        this.patient = patient
      }

      // setTimeout(function() {
      //   this.offcanvasStateClass = 'open ease-out'
      //   console.log('entrou?', this.offcanvasStateClass);
      // }, 100);
    },
    doSave: async function() {
      let params = {
        id: this.patient.id,
        exam: this.exam
      }

      NProgress.start()
      try {
        let response = await this.$patientService.saveExam(params)
        this.$message.popup(this, 'general.createdSuccess', 'operationSuccess', 'success')

        this.$nextTick(() => {
          this.close()
          // $('#closeBtn').click();
        });
      } catch (err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
      }
      NProgress.done(true)
    },
  },
  unmounted() {
    this.patient = {}
    // this.examNewOffcanvas = null
  }
}
</script>
