<template>
  <div class="main-box exam-list-box" v-loader.opacity="loading">
    <div class="exam-list-header">
      <div class="">
        <div class="title-box clear">
          <h2 class="title">{{ $t("exam.examsList") }}</h2>

          <button class="btn btn-outline-secondary" type="button" name="button" data-test="btnAddNewExam" @click="openOffCanvasNewExam">
            <IconPlus/>
            Criar exame
          </button>
        </div>

        <!-- <div class="filter-box flex-start">
          <label for="">{{ $t('filters') }}</label>

          <BasePatientFilter/>

          <button class="btn btn-outline-secondary" type="button" name="button">
            {{ $t('exam.type') }}
          </button>

          <div class="btn-group">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dateFilter" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
              {{ $t('exam.date') }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dateFilter">
              <BaseDateFilterNew class="d-inline-block" @change-date="setDates"/>
            </div>
          </div>

          <button class="btn btn-outline-secondary" type="button" name="button">
            <IconFavorite/>
          </button>
        </div> -->
      </div>

      <div class="">
        <div class="btn-group" role="group" aria-label="Action button group">
          <input type="radio" class="btn-check" name="btn-radio" id="btnList" autocomplete="off" @click="showTable = 'list'">
          <label class="btn btn-outline-secondary" for="btnList">
            <IconList/>
            lista
          </label>

          <input type="radio" class="btn-check" name="btn-radio" id="btnGrid" autocomplete="off" checked @click="showTable = 'grid'">
          <label class="btn btn-outline-secondary" for="btnGrid">
            <IconGrid/>
            grid
          </label>
        </div>
      </div>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <div ref="tableBox" id="tableBox">
      <ExamTableList :table_exam="tableExam" :thumb_path="thumbDataPath" v-if="tableExam && showTable == 'list'" @item-opened="examSelected" @loadmore="fetchMore()"/>

      <ExamGridList :table_exam="tableExam" :thumb_path="thumbDataPath" :data_path="dataPath" v-if="tableExam && showTable == 'grid'" @item-opened="examSelected" @loadmore="fetchMore()"/>
    </div>

    <button class="btn-icon" type="button" name="button" v-if="currentPage > 1 && tableExam.items.length > 20" @click="goTop">
      <IconArrowUp/>
    </button>

    <teleport to="body">
      <ExamDownloadModal v-if="showModalDownload" @close="showModalDownload = false"></ExamDownloadModal>
      <ExamNewOffcanvas ref="examNewOffcanvas"/>
      <!-- <ExamCreateNewModal v-if="showOffcanvasNewExam" :patient_id="null" @close="closeModal"@close="showOffcanvasNewExam = false"></ExamCreateNewModal> -->
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.btn-icon {
  position: fixed;
  padding: 0;
  bottom: 3rem;
  right: 3rem;
  z-index: 9999;
  background: white;
  border: 1px solid var(--Shades-30);
}
.dropdown-toggle {
  &.show {
    z-index:                     3 !important;
  }
  &:after {
    display:                     none;
  }
}

.dropdown-menu {
  @include                       border_radius(25px);
  @include                       box_shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.08));
  // display: inline-flex;
  padding:                       56px 15px 15px 15px;
  flex-direction:                column;
  align-items:                   flex-start;
  gap:                           20px;
  border:                        1px solid var(--Shades-30, #D8D8D8);
  background:                    var(--Shades-W, #FFF);
  z-index:                       1;
  top:                           -50px !important;
  left:                          -10px !important;
}

.exam-list-header {
  display:                       flex;
  flex-direction:                row;
  justify-content:               space-between;
  align-items:                   center;
  // padding-bottom:                1.5rem;
  padding:                       15px 20px;
  border-bottom:                 1px solid var(--Shades-20);

  > div {
    &:first-child {
      display:                   flex;
      align-items:               center;
    }
  }

  .btn-group {
    .btn-outline-secondary:not(.dropdown-toggle) {
      @include                   border_radius(99px 0 0 99px);
      color:                     var(--Shades-70, #404040);
      font-size:                 12px;
      font-weight:               400;
      line-height:               normal;
      letter-spacing:            1.8px;
      text-transform:            uppercase;
      display:                   flex;
      justify-content:           flex-end;
      align-items:               center;
      gap:                       10px;
      margin:                    0;
      padding:                   10px 15px;
      background:                var(--Shades-W, #FFF);
      border:                    1px solid var(--Shades-30, #D8D8D8);

      &:focus,
      &:hover,
      &:active,
      &.active {
        background:              #E3FDF4;
        border:                  1px solid var(--Support-Success, #81BAA7);
      }

      &:last-child {
        @include                 border_radius(0 99px 99px 0);
        border-left:             0;
      }
    }

    .btn-check:checked + .btn-outline-secondary {
      background:                #E3FDF4;
      border:                    1px solid var(--Support-Success, #81BAA7);
    }
  }
}

.title-box {
  display:                       flex;
  justify-content:               center;
  align-items:                   center;
  gap:                           10px;
  width:                         auto;
  margin:                        0;
  padding:                       0;

  .btn-outline-secondary {
    font-weight:                 400;
    letter-spacing:              3.12px;
    gap:                         10px;
    padding:                     10px 15px 10px 10px;
    margin:                      0;
  }
}

.filter-box {
  gap:                           10px;

  > label,
  .btn-outline-secondary {
    color:                       var(--Shades-70, #404040);
  }

  > label {
    font-size:                   12px;
    font-weight:                 400;
    line-height:                 normal;
    letter-spacing:              1.8px;
    text-transform:              uppercase;
  }

  .btn-outline-secondary {
    font-size:                   12px;
    font-weight:                 400;
    line-height:                 normal;
    letter-spacing:              1.8px;
    text-transform:              uppercase;
    padding:                     10px 15px;
    border:                      1px solid var(--Shades-30, #D8D8D8);
    background:                  var(--Shades-W, #FFF);

    &:hover,
    &:focus,
    &:active {
      border:                    1px solid var(--Shades-30, #D8D8D8);
      background:                var(--Shades-W, #FFF);
    }
  }
}

.title {
  color:                         var(--Shades-90, #101010);
  text-overflow:                 ellipsis;
  font-size:                     26px;
  font-weight:                   400;
  line-height:                   normal;
  margin:                        0;
}

.inside-box {
  max-height:                    calc(100vh - 212px);
}

.dragas-box {
  strong {
    font-size:                   73%;
    vertical-align:              middle;
  }
}

.dragas {
  display:                       inline-block;
  span {
    font-size:                   73%;
    color:                       black;
    background-color:            $gray;
    @include                     border_radius(0.25rem 0 0 0.25rem);
    &:first-child {
      border-right:              1px solid white;
    }
    &:last-child {
      @include                   border_radius(0 0.25rem 0.25rem 0);
      border-left:               1px solid white;
    }
    &.red {
      background-color:          $light-coral;
    }
    &.yellow {
      background-color:          $topaz;
    }
    &.green {
      background-color:          $phelcom-green;
    }
  }
  &.os {
    display:                     none;
  }
}

.icon-phelcom-ai.phelcom-dragas {
  color:                         white;
}

.table {
  margin-bottom:                 0;
  > tfoot > tr > td,
  > tfoot > tr > th,
  > thead > tr > td,
  > thead > tr > th,
  > tbody > tr > td,
  > tbody > tr > td .fa,
  > tbody > tr > th {
    vertical-align:              middle;
  }
}

.table > tbody > tr > td {
  height:                        46px !important;
}

.label.label-blue-phelcom {
  margin-right:                  5px;
}

.no-btn {
  padding:                       0;
  margin:                        0 10px 0 0;
  &.favorite {
    position:                    relative;
    z-index:                     0;
    &:after {
      @include                   border_radius(50%);
      @include                   transition(height 0.5s ease);
      content:                   "";
      display:                   block;
      opacity:                   0;
      position:                  absolute;
      z-index:                   -1;
      background:                none;
      box-sizing:                border-box;
      transform:                 scale(0);
      transition-property:       transform,opacity;
      bottom:                    -4.5px;
      left:                      -4.5px;
      right:                     -4.5px;
      top:                       -4.5px;
      width:                     30px;
      height:                    30px;
      background-color:          rgba(32,33,36,0.059);
    }
    .fa-star-o {
      color:                     grey;
    }
    .fa-star {
      @include                   animation(fadein .5s);
      color:                     #e5c04d;
    }
    &:hover,
    &:focus {
      &:after {
        border:                  none;
        box-shadow:              none;
        opacity:                 1;
        transform:               scale(1);
      }
      .fa-star-o {
        @include                 scale(1.2);
        color:                   black;
      }
    }
  }
}

.fa-tint {
  color:                         red;
}

.fa-check-circle-o {
  color:                         green;
}

.m-0 {
  font-size:                     0.9em;
  i {
    vertical-align:              top;
  }
}

@media (max-width: 767px) {
  .vtable-mob p .no-btn {
    margin-top:                  2px;
    vertical-align:              middle;
  }

  div.d-block {
    float:                       unset;
  }
}

@media (max-width: 991px) {
  .title-box {
    &.eyer2 {
      > div {
        > div:not(.input-group.search-group):not(.filter-main-box) {
          position:              fixed;
          bottom:                25px;
          right:                 15px;
          border:                unset;
          z-index:               3;

          .dropdown .btn-icon {
            @include             box_shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.08));
            background:          white;
            width:               45px !important;
            height:              45px !important;

            img, svg {
              width:             25px;
            }
          }

          > .btn {
            display:             none;
          }
        }
      }
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import ExamCreateNewModal from './ExamCreateNewModal.vue'
import ExamDownloadModal from './ExamDownloadModal.vue'
// import ExamFilterNew from './ExamFilterNew.vue'
import ExamGridList from './ExamGridList.vue'
import ExamNewOffcanvas from './ExamNewOffcanvas.vue'
import ExamTableList from './ExamTableList.vue'

const IS_PRODUCTION = process.env.NODE_ENV == 'production'

export default {
  name: 'exam-list',
  components: {
    ExamCreateNewModal,
    ExamDownloadModal,
    ExamGridList,
    ExamNewOffcanvas,
    ExamTableList
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    tableExam: {
      items: [],
      total: 0,
      columns: []
    },
    perPage: 20,
    currentPage: 1,
    totalPages: 0,
    session: null,
    filter: {},
    // customColumns: ["foo", "bar"], ?????
    dragasEyes: {
      os: null,
      od: null
    },
    showInputSearch: false,
    showFilterBox: false,
    showValue: null,
    showModalDownload: false,
    showOffcanvasNewExam: false,
    examImages: [],
    thumbDataPath: null,
    dataPath: null,
    showTable: 'grid',
  }),
  computed: {
    isCollapsed() {
      let element = document.querySelector('body')
      if (element.classList.contains('retract'))
        return true
    }
  },
  mounted() {
    this.$activeLink.value = 'exam'
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.currentPage = this.session.examCurrentPage ? this.session.examCurrentPage : 1;

    // this.currentPage > 1 ? this.doFilter() : this.fetchData()
    this.doFilter()
    // this.currentPage > 1 ? this.onPageChange(this.currentPage) : this.fetchData()
  },
  methods: {
    // onPageChange(page) {
    //   this.currentPage = page;
    //   this.session.examCurrentPage = page;
    //   this.filter.pagination = true
    //   this.filter.newRequest = false
    //   this.doFilter(this.filter);
    // },
    goTop() {
      this.tableExam = {
        items: [],
        total: 0,
        columns: []
      }

      this.$nextTick(() => {
        this.doFilter()
        this.currentPage = 1
      })

    },
    async fetchData() {
      this.alert.message = null;

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$examService.listWithExamdata();
        //let response = await this.$examService.list();
        // console.log('response fetch', response);
        this.loadTableData(response)
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async doFilter(filter) {
      this.alert.message = null;
      let params = {}

      if (filter) {
        this.filter = filter;
        params.startDate = this.filter.startDate
        params.endDate = this.filter.endDate
        params.statusFilter = this.filter.filterSelected

        if (!filter.pagination)
          this.currentPage = this.session.examCurrentPage ? this.session.examCurrentPage : 1;

        if (filter.newRequest) {
          this.session.examCurrentPage = 1
          this.currentPage = 1
        }
      }

      params.page = this.currentPage

      this.loading = true
      NProgress.start()
      try {
        //let response = await this.$examService.filteredList(params);
        let response = await this.$examService.filteredListWithExamdata(params);
        // console.log('response filter', response);
        this.loadTableData(response)
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async fetchMore() {
      this.currentPage++
      this.alert.message = null;
      let params = {}

      if (this.loading)
        return

      if (Object.keys(this.filter).length > 0) {
        params.startDate = this.filter.startDate
        params.endDate = this.filter.endDate
        params.statusFilter = this.filter.filterSelected
      }

      params.page = this.currentPage

      this.loading = true
      NProgress.start()
      try {
        //let response = await this.$examService.filteredList(params);
        let response = await this.$examService.filteredListWithExamdata(params);
        //this.examImages = response.
        response.result.forEach(async (item) => {
          item.status = this.$i18n.t(item.status);
          item.scoreResult = this.$aiService.alterationScore(item.metadata);
          //item.examImages = await this.fetchDataImage(item.id)

          this.$nextTick(() => {
            this.tableExam.items.push(item);
          })
        })
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async fetchDataImage(examID) {
      this.loading = true
      NProgress.start()
      try {

        let response = await this.$examDataService.list({id: examID});

        this.thumbDataPath = `${response.thumbDataPath}`
        this.dataPath = `${response.dataPath}`

        // TODO move to service
        if (!IS_PRODUCTION) {
          this.thumbDataPath = `http://localhost:1337${response.thumbDataPath}`
          this.dataPath = `http://localhost:1337${response.dataPath}`
        }

      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false

      return examImages
    },
    loadTableData(response) {
      this.alert = {}

      response.result.forEach(async (item) => {
        try {
          item.status = this.$i18n.t(item.status);
          item.scoreResult = this.$aiService.alterationScore(item.metadata);
        } catch(error) {
          item.scoreResult = 'error'
        }
      });

      this.thumbDataPath = `${response.thumbDataPath}`
      this.dataPath = `${response.dataPath}`

      // TODO move to service
      if (!IS_PRODUCTION) {
        this.thumbDataPath = `http://localhost:1337${response.thumbDataPath}`
        this.dataPath = `http://localhost:1337${response.dataPath}`
      }

      this.tableExam = {
        'items': response.result,
        'total': response.totalCount,
        'columns': [
          // {key:"favorite", name: '', type: 'custom', value: 'actions', width: '2%'},
          {key: 'name', name: this.$i18n.t('exam.name'), type: 'string', value: 'patient.fullName', width: '20%'},
          {key: 'captures', name: 'Capturas', type: 'custom', value: 'captures', width: '20%'},
          {name: this.$i18n.t('exam.date'), type: 'date', value: 'date', width: '20%'},
          {key: 'content', name: this.$i18n.t('exam.content'), type: 'custom', value: 'actions', width: '20%'},
          {key: 'ai', name: 'Status da AI', type: 'custom', value: 'status', width: '20%'}
        ]
      };

      this.totalPages = Math.ceil(response.totalCount / this.perPage);

      if (response.result.length === 0) {
        this.tableExam = {};
        this.alert = this.$message.alert(this, 'request_notfound', 'warning')
        // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
      }
    },
    examSelected(item) {
      if (!item) {
        return
      }
      let data = null

      if (!_.isEmpty(this.filter)) {
        const startDate = moment(this.filter.startDate, this.$i18n.t('dateFormat'))
        const endDate = moment(this.filter.endDate, this.$i18n.t('dateFormat'))

        data = this.$utils.dataQuery({
          startDate: startDate.format(),
          endDate: endDate.format(),
          filterSelected: this.filter.filterSelected
        })
      }

      this.$router.push({name: 'exam-show', params: {examID: item.id}, query: {tab: 'images', data: data}})
    },
    formatDate(date) {
      return moment(date).valueOf();
    },
    checkExamStatus(value) {
      return value == 'closed' ? 'label-closed' : 'label-open'
    },
    doDownload(exam) {
      this.alert.message = null;
      try {
        NProgress.start()
        this.$examService.download(exam.id)
      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
    },
    useHeader(exam) {
      return this.$aiService.mcRASHeader(exam) != ""
    },
    aiHeader(exam) {
      return this.$aiService.mcRASHeader(exam)
    },
    activeAiClasses(exam) {
      return this.$aiService.mcRASClasses(exam)
    },
    doFavorite(item) {
      item.favorite = true
    },
    doSearch() {
      this.showInputSearch = !this.showInputSearch
      // if (!this.showInputSearch)
      //   this.showInputSearch = true
      // else {
      //   console.log('this.searchValue', this.searchValue)
      // }
    },
    examHasMCRAS(exam) {
      return this.$aiService.hasMCRAS(exam)
    },
    examHasDragas(item){
        let res = false
        try{
            let data = JSON.parse(item.metadata)
            if (data.dragas)
                res = true
        } catch (err){
           // do nothing
        }
        return res
    },
    getDragas(exam) {

      let dragas = this.$aiService.dragasClasses(exam)

      return dragas
    },
    openModalDownload() {
      this.showModalDownload = true

      setTimeout(function() {
        let modaldownload = document.getElementById("modaldownload");
        new bootstrap.Modal(modaldownload).show();
      }, 100);
    },
    openOffCanvasNewExam() {
      this.showOffcanvasNewExam = true

      this.$nextTick(() => {
        this.$refs.examNewOffcanvas.show()
      })
    },
    closeModal() {
      this.showOffcanvasNewExam = false
      this.fetchData()
    },
  },
  // unmounted() {
  //   this.alert.message = null;
  //   this.tableExam = [];
  // }
}
</script>
