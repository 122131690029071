<template>
  <div class="dashboard" v-loader.opacity="loading">
    <div class="title-box eyer2">
      <div class="">
        <button class="btn-back" type="button" name="button" data-test="btnBack" @click="goBack">
          <IconBack/>
        </button>

        <h2 class="title">dashboard</h2>

      </div>
    </div>

    <div class="dashboard-box">
      <div class="text-center">
        <div class="usage-info-box">
          <h4 class="">Uso do seu plano</h4>

          <p>{{ $t('dashboard.usageInfo') }}</p>

          <div class="dashboard-bar progress-box">
            <!-- <h3 class="text-start">{{ clinicUsage.examTotal }} {{ $t('dashboard.examTotalCount') }}</h3> -->
            <div class="">
              <IconEye/>
              <p>imagens</p>
            </div>
            <div class="progress" role="progressbar" aria-label="Basic example" :aria-valuenow="usagePercentage" aria-valuemin="0" aria-valuemax="100">
              <div class="progress-bar" :class="getPercentageClass()" :style="`width: ${usagePercentage}%`"></div>
            </div>
            <p>
              {{ usagePercentage }}% {{ $t('dashboard.imageTotalUsage') }}
              <span>{{ $t('dashboard.imageTotalCount', {imageTotal: clinicUsage.imageTotal, quotaAvailable: isPaidService ? 50000 : 5000}) }}</span>
            </p>
          </div>

          <p>Tenha mais espaço contratando um de nossos planos clicando no botão abaixo para falar com o suporte técnico.</p>

          <a href="https://phelcom.com/pt-br/suporte/" target="_blank" class="btn btn-primary btn-support" data-test="anchorSupport">
            <IconHelp/>
            {{ $t('dashboard.talkSupport') }}
          </a>
        </div>

      </div>

      <div class="statistics-box">
        <div class="">
          <IconPatientsSimple/>
          <p>{{ $t('dashboard.patients') }}</p>
          <h3>{{ clinicUsage.patientTotal }}</h3>
        </div>

        <div class="">
          <IconExamsSimple/>
          <p>{{ $t('dashboard.exams') }}</p>
          <h3>{{ clinicUsage.examTotal }}</h3>
        </div>

        <div class="">
          <IconReports/>
          <p>{{ $t('dashboard.reports') }}</p>
          <h3>{{ clinicUsage.reportTotal }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-support {
  @include                          border_radius(10px);
  color:                            var(--Shades-W, #FFF);
  font-size:                        var(--font-size-14);
  font-weight:                      500;
  letter-spacing:                   2.8px;
  text-transform:                   uppercase;
  display:                          inline-flex;
  align-items:                      center;
  padding:                          10px 20px;
  background:                       var(--Brand-Primary, #1B294A);
  border-color:                     var(--Brand-Primary, #1B294A);

  svg {
    // width:                          24px;
    // height:                         24px;
    margin-right:                   10px;
  }
}

.dashboard-box {
  display:                          flex;
  flex-direction:                   column;
  justify-content:                  center;
  align-items:                      center;
  overflow-y:                       auto;
  overflow-x:                       hidden;
}

h4 {
  font-size:                        var(--font-size-18);
  letter-spacing:                   3.6px;
  text-transform:                   uppercase;
}

h3,
h4,
p {
  color:                            var(--Shades-90, #101010);
  font-weight:                      500;
}

.usage-info-box,
.statistics-box {
  width:                            480px;
}

.usage-info-box {
  h4 {
    margin:                         4rem 0 2rem;
  }

  > p {
    font-weight:                    400;
    font-size:                      var(--font-size-14);
    letter-spacing:                 0.7px;
    &:last-child {
      color:                        var(--Brand-Primary, #1B294A);
      font-size:                    var(--font-size-14); // need create 12
      letter-spacing:               0.6px;
    }
  }
}

.progress-box {
  p {
    text-transform:                 uppercase;
  }

  > p {
    font-size:                      var(--font-size-10); // need create 11
    letter-spacing:                 2.365px;
    margin:                         0;
    span {
      font-weight:                  400;
      letter-spacing:               normal;
      text-transform:               lowercase;
    }
  }

  div {
    &:first-child {
      display:                      flex;
      flex-direction:               row;
      align-items:                  center;
      justify-content:              center;
      p {
        font-size:                  var(--font-size-14); // need create 13
        letter-spacing:             2.795px;
        margin:                     0 0 0 10px !important;
      }
    }
  }

  .progress {
    margin:                         1.6rem 0;
  }
}

.progress-box,
.statistics-box {
  @include                          border_radius(10px);
  display:                          block;
  padding:                          20px;
  margin:                           1.7rem 0 1rem;
  background:                       var(--Shades-W, #FFF);
  border:                           1px solid var(--Shades-30, #D8D8D8);
}

.statistics-box {
  display:                          flex;
  align-items:                      flex-start;
  padding:                          0;
  margin-top:                       3.8rem;

  div {
    display:                        flex;
    padding:                        20px;
    flex-direction:                 column;
    justify-content:                center;
    align-items:                    center;
    gap:                            10px;
    flex:                           1 0 0;

    // img {
    //   width:                        24px;
    //   height:                       24px;
    // }

    p,
    h3 {
      margin:                       0;
    }

    p {
      font-size:                    var(--font-size-14); // need create 13
      font-weight:                  500;
      letter-spacing:               2.795px;
      text-transform:               uppercase;
    }

    h3 {
      font-size:                    var(--font-size-22); // need create 25
      margin-top:                   2px;
    }

    &:nth-child(2) {
      border-left:                  1px solid var(--Shades-30, #D8D8D8);
      border-right:                 1px solid var(--Shades-30, #D8D8D8);
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1600px) {
  .usage-info-box {
    h4 {
      margin-top:                   1rem;
    }
  }
  .statistics-box {
    margin-top:                     2rem;
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'dashboard',
  data: () => ({
    loading: false,
    clinicUsage: {},
    usagePercentage: null,
    session: {
      collaborator: {},
      currentClinic: {}
    },
    isPaidService: false,
    quotaAvailable: 5000,
  }),
  created() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    // this.isPaidService = this.$utils.getIsPaidService()
    // this.quotaAvailable = this.isPaidService ? 50000 : 5000
  },
  mounted() {
    this.$activeLink.value = 'dashboard'
    this.fetchData();
  },
  methods: {
    async fetchData() {
      let params = {
        id: this.session.currentClinic.id
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$dashboardService.getClinicUsage(params);
        this.clinicUsage = response.clinicUsage
        //debug > 80%
        this.clinicUsage.imageTotal = 4001

        this.getPercentageValue()
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true);
      this.loading = false
    },
    getPercentageValue() {
      this.usagePercentage = this.$dashboardService.getPercentageValue(this.clinicUsage.imageTotal, this.isPaidService)
    },
    getPercentageClass() {
      return this.$dashboardService.getPercentageClass(this.usagePercentage)
    }
  },
}
</script>
