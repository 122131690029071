<template>
  <div class="expired-main">
    <img src="@/assets/images/phelcom/eyercloud_logo.svg" alt="Logo EyerCloud" width="214">

    <div class="expired-box">
      <IconExpiredSession/>
      <h1>{{ $t('expiredSession.expiredSession') }}</h1>

      <p>
        Por favor, clique no botão abaixo para realizar o login novamente.
        Essa é uma medida para proteger seus dados e também norma de segurança.
        Para saber mais acesse nossas Políticas de Segurança
      </p>

      <a href="/login" class="btn btn-success" data-test="anchorLogin">{{ $t('login.login') }}</a>
    </div>

    <div class="powered-box">
      <p class="d-inline-block">POWERED BY</p>
      <a href="https://phelcom.com/" class="logo d-inline-block" target="_blank" data-test="anchorPhelcomLink">
        <img src="@/assets/images/phelcom/phelcom_logo_horizontal_blue.svg" alt="">
      </a>
      <p class="d-inline-block">v {{ appVersion }}</p>
    </div>
  </div>
</template>

<style lang="scss">
  .expired-main {
    display:                            flex;
    flex-direction:                     column;
    justify-content:                    space-between;
    align-items:                        center;
    min-height:                         500px;
    width:                              100%;
    padding:                            2rem 0 1rem;
    background:                         unset !important;

    .expired-box {
      @include                          border_radius(10px);
      text-align:                       center;
      display:                          flex;
      flex-direction: column;
      align-items: center;
      gap:                              28px;
      max-width:                        470px;
      padding:                          1rem 0 2rem;

      h1 {
        font-size:                      38px;
        font-weight:                    300;
        line-height:                    normal;
      }

      p {
        font-size:                      var(--font-size-14);
        font-weight:                    400;
        line-height:                    140%; /* 19.6px */
      }

      h1,
      p {
        color:                          var(--Brand-Primary);
        margin:                         0;
      }

      .btn.btn-success {
        color:                          var(--Shades-W, var(--W, #FFF));
        font-size:                      var(--font-size-14);
        font-weight:                    700;
        line-height:                    normal;
        letter-spacing:                 4.2px;
        text-transform:                 uppercase;
        width:                          100%;
        padding:                        20px;;
      }
    }
  }

  .powered-box {
    display:                            flex;
    flex-direction:                     row;
    align-items:                        center;
    gap:                                10px;
    p {
      color:                            var(--Brand-Primary, #1B294A);
      font-size:                        var(--font-size-8);
      font-weight:                      400;
      letter-spacing:                   1.52px;
      margin:                           0;
    }
    .logo {
      color:                            #1B294A;
      text-decoration:                  none;
      width:                            90px;
      // margin:                           0 10px;
      img {
        width:                          100%;
      }
    }
  }

  @media (max-width: 767px) {
    .expired-main {
      .expired-box {
        padding:                         15px;
      }
    }
  }
</style>

<script>
export default {
  name: 'auth-expired-session',
  data: () => ({
    appVersion: process.env.VUE_APP_VERSION,
  })
}
</script>

<!-- TODO userStore.logout! -->
