<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
    <circle cx="16" cy="16.5" r="15.5" fill="white" stroke="#D8D8D8"/>
    <path d="M18.77 20.5L22.77 24.5" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
    <circle cx="13.77" cy="14.5" r="6.25" stroke="black" stroke-width="1.5"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconSearch'
}
</script>
