<template>
  <div class="modal fade terms-modal" id="modal_terms" ref="modal_terms" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="termsModalLabel" aria-hidden="true" @self.click="close()">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" :class="{'d-flex' : accepted}">
          <h5 class="modal-title" id="termsModalLabel"> {{ $t('terms.privacyPolicy') }} {{ $t('terms.and') }} {{ $t('terms.termsOfUse') }}</h5>
          <button id="closeBtn" type="button" class="btn-close btn-close-white" :class="{'d-none' : !accepted}" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
        </div>
        <div class="modal-body">
          <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
            <span v-html="alert.message"></span>
            <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
          </div>

          <p>
            {{ $t('terms.modalTextLine1') }}
            {{ $t('terms.modalTextLine2') }}
            {{ $t('terms.modalTextLine3') }}
          </p>
          <a href="https://phelcom.com/lgpd" target="_blank">{{ $t('terms.knowOur') }}{{ $t('terms.privacyPolicy') }} {{ $t('terms.and') }} {{ $t('terms.termsOfUse') }}.</a>

          <div class="check-box" v-if="!accepted">
            <label>
              <input type="checkbox" id="acceptedTerm" v-model="collaborator.acceptedTerm" data-test="checkboxAcceptedTerm">
              {{ $t('terms.readAndConfirm') }}<b>{{ $t('terms.privacyPolicy') }}</b> {{ $t('terms.and') }} <b>{{ $t('terms.termsOfUse') }}</b> {{ $t('terms.fromEyerCloud') }}
            </label>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-primary" @click="doAccept" :disabled="!collaborator.acceptedTerm" v-if="!accepted" data-test="btnAccept">{{ $t('terms.accept') }}</button>
          <button type="button" class="btn btn-outline-secondary" name="button" data-bs-dismiss="modal" @click="close" v-else data-test="btnClose">{{ $t('terms.close') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.terms-modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        background-color:             $phelcom-light-blue;
        border-bottom:                1px solid $platinum !important;
        &.d-flex {
          display:                    flex !important;
        }
        h5 {
          color:                      white;
          font-size:                  18px;
        }
      }
      .modal-body {
        padding:                      30px 20px 15px;
        a {
          color:                      #052440;
          text-decoration:            underline;
          cursor:                     pointer;
        }
        a,
        b {
          font-weight:                bold;
        }
        .check-box {
          margin-top:                 15px;
          cursor:                     pointer;
          label {
            font-weight:              normal;
          }
          input {
            margin-top:               0;
            margin-right:             5px;
            vertical-align:           text-bottom;
          }
        }
      }
      .modal-footer {
        .btn-outline-secondary {
          border-color:               $gray;
        }
      }
      .modal-header
      .modal-body p {
        font-size:                    14px;
        margin:                       0;
      }
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'AcceptanceTermModal',
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    collaborator: {
      acceptedTerm: false,
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    accepted: false,
  }),
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.accepted = this.session.collaborator.acceptedTerm
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async doAccept() {
      NProgress.start()
      try {
        let response = await this.$userService.acceptTerms(this.collaborator)
        const userStore = useUserStore()
        userStore.termAccepted(response)
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)

      this.$nextTick(() => {
        $('#closeBtn').click();
      });
    }
  }
}
</script>
