<template>
  <div class="" v-loader.opacity.tab="loading">
    <div class="title-box rounded">
      <h2 class="title">{{ $t('clinic.attendance') }}</h2>

      <button class="btn btn-outline-primary rounded" type="button" name="button" data-test="btnSave" @click="doUpdate">
        <i class="fa fa-plus"></i>
        {{ $t('save') }}
      </button>
      <button class="btn btn-outline-danger rounded" type="button" name="button" data-test="btnReset" @click="doReset">
        <i class="fa fa-times"></i>
        {{ $t('reset') }}
      </button>
      <button class="btn btn-outline-success rounded" type="button" name="button" data-test="btnDashboard" @click="dashboard">
        <i class="fa fa-tachometer"></i>
        Dashboard
      </button>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <div class="">
      <form class="">
        <div class="row">
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input class="form-control" type="text" id="name" ref="name" name="name" v-model="mutirao.name" data-test="inputMutiraoName" :placeholder="$t('mutirao.name')" autofocus>
              <label for="name">{{ $t('mutirao.name') }}*</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input class="form-control" type="text" id="patientTotal" name="patientTotal" v-model="mutirao.patientTotal" data-test="inputMutiraoPatient" :placeholder="$t('mutirao.totalPatients')">
              <label for="patientTotal">{{ $t('mutirao.totalPatients') }}</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input class="form-control" type="text" name="examTotal" id="examTotal" v-model="mutirao.examTotal" data-test="inputMutiraoExam" :placeholder="$t('mutirao.totalExams')">
              <label for="examTotal">{{ $t('mutirao.totalExams') }}</label>
            </div>
          </div>
        </div>

        <hr>

        <p class="label" :class="mutirao.active ? 'label-open' : 'label-danger'">
          {{ mutirao.active ? $t('active') : $t('inactive') }}
        </p>

        <div class="row">
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input class="form-control" type="text" name="referredExamCount" id="referredExamCount" v-model="mutirao.referredExamCount" data-test="inputMutiraoReferred" :placeholder="$t('mutirao.referredExamCount')" disabled>
              <label for="referredExamCount">{{ $t('mutirao.referredExamCount') }}</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input class="form-control" type="text" name="unreferredExamCount" id="unreferredExamCount" v-model="mutirao.unreferredExamCount" data-test="inputMutiraoUnreferred" :placeholder="$t('mutirao.unreferredExamCount')" disabled>
              <label for="unreferredExamCount">{{ $t('mutirao.unreferredExamCount') }}</label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-floating mb-3">
              <input class="form-control" type="text" name="examinedExamCount" id="examinedExamCount" v-model="mutirao.examinedCount" data-test="inputMutiraoExamined" :placeholder="$t('mutirao.examinedExamCount')" disabled>
              <label for="examinedExamCount">{{ $t('mutirao.examinedExamCount') }}</label>
            </div>
          </div>
        </div>
      </form>

      <hr>

      <!--
        <div class="import-box">
          <label for="">Importar pacientes</label>
          <div class="">
            <label for="patientCsvFile" class="btn btn-success">
              <i class="glyphicon glyphicon-upload"></i>
              Selecionar pacientes
            </label>
            <input id="patientCsvFile" type="file"/>
          </div>
          <button class="btn btn-success" type="button" name="button" @click="doImport">Importar</button>
        </div>
      -->

    </div>
  </div>
</template>

<style lang="scss" scoped>
.title-box {
  .title {
    max-width:                    unset;
  }
  button {
    display:                      inherit;
  }
}

p.label {
  margin-bottom:                  20px;
  display:                        inline-block;
  padding:                        0.3em 0.6em;
}

.import-box {
  > label {
    display:                      inline-block;
    font-size:                    10px;
    font-weight:                  400;
    text-transform:               uppercase;
    letter-spacing:               3px;
    width:                        190px;
    margin-bottom:                0;
    vertical-align:               middle;
  }
  div {
    margin-right:                 10px;
    display:                      inline-block;
  }
  input[type="file"] {
    display:                      none;
  }
  div label,
  button {
    margin:                       0;
  }
}

@media (max-width: 540px) {
  .title-box {
    text-align:                   center;
    button {
      display:                    inline-block;
    }
  }
}
</style>

<script>
export default {
  name: 'ClinicMutiraoTab',
  props: ['clinic'],
  components: {
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    mutirao: {
      name: "",
      examTotal: 0,
      patientTotal: 0,
      active: null,
      referredExamCount: 0,
      unreferredExamCount: 0,
      examinedExamCount: 0,
    },
  }),
  mounted() {
    this.doGet()
    this.$refs.name.focus()
  },
  methods: {
    doGet: async function() {
      let params = {
        clinic: this.clinic.id
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$mutiraoService.get(params);
        console.log(">>>>>> fetch data getMutirao >> ", response)

        this.mutirao = response.mutirao

      } catch (err) {
        this.alert = showAlert('request_error', 'alert-danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    doUpdate: async function() {
      let params = {
        name: this.mutirao.name,
        patientTotal: this.mutirao.patientTotal,
        examTotal: this.mutirao.examTotal,
        emailNotification: this.mutirao.emailNotification
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$mutiraoService.updateMutirao(params);
        console.log(">>>>>> fetch data updateMutirao >> ", response)
        // this.mutirao = response
        this.doGet();
      } catch (err) {
        this.alert = showAlert('request_error', 'alert-danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    doReset: async function() {
      let confirm = window.prompt("Para confirmar, informe a senha:")
      console.log(confirm)
      if (confirm != "phelcomtech")
        return

      let params = {
        mutirao: this.mutirao,
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$mutiraoService.resetMutirao(params);
        console.log(">>>>>> fetch data resetMutirao >> ", response)

        // this.mutirao = response
      } catch (err) {
        this.alert = showAlert('request_error', 'alert-danger')
      }
      NProgress.done(true)
      this.loading = false
      location.reload()
    },
    doImport: async function(e) {
      e.preventDefault()

      let fd = new FormData();
      let files = $('#patientCsvFile')[0].files[0];
      fd.append('data', files);

      this.loading = true
      NProgress.start()
      try {
        await this.$mutiraoService.importMutirao(fd);
      } catch (err) {
        this.alert = showAlert('request_error', 'alert-danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    dashboard() {
      this.$router.push({name: 'dashboard-mutirao'})
    }
  }
}
</script>
