<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4766 20.5C17.171 20.5 20.9766 16.6944 20.9766 12C20.9766 7.30558 17.171 3.5 12.4766 3.5C7.78214 3.5 3.97656 7.30558 3.97656 12C3.97656 16.6944 7.78214 20.5 12.4766 20.5ZM12.4766 22C17.9994 22 22.4766 17.5228 22.4766 12C22.4766 6.47715 17.9994 2 12.4766 2C6.95371 2 2.47656 6.47715 2.47656 12C2.47656 17.5228 6.95371 22 12.4766 22Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.22656 9C9.22656 7.20507 10.6816 5.75 12.4766 5.75C14.2715 5.75 15.7266 7.20507 15.7266 9V9.66201C15.7266 10.7786 15.0423 11.7815 14.009 12.1948C13.5371 12.3836 13.2266 12.8417 13.2266 13.3463V14C13.2266 14.4142 12.8908 14.75 12.4766 14.75C12.0623 14.75 11.7266 14.4142 11.7266 14V13.3463C11.7266 12.2247 12.4139 11.2173 13.4519 10.8021C13.9191 10.6152 14.2266 10.1616 14.2266 9.66201V9C14.2266 8.0335 13.4431 7.25 12.4766 7.25C11.5101 7.25 10.7266 8.0335 10.7266 9V9.5C10.7266 9.91421 10.3908 10.25 9.97656 10.25C9.56235 10.25 9.22656 9.91421 9.22656 9.5V9Z" fill="white"/>
    <path d="M13.4766 17C13.4766 17.5523 13.0288 18 12.4766 18C11.9243 18 11.4766 17.5523 11.4766 17C11.4766 16.4477 11.9243 16 12.4766 16C13.0288 16 13.4766 16.4477 13.4766 17Z" fill="white"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconHelp'
}
</script>
