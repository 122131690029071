<template>
  <div class="modal" id="modal_new_exam" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('exam.newExam') }}</h5>
          <button id="closeBtn" type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
        </div>
        <div class="modal-body">
          <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
            <span v-html="alert.message"></span>
            <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
          </div>

          <div class="form-group">
            <label for="examDate">{{ $t('exam.dateAndTime') }}</label>
            <date-picker
              menuClassName="dp-date-time"
              v-model="exam.date"
              :locale="locale"
              value-type="date"
              :selectText="$t('createClinic.select')"
              :cancelText="$t('cancel')"
              minimumView="time"
              :format="$t('dateTimeFormatFns')"
              :inputFormat="$t('dateTimeFormatFns')"
              :min-date="new Date()"
              data-test="datePickerNewExam">
            </date-picker>
          </div>

          <div class="form-group">
            <label for="mydriasis" class="">{{ $t('exam.mydriasis') }}</label>
            <input type="checkbox" id="mydriasis" v-model="exam.mydriasis" data-test="checkboxExamMydriasis">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" data-test="btnModalCancel" @click="close">{{ $t('cancel') }}</button>
          <button type="button" class="btn btn-primary" data-test="btnModalSave" @click="doSave">{{ $t('create') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  .modal-dialog {
    .modal-header {
      background-color:             $phelcom-light-blue;
    }
    .modal-body {
      .form-group {
        text-align:                 center;
      }
      input[type="checkbox"] {
        width:                      100%;
        max-width:                  210px;
      }
      label {
        color:                      unset;
        text-align:                 left;
      }
    }
  }
}

@media (min-width: 320px) {
  .form-group input[type="text"] {
    width:                          100% !important;
  }
}

@media (max-width: 991px) {
  .panel-content .modal-body label {
    text-align:                     center;
  }
}

@media (min-width: 992px) {
  .modal .modal-dialog .modal-body .dp__main.dp__theme_light {
    width:                          210px;
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import { enUS, ptBR, es } from 'date-fns/locale'

export default {
  name: 'ExamCreateNewModal',
  props: ['patient_id'],
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    exam: {
      date: new Date(),
      mydriasis: false
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
  }),
  computed: {
    locale() {
      return this.$utils.datePickerLocale()
    }
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
  },
  methods: {
    close() {
      this.$emit('close')
    },
    doSave: async function() {
      let params = {
        id: this.patient_id,
        exam: this.exam
      }

      NProgress.start()
      try {
        let response = await this.$patientService.saveExam(params)
        this.$message.popup(this, 'general.createdSuccess', 'operationSuccess', 'success')
        this.$nextTick(() => {
          $('#closeBtn').click();
        });
      } catch (err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
      }
      NProgress.done(true)
    },
  }
}
</script>
