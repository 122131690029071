<template>
  <div class="profile">
    <div class="flex-between" v-if="!isPaid">
      <p class="no-wrap">
        <span>{{ $t('clinic.clinic') }}</span>
        {{ session.currentClinic.name }}
      </p>
      <button class="btn-icon" type="button" name="button" data-test="btnChangeClinic" @click="openChangeOffcanvas">
        <IconChange/>
      </button>
    </div>

    <div class="flex-between">
      <p class="no-wrap">
        <span>{{ $t('user.user') }}</span>
        {{ fullName }}
      </p>

      <div class="flex-center">
        <!-- <button class="btn-icon btn-notif" :class="{'btn-spinner': isLoading}" type="button" name="button" id="notifications"
          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-test="btnNotification" @click="resetNotifications"> -->
        <button class="btn-icon btn-notif" :class="{'btn-spinner': isLoading}" type="button" name="button" id="notifications"
                data-test="btnNotification" @click="resetNotifications">
          <IconAlert/>
          <span id="notification-count" class="notification-count">{{ notificationCount }}</span>
        </button>
        <!-- <Notifications :notification="notification"></Notifications> -->

        <button class="btn-icon" type="button" name="button" data-test="btnLogout" @click="logout()">
          <IconLogout/>
        </button>
      </div>
    </div>

    <teleport to="body">
      <ClinicChangeOffcanvas :offcanvas_id="'ClinicChangeOffcanvas'" ref="clinicChangeOffcanvas"/>
      <NotificationsOffcanvas v-if="notification.length" :offcanvas_id="'NotificationsOffcanvas'" ref="notificationsOffcanvas" :notification="notification"/>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>

.profile {
  display:                            flex;
  flex-direction:                     row;
  height: 62px;
  > div {
    gap:                              10px;
    padding:                          10px 10px 10px 20px;
    width:                            auto;
    &:first-child {
      // width:                          auto;
      border-right:                   1px solid var(--Shades-20);
      border-left:                    1px solid var(--Shades-20);
    }
    &:last-child {
      // width: auto;
      // border-left: unset;
      border-right:                   1px solid var(--Shades-20);
    }
    p {
      color:                          var(--Shades-80);
      font-size:                      var(--font-size-14);
      font-weight:                    500;
      letter-spacing:                 0.7px;
      margin:                         0;
      span {
        color:                        var(--Shades-50);
        font-size:                    var(--font-size-10);
        letter-spacing:               1.5px;
        text-transform:               uppercase;
        display:                      block;
      }
    }
    .btn-icon {
      display:                        flex;
      align-items:                    center;
      justify-content:                center;
      width:                          30px;
      height:                         30px;
      padding:                        unset;
      background:                     var(--Shades-W);
      border:                         1px solid var(--Shades-30);

      svg {
        width:                        20px;
        height:                       20px;
      }
      // vertical-align:                 middle;
      &.btn-notif {
        position:                     relative;
        padding:                      unset;
        margin-right:                 10px;
        // svg {
        //   width:                      14px;
        // }
        .notification-count {
          @include                    border_radius(50%);
          color:                      white;
          font-size:                  0.8em;
          line-height:                normal;
          position:                   absolute;
          top:                        -2px;
          right:                      -5px;
          width:                      15px;
          height:                     15px;
          background-color:           #00b99f;
        }
      }
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import ClinicChangeOffcanvas from '../clinic/ClinicChangeOffcanvas.vue'
import NotificationsOffcanvas from './NotificationsOffcanvas.vue'
// import Notifications from './Notifications.vue'
import profile_img from "@/assets/images/phelcom/profile_img.png";

export default {
  name: 'MenuProfile',
  components: {
    ClinicChangeOffcanvas,
    NotificationsOffcanvas
  },
  data: () => ({
    profileAvatar: false,
    currentClinicID: null,
    key: null,
    session: {
      collaborator: {},
      currentClinic: {}
    },
    isLoading: false,
    fullName: null,
    isWebSocketConnected: false,
    notificationData: {
      count: 0,
      events: []
    },
    notificationCount: 0,
    notification: [],
    isPaid: false,
    showOffcanvas: false,
  }),
  computed: {
    avatar() {
      return this.session.userPhoto ? this.session.userPhoto : profile_img;
    }
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.fullName = `${this.session.collaborator.firstName} ${this.session.collaborator.lastName}`
    this.currentClinicID = this.session.currentClinic.id
    const uuid = this.session.currentClinic.uuid
    this.key = `clinic_${this.currentClinicID}_${uuid}`

    console.log('this.session', this.session);
    // this.listenNotificationEvents()

    // this.$nextTick(() => {
    //   this.reloadNotifications()
    // })

    this.emitter.on("profilePhotoChanged", imagePath => {
      this.session.userPhoto = imagePath
    })

    this.webSocketInitialization()

    this.$nextTick(() => {
      this.reloadNotifications()
    })

    // called from notificationService (logic is there)
    this.emitter.on("newNotification", event => {
      this.notification.push(event)
      this.notificationCount += 1 // view logic
    })

    // // called from notificationService (logic is there)
    // this.emitter.on("newNotification", event => {
    //   this.notification.push(event)
    //   this.notificationCount += 1 // view logic
    // })

  },
  methods: {
    profileMenu: async function() {
      this.session.activeLink = 'users'
      this.$router.push(`/user/${this.session.user.id}`)
    },
    async clinicDetails() {
      NProgress.start()
      try {
        let params = {
          id: this.session.currentClinic.id
        }

        let response = await this.$clinicService.getClinic(params);
        const freshClinic = response.clinic
        this.session.activeLink = 'clinic'

        let data = this.$utils.dataQuery({
          clinic: JSON.stringify(freshClinic)
        })

        this.$router.push({name: 'clinic-show', params: {clinicID: freshClinic.id}, query: {tab: 'data', data: data}})
      } catch (err) {
        console.log("unexpectedError", err)
      }
      NProgress.done(true)
    },

    webSocketInitialization() {
      // TODO REFACTOR duplicated code -> use event bus
      const io = this.$io
      this.isWebSocketConnected = io.socket.isConnected()

      if (this.isWebSocketConnected) {
        io.socket.disconnect()
        io.socket.reconnect()
      }

      io.socket.on('connect', () => {
        this.isWebSocketConnected = true
      })

      io.socket.on('disconnect', () => {
        this.isWebSocketConnected = false
      })
    },

    reloadNotifications() {
      if (!this.currentClinicID)
        return

      const clinicData = this.$notificationService.reloadNotifications(this.currentClinicID)
      for (let e of clinicData.events) {
        this.addNotification(e)
      }

      this.notificationCount = clinicData.count
    },

    // bell icon clicked
    async resetNotifications() {
      this.notificationCount = 0
      this.$notificationService.resetCount(this.currentClinicID)
      this.$notificationService.enable() // force notification activation

      const ok = await this.$refs.notificationsOffcanvas.show({})
      if (ok) {

      }
    },

    addNotification(data) {
      console.log('NOTIFICATION LENGHT', this.notification.length);
      try {
        this.notification.unshift(data)
      } catch (e) {
        console.log('error(addNotification)', e);
      }
    },

    notificationClicked: async function(examid) {
      try {
        let params = {
          id: examid,
        }

        let response = await this.$examService.get(params);

        let data = this.$utils.dataQuery({
          exam: JSON.stringify(response.exam)
        })

        this.$router.push({name: 'exam-show', params: {examID: examid}, query: {tab: 'images', data: data}})
      } catch(err) {
        this.alert = {
          message: `${this.$i18n.t('request_error')} <strong>${err}</strong>`,
          class: 'alert-danger'
        }
      }
    },

    async openChangeOffcanvas() {
        const ok = await this.$refs.clinicChangeOffcanvas.show({})
        if (ok) {

        }
    },

    async logout() {
      await this.$authService.logout(this)
      window.open('/', '_self');
    },
  }
}

</script>
