<template>
  <div class="" v-show="tabledata">
    <div class="table-responsive desk inside-box" ref="tableContainer" @scroll="handleScroll('table')">
      <table class="table vtable eyer2">
        <thead>
          <tr>
            <th v-show="!column.hide" :class="column.align" :width="column.width" v-for="(column, index) in tabledata.columns" v-html="column.name" :key="index"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tabledata.items" @click="selectRow(item)" :class="`tr_${item.id}`">
            <td :class="[column.align, column.type != undefined ? `${column.type}-line` : '']"
                v-for="(column, indexColumn) in tabledata.columns" :key="indexColumn">
              <slot v-if="column.type == 'custom'" :name="`column_${column.key}`" :item="item"/>
              <span v-if="column.type == 'date'">{{ formatDate(item, column.value) }}</span>
              <span v-if="column.type == undefined || column.type == 'string'" v-html="getValue(item, column.value)"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="vtable-mob mob inside-box-mob">
      <ul class="" ref="tableResponsiveContainer" @scroll="handleScroll('responsive')">
        <li class="inside-box-mob-item" v-for="(item, index) in tabledata.items" @click="selectRow(item)">
          <p :class="`${column.type}-line`" v-for="(column, indexColumn) in tabledata.columns" :key="indexColumn">
            <span v-html="column.name"></span>
            <slot v-if="column.type == 'custom'" :name="`column_${column.key}`" :item="item"/>
            <span v-if="column.type == 'date'">{{ formatDate(item, column.value) }}</span>
            <span v-if="column.type == undefined || column.type == 'string'" v-html="getValue(item, column.value)"></span>
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .mob {
    display:                        none;
  }

  .vtable-mob:not(.inside-box-mob) {
    ul {
      margin:                       0.5em 0;
      li {
        p {
          display:                  block;
          margin-bottom:            10px;
          // span:last-child:not(:first-child) {
          span:not(:first-child), button {
            float:                  right;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .desk {
      display:                      none;
    }
    .mob {
      display:                      block;
    }
    .vtable-mob .label {
      line-height:                  1.4;
    }
    .eyerweb {
      .vtable-mob {
        > ul > li p {
          &:first-child {
            margin-bottom:          25px;
          }
        }
      }
    }
  }
</style>

<script>
export default {
  name: 'BaseTable',
  props: ['tabledata'],
  data: () => ({
    dateFormat: "",
  }),
  mounted: function() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    this.dateFormat = this.$i18n.t('dateTimeFormat')
  },
  methods: {
    getValue(data, key) {
      return _.get(data, key)
    },
    formatDate(data, key) {
      let value = _.get(data, key)
      let result = moment(value).format(this.dateFormat);
      return result
    },
    checkColumn(column){
      // if (column !== 'createdAt' && column !== 'updatedAt' && column !== 'id') return true;
    },
    selectRow(item) {
      this.$emit('item-opened', item);
    },
    // @deprecated
    checkStatus(status) {
      if (status === 'Closed' || status === 'Fechado' || status === 'closed' || status === 'fechado')
        return 'label-closed';

      return 'label-open';
    },
    handleScroll(container) {
      const tableContainer = container == 'table' ? this.$refs.tableContainer : this.$refs.tableResponsiveContainer;
      const scrollTop = tableContainer.scrollTop;
      const scrollHeight = tableContainer.scrollHeight;
      const clientHeight = tableContainer.clientHeight;

      // Verifica se o final do contêiner está a 90% da altura do contêiner
      if (scrollHeight - scrollTop - clientHeight < clientHeight * 0.1) {
        // this.$emit('loadmore');
        this.debounce()
      }
    },
    debounce: _.debounce(function() {
      this.$emit('loadmore');
    }, 100, { 'maxWait': 100 }),
  },
  beforeDestroy() {
    this.$refs.scrollContainer.removeEventListener('scroll', this.onScroll);
  }
}
</script>
