<template>
  <li>
    <div class="tags-box">
      <span class="tag">{{recordDate}}</span>

      <div class="tags">
        <button class="no-btn no-print" type="button" name="button" v-if="hasEntries('s8')" data-test="btnGjs" @click="initializeGjs(record)">
          <i class="fa fa-file-text-o"></i>
          {{ $t('medicalRecord.report') }}
        </button>
      </div>
    </div>

    <div class="content-box">
      <div class="">
        <template v-if="hasEntries('s1')">
          <PatientMedicalRecordTextSection :section="'s1'" :content="getContent('s1')"></PatientMedicalRecordTextSection>
        </template>
        <template v-if="hasEntries('s2')">
          <PatientMedicalRecordTextSection :section="'s2'" :content="getContent('s2')"></PatientMedicalRecordTextSection>
        </template>

        <template v-if="hasFiles()">
          <PatientMedicalRecordFileSection :section="'s3'" :record="record" :data_path="data_path"></PatientMedicalRecordFileSection>
        </template>

        <template v-if="hasEntries('s4')">
          <PatientMedicalRecordTextSection :section="'s4'" :content="getContent('s4')"></PatientMedicalRecordTextSection>
        </template>

        <template v-if="hasEntries('s5')">
          <PatientMedicalRecordTextSection :section="'s5'" :content="getContent('s5')"></PatientMedicalRecordTextSection>
        </template>

        <template v-if="hasEntries('s6')">
          <PatientMedicalRecordTextSection :section="'s6'" :content="getContent('s6')"></PatientMedicalRecordTextSection>
        </template>

        <template v-if="hasEntries('s7')">
          <PatientMedicalRecordTextSection :section="'s7'" :content="getContent('s7')"></PatientMedicalRecordTextSection>
        </template>

      </div>

      <button class="btn btn-danger btn-sm no-print" type="button" name="button" data-title="Delete" data-test="btnRemove" @click="confirmMedicalRecordRemoval(record.id)" v-if="validDate">
        <i class="fa fa-trash-o"></i>
      </button>

      <button class="btn btn-info btn-sm no-print" :data-title="$t('editAction')" data-test="btnEdit" @click="editReport(record)" v-if="validDate">
        <i role="link" class="fa fa-pencil"></i>
      </button>
    </div>

    <div class="d-none" id="gjs"></div>


    <teleport to="body">
      <BaseGenericModal :modal_id="'modalPatientMedicalRecordGeneric'" ref="modalGeneric"></BaseGenericModal>

      <ReportGeneralPrintModal v-if="showModalPrint" @close="showModalPrint = false"
                          :content_print="contentPrint" :patient_name="patient_name"></ReportGeneralPrintModal>
    </teleport>
  </li>
</template>

<script>
import grapesjs from 'grapesjs'
import grapesjsPresetNewsletter from 'grapesjs-preset-newsletter'
import PatientMedicalRecordTextSection from './PatientMedicalRecordTextSection.vue'
import PatientMedicalRecordFileSection from './PatientMedicalRecordFileSection.vue'
import ReportGeneralPrintModal from '@/views/account/report/report-general/ReportGeneralPrintModal.vue'

let editor // BEWARE avoid adding proxy stuff into it (browser freezes!)

export default {
  name: 'PatientMedicalRecordEntry',
  props: ['record', 'patient_name', 'data_path'],
  components: {
    PatientMedicalRecordFileSection,
    PatientMedicalRecordTextSection,
    ReportGeneralPrintModal,
  },
  data: () => ({
    content: {},
    recordDate: "",
    validDate: false,
    showModalPrint: false,
    contentPrint: null,
  }),
  mounted() {
    this.content = this.record.content

    const DATE_FORMAT = this.$i18n.t('dateFormat')
    this.recordDate = moment.utc(this.record.date).format(DATE_FORMAT)

    this.checkValidDate(DATE_FORMAT)
  },
  methods: {
    hasEntries(sessionKey) {
      let target = this.content[sessionKey]
      if (!target)
        return false

      return Object.values(target).length > 0
    },
    hasFiles() {
      return this.record.recordData.length > 0
    },
    getContent(sessionKey) {
      return this.content[sessionKey]
    },
    async confirmMedicalRecordRemoval(medicalRecordID) {
      const ok = await this.$refs.modalGeneric.show({
        modalType: 'danger',
        title: this.$i18n.t('confirm'),
        text: this.$i18n.t('medicalRecord.confirmRecordRemoval'),
        okButton: this.$i18n.t('remove'),
        okButtonClass: 'btn-danger',
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })

      if (ok)
        this.doDelete(medicalRecordID)
    },
    async doDelete(medicalRecordID) {
      NProgress.start()
      try {
        await this.$medicalRecordService.remove(medicalRecordID);
        this.$message.popup(this, 'general.removedSuccess', 'operationSuccess', 'success')
        this.$emit('reloadRecords')
      } catch (err) {
        this.$message.popup(this, 'general.removedError', 'operationFail', 'error')
      }
      NProgress.done(true)
    },
    editReport(record) {
      this.$router.push({name: 'patient-medical-record',
          params: {
            patientID: record.patient,
            recordID: record.id
          }})
    },
    checkValidDate(dateFormat) {
      let today = moment().format(dateFormat)
      let recordDate = moment(this.recordDate, dateFormat)
      let validDate = moment(today, dateFormat).diff(moment(recordDate, dateFormat));

      if (validDate < 86400000) // 24 hours
        this.validDate = true
    },
    initializeGjs(record) {
      let _self = this
      editor = grapesjs.init({
        container: '#gjs',
        plugins: [grapesjsPresetNewsletter],
        fromElement: true,
        storageManager: false,
        panels: { defaults: [] },
      })

      editor.loadProjectData(record.content.s8);
      // TODO REFACTOR remove timeout when possible
      setTimeout(function() {
        _self.doPrint()
      }, 200);
    },
    doPrint() {
      this.contentPrint = editor.runCommand('gjs-get-inlined-html');

      this.showModalPrint = true;

      setTimeout(function() {
        let modal_print_report = document.getElementById('modal_print_report');
        new bootstrap.Modal(modal_print_report).show();
      }, 100);
    }
  }
}
</script>
