<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M1.77319 8.69518C2.62838 7.79864 3.82977 6.69861 5.25336 5.82527C6.67844 4.95103 8.29828 4.32092 10.0002 4.32092C11.7022 4.32092 13.3221 4.95103 14.7471 5.82527C16.1707 6.69861 17.3721 7.79864 18.2273 8.69518C18.9381 9.44035 18.9381 10.5598 18.2273 11.305C17.3721 12.2015 16.1707 13.3015 14.7471 14.1749C13.3221 15.0491 11.7022 15.6792 10.0002 15.6792C8.29828 15.6792 6.67844 15.0491 5.25336 14.1749C3.82977 13.3015 2.62838 12.2015 1.77319 11.305C1.0624 10.5598 1.0624 9.44035 1.77319 8.69518Z" stroke="black"/>
    <circle cx="10.0008" cy="10.0007" r="3.50471" stroke="black"/>
    <circle cx="8.7584" cy="8.80723" r="1.59678" stroke="black"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconEye'
}
</script>
